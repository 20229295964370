import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from '../utils/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum Active {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
}

export type AdminDashboardAnalytics = {
  __typename?: 'AdminDashboardAnalytics';
  placeUuid: Scalars['String'];
  institutionUuid: Scalars['String'];
  reactions?: Maybe<Scalars['String']>;
  subscribers?: Maybe<Scalars['String']>;
  followers?: Maybe<Scalars['String']>;
  reach?: Maybe<Scalars['String']>;
};

export type Audio = {
  __typename?: 'Audio';
  id: Scalars['ID'];
  links?: Maybe<Links>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  metadata?: Maybe<AudioMetadata>;
};

export type AudioInput = {
  id: Scalars['ID'];
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<AudioMetadataInput>;
};

export type AudioMetadata = {
  __typename?: 'AudioMetadata';
  voiceType?: Maybe<AudioVoiceType>;
};

export type AudioMetadataInput = {
  voiceType?: InputMaybe<AudioVoiceType>;
};

export enum AudioVoiceType {
  MaleGoogle = 'MALE_GOOGLE',
  FemaleGoogle = 'FEMALE_GOOGLE',
}

export type Branding = {
  __typename?: 'Branding';
  logo: Image;
  avatar: Image;
};

export type BrandingInput = {
  logo: ImageInput;
  avatar: ImageInput;
};

export type BrandingInputPlace = {
  avatar?: InputMaybe<ImageInput>;
  background?: InputMaybe<ImageInput>;
  logo?: InputMaybe<ImageInput>;
  topTenBackground?: InputMaybe<ImageInput>;
};

export type BrandingPlace = {
  __typename?: 'BrandingPlace';
  avatar?: Maybe<Image>;
  background?: Maybe<Image>;
  logo?: Maybe<Image>;
  topTenBackground?: Maybe<Image>;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  name: Scalars['String'];
  translations?: Maybe<CategoryTranslations>;
  nameTranslations?: Maybe<Array<Translation>>;
  images?: Maybe<CategoryImages>;
  categoryTypes: Array<CategoryType>;
  count?: Maybe<Scalars['Int']>;
  legacy?: Maybe<Scalars['Boolean']>;
  isSystem?: Maybe<Scalars['Boolean']>;
  customOrder?: Maybe<Scalars['Int']>;
};

export type CategoryImages = {
  __typename?: 'CategoryImages';
  svgIcon?: Maybe<Scalars['String']>;
};

export type CategoryImagesInput = {
  svgIcon?: InputMaybe<Scalars['String']>;
};

export type CategoryInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  nameTranslation?: InputMaybe<Array<TranslationInput>>;
  images?: InputMaybe<CategoryImagesInput>;
  categoryTypes: Array<CategoryType>;
  customOrder?: InputMaybe<Scalars['Int']>;
};

export type CategoryTranslations = {
  __typename?: 'CategoryTranslations';
  uk?: Maybe<Scalars['String']>;
};

export enum CategoryType {
  Event = 'EVENT',
  Announcement = 'ANNOUNCEMENT',
  Emergency = 'EMERGENCY',
  AppInterests = 'APP_INTERESTS',
  Poll = 'POLL',
  NonOfficialInstitution = 'NON_OFFICIAL_INSTITUTION',
  Raffle = 'RAFFLE',
  QuickQuestion = 'QUICK_QUESTION',
}

export type ChangePasswordInput = {
  accessToken: Scalars['String'];
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type ChatBotSettings = {
  __typename?: 'ChatBotSettings';
  topics?: Maybe<Array<ChatGptTopic>>;
  knowledgeBase?: Maybe<ChatGptKnowledgeBase>;
  institutions?: Maybe<Array<ChatGptInstitution>>;
};

export type ChatBotSettingsInput = {
  topics?: InputMaybe<Array<ChatGptTopic>>;
  knowledgeBase?: InputMaybe<ChatGptKnowledgeBase>;
  institutions?: InputMaybe<Array<ChatGptInstitutionInput>>;
};

export type ChatContextFeatureFlag = {
  __typename?: 'ChatContextFeatureFlag';
  landmarks?: Maybe<ChatContextTypeFeatureFlag>;
};

export type ChatContextFeatureFlagInput = {
  landmarks?: InputMaybe<ChatContextTypeFeatureFlagInput>;
};

export type ChatContextTypeFeatureFlag = {
  __typename?: 'ChatContextTypeFeatureFlag';
  main?: Maybe<Scalars['Boolean']>;
  map?: Maybe<Scalars['Boolean']>;
};

export type ChatContextTypeFeatureFlagInput = {
  main?: InputMaybe<Scalars['Boolean']>;
  map?: InputMaybe<Scalars['Boolean']>;
};

export type ChatGptInstitution = {
  __typename?: 'ChatGPTInstitution';
  type: ChatGptInstitutionType;
  id: Scalars['String'];
};

export type ChatGptInstitutionInput = {
  type: ChatGptInstitutionType;
  id: Scalars['String'];
};

export enum ChatGptInstitutionType {
  Museum = 'MUSEUM',
}

export enum ChatGptKnowledgeBase {
  Datafeed = 'DATAFEED',
  DatafeedPosts = 'DATAFEED_POSTS',
  DatafeedPostsOpenai = 'DATAFEED_POSTS_OPENAI',
}

export enum ChatGptTopic {
  History = 'HISTORY',
  Info = 'INFO',
  Payments = 'PAYMENTS',
  ReportAProblem = 'REPORT_A_PROBLEM',
}

export enum Component {
  Notifications = 'NOTIFICATIONS',
  Posts = 'POSTS',
}

export type ComponentAction = {
  __typename?: 'ComponentAction';
  component: Component;
  componentState: ComponentState;
};

export type ComponentActionInput = {
  component: Component;
  componentState: ComponentState;
};

export enum ComponentState {
  TurnOff = 'TURN_OFF',
  TurnOn = 'TURN_ON',
}

export type ConfigPath = {
  __typename?: 'ConfigPath';
  code: Scalars['String'];
  configPathType: ConfigPathType;
  jsonPath: Scalars['String'];
};

export type ConfigPathInput = {
  code: Scalars['String'];
  configPathType: ConfigPathType;
  jsonPath: Scalars['String'];
};

export enum ConfigPathType {
  Json = 'JSON',
}

export type Coupon = {
  __typename?: 'Coupon';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  publishedAt?: Maybe<Scalars['String']>;
  institution: Institution;
  /** @deprecated Replaced with 'institution' field */
  institutionUuid?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageObjects?: Maybe<Array<Image>>;
  validity: CouponValidity;
  publishmentState: State;
  scheduledAt?: Maybe<Scalars['String']>;
  notifyNow?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<Array<Scalars['String']>>;
  userActivities: PaginatedCouponUserActivities;
};

export type CouponUserActivitiesArgs = {
  filter?: InputMaybe<CouponActivityFilterInput>;
  orderBy?: InputMaybe<CouponActivitiesOrderByInput>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type CouponActivitiesOrderByInput = {
  by: CouponActivitiesSortBy;
  order: SortOrder;
};

export enum CouponActivitiesSortBy {
  CreatedAt = 'CREATED_AT',
}

export type CouponActivityFilterInput = {
  installationUuid?: InputMaybe<Scalars['ID']>;
};

export enum CouponUserAction {
  Activate = 'ACTIVATE',
}

export type CouponUserActivity = {
  __typename?: 'CouponUserActivity';
  createdAt: Scalars['String'];
  action: CouponUserAction;
  couponUuid: Scalars['ID'];
  installationUuid: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
};

export type CouponUserActivityInput = {
  action: CouponUserAction;
  couponUuid: Scalars['ID'];
  installationUuid: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
};

export type CouponValidity = {
  __typename?: 'CouponValidity';
  isInfinite?: Maybe<Scalars['Boolean']>;
  interval?: Maybe<IntervalValidity>;
  recurring?: Maybe<RecurringValidity>;
};

export type CouponsOrderByInput = {
  by: CouponsSortBy;
  order: SortOrder;
};

export enum CouponsSortBy {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
  PublishedAt = 'PUBLISHED_AT',
}

export type DashboardAnalyticsFilterInput = {
  placeUuid: Scalars['String'];
  institutionUuid: Scalars['String'];
};

export type DashboardAnalyticsTotalsDashboard = {
  __typename?: 'DashboardAnalyticsTotalsDashboard';
  placeUuid: Scalars['String'];
  institutionUuid: Scalars['String'];
  Followers?: Maybe<DashboardDiff>;
  Subscribers?: Maybe<DashboardDiff>;
  Reach?: Maybe<DashboardDiff>;
  Reactions?: Maybe<DashboardDiff>;
};

export type DashboardDiff = {
  __typename?: 'DashboardDiff';
  actual?: Maybe<Scalars['Int']>;
  diff?: Maybe<Scalars['Float']>;
};

export type DashboardImages = {
  __typename?: 'DashboardImages';
  forYou: Array<Image>;
  restaurants: Array<Image>;
  landmarks: Array<Image>;
  attractions: Array<Image>;
  events: Array<Image>;
  services: Array<Image>;
};

export type DashboardImagesInput = {
  forYou?: InputMaybe<Array<ImageInput>>;
  restaurants?: InputMaybe<Array<ImageInput>>;
  landmarks?: InputMaybe<Array<ImageInput>>;
  attractions?: InputMaybe<Array<ImageInput>>;
  events?: InputMaybe<Array<ImageInput>>;
  services?: InputMaybe<Array<ImageInput>>;
};

export type DelightedSettings = {
  __typename?: 'DelightedSettings';
  delightedID: Scalars['String'];
};

export type DelightedSettingsInput = {
  delightedID: Scalars['String'];
};

export type EmailTransmissionsResponse = {
  __typename?: 'EmailTransmissionsResponse';
  total_rejected_recipients?: Maybe<Scalars['Int']>;
  total_accepted_recipients?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
};

export type Entity = Institution | Place;

export enum EntityInput {
  Institution = 'INSTITUTION',
  Place = 'PLACE',
}

export enum EntryMood {
  Adventurous = 'ADVENTUROUS',
  Cultural = 'CULTURAL',
  Relaxed = 'RELAXED',
  Foodie = 'FOODIE',
  Romantic = 'ROMANTIC',
  FamilyFriendly = 'FAMILY_FRIENDLY',
  Educational = 'EDUCATIONAL',
  Nightlife = 'NIGHTLIFE',
  Wellness = 'WELLNESS',
  Offbeat = 'OFFBEAT',
  Sporty = 'SPORTY',
}

export enum FeatureFlagInternalType {
  AnyPayments = 'ANY_PAYMENTS',
  BuiltIn = 'BUILT_IN',
  Chatbot = 'CHATBOT',
  Default = 'DEFAULT',
  Delighted = 'DELIGHTED',
  Iframe = 'IFRAME',
  PlateChecker = 'PLATE_CHECKER',
  Reminder = 'REMINDER',
  VolunteeringMode = 'VOLUNTEERING_MODE',
  DisableSimplichat = 'DISABLE_SIMPLICHAT',
  PayParking = 'PAY_PARKING',
  TuiCityId = 'TUI_CITY_ID',
  TicketmasterEvents = 'TICKETMASTER_EVENTS',
  TheInfatuationRestaurants = 'THE_INFATUATION_RESTAURANTS',
  ChatContextEnabled = 'CHAT_CONTEXT_ENABLED',
  PayWall = 'PAY_WALL',
  ViatorDestination = 'VIATOR_DESTINATION',
  Itinerary = 'ITINERARY',
  PlanMyTrip = 'PLAN_MY_TRIP',
  CelebrityItineraries = 'CELEBRITY_ITINERARIES',
  Services = 'SERVICES',
}

export type FindScrapeEventsByTaskCodeInput = {
  limit?: InputMaybe<Scalars['Float']>;
  nextToken?: InputMaybe<Scalars['String']>;
  taskCode: Scalars['String'];
};

export type GeoPoint = {
  __typename?: 'GeoPoint';
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};

export type GeoPointInput = {
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID'];
  links?: Maybe<Links>;
  blurHash?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  imageVerification?: Maybe<ImageVerification>;
  metadata?: Maybe<ImageMetadata>;
};

export type ImageInput = {
  id: Scalars['ID'];
  blurHash?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<ImageMetadataInput>;
};

export type ImageMetadata = {
  __typename?: 'ImageMetadata';
  mimeType?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type ImageMetadataInput = {
  mimeType?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type ImageVerification = {
  __typename?: 'ImageVerification';
  status: ImageVerificationStatus;
  message?: Maybe<Scalars['String']>;
};

export enum ImageVerificationStatus {
  Ok = 'OK',
  Error = 'ERROR',
}

export type Institution = {
  __typename?: 'Institution';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  places?: Maybe<Array<Place>>;
  enabled?: Maybe<Scalars['String']>;
  isRecommended?: Maybe<Scalars['Boolean']>;
  visitorMode?: Maybe<Scalars['Boolean']>;
  isChatEnabled?: Maybe<Scalars['Boolean']>;
  inPlace?: Maybe<InstitutionInPlace>;
  inPlaces?: Maybe<Array<InstitutionInPlace>>;
  name: Scalars['String'];
  category: Category;
  countryCode?: Maybe<Scalars['String']>;
  branding?: Maybe<Branding>;
  info?: Maybe<InstitutionInfo>;
  lang?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  description?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  logo?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  webSite?: Maybe<Scalars['String']>;
  taskCodes?: Maybe<Array<Scalars['String']>>;
  coupons: PaginatedCoupons;
  newsCount?: Maybe<Scalars['Int']>;
  eventsCount?: Maybe<Scalars['Int']>;
  publishedNewsCount?: Maybe<Scalars['Int']>;
  publishedEventsCount?: Maybe<Scalars['Int']>;
  publishedPollNewsCount?: Maybe<Scalars['Int']>;
  unpublishedEventsCount?: Maybe<Scalars['Int']>;
  unpublishedNewsCount?: Maybe<Scalars['Int']>;
  unpublishedPollNewsCount?: Maybe<Scalars['Int']>;
  draftsEventsCount?: Maybe<Scalars['Int']>;
  draftsNewsCount?: Maybe<Scalars['Int']>;
  draftsPollNewsCount?: Maybe<Scalars['Int']>;
  scheduledNewsCount?: Maybe<Scalars['Int']>;
  scheduledEventsCount?: Maybe<Scalars['Int']>;
  scheduledPollNewsCount?: Maybe<Scalars['Int']>;
  publishedRaffleNewsCount?: Maybe<Scalars['Int']>;
  unpublishedRaffleNewsCount?: Maybe<Scalars['Int']>;
  draftsRaffleNewsCount?: Maybe<Scalars['Int']>;
  scheduledRaffleNewsCount?: Maybe<Scalars['Int']>;
  followersCount?: Maybe<Scalars['Int']>;
  isDisabledFromMap?: Maybe<Scalars['Boolean']>;
};

export type InstitutionInPlaceArgs = {
  placeUuid: Scalars['ID'];
};

export type InstitutionCouponsArgs = {
  orderBy?: InputMaybe<CouponsOrderByInput>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export enum InstitutionFeIconEnum {
  Bin = 'BIN',
  Car = 'CAR',
  Earth = 'EARTH',
  Form = 'FORM',
  Gps = 'GPS',
  PaperPlane = 'PAPER_PLANE',
  Payment = 'PAYMENT',
  Phone = 'PHONE',
}

export type InstitutionFeatureFlag = {
  __typename?: 'InstitutionFeatureFlag';
  type: InstitutionFeatureFlagEnum;
  title: Scalars['String'];
  icon?: Maybe<Image>;
  value: Scalars['String'];
  feIcon?: Maybe<InstitutionFeIconEnum>;
  benefitsEnabled?: Maybe<Scalars['Boolean']>;
};

export enum InstitutionFeatureFlagEnum {
  Email = 'EMAIL',
  Website = 'WEBSITE',
  Phone = 'PHONE',
  Address = 'ADDRESS',
  FeatureFlag = 'FEATURE_FLAG',
  DisableNer = 'DISABLE_NER',
}

export type InstitutionFeatureFlagInput = {
  type: InstitutionFeatureFlagEnum;
  title: Scalars['String'];
  icon?: InputMaybe<ImageInput>;
  value: Scalars['String'];
  feIcon?: InputMaybe<InstitutionFeIconEnum>;
  benefitsEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type InstitutionInPlace = {
  __typename?: 'InstitutionInPlace';
  placeId: Scalars['ID'];
  place: Place;
  role?: Maybe<InstitutionPlaceRole>;
};

export type InstitutionInPlaceInput = {
  placeId: Scalars['ID'];
  role?: InputMaybe<InstitutionPlaceRole>;
};

export type InstitutionInfo = {
  __typename?: 'InstitutionInfo';
  /** @deprecated Use Place->info->about instead. */
  aboutPlace?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use Place->info->reportsEmail instead. */
  reportsEmail?: Maybe<Scalars['String']>;
  featureFlags?: Maybe<Array<InstitutionFeatureFlag>>;
  socials?: Maybe<Array<SocialLink>>;
  /** @deprecated Replaced by featureFlags Array */
  website?: Maybe<Scalars['String']>;
  /** @deprecated Replaced by featureFlags Array */
  contactEmail?: Maybe<Scalars['String']>;
  /** @deprecated Replaced by featureFlags Array */
  contactPhone?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
};

export type InstitutionInfoInput = {
  description?: InputMaybe<Scalars['String']>;
  reportsEmail?: InputMaybe<Scalars['String']>;
  featureFlags?: InputMaybe<Array<InstitutionFeatureFlagInput>>;
  socials?: InputMaybe<Array<SocialLinkInput>>;
  location?: InputMaybe<LocationInput>;
};

export type InstitutionInput = {
  id?: InputMaybe<Scalars['ID']>;
  isRecommended?: InputMaybe<Scalars['Boolean']>;
  isChatEnabled?: InputMaybe<Scalars['Boolean']>;
  places?: InputMaybe<Array<InstitutionInPlaceInput>>;
  taskCodes?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  categoryUuid: Scalars['String'];
  logo?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  webSite: Scalars['String'];
  branding?: InputMaybe<BrandingInput>;
  info?: InputMaybe<InstitutionInfoInput>;
  lang?: InputMaybe<Scalars['String']>;
  isDisabledFromMap?: InputMaybe<Scalars['Boolean']>;
  visitorMode?: InputMaybe<Scalars['Boolean']>;
  countryCode?: InputMaybe<Scalars['String']>;
};

export enum InstitutionPlaceRole {
  City = 'CITY',
  ElectedOfficials = 'ELECTED_OFFICIALS',
  LocalBusiness = 'LOCAL_BUSINESS',
  Official = 'OFFICIAL',
  Religional = 'RELIGIONAL',
  Unions = 'UNIONS',
  Unofficial = 'UNOFFICIAL',
}

export enum InstitutionSortBy {
  FollowersCount = 'FOLLOWERS_COUNT',
  Name = 'NAME',
  Geo = 'GEO',
  All = 'ALL',
}

export type InstitutionsByFilterInput = {
  prefix?: InputMaybe<Scalars['String']>;
  placeUuid?: InputMaybe<Scalars['String']>;
  taskCode?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<InstitutionPlaceRole>;
};

export type InstitutionsBySearchFilterInput = {
  text?: InputMaybe<Scalars['String']>;
  placeUuid?: InputMaybe<Scalars['String']>;
};

export type InstitutionsOrderByInput = {
  by: InstitutionSortBy;
  order: SortOrder;
};

export type InstitutionsPlacesBySearchFilterInput = {
  text: Scalars['String'];
  entity?: InputMaybe<EntityInput>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  placeUuid?: InputMaybe<Scalars['String']>;
  parentPlaceUuid?: InputMaybe<Scalars['ID']>;
  geo?: InputMaybe<GeoPointInput>;
  roles?: InputMaybe<Array<InputMaybe<InstitutionPlaceRole>>>;
  onlyRecommended?: InputMaybe<Scalars['Boolean']>;
  visitorMode?: InputMaybe<Scalars['Boolean']>;
};

export type Integration = {
  __typename?: 'Integration';
  id: Scalars['ID'];
  institutionId: Scalars['ID'];
  type: IntegrationType;
  channelId: Scalars['String'];
  channelName: Scalars['String'];
  channelAvatar: Scalars['String'];
  channelAccessToken: Scalars['String'];
  channelAccessTokenSecret: Scalars['String'];
  channelAccessVerifier?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  response: Scalars['String'];
};

export type IntegrationInput = {
  institutionId: Scalars['ID'];
  type: IntegrationType;
  channelAccessToken: Scalars['String'];
  channelAccessVerifier?: InputMaybe<Scalars['String']>;
  channelId?: InputMaybe<Scalars['String']>;
  channelName?: InputMaybe<Scalars['String']>;
  channelAvatar?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
};

export enum IntegrationType {
  Facebook = 'FACEBOOK',
  Twitter = 'TWITTER',
}

export type IntervalValidity = {
  __typename?: 'IntervalValidity';
  fromDate: Scalars['String'];
  toDate?: Maybe<Scalars['String']>;
  fromTime?: Maybe<Scalars['String']>;
  toTime?: Maybe<Scalars['String']>;
};

export enum ItineraryCompany {
  Solo = 'SOLO',
  Family = 'FAMILY',
  Couple = 'COUPLE',
  Friends = 'FRIENDS',
}

export enum ItineraryCreationStatus {
  Creating = 'CREATING',
  Created = 'CREATED',
  Failed = 'FAILED',
  NotGenerated = 'NOT_GENERATED',
}

export enum ItineraryMode {
  Local = 'LOCAL',
  Tourist = 'TOURIST',
}

export type ItineraryOverview = {
  __typename?: 'ItineraryOverview';
  dayOfWeek: MainItineraryDay;
  company: ItineraryCompany;
  priceLevel: ItineraryPriceLevel;
  mode: ItineraryMode;
  creationStatus: ItineraryCreationStatus;
  date?: Maybe<Scalars['String']>;
  logGroupName?: Maybe<Scalars['String']>;
  logStreamName?: Maybe<Scalars['String']>;
  cloudWatchLogUrl?: Maybe<Scalars['String']>;
  failReason?: Maybe<Scalars['String']>;
  isRetriable?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type ItineraryPreferences = {
  __typename?: 'ItineraryPreferences';
  mode: ItineraryMode;
  company: ItineraryCompany;
  priceLevel: ItineraryPriceLevel;
};

export enum ItineraryPriceLevel {
  Free = 'FREE',
  Cheap = 'CHEAP',
  Moderate = 'MODERATE',
  Expensive = 'EXPENSIVE',
  VeryExpensive = 'VERY_EXPENSIVE',
}

export type Landmark = {
  __typename?: 'Landmark';
  typename?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  additionalType?: Maybe<Array<LandmarkAdditionalType>>;
  googlePlacesId?: Maybe<Scalars['String']>;
  gpsLocation?: Maybe<GeoPoint>;
  imageObjects?: Maybe<Array<Image>>;
  imageUrl?: Maybe<Scalars['String']>;
  initials: LandmarkInitials;
  isHiddenGem?: Maybe<Scalars['Boolean']>;
  /** @deprecated Replaced with title */
  landmarkName?: Maybe<Scalars['String']>;
  placeUuid: Scalars['ID'];
  poiType: PoiType;
  primaryType?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  types?: Maybe<Array<Scalars['String']>>;
  userRatingCount?: Maybe<Scalars['Int']>;
  videoUrl?: Maybe<Scalars['String']>;
  audioObjects?: Maybe<Array<Audio>>;
  description?: Maybe<Scalars['String']>;
};

export enum LandmarkAdditionalType {
  MustSee = 'MUST_SEE',
  Museum = 'MUSEUM',
  HiddenGem = 'HIDDEN_GEM',
  PhotoWorthy = 'PHOTO_WORTHY',
}

export type LandmarkInitials = {
  __typename?: 'LandmarkInitials';
  question: Scalars['String'];
  answer: Scalars['String'];
  followups: Array<Scalars['String']>;
};

export type LandmarkInitialsInput = {
  question: Scalars['String'];
  answer: Scalars['String'];
  followups: Array<Scalars['String']>;
};

export type LandmarkInput = {
  typename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  additionalType?: InputMaybe<Array<LandmarkAdditionalType>>;
  googlePlacesId?: InputMaybe<Scalars['String']>;
  gpsLocation?: InputMaybe<GeoPointInput>;
  imageObjects?: InputMaybe<Array<ImageInput>>;
  imageUrl?: InputMaybe<Scalars['String']>;
  initials?: InputMaybe<LandmarkInitialsInput>;
  isHiddenGem?: InputMaybe<Scalars['Boolean']>;
  placeUuid?: InputMaybe<Scalars['ID']>;
  poiType?: InputMaybe<PoiType>;
  primaryType?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<Scalars['String']>>;
  userRatingCount?: InputMaybe<Scalars['Int']>;
  videoUrl?: InputMaybe<Scalars['String']>;
  audioObjects?: InputMaybe<Array<AudioInput>>;
  description?: InputMaybe<Scalars['String']>;
};

export type Links = {
  __typename?: 'Links';
  self: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  thumbnail1024x576?: Maybe<Scalars['String']>;
  thumbnail420x420?: Maybe<Scalars['String']>;
};

export type Location = {
  __typename?: 'Location';
  address: Scalars['String'];
  geo: GeoPoint;
};

export type LocationInput = {
  address: Scalars['String'];
  geo: GeoPointInput;
};

export enum MainItineraryDay {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

export enum MainItineraryEntryType {
  Event = 'EVENT',
  Restaurant = 'RESTAURANT',
  Landmark = 'LANDMARK',
  Tour = 'TOUR',
  Bar = 'BAR',
}

export enum MainItineraryTopic {
  Solo = 'SOLO',
  Family = 'FAMILY',
  Newbie = 'NEWBIE',
  Romantic = 'ROMANTIC',
  Adventure = 'ADVENTURE',
  CulturalDive = 'CULTURAL_DIVE',
  Nature = 'NATURE',
  Foodie = 'FOODIE',
}

export type MapLimits = {
  __typename?: 'MapLimits';
  announcementRangeInHours?: Maybe<Scalars['Int']>;
  eventRangeInHours?: Maybe<Scalars['Int']>;
  centerMapToCityIfDistanceLessThanKm?: Maybe<Scalars['Float']>;
};

export type MapLimitsInput = {
  announcementRangeInHours?: InputMaybe<Scalars['Int']>;
  eventRangeInHours?: InputMaybe<Scalars['Int']>;
  centerMapToCityIfDistanceLessThanKm?: InputMaybe<Scalars['Float']>;
};

export type Message = {
  __typename?: 'Message';
  message: Scalars['String'];
};

export type MessagingCategoriesByFilterInput = {
  placeUuid: Scalars['String'];
};

export type MessagingCategory = {
  __typename?: 'MessagingCategory';
  additionalEmails?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  placeUuid: Scalars['String'];
};

export type MessagingCategoryInput = {
  additionalEmails?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  name: Scalars['String'];
  placeUuid: Scalars['String'];
};

export type MessagingProblem = {
  __typename?: 'MessagingProblem';
  address?: Maybe<Scalars['String']>;
  categoryUuid?: Maybe<Scalars['String']>;
  connector?: Maybe<MessagingProblemConnector>;
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  geoPoint?: Maybe<GeoPoint>;
  id: Scalars['String'];
  imageObjects?: Maybe<Array<Image>>;
  installationUuid: Scalars['String'];
  lane?: Maybe<MessagingProblemLane>;
  placeUuid: Scalars['String'];
  reporter?: Maybe<Scalars['String']>;
  resolution?: Maybe<Scalars['String']>;
  status: MessagingProblemStatus;
  videoObjects?: Maybe<Array<Video>>;
  solvedAt?: Maybe<Scalars['String']>;
  commentFromAuthority?: Maybe<Scalars['String']>;
  commentAuthorityId?: Maybe<Scalars['String']>;
  cityIsNotCompetent?: Maybe<Scalars['Boolean']>;
  cityIsNotCompetentTimestamp?: Maybe<Scalars['String']>;
};

export type MessagingProblemConnector = {
  __typename?: 'MessagingProblemConnector';
  id?: Maybe<Scalars['String']>;
  status: MessagingProblemConnectorStatus;
  statusMessage?: Maybe<Scalars['String']>;
  type: MessagingProblemConnectorType;
};

export type MessagingProblemConnectorInput = {
  id?: InputMaybe<Scalars['String']>;
  status: MessagingProblemConnectorStatus;
  statusMessage?: InputMaybe<Scalars['String']>;
  type: MessagingProblemConnectorType;
};

export enum MessagingProblemConnectorStatus {
  InputError = 'INPUT_ERROR',
  Rejected = 'REJECTED',
  Solved = 'SOLVED',
  Uploaded = 'UPLOADED',
  UploadError = 'UPLOAD_ERROR',
  WaitingForUpload = 'WAITING_FOR_UPLOAD',
}

export enum MessagingProblemConnectorType {
  IndianHeadPark = 'INDIAN_HEAD_PARK',
  NycPotholes = 'NYC_POTHOLES',
}

export type MessagingProblemInput = {
  address?: InputMaybe<Scalars['String']>;
  categoryUuid?: InputMaybe<Scalars['String']>;
  connector?: InputMaybe<MessagingProblemConnectorInput>;
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  geoPoint?: InputMaybe<GeoPointInput>;
  imageObjects?: InputMaybe<Array<ImageInput>>;
  installationUuid: Scalars['String'];
  lane?: InputMaybe<MessagingProblemLane>;
  placeUuid: Scalars['String'];
  reporter?: InputMaybe<Scalars['String']>;
  resolution?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MessagingProblemStatus>;
  videoObjects?: InputMaybe<Array<VideoInput>>;
  solvedAt?: InputMaybe<Scalars['String']>;
  commentFromAuthority?: InputMaybe<Scalars['String']>;
  commentAuthorityId?: InputMaybe<Scalars['String']>;
  cityIsNotCompetent?: InputMaybe<Scalars['Boolean']>;
  cityIsNotCompetentTimestamp?: InputMaybe<Scalars['String']>;
};

export enum MessagingProblemLane {
  BikeLane = 'BIKE_LANE',
  Crosswalk = 'CROSSWALK',
  DrivingLane = 'DRIVING_LANE',
  ParkingLane = 'PARKING_LANE',
}

export enum MessagingProblemStatus {
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  Rejected = 'REJECTED',
  Solved = 'SOLVED',
}

export type MessagingProblemsFilterInput = {
  placeUuid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MessagingProblemStatus>;
};

export type MessagingProblemsOrderByInput = {
  by: MessagingProblemsSortBy;
  order: SortOrder;
};

export enum MessagingProblemsSortBy {
  CreatedAt = 'CREATED_AT',
}

export type Mutation = {
  __typename?: 'Mutation';
  addIntegration: Integration;
  deleteIntegration?: Maybe<Integration>;
  updateIntegration?: Maybe<Integration>;
  getTwitterRequestToken: TwitterRequestTokenResponse;
  addInstitution: Institution;
  updateInstitution: Institution;
  hideInstitution?: Maybe<Institution>;
  putInstitutionPlaces?: Maybe<Institution>;
  setInstitutionInfo?: Maybe<Institution>;
  createLandmark?: Maybe<Landmark>;
  updateLandmark?: Maybe<Landmark>;
  removeLandmark?: Maybe<Scalars['String']>;
  addMessagingCategory: MessagingCategory;
  deleteMessagingCategory?: Maybe<MessagingCategory>;
  updateMessagingCategory?: Maybe<MessagingCategory>;
  addMessagingProblem?: Maybe<Scalars['String']>;
  deleteMessagingProblem: Scalars['Int'];
  updateMessagingProblem: Scalars['Int'];
  sendNewsletter?: Maybe<EmailTransmissionsResponse>;
  visitorPromoNotification: Scalars['String'];
  addPlace: Place;
  deletePlace?: Maybe<Place>;
  hidePlace?: Maybe<Place>;
  setDashboardImages?: Maybe<Place>;
  setPlaceBranding?: Maybe<Place>;
  setMapIntroImage?: Maybe<Place>;
  setPlaceInfo?: Maybe<Place>;
  updatePlace: Place;
  updatePlaceFeatureFlags: Place;
  createRestaurant?: Maybe<Restaurant>;
  updateRestaurant?: Maybe<Restaurant>;
  deleteRestaurant?: Maybe<SuccessMessage>;
  addScrapeSelector?: Maybe<ScrapeSelector>;
  deleteScrapeSelector?: Maybe<ScrapeSelector>;
  allEnvAddScrapeTask?: Maybe<ScrapeTask>;
  allEnvDeleteScrapeTask?: Maybe<ScrapeTask>;
  runScrapeTaskByCode?: Maybe<Scalars['String']>;
  updateSystemStatus: Scalars['String'];
  changePassword: Scalars['String'];
  deleteScrapeEventsByCode?: Maybe<Message>;
  deleteScrapeNewsByCode?: Maybe<Message>;
};

export type MutationAddIntegrationArgs = {
  integration: IntegrationInput;
};

export type MutationDeleteIntegrationArgs = {
  id: Scalars['String'];
};

export type MutationUpdateIntegrationArgs = {
  id: Scalars['String'];
  integration: IntegrationInput;
};

export type MutationGetTwitterRequestTokenArgs = {
  callbackUrl: Scalars['String'];
};

export type MutationAddInstitutionArgs = {
  institution: InstitutionInput;
};

export type MutationUpdateInstitutionArgs = {
  id: Scalars['ID'];
  institution: InstitutionInput;
};

export type MutationHideInstitutionArgs = {
  id: Scalars['ID'];
};

export type MutationPutInstitutionPlacesArgs = {
  id: Scalars['ID'];
  inPlaces: Array<InstitutionInPlaceInput>;
};

export type MutationSetInstitutionInfoArgs = {
  id: Scalars['ID'];
  info: InstitutionInfoInput;
};

export type MutationCreateLandmarkArgs = {
  landmark: LandmarkInput;
};

export type MutationUpdateLandmarkArgs = {
  id: Scalars['String'];
  landmark: LandmarkInput;
};

export type MutationRemoveLandmarkArgs = {
  id: Scalars['String'];
};

export type MutationAddMessagingCategoryArgs = {
  data: MessagingCategoryInput;
};

export type MutationDeleteMessagingCategoryArgs = {
  id: Scalars['String'];
};

export type MutationUpdateMessagingCategoryArgs = {
  data: MessagingCategoryInput;
  id: Scalars['String'];
};

export type MutationAddMessagingProblemArgs = {
  data: MessagingProblemInput;
};

export type MutationDeleteMessagingProblemArgs = {
  id: Scalars['String'];
};

export type MutationUpdateMessagingProblemArgs = {
  data: MessagingProblemInput;
  id: Scalars['String'];
};

export type MutationSendNewsletterArgs = {
  input: SendNewsletterInput;
};

export type MutationVisitorPromoNotificationArgs = {
  data: VisitorPromoNotificationInput;
  placeID: Scalars['String'];
};

export type MutationAddPlaceArgs = {
  place: PlaceInput;
};

export type MutationDeletePlaceArgs = {
  id: Scalars['String'];
};

export type MutationHidePlaceArgs = {
  id: Scalars['String'];
};

export type MutationSetDashboardImagesArgs = {
  id: Scalars['String'];
  dashboardImages: DashboardImagesInput;
};

export type MutationSetPlaceBrandingArgs = {
  branding: BrandingInputPlace;
  id: Scalars['String'];
};

export type MutationSetMapIntroImageArgs = {
  id: Scalars['String'];
  image: ImageInput;
};

export type MutationSetPlaceInfoArgs = {
  id: Scalars['String'];
  info: PlaceInfoInput;
};

export type MutationUpdatePlaceArgs = {
  id: Scalars['String'];
  place: PlaceInput;
};

export type MutationUpdatePlaceFeatureFlagsArgs = {
  id: Scalars['String'];
  featureFlags?: InputMaybe<Array<PlaceFeatureFlagInput>>;
};

export type MutationCreateRestaurantArgs = {
  restaurant: RestaurantInput;
};

export type MutationUpdateRestaurantArgs = {
  id: Scalars['String'];
  restaurant: RestaurantInput;
};

export type MutationDeleteRestaurantArgs = {
  id: Scalars['String'];
};

export type MutationAddScrapeSelectorArgs = {
  selector: ScrapeSelectorInput;
};

export type MutationDeleteScrapeSelectorArgs = {
  code: Scalars['String'];
};

export type MutationAllEnvAddScrapeTaskArgs = {
  task: ScrapeTaskInput;
};

export type MutationAllEnvDeleteScrapeTaskArgs = {
  code: Scalars['String'];
};

export type MutationRunScrapeTaskByCodeArgs = {
  code: Scalars['String'];
};

export type MutationUpdateSystemStatusArgs = {
  data: Array<ComponentActionInput>;
};

export type MutationChangePasswordArgs = {
  data: ChangePasswordInput;
};

export type MutationDeleteScrapeEventsByCodeArgs = {
  code: Scalars['String'];
};

export type MutationDeleteScrapeNewsByCodeArgs = {
  code: Scalars['String'];
};

export type PaginatedComponentStates = {
  __typename?: 'PaginatedComponentStates';
  componentActions: Array<ComponentAction>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedCouponUserActivities = {
  __typename?: 'PaginatedCouponUserActivities';
  nextToken?: Maybe<Scalars['String']>;
  activities: Array<CouponUserActivity>;
  activationsCount?: Maybe<Scalars['Int']>;
};

export type PaginatedCoupons = {
  __typename?: 'PaginatedCoupons';
  nextToken?: Maybe<Scalars['String']>;
  coupons: Array<Coupon>;
  count: Scalars['Int'];
};

export type PaginatedEntities = {
  __typename?: 'PaginatedEntities';
  place: Place;
  totalCount: Scalars['Int'];
  entities: Array<Entity>;
  /** @deprecated use pageOffset and pageSize */
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedInstitutions = {
  __typename?: 'PaginatedInstitutions';
  place: Place;
  institutions: Array<Institution>;
  /** @deprecated use pageOffset and pageSize */
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedIntegrations = {
  __typename?: 'PaginatedIntegrations';
  integrations: Array<Integration>;
  nextToken?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
};

export type PaginatedItineraries = {
  __typename?: 'PaginatedItineraries';
  itineraries: Array<ItineraryOverview>;
  nextToken?: Maybe<Scalars['String']>;
  placeUuid: Scalars['ID'];
};

export type PaginatedLandmarks = {
  __typename?: 'PaginatedLandmarks';
  landmarks: Array<Landmark>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedMessagingCategories = {
  __typename?: 'PaginatedMessagingCategories';
  messagingCategories: Array<MessagingCategory>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedMessagingProblems = {
  __typename?: 'PaginatedMessagingProblems';
  messagingProblems: Array<MessagingProblem>;
  totalCount: Scalars['Int'];
};

export type PaginatedPlaces = {
  __typename?: 'PaginatedPlaces';
  nextToken?: Maybe<Scalars['String']>;
  places: Array<Place>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatedRestaurants = {
  __typename?: 'PaginatedRestaurants';
  restaurants: Array<Restaurant>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatedScrapeEvents = {
  __typename?: 'PaginatedScrapeEvents';
  events: Array<ScrapeEvent>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedScrapeSelectors = {
  __typename?: 'PaginatedScrapeSelectors';
  selectors: Array<ScrapeSelector>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedScrapeTasks = {
  __typename?: 'PaginatedScrapeTasks';
  tasks: Array<ScrapeTask>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedTemplates = {
  __typename?: 'PaginatedTemplates';
  items?: Maybe<Array<Maybe<Template>>>;
  itemsCount?: Maybe<Scalars['Int']>;
};

export enum PaginationType {
  Pages = 'PAGES',
  LoadButton = 'LOAD_BUTTON',
  InfiniteScroll = 'INFINITE_SCROLL',
}

export type PayParkingSettings = {
  __typename?: 'PayParkingSettings';
  connectorTypes?: Maybe<Array<Scalars['String']>>;
};

export type PayParkingSettingsInput = {
  connectorTypes?: InputMaybe<Array<Scalars['String']>>;
};

export enum PaymentReceiver {
  CityOfPaloAlto = 'CITY_OF_PALO_ALTO',
  CityOfSanJose = 'CITY_OF_SAN_JOSE',
  Nyc = 'NYC',
  Sfmta = 'SFMTA',
  DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
}

export type Place = {
  __typename?: 'Place';
  additionalGroupsOfInstitutions?: Maybe<Array<InstitutionPlaceRole>>;
  borough?: Maybe<Scalars['String']>;
  dashboardImages?: Maybe<DashboardImages>;
  mapIntroImage?: Maybe<Image>;
  branding?: Maybe<BrandingPlace>;
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
  createdAt: Scalars['String'];
  defaultLanguage?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['String']>;
  featureFlags?: Maybe<Array<PlaceFeatureFlag>>;
  features?: Maybe<PlaceFeatures>;
  gps: GeoPoint;
  hasDistricts?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  info?: Maybe<PlaceInfo>;
  isDistrict?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  noNotificationsFromScrapers?: Maybe<Scalars['Boolean']>;
  parentPlaceUuid?: Maybe<Scalars['String']>;
  sharingUrl?: Maybe<Scalars['String']>;
  stateCode: Scalars['String'];
  stateName: Scalars['String'];
  timezoneCode: Scalars['String'];
  translateTo?: Maybe<Array<Scalars['String']>>;
  weather?: Maybe<Weather>;
  mapLimits?: Maybe<MapLimits>;
  visitorWelcomeVideoUrl?: Maybe<Scalars['String']>;
  isVisitorModeAvailable?: Maybe<Scalars['Boolean']>;
  isItineraryModeAvailable?: Maybe<Scalars['Boolean']>;
  isResidentModeAvailable?: Maybe<Scalars['Boolean']>;
  isOgCity?: Maybe<Scalars['Boolean']>;
  disabledContent?: Maybe<Array<PlaceContent>>;
  localCuisines?: Maybe<Array<RestaurantCuisine>>;
  sectionImages?: Maybe<SectionImages>;
  badgesImage?: Maybe<Image>;
  simCardLink?: Maybe<Scalars['String']>;
  headliner?: Maybe<Scalars['Boolean']>;
  welcomeScreenImage?: Maybe<Image>;
  popularDestination?: Maybe<Scalars['Boolean']>;
  trafficAvgSpeed?: Maybe<Scalars['Int']>;
};

export type PlaceFeatureFlagsArgs = {
  visitorMode?: InputMaybe<Scalars['Boolean']>;
};

export enum PlaceContent {
  Attractions = 'ATTRACTIONS',
  Events = 'EVENTS',
}

export type PlaceFeatureFlag = {
  __typename?: 'PlaceFeatureFlag';
  allowedPaymentReceivers?: Maybe<Array<PaymentReceiver>>;
  chatBotSettings?: Maybe<ChatBotSettings>;
  delightedSettings?: Maybe<DelightedSettings>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Image>;
  /** @deprecated Replaced with reportedProblemsSettings */
  messagingProblemCategoryUuid?: Maybe<Scalars['String']>;
  /** @deprecated Replaced with reportedProblemsSettings */
  messagingProblemIsLaneAvailable?: Maybe<Scalars['Boolean']>;
  reportedProblemsSettings?: Maybe<ReportedProblemsSettings>;
  order?: Maybe<Scalars['Float']>;
  reminderSettings?: Maybe<ReminderSettings>;
  title?: Maybe<Scalars['String']>;
  titleLocalizations?: Maybe<Array<TitleLocalization>>;
  type?: Maybe<FeatureFlagInternalType>;
  url?: Maybe<Scalars['String']>;
  plateCheckerSettings?: Maybe<PlateCheckerSettings>;
  isFullnameRequired?: Maybe<Scalars['Boolean']>;
  disableProblemsOnMap?: Maybe<Scalars['Boolean']>;
  payParkingSettings?: Maybe<PayParkingSettings>;
  visitorMode?: Maybe<Scalars['Boolean']>;
  tuiCityId?: Maybe<Scalars['Int']>;
  tuiCountryCode?: Maybe<Scalars['String']>;
  tuiIsSandbox?: Maybe<Scalars['Boolean']>;
  ticketmasterSettings?: Maybe<TicketmasterSettings>;
  theInfatuationPlaceId?: Maybe<Scalars['String']>;
  chatContextEnabled?: Maybe<ChatContextFeatureFlag>;
  isSystem?: Maybe<Scalars['Boolean']>;
  viatorDestination?: Maybe<Scalars['String']>;
};

export type PlaceFeatureFlagInput = {
  allowedPaymentReceivers?: InputMaybe<Array<PaymentReceiver>>;
  chatBotSettings?: InputMaybe<ChatBotSettingsInput>;
  delightedSettings?: InputMaybe<DelightedSettingsInput>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  icon?: InputMaybe<ImageInput>;
  reportedProblemsSettings?: InputMaybe<ReportedProblemsSettingsInput>;
  order?: InputMaybe<Scalars['Float']>;
  reminderSettings?: InputMaybe<ReminderSettingsInput>;
  title?: InputMaybe<Scalars['String']>;
  titleLocalizations?: InputMaybe<Array<TitleLocalizationInput>>;
  type?: InputMaybe<FeatureFlagInternalType>;
  url?: InputMaybe<Scalars['String']>;
  plateCheckerSettings?: InputMaybe<PlateCheckerSettingsInput>;
  isFullnameRequired?: InputMaybe<Scalars['Boolean']>;
  disableProblemsOnMap?: InputMaybe<Scalars['Boolean']>;
  payParkingSettings?: InputMaybe<PayParkingSettingsInput>;
  visitorMode?: InputMaybe<Scalars['Boolean']>;
  tuiCityId?: InputMaybe<Scalars['Int']>;
  tuiCountryCode?: InputMaybe<Scalars['String']>;
  tuiIsSandbox?: InputMaybe<Scalars['Boolean']>;
  ticketmasterSettings?: InputMaybe<TicketmasterSettingsInput>;
  theInfatuationPlaceId?: InputMaybe<Scalars['String']>;
  chatContextEnabled?: InputMaybe<ChatContextFeatureFlagInput>;
  isSystem?: InputMaybe<Scalars['Boolean']>;
  viatorDestination?: InputMaybe<Scalars['String']>;
};

export type PlaceFeatures = {
  __typename?: 'PlaceFeatures';
  enableCommunityFund?: Maybe<Scalars['Boolean']>;
  enableCoupon?: Maybe<Scalars['Boolean']>;
  enableMessaging?: Maybe<Scalars['Boolean']>;
  enablePetDirectory?: Maybe<Scalars['Boolean']>;
  enableRaffle?: Maybe<Scalars['Boolean']>;
  raffleUrl?: Maybe<Scalars['String']>;
};

export type PlaceFeaturesInput = {
  enableCommunityFund?: InputMaybe<Scalars['Boolean']>;
  enableCoupon?: InputMaybe<Scalars['Boolean']>;
  enableMessaging?: InputMaybe<Scalars['Boolean']>;
  enablePetDirectory?: InputMaybe<Scalars['Boolean']>;
  enableRaffle?: InputMaybe<Scalars['Boolean']>;
  raffleUrl?: InputMaybe<Scalars['String']>;
};

export type PlaceInfo = {
  __typename?: 'PlaceInfo';
  about?: Maybe<Scalars['String']>;
  aboutHeader?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  reportsEmail?: Maybe<Scalars['String']>;
};

export type PlaceInfoInput = {
  about?: InputMaybe<Scalars['String']>;
  aboutHeader?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  reportsEmail?: InputMaybe<Scalars['String']>;
};

export type PlaceInput = {
  additionalGroupsOfInstitutions?: InputMaybe<Array<InstitutionPlaceRole>>;
  borough?: InputMaybe<Scalars['String']>;
  branding?: InputMaybe<BrandingInputPlace>;
  dashboardImages?: InputMaybe<DashboardImagesInput>;
  mapIntroImage?: InputMaybe<ImageInput>;
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
  defaultLanguage?: InputMaybe<Scalars['String']>;
  featureFlags?: InputMaybe<Array<PlaceFeatureFlagInput>>;
  features?: InputMaybe<PlaceFeaturesInput>;
  gps: GeoPointInput;
  hasDistricts?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<PlaceInfoInput>;
  name: Scalars['String'];
  noNotificationsFromScrapers?: InputMaybe<Scalars['Boolean']>;
  parentPlaceUuid?: InputMaybe<Scalars['String']>;
  sharingUrl?: InputMaybe<Scalars['String']>;
  stateCode: Scalars['String'];
  stateName: Scalars['String'];
  timezoneCode: Scalars['String'];
  translateTo?: InputMaybe<Array<Scalars['String']>>;
  mapLimits?: InputMaybe<MapLimitsInput>;
  isVisitorModeAvailable?: InputMaybe<Scalars['Boolean']>;
  isItineraryModeAvailable?: InputMaybe<Scalars['Boolean']>;
  isResidentModeAvailable?: InputMaybe<Scalars['Boolean']>;
  isOgCity?: InputMaybe<Scalars['Boolean']>;
  visitorWelcomeVideoUrl?: InputMaybe<Scalars['String']>;
  disabledContent?: InputMaybe<Array<PlaceContent>>;
  localCuisines?: InputMaybe<Array<RestaurantCuisine>>;
  sectionImages?: InputMaybe<SectionImagesInput>;
  badgesImage?: InputMaybe<ImageInput>;
  simCardLink?: InputMaybe<Scalars['String']>;
  headliner?: InputMaybe<Scalars['Boolean']>;
  welcomeScreenImage?: InputMaybe<ImageInput>;
  popularDestination?: InputMaybe<Scalars['Boolean']>;
  trafficAvgSpeed?: InputMaybe<Scalars['Int']>;
};

export enum PlaceSortBy {
  Location = 'LOCATION',
}

export type PlacesFilterInput = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  parentPlaceUuid?: InputMaybe<Scalars['String']>;
  timezoneCode?: InputMaybe<Scalars['String']>;
};

export type PlacesOrderByInput = {
  Order: SortOrder;
  by: PlaceSortBy;
};

export enum PlateCheckerConnector {
  NycCityPay = 'NYC_CITY_PAY',
  CitationProcessing = 'CITATION_PROCESSING',
}

export type PlateCheckerSettings = {
  __typename?: 'PlateCheckerSettings';
  connector?: Maybe<PlateCheckerConnector>;
};

export type PlateCheckerSettingsInput = {
  connector?: InputMaybe<PlateCheckerConnector>;
};

export enum PoiType {
  Landmark = 'LANDMARK',
}

export type Post = {
  __typename?: 'Post';
  PostContent?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  generatePost: Post;
  integration?: Maybe<Integration>;
  integrationsByInstitution: PaginatedIntegrations;
  crawlUrl?: Maybe<TaskArn>;
  getUrlsForPlace?: Maybe<UrlList>;
  scrapeUrls?: Maybe<TaskArn>;
  institution?: Maybe<Institution>;
  institutionsBy: PaginatedInstitutions;
  allInstitutions: PaginatedInstitutions;
  /** @deprecated use institutionsPlacesBySearch with filter.entity */
  institutionsBySearch: PaginatedInstitutions;
  institutionsPlacesBySearch: PaginatedEntities;
  failedPlaceItineraries: PaginatedItineraries;
  placeItineraries: PaginatedItineraries;
  landmark?: Maybe<Landmark>;
  landmarks: PaginatedLandmarks;
  allMessagingCategories?: Maybe<PaginatedMessagingCategories>;
  messagingCategoriesBy?: Maybe<PaginatedMessagingCategories>;
  messagingCategory?: Maybe<MessagingCategory>;
  allMessagingProblems: PaginatedMessagingProblems;
  messagingProblem?: Maybe<MessagingProblem>;
  messagingProblemsBy: PaginatedMessagingProblems;
  listNewsletterTemplates?: Maybe<PaginatedTemplates>;
  allPlaces: PaginatedPlaces;
  findPlaces: PaginatedPlaces;
  place?: Maybe<Place>;
  places: PaginatedPlaces;
  placesBy: PaginatedPlaces;
  placesUniqueTimezoneCodes: TimezoneCodes;
  getItineraryPlaces: PaginatedPlaces;
  restaurant?: Maybe<Restaurant>;
  restaurants: PaginatedRestaurants;
  searchRestaurants: PaginatedRestaurants;
  allScrapeSelectors: PaginatedScrapeSelectors;
  scrapeSelector?: Maybe<ScrapeSelector>;
  allScrapeTasks: PaginatedScrapeTasks;
  scrapeTaskByCode?: Maybe<ScrapeTask>;
  adminDashboardTotalsAnalytics?: Maybe<DashboardAnalyticsTotalsDashboard>;
  adminDashboardAnalytics?: Maybe<AdminDashboardAnalytics>;
  installationsCount: Scalars['Int'];
  followersCount: Scalars['Int'];
  subscribersCount: Scalars['Int'];
  systemStatus: PaginatedComponentStates;
  scrapeFutureEventsByCode: PaginatedScrapeEvents;
};

export type QueryGeneratePostArgs = {
  Specification: Scalars['String'];
  InstitutionAddress?: InputMaybe<Scalars['String']>;
  InstitutionName?: InputMaybe<Scalars['String']>;
  PostType?: InputMaybe<Scalars['String']>;
};

export type QueryIntegrationArgs = {
  id: Scalars['String'];
};

export type QueryIntegrationsByInstitutionArgs = {
  institutionId?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryCrawlUrlArgs = {
  PlaceName: Scalars['String'];
  URL: Scalars['String'];
};

export type QueryGetUrlsForPlaceArgs = {
  PlaceName: Scalars['String'];
};

export type QueryScrapeUrlsArgs = {
  PlaceName: Scalars['String'];
  Urls: UrlListInput;
};

export type QueryInstitutionArgs = {
  id: Scalars['ID'];
};

export type QueryInstitutionsByArgs = {
  filter: InstitutionsByFilterInput;
  orderBy?: InputMaybe<InstitutionsOrderByInput>;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryAllInstitutionsArgs = {
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryInstitutionsBySearchArgs = {
  filter: InstitutionsBySearchFilterInput;
  orderBy?: InputMaybe<InstitutionsOrderByInput>;
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryInstitutionsPlacesBySearchArgs = {
  filter: InstitutionsPlacesBySearchFilterInput;
  orderBy?: InputMaybe<InstitutionsOrderByInput>;
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  visitorMode?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFailedPlaceItinerariesArgs = {
  placeUuid: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Float']>;
};

export type QueryPlaceItinerariesArgs = {
  placeUuid: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Float']>;
};

export type QueryLandmarkArgs = {
  id: Scalars['String'];
};

export type QueryLandmarksArgs = {
  placeUuid: Scalars['String'];
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

export type QueryAllMessagingCategoriesArgs = {
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryMessagingCategoriesByArgs = {
  filter: MessagingCategoriesByFilterInput;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryMessagingCategoryArgs = {
  id: Scalars['String'];
};

export type QueryAllMessagingProblemsArgs = {
  orderBy?: InputMaybe<MessagingProblemsOrderByInput>;
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryMessagingProblemArgs = {
  id: Scalars['String'];
};

export type QueryMessagingProblemsByArgs = {
  filter: MessagingProblemsFilterInput;
  orderBy?: InputMaybe<MessagingProblemsOrderByInput>;
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryAllPlacesArgs = {
  gpsLocation?: InputMaybe<GeoPointInput>;
  includeDistricts?: InputMaybe<Scalars['Boolean']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<PlacesOrderByInput>;
  pageSize?: InputMaybe<Scalars['Float']>;
  parentPlaceUuid?: InputMaybe<Scalars['String']>;
};

export type QueryFindPlacesArgs = {
  prefix: Scalars['String'];
};

export type QueryPlaceArgs = {
  id: Scalars['String'];
};

export type QueryPlacesArgs = {
  gpsLocation?: InputMaybe<GeoPointInput>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  includeDistricts?: InputMaybe<Scalars['Boolean']>;
  institutionUuid?: InputMaybe<Scalars['String']>;
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  parentPlaceUuid?: InputMaybe<Scalars['String']>;
  placeUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  prefix?: InputMaybe<Scalars['String']>;
  timezoneCode?: InputMaybe<Scalars['String']>;
  visitorMode?: InputMaybe<Scalars['Boolean']>;
  popularDestination?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPlacesByArgs = {
  filter: PlacesFilterInput;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Float']>;
};

export type QueryGetItineraryPlacesArgs = {
  from?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryRestaurantArgs = {
  id: Scalars['String'];
};

export type QueryRestaurantsArgs = {
  placeUuid: Scalars['String'];
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QuerySearchRestaurantsArgs = {
  placeUuid: Scalars['String'];
  query: Scalars['String'];
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryAllScrapeSelectorsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  affix?: InputMaybe<Scalars['String']>;
};

export type QueryScrapeSelectorArgs = {
  code: Scalars['String'];
};

export type QueryAllScrapeTasksArgs = {
  affix?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryScrapeTaskByCodeArgs = {
  code: Scalars['String'];
};

export type QueryAdminDashboardTotalsAnalyticsArgs = {
  filter: DashboardAnalyticsFilterInput;
};

export type QueryAdminDashboardAnalyticsArgs = {
  filter: DashboardAnalyticsFilterInput;
};

export type QueryInstallationsCountArgs = {
  placeUuid?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Active>;
};

export type QueryFollowersCountArgs = {
  institutionUuid: Scalars['String'];
  active?: InputMaybe<Active>;
};

export type QuerySubscribersCountArgs = {
  institutionUuid: Scalars['String'];
  active?: InputMaybe<Active>;
};

export type QuerySystemStatusArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryScrapeFutureEventsByCodeArgs = {
  data: FindScrapeEventsByTaskCodeInput;
};

export type RecipientInput = {
  email: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export enum RecurringPeriod {
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
}

export type RecurringValidity = {
  __typename?: 'RecurringValidity';
  type: RecurringPeriod;
  day: Array<Scalars['Int']>;
  every: Scalars['Int'];
  fromDate: Scalars['String'];
  toDate?: Maybe<Scalars['String']>;
  timeFrom: Scalars['String'];
  timeTo: Scalars['String'];
  ocurrences?: Maybe<Scalars['Int']>;
};

export type ReminderParserSettings = {
  __typename?: 'ReminderParserSettings';
  apiUrl: Scalars['String'];
  config: Array<ConfigPath>;
  parser: Scalars['String'];
};

export type ReminderParserSettingsInput = {
  apiUrl: Scalars['String'];
  config: Array<ConfigPathInput>;
  parser: Scalars['String'];
};

export type ReminderSettings = {
  __typename?: 'ReminderSettings';
  institutionUuid: Scalars['String'];
  parserSettings: ReminderParserSettings;
  reminderCategoryCodes?: Maybe<Array<Scalars['String']>>;
  reminderType: ReminderType;
  visualization: ReminderVisualization;
};

export type ReminderSettingsInput = {
  institutionUuid: Scalars['String'];
  parserSettings: ReminderParserSettingsInput;
  reminderCategoryCodes?: InputMaybe<Array<Scalars['String']>>;
  reminderType: ReminderType;
  visualization: ReminderVisualization;
};

export enum ReminderType {
  WasteManagement = 'WASTE_MANAGEMENT',
  StreetSweeping = 'STREET_SWEEPING',
}

export enum ReminderVisualization {
  Standard = 'STANDARD',
  WeekDays = 'WEEK_DAYS',
}

export type ReportedProblemsSettings = {
  __typename?: 'ReportedProblemsSettings';
  connectorTypes?: Maybe<Array<MessagingProblemConnectorType>>;
  isLaneAvailable?: Maybe<Scalars['Boolean']>;
  showCategories?: Maybe<Scalars['Boolean']>;
  isMediaRequired?: Maybe<Scalars['Boolean']>;
  potholeCategoryUuid?: Maybe<Scalars['String']>;
};

export type ReportedProblemsSettingsInput = {
  connectorTypes?: InputMaybe<Array<MessagingProblemConnectorType>>;
  isLaneAvailable?: InputMaybe<Scalars['Boolean']>;
  showCategories?: InputMaybe<Scalars['Boolean']>;
  isMediaRequired?: InputMaybe<Scalars['Boolean']>;
  potholeCategoryUuid?: InputMaybe<Scalars['String']>;
};

export type Restaurant = {
  __typename?: 'Restaurant';
  id: Scalars['String'];
  placeUuid: Scalars['String'];
  gpsLocation?: Maybe<GeoPoint>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  userRatingCount?: Maybe<Scalars['Int']>;
  priceLevel?: Maybe<Scalars['Int']>;
  cuisines?: Maybe<Array<Maybe<Scalars['String']>>>;
  photoUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageObjects?: Maybe<Array<Image>>;
  class?: Maybe<Scalars['String']>;
  typename?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
  googleId?: Maybe<Scalars['String']>;
};

export enum RestaurantCuisine {
  AmericanRestaurant = 'AMERICAN_RESTAURANT',
  Bakery = 'BAKERY',
  Bar = 'BAR',
  BarbecueRestaurant = 'BARBECUE_RESTAURANT',
  BrazilianRestaurant = 'BRAZILIAN_RESTAURANT',
  BreakfastRestaurant = 'BREAKFAST_RESTAURANT',
  BrunchRestaurant = 'BRUNCH_RESTAURANT',
  Cafe = 'CAFE',
  ChineseRestaurant = 'CHINESE_RESTAURANT',
  CoffeeShop = 'COFFEE_SHOP',
  FastFoodRestaurant = 'FAST_FOOD_RESTAURANT',
  FrenchRestaurant = 'FRENCH_RESTAURANT',
  GreekRestaurant = 'GREEK_RESTAURANT',
  HamburgerRestaurant = 'HAMBURGER_RESTAURANT',
  IceCreamShop = 'ICE_CREAM_SHOP',
  IndianRestaurant = 'INDIAN_RESTAURANT',
  IndonesianRestaurant = 'INDONESIAN_RESTAURANT',
  ItalianRestaurant = 'ITALIAN_RESTAURANT',
  JapaneseRestaurant = 'JAPANESE_RESTAURANT',
  KoreanRestaurant = 'KOREAN_RESTAURANT',
  LebaneseRestaurant = 'LEBANESE_RESTAURANT',
  MediterraneanRestaurant = 'MEDITERRANEAN_RESTAURANT',
  MexicanRestaurant = 'MEXICAN_RESTAURANT',
  MiddleEasternRestaurant = 'MIDDLE_EASTERN_RESTAURANT',
  PizzaRestaurant = 'PIZZA_RESTAURANT',
  RamenRestaurant = 'RAMEN_RESTAURANT',
  Restaurant = 'RESTAURANT',
  SandwichShop = 'SANDWICH_SHOP',
  SeafoodRestaurant = 'SEAFOOD_RESTAURANT',
  SpanishRestaurant = 'SPANISH_RESTAURANT',
  SteakHouse = 'STEAK_HOUSE',
  SushiRestaurant = 'SUSHI_RESTAURANT',
  ThaiRestaurant = 'THAI_RESTAURANT',
  TurkishRestaurant = 'TURKISH_RESTAURANT',
  VeganRestaurant = 'VEGAN_RESTAURANT',
  VegetarianRestaurant = 'VEGETARIAN_RESTAURANT',
  VietnameseRestaurant = 'VIETNAMESE_RESTAURANT',
}

export type RestaurantInput = {
  id?: InputMaybe<Scalars['String']>;
  placeUuid?: InputMaybe<Scalars['String']>;
  gpsLocation?: InputMaybe<GeoPointInput>;
  title?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Float']>;
  userRatingCount?: InputMaybe<Scalars['Int']>;
  priceLevel?: InputMaybe<Scalars['Int']>;
  cuisines?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  photoUrls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageObjects?: InputMaybe<Array<ImageInput>>;
  class?: InputMaybe<Scalars['String']>;
  typename?: InputMaybe<Scalars['String']>;
  videoUrl?: InputMaybe<Scalars['String']>;
  googleId?: InputMaybe<Scalars['String']>;
};

export type ScrapeCategory = {
  __typename?: 'ScrapeCategory';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ScrapeCategoryInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export enum ScrapeContentType {
  Html = 'HTML',
  Json = 'JSON',
}

export enum ScrapeDataType {
  News = 'NEWS',
  Events = 'EVENTS',
  Uploader = 'UPLOADER',
}

export type ScrapeEvent = {
  __typename?: 'ScrapeEvent';
  bodyHtml?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  contentRaw?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  dateTimeFrom: Scalars['String'];
  dateTimeTo?: Maybe<Scalars['String']>;
  dedup?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  imageObjects?: Maybe<Array<Image>>;
  initial: Scalars['Boolean'];
  lang?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  originalHtml?: Maybe<Scalars['String']>;
  perexText?: Maybe<Scalars['String']>;
  taskCode: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['String'];
  url: Scalars['String'];
  postClass?: Maybe<Scalars['String']>;
};

export type ScrapeItemSelector = {
  __typename?: 'ScrapeItemSelector';
  field: Scalars['String'];
  selector: Array<ScrapeSingleSelector>;
};

export type ScrapeItemSelectorInput = {
  field: Scalars['String'];
  selector: Array<ScrapeSingleSelectorInput>;
};

export type ScrapeSelector = {
  __typename?: 'ScrapeSelector';
  code: Scalars['String'];
  nodeSelector: Array<Scalars['String']>;
  subpageSelector: Array<ScrapeSingleSelector>;
  subpageUrlFormat?: Maybe<Scalars['String']>;
  imports: Array<Scalars['String']>;
  variables: Array<ScrapeVariable>;
  nodeSelectors: Array<ScrapeItemSelector>;
  subpageSelectors: Array<ScrapeItemSelector>;
  removePageTrash: Array<Scalars['String']>;
  nextPageSelector: Array<ScrapeSingleSelector>;
  maxPages: Scalars['Int'];
  originalSourceOverride?: Maybe<Scalars['String']>;
  paginationType?: Maybe<PaginationType>;
};

export type ScrapeSelectorInput = {
  code: Scalars['String'];
  nodeSelector: Array<Scalars['String']>;
  subpageSelector: Array<ScrapeSingleSelectorInput>;
  subpageUrlFormat?: InputMaybe<Scalars['String']>;
  imports: Array<Scalars['String']>;
  variables: Array<ScrapeVariableInput>;
  nodeSelectors: Array<ScrapeItemSelectorInput>;
  subpageSelectors: Array<ScrapeItemSelectorInput>;
  removePageTrash: Array<Scalars['String']>;
  nextPageSelector: Array<ScrapeSingleSelectorInput>;
  maxPages: Scalars['Int'];
  originalSourceOverride?: InputMaybe<Scalars['String']>;
  paginationType?: InputMaybe<PaginationType>;
};

export type ScrapeSingleSelector = {
  __typename?: 'ScrapeSingleSelector';
  collect: Array<Scalars['String']>;
  remove: Array<Scalars['String']>;
  regex?: Maybe<Scalars['String']>;
  replace: Array<Array<Scalars['String']>>;
};

export type ScrapeSingleSelectorInput = {
  collect: Array<Scalars['String']>;
  remove: Array<Scalars['String']>;
  regex?: InputMaybe<Scalars['String']>;
  replace: Array<Array<Scalars['String']>>;
};

export type ScrapeTask = {
  __typename?: 'ScrapeTask';
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  enabledStaging?: Maybe<Scalars['Boolean']>;
  enabledTesting?: Maybe<Scalars['Boolean']>;
  dataType: ScrapeDataType;
  scraperType: ScrapeType;
  websiteTemplate?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  JSRender: Scalars['Boolean'];
  contentType: ScrapeContentType;
  subpageContentType: ScrapeContentType;
  checkCodes: Array<Scalars['String']>;
  timeZone: Scalars['String'];
  proxy: Scalars['Boolean'];
  lang: Scalars['String'];
  runSchedule?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<ScrapeCategory>>;
  postClass?: Maybe<Scalars['String']>;
  visitorScraper?: Maybe<Scalars['Boolean']>;
  streamingPlatform?: Maybe<Scalars['String']>;
  muteAlerts?: Maybe<Scalars['Boolean']>;
};

export type ScrapeTaskInput = {
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  enabledStaging?: InputMaybe<Scalars['Boolean']>;
  enabledTesting?: InputMaybe<Scalars['Boolean']>;
  dataType: ScrapeDataType;
  scraperType: ScrapeType;
  websiteTemplate?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
  JSRender: Scalars['Boolean'];
  contentType: ScrapeContentType;
  subpageContentType: ScrapeContentType;
  checkCodes: Array<Scalars['String']>;
  timeZone: Scalars['String'];
  proxy: Scalars['Boolean'];
  lang: Scalars['String'];
  runSchedule?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<ScrapeCategoryInput>>;
  visitorScraper?: InputMaybe<Scalars['Boolean']>;
  streamingPlatform?: InputMaybe<Scalars['String']>;
  muteAlerts?: InputMaybe<Scalars['Boolean']>;
};

export enum ScrapeType {
  Website = 'WEBSITE',
  Twitter = 'TWITTER',
  Google = 'GOOGLE',
  Yelp = 'YELP',
  NycPotholes = 'NYC_POTHOLES',
  TwitterOfficial = 'TWITTER_OFFICIAL',
  WebsiteMarket = 'WEBSITE_MARKET',
}

export type ScrapeVariable = {
  __typename?: 'ScrapeVariable';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ScrapeVariableInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SectionImages = {
  __typename?: 'SectionImages';
  attractions?: Maybe<Image>;
  museums?: Maybe<Image>;
  theatre?: Maybe<Image>;
  concerts?: Maybe<Image>;
  sports?: Maybe<Image>;
  cell?: Maybe<Image>;
};

export type SectionImagesInput = {
  attractions?: InputMaybe<ImageInput>;
  museums?: InputMaybe<ImageInput>;
  theatre?: InputMaybe<ImageInput>;
  concerts?: InputMaybe<ImageInput>;
  sports?: InputMaybe<ImageInput>;
  cell?: InputMaybe<ImageInput>;
};

export type SendNewsletterInput = {
  templateId: Scalars['String'];
  campaignId: Scalars['String'];
  subject?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  sendAt?: InputMaybe<Scalars['String']>;
  recipients?: InputMaybe<Array<RecipientInput>>;
  isGlobalNewsletter?: InputMaybe<Scalars['Boolean']>;
};

export type SocialLink = {
  __typename?: 'SocialLink';
  name: Scalars['String'];
  url: Scalars['String'];
};

export type SocialLinkInput = {
  name: Scalars['String'];
  url: Scalars['String'];
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum State {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
  Scheduled = 'SCHEDULED',
  Pinned = 'PINNED',
  Removed = 'REMOVED',
}

export type SuccessMessage = {
  __typename?: 'SuccessMessage';
  message?: Maybe<Scalars['Boolean']>;
};

export type TaskArn = {
  __typename?: 'TaskARN';
  arn: Scalars['String'];
};

export type Template = {
  __typename?: 'Template';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  last_update_time?: Maybe<Scalars['String']>;
  typename?: Maybe<Scalars['String']>;
};

export enum TicketmasterCountryCode {
  Us = 'US',
  Ca = 'CA',
  Ie = 'IE',
  Gb = 'GB',
  Au = 'AU',
  Nz = 'NZ',
  Mx = 'MX',
  Ae = 'AE',
  At = 'AT',
  Be = 'BE',
  De = 'DE',
  Dk = 'DK',
  Es = 'ES',
  Fi = 'FI',
  Nl = 'NL',
  No = 'NO',
  Pl = 'PL',
  Se = 'SE',
  Ch = 'CH',
  Cz = 'CZ',
  It = 'IT',
  Fr = 'FR',
  Za = 'ZA',
  Tr = 'TR',
}

export type TicketmasterSettings = {
  __typename?: 'TicketmasterSettings';
  placeName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<TicketmasterCountryCode>;
  institutionUuid?: Maybe<Scalars['ID']>;
  stateCode?: Maybe<Scalars['String']>;
};

export type TicketmasterSettingsInput = {
  placeName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<TicketmasterCountryCode>;
  institutionUuid?: InputMaybe<Scalars['ID']>;
  stateCode?: InputMaybe<Scalars['String']>;
};

export type TimezoneCodes = {
  __typename?: 'TimezoneCodes';
  timezoneCodes: Array<Scalars['String']>;
};

export type TitleLocalization = {
  __typename?: 'TitleLocalization';
  title: Scalars['String'];
  language: Scalars['String'];
};

export type TitleLocalizationInput = {
  title: Scalars['String'];
  language: Scalars['String'];
};

export type Translation = {
  __typename?: 'Translation';
  lang: Scalars['String'];
  text: Scalars['String'];
};

export type TranslationInput = {
  lang: Scalars['String'];
  text: Scalars['String'];
};

export type TwitterRequestTokenResponse = {
  __typename?: 'TwitterRequestTokenResponse';
  token: Scalars['String'];
};

export type UrlList = {
  __typename?: 'UrlList';
  urls?: Maybe<Array<Scalars['String']>>;
};

export type UrlListInput = {
  urls?: InputMaybe<Array<Scalars['String']>>;
};

export type Video = {
  __typename?: 'Video';
  id: Scalars['ID'];
  links?: Maybe<Links>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
};

export type VideoInput = {
  id: Scalars['ID'];
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
};

export type VisitorPromoNotificationInput = {
  notificationTitle: Scalars['String'];
  notificationBody: Scalars['String'];
};

export type Weather = {
  __typename?: 'Weather';
  description: Scalars['String'];
  iconName: Scalars['String'];
  id: Scalars['String'];
  label: Scalars['String'];
  tempUnit: Scalars['String'];
  tempValue: Scalars['Float'];
};

export enum WeatherType {
  Sunny = 'SUNNY',
  Rainy = 'RAINY',
}

export type AddMessagingCategoryMutationVariables = Exact<{
  data: MessagingCategoryInput;
}>;

export type AddMessagingCategoryMutation = {
  __typename?: 'Mutation';
  addMessagingCategory: {
    __typename?: 'MessagingCategory';
    id: string;
    placeUuid: string;
    name: string;
    description?: string | null | undefined;
    email: string;
    additionalEmails?: Array<string> | null | undefined;
  };
};

export type UpdateMessagingCategoryMutationVariables = Exact<{
  id: Scalars['String'];
  data: MessagingCategoryInput;
}>;

export type UpdateMessagingCategoryMutation = {
  __typename?: 'Mutation';
  updateMessagingCategory?:
    | {
        __typename?: 'MessagingCategory';
        id: string;
        placeUuid: string;
        name: string;
        description?: string | null | undefined;
        email: string;
        additionalEmails?: Array<string> | null | undefined;
      }
    | null
    | undefined;
};

export type DeleteMessagingCategoryMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteMessagingCategoryMutation = {
  __typename?: 'Mutation';
  deleteMessagingCategory?:
    | {
        __typename?: 'MessagingCategory';
        id: string;
        placeUuid: string;
        name: string;
        description?: string | null | undefined;
        email: string;
        additionalEmails?: Array<string> | null | undefined;
      }
    | null
    | undefined;
};

export type MessagingCategoryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type MessagingCategoryQuery = {
  __typename?: 'Query';
  messagingCategory?:
    | {
        __typename?: 'MessagingCategory';
        id: string;
        placeUuid: string;
        name: string;
        description?: string | null | undefined;
        email: string;
        additionalEmails?: Array<string> | null | undefined;
      }
    | null
    | undefined;
};

export type MessagingCategoriesByQueryVariables = Exact<{
  filter: MessagingCategoriesByFilterInput;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type MessagingCategoriesByQuery = {
  __typename?: 'Query';
  messagingCategoriesBy?:
    | {
        __typename?: 'PaginatedMessagingCategories';
        nextToken?: string | null | undefined;
        messagingCategories: Array<{
          __typename?: 'MessagingCategory';
          id: string;
          placeUuid: string;
          name: string;
          description?: string | null | undefined;
          email: string;
          additionalEmails?: Array<string> | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type AllMessagingCategoriesQueryVariables = Exact<{
  nextToken?: Maybe<Scalars['String']>;
}>;

export type AllMessagingCategoriesQuery = {
  __typename?: 'Query';
  allMessagingCategories?:
    | {
        __typename?: 'PaginatedMessagingCategories';
        nextToken?: string | null | undefined;
        messagingCategories: Array<{
          __typename?: 'MessagingCategory';
          id: string;
          placeUuid: string;
          name: string;
          description?: string | null | undefined;
          email: string;
          additionalEmails?: Array<string> | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type FullPlaceFragmentFragment = {
  __typename?: 'Place';
  countryCode: string;
  countryName: string;
  createdAt: string;
  id: string;
  name: string;
  stateCode: string;
  stateName: string;
  timezoneCode: string;
  translateTo?: Array<string> | null | undefined;
  gps: { __typename?: 'GeoPoint'; lat: number; lon: number };
  info?:
    | {
        __typename?: 'PlaceInfo';
        aboutHeader?: string | null | undefined;
        about?: string | null | undefined;
        description?: string | null | undefined;
        reportsEmail?: string | null | undefined;
      }
    | null
    | undefined;
  branding?:
    | {
        __typename?: 'BrandingPlace';
        logo?:
          | {
              __typename?: 'Image';
              id: string;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }
          | null
          | undefined;
        avatar?:
          | {
              __typename?: 'Image';
              id: string;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  features?:
    | {
        __typename?: 'PlaceFeatures';
        enableCommunityFund?: boolean | null | undefined;
        enableMessaging?: boolean | null | undefined;
        enablePetDirectory?: boolean | null | undefined;
        enableRaffle?: boolean | null | undefined;
      }
    | null
    | undefined;
  featureFlags?:
    | Array<{
        __typename?: 'PlaceFeatureFlag';
        title?: string | null | undefined;
        url?: string | null | undefined;
        type?: FeatureFlagInternalType | null | undefined;
        enabled?: boolean | null | undefined;
      }>
    | null
    | undefined;
};

export type SetPlaceInfoMutationVariables = Exact<{
  id: Scalars['String'];
  info: PlaceInfoInput;
}>;

export type SetPlaceInfoMutation = {
  __typename?: 'Mutation';
  setPlaceInfo?: { __typename?: 'Place'; id: string } | null | undefined;
};

export type PlaceQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type PlaceQuery = {
  __typename?: 'Query';
  place?:
    | {
        __typename?: 'Place';
        countryCode: string;
        countryName: string;
        createdAt: string;
        id: string;
        name: string;
        stateCode: string;
        stateName: string;
        timezoneCode: string;
        translateTo?: Array<string> | null | undefined;
        gps: { __typename?: 'GeoPoint'; lat: number; lon: number };
        info?:
          | {
              __typename?: 'PlaceInfo';
              aboutHeader?: string | null | undefined;
              about?: string | null | undefined;
              description?: string | null | undefined;
              reportsEmail?: string | null | undefined;
            }
          | null
          | undefined;
        branding?:
          | {
              __typename?: 'BrandingPlace';
              logo?:
                | {
                    __typename?: 'Image';
                    id: string;
                    links?: { __typename?: 'Links'; self: string } | null | undefined;
                  }
                | null
                | undefined;
              avatar?:
                | {
                    __typename?: 'Image';
                    id: string;
                    links?: { __typename?: 'Links'; self: string } | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        features?:
          | {
              __typename?: 'PlaceFeatures';
              enableCommunityFund?: boolean | null | undefined;
              enableMessaging?: boolean | null | undefined;
              enablePetDirectory?: boolean | null | undefined;
              enableRaffle?: boolean | null | undefined;
            }
          | null
          | undefined;
        featureFlags?:
          | Array<{
              __typename?: 'PlaceFeatureFlag';
              title?: string | null | undefined;
              url?: string | null | undefined;
              type?: FeatureFlagInternalType | null | undefined;
              enabled?: boolean | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PlaceFeautureFlagsQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type PlaceFeautureFlagsQuery = {
  __typename?: 'Query';
  place?:
    | {
        __typename?: 'Place';
        featureFlags?:
          | Array<{
              __typename?: 'PlaceFeatureFlag';
              title?: string | null | undefined;
              url?: string | null | undefined;
              type?: FeatureFlagInternalType | null | undefined;
              enabled?: boolean | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GeneratePostQueryVariables = Exact<{
  institutionAddress: Scalars['String'];
  institutionName: Scalars['String'];
  postType: Scalars['String'];
  specification: Scalars['String'];
}>;

export type GeneratePostQuery = {
  __typename?: 'Query';
  generatePost: { __typename?: 'Post'; PostContent?: string | null | undefined };
};

export type AddMessagingProblemMutationVariables = Exact<{
  data: MessagingProblemInput;
}>;

export type AddMessagingProblemMutation = {
  __typename?: 'Mutation';
  addMessagingProblem?: string | null | undefined;
};

export type UpdateMessagingProblemMutationVariables = Exact<{
  data: MessagingProblemInput;
  id: Scalars['String'];
}>;

export type UpdateMessagingProblemMutation = {
  __typename?: 'Mutation';
  updateMessagingProblem: number;
};

export type DeleteMessagingProblemMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteMessagingProblemMutation = {
  __typename?: 'Mutation';
  deleteMessagingProblem: number;
};

export type MessagingProblemQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type MessagingProblemQuery = {
  __typename?: 'Query';
  messagingProblem?:
    | {
        __typename?: 'MessagingProblem';
        createdAt: string;
        id: string;
        reporter?: string | null | undefined;
        lane?: MessagingProblemLane | null | undefined;
        installationUuid: string;
        placeUuid: string;
        categoryUuid?: string | null | undefined;
        email: string;
        address?: string | null | undefined;
        description?: string | null | undefined;
        status: MessagingProblemStatus;
        resolution?: string | null | undefined;
        commentFromAuthority?: string | null | undefined;
        commentAuthorityId?: string | null | undefined;
        cityIsNotCompetent?: boolean | null | undefined;
        cityIsNotCompetentTimestamp?: string | null | undefined;
        geoPoint?: { __typename?: 'GeoPoint'; lat: number; lon: number } | null | undefined;
        connector?:
          | {
              __typename?: 'MessagingProblemConnector';
              type: MessagingProblemConnectorType;
              status: MessagingProblemConnectorStatus;
              statusMessage?: string | null | undefined;
              id?: string | null | undefined;
            }
          | null
          | undefined;
        imageObjects?:
          | Array<{
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              blurHash?: string | null | undefined;
              links?:
                | { __typename?: 'Links'; self: string; thumbnail?: string | null | undefined }
                | null
                | undefined;
            }>
          | null
          | undefined;
        videoObjects?:
          | Array<{
              __typename?: 'Video';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?:
                | { __typename?: 'Links'; self: string; thumbnail?: string | null | undefined }
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type MessagingProblemsByQueryVariables = Exact<{
  filter: MessagingProblemsFilterInput;
  orderBy?: Maybe<MessagingProblemsOrderByInput>;
  pageOffset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;

export type MessagingProblemsByQuery = {
  __typename?: 'Query';
  messagingProblemsBy: {
    __typename?: 'PaginatedMessagingProblems';
    totalCount: number;
    messagingProblems: Array<{
      __typename?: 'MessagingProblem';
      createdAt: string;
      id: string;
      reporter?: string | null | undefined;
      installationUuid: string;
      placeUuid: string;
      categoryUuid?: string | null | undefined;
      email: string;
      address?: string | null | undefined;
      description?: string | null | undefined;
      status: MessagingProblemStatus;
      resolution?: string | null | undefined;
      geoPoint?: { __typename?: 'GeoPoint'; lat: number; lon: number } | null | undefined;
      imageObjects?:
        | Array<{
            __typename?: 'Image';
            id: string;
            fileName?: string | null | undefined;
            filePath?: string | null | undefined;
            blurHash?: string | null | undefined;
            links?:
              | { __typename?: 'Links'; self: string; thumbnail?: string | null | undefined }
              | null
              | undefined;
          }>
        | null
        | undefined;
    }>;
  };
};

export type AllMessagingProblemsQueryVariables = Exact<{
  pageOffset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;

export type AllMessagingProblemsQuery = {
  __typename?: 'Query';
  allMessagingProblems: {
    __typename?: 'PaginatedMessagingProblems';
    totalCount: number;
    messagingProblems: Array<{
      __typename?: 'MessagingProblem';
      createdAt: string;
      id: string;
      installationUuid: string;
      placeUuid: string;
      categoryUuid?: string | null | undefined;
      email: string;
      address?: string | null | undefined;
      description?: string | null | undefined;
      status: MessagingProblemStatus;
      resolution?: string | null | undefined;
      geoPoint?: { __typename?: 'GeoPoint'; lat: number; lon: number } | null | undefined;
      imageObjects?:
        | Array<{
            __typename?: 'Image';
            id: string;
            fileName?: string | null | undefined;
            filePath?: string | null | undefined;
            blurHash?: string | null | undefined;
            links?:
              | { __typename?: 'Links'; self: string; thumbnail?: string | null | undefined }
              | null
              | undefined;
          }>
        | null
        | undefined;
    }>;
  };
};

export type ItineraryOverviewFragmentFragment = {
  __typename?: 'ItineraryOverview';
  dayOfWeek: MainItineraryDay;
  company: ItineraryCompany;
  priceLevel: ItineraryPriceLevel;
  mode: ItineraryMode;
  creationStatus: ItineraryCreationStatus;
  createdAt?: string | null | undefined;
  date?: string | null | undefined;
  cloudWatchLogUrl?: string | null | undefined;
  isRetriable?: boolean | null | undefined;
  failReason?: string | null | undefined;
};

export type FailedPlaceItinerariesQueryVariables = Exact<{
  placeUuid: Scalars['ID'];
  nextToken?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Float']>;
}>;

export type FailedPlaceItinerariesQuery = {
  __typename?: 'Query';
  failedPlaceItineraries: {
    __typename?: 'PaginatedItineraries';
    nextToken?: string | null | undefined;
    itineraries: Array<{
      __typename?: 'ItineraryOverview';
      dayOfWeek: MainItineraryDay;
      company: ItineraryCompany;
      priceLevel: ItineraryPriceLevel;
      mode: ItineraryMode;
      creationStatus: ItineraryCreationStatus;
      createdAt?: string | null | undefined;
      date?: string | null | undefined;
      cloudWatchLogUrl?: string | null | undefined;
      isRetriable?: boolean | null | undefined;
      failReason?: string | null | undefined;
    }>;
  };
};

export type PlaceItinerariesQueryVariables = Exact<{
  placeUuid: Scalars['ID'];
  nextToken?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Float']>;
}>;

export type PlaceItinerariesQuery = {
  __typename?: 'Query';
  placeItineraries: {
    __typename?: 'PaginatedItineraries';
    nextToken?: string | null | undefined;
    itineraries: Array<{
      __typename?: 'ItineraryOverview';
      dayOfWeek: MainItineraryDay;
      company: ItineraryCompany;
      priceLevel: ItineraryPriceLevel;
      mode: ItineraryMode;
      creationStatus: ItineraryCreationStatus;
      createdAt?: string | null | undefined;
      date?: string | null | undefined;
      cloudWatchLogUrl?: string | null | undefined;
      isRetriable?: boolean | null | undefined;
      failReason?: string | null | undefined;
    }>;
  };
};

export type RemoveLandmarkMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type RemoveLandmarkMutation = {
  __typename?: 'Mutation';
  removeLandmark?: string | null | undefined;
};

export type CreateLandmarkMutationVariables = Exact<{
  landmark: LandmarkInput;
}>;

export type CreateLandmarkMutation = {
  __typename?: 'Mutation';
  createLandmark?: { __typename?: 'Landmark'; id: string } | null | undefined;
};

export type UpdateLandmarkMutationVariables = Exact<{
  id: Scalars['String'];
  landmark: LandmarkInput;
}>;

export type UpdateLandmarkMutation = {
  __typename?: 'Mutation';
  updateLandmark?: { __typename?: 'Landmark'; id: string } | null | undefined;
};

export type SaLandmarksQueryVariables = Exact<{
  placeUuid: Scalars['String'];
  pageOffset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
}>;

export type SaLandmarksQuery = {
  __typename?: 'Query';
  landmarks: {
    __typename?: 'PaginatedLandmarks';
    nextToken?: string | null | undefined;
    landmarks: Array<{
      __typename?: 'Landmark';
      id: string;
      title: string;
      additionalType?: Array<LandmarkAdditionalType> | null | undefined;
      rating?: number | null | undefined;
      userRatingCount?: number | null | undefined;
    }>;
  };
};

export type SaLandmarkQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type SaLandmarkQuery = {
  __typename?: 'Query';
  landmark?:
    | {
        __typename?: 'Landmark';
        id: string;
        additionalType?: Array<LandmarkAdditionalType> | null | undefined;
        googlePlacesId?: string | null | undefined;
        isHiddenGem?: boolean | null | undefined;
        landmarkName?: string | null | undefined;
        primaryType?: string | null | undefined;
        rating?: number | null | undefined;
        title: string;
        types?: Array<string> | null | undefined;
        userRatingCount?: number | null | undefined;
        placeUuid: string;
        description?: string | null | undefined;
        gpsLocation?: { __typename?: 'GeoPoint'; lat: number; lon: number } | null | undefined;
        imageObjects?:
          | Array<{
              __typename?: 'Image';
              id: string;
              blurHash?: string | null | undefined;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?:
                | {
                    __typename?: 'Links';
                    self: string;
                    thumbnail?: string | null | undefined;
                    thumbnail1024x576?: string | null | undefined;
                    thumbnail420x420?: string | null | undefined;
                  }
                | null
                | undefined;
              metadata?:
                | {
                    __typename?: 'ImageMetadata';
                    mimeType?: string | null | undefined;
                    height?: number | null | undefined;
                    width?: number | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        initials: {
          __typename?: 'LandmarkInitials';
          question: string;
          answer: string;
          followups: Array<string>;
        };
      }
    | null
    | undefined;
};

export type SaMessagingCategoriesByQueryVariables = Exact<{
  filter: MessagingCategoriesByFilterInput;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type SaMessagingCategoriesByQuery = {
  __typename?: 'Query';
  messagingCategoriesBy?:
    | {
        __typename?: 'PaginatedMessagingCategories';
        nextToken?: string | null | undefined;
        messagingCategories: Array<{
          __typename?: 'MessagingCategory';
          id: string;
          placeUuid: string;
          name: string;
          description?: string | null | undefined;
          email: string;
          additionalEmails?: Array<string> | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type SendVisitorPromoNotificationMutationVariables = Exact<{
  data: VisitorPromoNotificationInput;
  placeId: Scalars['String'];
}>;

export type SendVisitorPromoNotificationMutation = {
  __typename?: 'Mutation';
  visitorPromoNotification: string;
};

export type SaPlaceFeatureFlagFragmentFragment = {
  __typename?: 'PlaceFeatureFlag';
  visitorMode?: boolean | null | undefined;
  isSystem?: boolean | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  url?: string | null | undefined;
  enabled?: boolean | null | undefined;
  order?: number | null | undefined;
  type?: FeatureFlagInternalType | null | undefined;
  allowedPaymentReceivers?: Array<PaymentReceiver> | null | undefined;
  messagingProblemIsLaneAvailable?: boolean | null | undefined;
  messagingProblemCategoryUuid?: string | null | undefined;
  isFullnameRequired?: boolean | null | undefined;
  disableProblemsOnMap?: boolean | null | undefined;
  tuiCityId?: number | null | undefined;
  tuiCountryCode?: string | null | undefined;
  tuiIsSandbox?: boolean | null | undefined;
  theInfatuationPlaceId?: string | null | undefined;
  viatorDestination?: string | null | undefined;
  icon?:
    | {
        __typename?: 'Image';
        id: string;
        blurHash?: string | null | undefined;
        fileName?: string | null | undefined;
        filePath?: string | null | undefined;
        links?:
          | { __typename?: 'Links'; self: string; thumbnail?: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
  titleLocalizations?:
    | Array<{ __typename?: 'TitleLocalization'; title: string; language: string }>
    | null
    | undefined;
  delightedSettings?: { __typename?: 'DelightedSettings'; delightedID: string } | null | undefined;
  chatBotSettings?:
    | {
        __typename?: 'ChatBotSettings';
        topics?: Array<ChatGptTopic> | null | undefined;
        knowledgeBase?: ChatGptKnowledgeBase | null | undefined;
      }
    | null
    | undefined;
  reportedProblemsSettings?:
    | {
        __typename?: 'ReportedProblemsSettings';
        isLaneAvailable?: boolean | null | undefined;
        connectorTypes?: Array<MessagingProblemConnectorType> | null | undefined;
        showCategories?: boolean | null | undefined;
        isMediaRequired?: boolean | null | undefined;
        potholeCategoryUuid?: string | null | undefined;
      }
    | null
    | undefined;
  plateCheckerSettings?:
    | { __typename?: 'PlateCheckerSettings'; connector?: PlateCheckerConnector | null | undefined }
    | null
    | undefined;
  payParkingSettings?:
    | { __typename?: 'PayParkingSettings'; connectorTypes?: Array<string> | null | undefined }
    | null
    | undefined;
  ticketmasterSettings?:
    | {
        __typename?: 'TicketmasterSettings';
        placeName?: string | null | undefined;
        countryCode?: TicketmasterCountryCode | null | undefined;
        institutionUuid?: string | null | undefined;
        stateCode?: string | null | undefined;
      }
    | null
    | undefined;
  chatContextEnabled?:
    | {
        __typename?: 'ChatContextFeatureFlag';
        landmarks?:
          | {
              __typename?: 'ChatContextTypeFeatureFlag';
              main?: boolean | null | undefined;
              map?: boolean | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SAfullPlaceFragmentFragment = {
  __typename?: 'Place';
  defaultLanguage?: string | null | undefined;
  countryCode: string;
  countryName: string;
  createdAt: string;
  id: string;
  name: string;
  isDistrict?: boolean | null | undefined;
  hasDistricts?: boolean | null | undefined;
  borough?: string | null | undefined;
  parentPlaceUuid?: string | null | undefined;
  stateCode: string;
  stateName: string;
  timezoneCode: string;
  enabled?: string | null | undefined;
  additionalGroupsOfInstitutions?: Array<InstitutionPlaceRole> | null | undefined;
  sharingUrl?: string | null | undefined;
  visitorWelcomeVideoUrl?: string | null | undefined;
  isVisitorModeAvailable?: boolean | null | undefined;
  isItineraryModeAvailable?: boolean | null | undefined;
  isResidentModeAvailable?: boolean | null | undefined;
  isOgCity?: boolean | null | undefined;
  disabledContent?: Array<PlaceContent> | null | undefined;
  localCuisines?: Array<RestaurantCuisine> | null | undefined;
  popularDestination?: boolean | null | undefined;
  trafficAvgSpeed?: number | null | undefined;
  gps: { __typename?: 'GeoPoint'; lat: number; lon: number };
  dashboardImages?:
    | {
        __typename?: 'DashboardImages';
        forYou: Array<{
          __typename?: 'Image';
          id: string;
          fileName?: string | null | undefined;
          filePath?: string | null | undefined;
          links?: { __typename?: 'Links'; self: string } | null | undefined;
        }>;
        restaurants: Array<{
          __typename?: 'Image';
          id: string;
          fileName?: string | null | undefined;
          filePath?: string | null | undefined;
          links?: { __typename?: 'Links'; self: string } | null | undefined;
        }>;
        landmarks: Array<{
          __typename?: 'Image';
          id: string;
          fileName?: string | null | undefined;
          filePath?: string | null | undefined;
          links?: { __typename?: 'Links'; self: string } | null | undefined;
        }>;
        attractions: Array<{
          __typename?: 'Image';
          id: string;
          fileName?: string | null | undefined;
          filePath?: string | null | undefined;
          links?: { __typename?: 'Links'; self: string } | null | undefined;
        }>;
        events: Array<{
          __typename?: 'Image';
          id: string;
          fileName?: string | null | undefined;
          filePath?: string | null | undefined;
          links?: { __typename?: 'Links'; self: string } | null | undefined;
        }>;
      }
    | null
    | undefined;
  info?:
    | {
        __typename?: 'PlaceInfo';
        reportsEmail?: string | null | undefined;
        about?: string | null | undefined;
        aboutHeader?: string | null | undefined;
        description?: string | null | undefined;
      }
    | null
    | undefined;
  branding?:
    | {
        __typename?: 'BrandingPlace';
        logo?:
          | {
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }
          | null
          | undefined;
        avatar?:
          | {
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }
          | null
          | undefined;
        background?:
          | {
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }
          | null
          | undefined;
        topTenBackground?:
          | {
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  featureFlags?:
    | Array<{
        __typename?: 'PlaceFeatureFlag';
        visitorMode?: boolean | null | undefined;
        isSystem?: boolean | null | undefined;
        title?: string | null | undefined;
        description?: string | null | undefined;
        url?: string | null | undefined;
        enabled?: boolean | null | undefined;
        order?: number | null | undefined;
        type?: FeatureFlagInternalType | null | undefined;
        allowedPaymentReceivers?: Array<PaymentReceiver> | null | undefined;
        messagingProblemIsLaneAvailable?: boolean | null | undefined;
        messagingProblemCategoryUuid?: string | null | undefined;
        isFullnameRequired?: boolean | null | undefined;
        disableProblemsOnMap?: boolean | null | undefined;
        tuiCityId?: number | null | undefined;
        tuiCountryCode?: string | null | undefined;
        tuiIsSandbox?: boolean | null | undefined;
        theInfatuationPlaceId?: string | null | undefined;
        viatorDestination?: string | null | undefined;
        icon?:
          | {
              __typename?: 'Image';
              id: string;
              blurHash?: string | null | undefined;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?:
                | { __typename?: 'Links'; self: string; thumbnail?: string | null | undefined }
                | null
                | undefined;
            }
          | null
          | undefined;
        titleLocalizations?:
          | Array<{ __typename?: 'TitleLocalization'; title: string; language: string }>
          | null
          | undefined;
        delightedSettings?:
          | { __typename?: 'DelightedSettings'; delightedID: string }
          | null
          | undefined;
        chatBotSettings?:
          | {
              __typename?: 'ChatBotSettings';
              topics?: Array<ChatGptTopic> | null | undefined;
              knowledgeBase?: ChatGptKnowledgeBase | null | undefined;
            }
          | null
          | undefined;
        reportedProblemsSettings?:
          | {
              __typename?: 'ReportedProblemsSettings';
              isLaneAvailable?: boolean | null | undefined;
              connectorTypes?: Array<MessagingProblemConnectorType> | null | undefined;
              showCategories?: boolean | null | undefined;
              isMediaRequired?: boolean | null | undefined;
              potholeCategoryUuid?: string | null | undefined;
            }
          | null
          | undefined;
        plateCheckerSettings?:
          | {
              __typename?: 'PlateCheckerSettings';
              connector?: PlateCheckerConnector | null | undefined;
            }
          | null
          | undefined;
        payParkingSettings?:
          | { __typename?: 'PayParkingSettings'; connectorTypes?: Array<string> | null | undefined }
          | null
          | undefined;
        ticketmasterSettings?:
          | {
              __typename?: 'TicketmasterSettings';
              placeName?: string | null | undefined;
              countryCode?: TicketmasterCountryCode | null | undefined;
              institutionUuid?: string | null | undefined;
              stateCode?: string | null | undefined;
            }
          | null
          | undefined;
        chatContextEnabled?:
          | {
              __typename?: 'ChatContextFeatureFlag';
              landmarks?:
                | {
                    __typename?: 'ChatContextTypeFeatureFlag';
                    main?: boolean | null | undefined;
                    map?: boolean | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
  mapLimits?:
    | {
        __typename?: 'MapLimits';
        announcementRangeInHours?: number | null | undefined;
        eventRangeInHours?: number | null | undefined;
        centerMapToCityIfDistanceLessThanKm?: number | null | undefined;
      }
    | null
    | undefined;
  badgesImage?:
    | {
        __typename?: 'Image';
        id: string;
        fileName?: string | null | undefined;
        filePath?: string | null | undefined;
        links?: { __typename?: 'Links'; self: string } | null | undefined;
      }
    | null
    | undefined;
  sectionImages?:
    | {
        __typename?: 'SectionImages';
        attractions?:
          | {
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }
          | null
          | undefined;
        museums?:
          | {
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }
          | null
          | undefined;
        theatre?:
          | {
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }
          | null
          | undefined;
        concerts?:
          | {
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }
          | null
          | undefined;
        sports?:
          | {
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }
          | null
          | undefined;
        cell?:
          | {
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  welcomeScreenImage?:
    | {
        __typename?: 'Image';
        id: string;
        fileName?: string | null | undefined;
        filePath?: string | null | undefined;
        links?: { __typename?: 'Links'; self: string } | null | undefined;
      }
    | null
    | undefined;
  mapIntroImage?:
    | {
        __typename?: 'Image';
        id: string;
        fileName?: string | null | undefined;
        filePath?: string | null | undefined;
        links?: { __typename?: 'Links'; self: string } | null | undefined;
      }
    | null
    | undefined;
};

export type SaAddPlaceMutationVariables = Exact<{
  place: PlaceInput;
}>;

export type SaAddPlaceMutation = {
  __typename?: 'Mutation';
  addPlace: { __typename?: 'Place'; id: string };
};

export type SaDeletePlaceMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type SaDeletePlaceMutation = {
  __typename?: 'Mutation';
  deletePlace?: { __typename?: 'Place'; id: string } | null | undefined;
};

export type SaHidePlaceMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type SaHidePlaceMutation = {
  __typename?: 'Mutation';
  hidePlace?: { __typename?: 'Place'; id: string } | null | undefined;
};

export type SaUpdatePlaceMutationVariables = Exact<{
  id: Scalars['String'];
  place: PlaceInput;
}>;

export type SaUpdatePlaceMutation = {
  __typename?: 'Mutation';
  updatePlace: { __typename?: 'Place'; id: string };
};

export type SaPlaceQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type SaPlaceQuery = {
  __typename?: 'Query';
  place?:
    | {
        __typename?: 'Place';
        defaultLanguage?: string | null | undefined;
        countryCode: string;
        countryName: string;
        createdAt: string;
        id: string;
        name: string;
        isDistrict?: boolean | null | undefined;
        hasDistricts?: boolean | null | undefined;
        borough?: string | null | undefined;
        parentPlaceUuid?: string | null | undefined;
        stateCode: string;
        stateName: string;
        timezoneCode: string;
        enabled?: string | null | undefined;
        additionalGroupsOfInstitutions?: Array<InstitutionPlaceRole> | null | undefined;
        sharingUrl?: string | null | undefined;
        visitorWelcomeVideoUrl?: string | null | undefined;
        isVisitorModeAvailable?: boolean | null | undefined;
        isItineraryModeAvailable?: boolean | null | undefined;
        isResidentModeAvailable?: boolean | null | undefined;
        isOgCity?: boolean | null | undefined;
        disabledContent?: Array<PlaceContent> | null | undefined;
        localCuisines?: Array<RestaurantCuisine> | null | undefined;
        popularDestination?: boolean | null | undefined;
        trafficAvgSpeed?: number | null | undefined;
        gps: { __typename?: 'GeoPoint'; lat: number; lon: number };
        dashboardImages?:
          | {
              __typename?: 'DashboardImages';
              forYou: Array<{
                __typename?: 'Image';
                id: string;
                fileName?: string | null | undefined;
                filePath?: string | null | undefined;
                links?: { __typename?: 'Links'; self: string } | null | undefined;
              }>;
              restaurants: Array<{
                __typename?: 'Image';
                id: string;
                fileName?: string | null | undefined;
                filePath?: string | null | undefined;
                links?: { __typename?: 'Links'; self: string } | null | undefined;
              }>;
              landmarks: Array<{
                __typename?: 'Image';
                id: string;
                fileName?: string | null | undefined;
                filePath?: string | null | undefined;
                links?: { __typename?: 'Links'; self: string } | null | undefined;
              }>;
              attractions: Array<{
                __typename?: 'Image';
                id: string;
                fileName?: string | null | undefined;
                filePath?: string | null | undefined;
                links?: { __typename?: 'Links'; self: string } | null | undefined;
              }>;
              events: Array<{
                __typename?: 'Image';
                id: string;
                fileName?: string | null | undefined;
                filePath?: string | null | undefined;
                links?: { __typename?: 'Links'; self: string } | null | undefined;
              }>;
            }
          | null
          | undefined;
        info?:
          | {
              __typename?: 'PlaceInfo';
              reportsEmail?: string | null | undefined;
              about?: string | null | undefined;
              aboutHeader?: string | null | undefined;
              description?: string | null | undefined;
            }
          | null
          | undefined;
        branding?:
          | {
              __typename?: 'BrandingPlace';
              logo?:
                | {
                    __typename?: 'Image';
                    id: string;
                    fileName?: string | null | undefined;
                    filePath?: string | null | undefined;
                    links?: { __typename?: 'Links'; self: string } | null | undefined;
                  }
                | null
                | undefined;
              avatar?:
                | {
                    __typename?: 'Image';
                    id: string;
                    fileName?: string | null | undefined;
                    filePath?: string | null | undefined;
                    links?: { __typename?: 'Links'; self: string } | null | undefined;
                  }
                | null
                | undefined;
              background?:
                | {
                    __typename?: 'Image';
                    id: string;
                    fileName?: string | null | undefined;
                    filePath?: string | null | undefined;
                    links?: { __typename?: 'Links'; self: string } | null | undefined;
                  }
                | null
                | undefined;
              topTenBackground?:
                | {
                    __typename?: 'Image';
                    id: string;
                    fileName?: string | null | undefined;
                    filePath?: string | null | undefined;
                    links?: { __typename?: 'Links'; self: string } | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        featureFlags?:
          | Array<{
              __typename?: 'PlaceFeatureFlag';
              visitorMode?: boolean | null | undefined;
              isSystem?: boolean | null | undefined;
              title?: string | null | undefined;
              description?: string | null | undefined;
              url?: string | null | undefined;
              enabled?: boolean | null | undefined;
              order?: number | null | undefined;
              type?: FeatureFlagInternalType | null | undefined;
              allowedPaymentReceivers?: Array<PaymentReceiver> | null | undefined;
              messagingProblemIsLaneAvailable?: boolean | null | undefined;
              messagingProblemCategoryUuid?: string | null | undefined;
              isFullnameRequired?: boolean | null | undefined;
              disableProblemsOnMap?: boolean | null | undefined;
              tuiCityId?: number | null | undefined;
              tuiCountryCode?: string | null | undefined;
              tuiIsSandbox?: boolean | null | undefined;
              theInfatuationPlaceId?: string | null | undefined;
              viatorDestination?: string | null | undefined;
              icon?:
                | {
                    __typename?: 'Image';
                    id: string;
                    blurHash?: string | null | undefined;
                    fileName?: string | null | undefined;
                    filePath?: string | null | undefined;
                    links?:
                      | {
                          __typename?: 'Links';
                          self: string;
                          thumbnail?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              titleLocalizations?:
                | Array<{ __typename?: 'TitleLocalization'; title: string; language: string }>
                | null
                | undefined;
              delightedSettings?:
                | { __typename?: 'DelightedSettings'; delightedID: string }
                | null
                | undefined;
              chatBotSettings?:
                | {
                    __typename?: 'ChatBotSettings';
                    topics?: Array<ChatGptTopic> | null | undefined;
                    knowledgeBase?: ChatGptKnowledgeBase | null | undefined;
                  }
                | null
                | undefined;
              reportedProblemsSettings?:
                | {
                    __typename?: 'ReportedProblemsSettings';
                    isLaneAvailable?: boolean | null | undefined;
                    connectorTypes?: Array<MessagingProblemConnectorType> | null | undefined;
                    showCategories?: boolean | null | undefined;
                    isMediaRequired?: boolean | null | undefined;
                    potholeCategoryUuid?: string | null | undefined;
                  }
                | null
                | undefined;
              plateCheckerSettings?:
                | {
                    __typename?: 'PlateCheckerSettings';
                    connector?: PlateCheckerConnector | null | undefined;
                  }
                | null
                | undefined;
              payParkingSettings?:
                | {
                    __typename?: 'PayParkingSettings';
                    connectorTypes?: Array<string> | null | undefined;
                  }
                | null
                | undefined;
              ticketmasterSettings?:
                | {
                    __typename?: 'TicketmasterSettings';
                    placeName?: string | null | undefined;
                    countryCode?: TicketmasterCountryCode | null | undefined;
                    institutionUuid?: string | null | undefined;
                    stateCode?: string | null | undefined;
                  }
                | null
                | undefined;
              chatContextEnabled?:
                | {
                    __typename?: 'ChatContextFeatureFlag';
                    landmarks?:
                      | {
                          __typename?: 'ChatContextTypeFeatureFlag';
                          main?: boolean | null | undefined;
                          map?: boolean | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        mapLimits?:
          | {
              __typename?: 'MapLimits';
              announcementRangeInHours?: number | null | undefined;
              eventRangeInHours?: number | null | undefined;
              centerMapToCityIfDistanceLessThanKm?: number | null | undefined;
            }
          | null
          | undefined;
        badgesImage?:
          | {
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }
          | null
          | undefined;
        sectionImages?:
          | {
              __typename?: 'SectionImages';
              attractions?:
                | {
                    __typename?: 'Image';
                    id: string;
                    fileName?: string | null | undefined;
                    filePath?: string | null | undefined;
                    links?: { __typename?: 'Links'; self: string } | null | undefined;
                  }
                | null
                | undefined;
              museums?:
                | {
                    __typename?: 'Image';
                    id: string;
                    fileName?: string | null | undefined;
                    filePath?: string | null | undefined;
                    links?: { __typename?: 'Links'; self: string } | null | undefined;
                  }
                | null
                | undefined;
              theatre?:
                | {
                    __typename?: 'Image';
                    id: string;
                    fileName?: string | null | undefined;
                    filePath?: string | null | undefined;
                    links?: { __typename?: 'Links'; self: string } | null | undefined;
                  }
                | null
                | undefined;
              concerts?:
                | {
                    __typename?: 'Image';
                    id: string;
                    fileName?: string | null | undefined;
                    filePath?: string | null | undefined;
                    links?: { __typename?: 'Links'; self: string } | null | undefined;
                  }
                | null
                | undefined;
              sports?:
                | {
                    __typename?: 'Image';
                    id: string;
                    fileName?: string | null | undefined;
                    filePath?: string | null | undefined;
                    links?: { __typename?: 'Links'; self: string } | null | undefined;
                  }
                | null
                | undefined;
              cell?:
                | {
                    __typename?: 'Image';
                    id: string;
                    fileName?: string | null | undefined;
                    filePath?: string | null | undefined;
                    links?: { __typename?: 'Links'; self: string } | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        welcomeScreenImage?:
          | {
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }
          | null
          | undefined;
        mapIntroImage?:
          | {
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SaPlacesQueryVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>;
  pageOffset?: Maybe<Scalars['Int']>;
  gpsLocation?: Maybe<GeoPointInput>;
  parentPlaceUuid?: Maybe<Scalars['String']>;
  includeDistricts?: Maybe<Scalars['Boolean']>;
  prefix?: Maybe<Scalars['String']>;
  visitorMode?: Maybe<Scalars['Boolean']>;
}>;

export type SaPlacesQuery = {
  __typename?: 'Query';
  places: {
    __typename?: 'PaginatedPlaces';
    totalCount?: number | null | undefined;
    places: Array<{
      __typename?: 'Place';
      id: string;
      name: string;
      countryName: string;
      stateName: string;
      createdAt: string;
      isDistrict?: boolean | null | undefined;
      hasDistricts?: boolean | null | undefined;
      borough?: string | null | undefined;
      parentPlaceUuid?: string | null | undefined;
      timezoneCode: string;
      enabled?: string | null | undefined;
      branding?:
        | {
            __typename?: 'BrandingPlace';
            avatar?:
              | {
                  __typename?: 'Image';
                  id: string;
                  links?: { __typename?: 'Links'; self: string } | null | undefined;
                }
              | null
              | undefined;
            logo?:
              | {
                  __typename?: 'Image';
                  id: string;
                  links?: { __typename?: 'Links'; self: string } | null | undefined;
                }
              | null
              | undefined;
            background?:
              | {
                  __typename?: 'Image';
                  id: string;
                  links?: { __typename?: 'Links'; self: string } | null | undefined;
                }
              | null
              | undefined;
            topTenBackground?:
              | {
                  __typename?: 'Image';
                  id: string;
                  links?: { __typename?: 'Links'; self: string } | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
    }>;
  };
};

export type SaAddSelectorMutationVariables = Exact<{
  selector: ScrapeSelectorInput;
}>;

export type SaAddSelectorMutation = {
  __typename?: 'Mutation';
  addScrapeSelector?: { __typename?: 'ScrapeSelector'; code: string } | null | undefined;
};

export type SaDeleteSelectorMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type SaDeleteSelectorMutation = {
  __typename?: 'Mutation';
  deleteScrapeSelector?: { __typename?: 'ScrapeSelector'; code: string } | null | undefined;
};

export type SaDeleteScrapeNewsByCodeMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type SaDeleteScrapeNewsByCodeMutation = {
  __typename?: 'Mutation';
  deleteScrapeNewsByCode?: { __typename?: 'Message'; message: string } | null | undefined;
};

export type SaDeleteScrapeEventsByCodeMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type SaDeleteScrapeEventsByCodeMutation = {
  __typename?: 'Mutation';
  deleteScrapeEventsByCode?: { __typename?: 'Message'; message: string } | null | undefined;
};

export type SaAllSelectorsQueryVariables = Exact<{
  affix?: Maybe<Scalars['String']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type SaAllSelectorsQuery = {
  __typename?: 'Query';
  allScrapeSelectors: {
    __typename?: 'PaginatedScrapeSelectors';
    nextToken?: string | null | undefined;
    selectors: Array<{
      __typename?: 'ScrapeSelector';
      code: string;
      nodeSelector: Array<string>;
      subpageUrlFormat?: string | null | undefined;
      imports: Array<string>;
      removePageTrash: Array<string>;
      maxPages: number;
      originalSourceOverride?: string | null | undefined;
      subpageSelector: Array<{
        __typename?: 'ScrapeSingleSelector';
        collect: Array<string>;
        remove: Array<string>;
        regex?: string | null | undefined;
        replace: Array<Array<string>>;
      }>;
      variables: Array<{ __typename?: 'ScrapeVariable'; key: string; value: string }>;
      nodeSelectors: Array<{
        __typename?: 'ScrapeItemSelector';
        field: string;
        selector: Array<{
          __typename?: 'ScrapeSingleSelector';
          collect: Array<string>;
          remove: Array<string>;
          regex?: string | null | undefined;
          replace: Array<Array<string>>;
        }>;
      }>;
      subpageSelectors: Array<{
        __typename?: 'ScrapeItemSelector';
        field: string;
        selector: Array<{
          __typename?: 'ScrapeSingleSelector';
          collect: Array<string>;
          remove: Array<string>;
          regex?: string | null | undefined;
          replace: Array<Array<string>>;
        }>;
      }>;
      nextPageSelector: Array<{
        __typename?: 'ScrapeSingleSelector';
        collect: Array<string>;
        remove: Array<string>;
        regex?: string | null | undefined;
        replace: Array<Array<string>>;
      }>;
    }>;
  };
};

export type SaSelectorByCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;

export type SaSelectorByCodeQuery = {
  __typename?: 'Query';
  scrapeSelector?:
    | {
        __typename?: 'ScrapeSelector';
        code: string;
        nodeSelector: Array<string>;
        subpageUrlFormat?: string | null | undefined;
        imports: Array<string>;
        removePageTrash: Array<string>;
        maxPages: number;
        paginationType?: PaginationType | null | undefined;
        originalSourceOverride?: string | null | undefined;
        subpageSelector: Array<{
          __typename?: 'ScrapeSingleSelector';
          collect: Array<string>;
          remove: Array<string>;
          regex?: string | null | undefined;
          replace: Array<Array<string>>;
        }>;
        variables: Array<{ __typename?: 'ScrapeVariable'; key: string; value: string }>;
        nodeSelectors: Array<{
          __typename?: 'ScrapeItemSelector';
          field: string;
          selector: Array<{
            __typename?: 'ScrapeSingleSelector';
            collect: Array<string>;
            remove: Array<string>;
            regex?: string | null | undefined;
            replace: Array<Array<string>>;
          }>;
        }>;
        subpageSelectors: Array<{
          __typename?: 'ScrapeItemSelector';
          field: string;
          selector: Array<{
            __typename?: 'ScrapeSingleSelector';
            collect: Array<string>;
            remove: Array<string>;
            regex?: string | null | undefined;
            replace: Array<Array<string>>;
          }>;
        }>;
        nextPageSelector: Array<{
          __typename?: 'ScrapeSingleSelector';
          collect: Array<string>;
          remove: Array<string>;
          regex?: string | null | undefined;
          replace: Array<Array<string>>;
        }>;
      }
    | null
    | undefined;
};

export type UpdateSystemStatusMutationVariables = Exact<{
  componentActions: Array<ComponentActionInput> | ComponentActionInput;
}>;

export type UpdateSystemStatusMutation = { __typename?: 'Mutation'; updateSystemStatus: string };

export type SystemStatusQueryVariables = Exact<{
  nextToken?: Maybe<Scalars['String']>;
}>;

export type SystemStatusQuery = {
  __typename?: 'Query';
  systemStatus: {
    __typename?: 'PaginatedComponentStates';
    componentActions: Array<{
      __typename?: 'ComponentAction';
      component: Component;
      componentState: ComponentState;
    }>;
  };
};

export type SaAddTaskMutationVariables = Exact<{
  task: ScrapeTaskInput;
}>;

export type SaAddTaskMutation = {
  __typename?: 'Mutation';
  allEnvAddScrapeTask?: { __typename?: 'ScrapeTask'; code: string } | null | undefined;
};

export type SaDeleteTaskMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type SaDeleteTaskMutation = {
  __typename?: 'Mutation';
  allEnvDeleteScrapeTask?: { __typename?: 'ScrapeTask'; code: string } | null | undefined;
};

export type SaAllTasksQueryVariables = Exact<{
  affix?: Maybe<Scalars['String']>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type SaAllTasksQuery = {
  __typename?: 'Query';
  allScrapeTasks: {
    __typename?: 'PaginatedScrapeTasks';
    nextToken?: string | null | undefined;
    tasks: Array<{
      __typename?: 'ScrapeTask';
      code: string;
      enabled: boolean;
      enabledStaging?: boolean | null | undefined;
      enabledTesting?: boolean | null | undefined;
      url: string;
      JSRender: boolean;
      contentType: ScrapeContentType;
      checkCodes: Array<string>;
      timeZone: string;
      proxy: boolean;
      lang: string;
      runSchedule?: string | null | undefined;
      dataType: ScrapeDataType;
      scraperType: ScrapeType;
      websiteTemplate?: string | null | undefined;
      subpageContentType: ScrapeContentType;
      visitorScraper?: boolean | null | undefined;
      streamingPlatform?: string | null | undefined;
      categories?:
        | Array<{ __typename?: 'ScrapeCategory'; id: string; name: string }>
        | null
        | undefined;
    }>;
  };
};

export type SaTaskByCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;

export type SaTaskByCodeQuery = {
  __typename?: 'Query';
  scrapeTaskByCode?:
    | {
        __typename?: 'ScrapeTask';
        code: string;
        enabled: boolean;
        enabledStaging?: boolean | null | undefined;
        enabledTesting?: boolean | null | undefined;
        url: string;
        JSRender: boolean;
        contentType: ScrapeContentType;
        checkCodes: Array<string>;
        timeZone: string;
        proxy: boolean;
        lang: string;
        runSchedule?: string | null | undefined;
        dataType: ScrapeDataType;
        scraperType: ScrapeType;
        websiteTemplate?: string | null | undefined;
        subpageContentType: ScrapeContentType;
        visitorScraper?: boolean | null | undefined;
        streamingPlatform?: string | null | undefined;
        categories?:
          | Array<{ __typename?: 'ScrapeCategory'; id: string; name: string }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const FullPlaceFragmentFragmentDoc = `
    fragment fullPlaceFragment on Place {
  countryCode
  countryName
  createdAt
  gps {
    lat
    lon
  }
  id
  name
  stateCode
  stateName
  timezoneCode
  info {
    aboutHeader
    about
    description
    reportsEmail
  }
  branding {
    logo {
      id
      links {
        self
      }
    }
    avatar {
      id
      links {
        self
      }
    }
  }
  features {
    enableCommunityFund
    enableMessaging
    enablePetDirectory
    enableRaffle
  }
  featureFlags {
    title
    url
    type
    enabled
  }
  translateTo
}
    `;
export const ItineraryOverviewFragmentFragmentDoc = `
    fragment ItineraryOverviewFragment on ItineraryOverview {
  dayOfWeek
  company
  priceLevel
  mode
  creationStatus
  createdAt
  date
  cloudWatchLogUrl
  isRetriable
  failReason
}
    `;
export const SaPlaceFeatureFlagFragmentFragmentDoc = `
    fragment SAPlaceFeatureFlagFragment on PlaceFeatureFlag {
  visitorMode
  isSystem
  icon {
    id
    links {
      self
      thumbnail
    }
    blurHash
    fileName
    filePath
  }
  title
  titleLocalizations {
    title
    language
  }
  description
  url
  enabled
  order
  type
  allowedPaymentReceivers
  messagingProblemIsLaneAvailable
  delightedSettings {
    delightedID
  }
  chatBotSettings {
    topics
    knowledgeBase
  }
  reportedProblemsSettings {
    isLaneAvailable
    connectorTypes
    showCategories
    isMediaRequired
    potholeCategoryUuid
  }
  messagingProblemCategoryUuid
  plateCheckerSettings {
    connector
  }
  isFullnameRequired
  disableProblemsOnMap
  payParkingSettings {
    connectorTypes
  }
  tuiCityId
  tuiCountryCode
  tuiIsSandbox
  ticketmasterSettings {
    placeName
    countryCode
    institutionUuid
    stateCode
  }
  theInfatuationPlaceId
  chatContextEnabled {
    landmarks {
      main
      map
    }
  }
  viatorDestination
}
    `;
export const SAfullPlaceFragmentFragmentDoc = `
    fragment SAfullPlaceFragment on Place {
  defaultLanguage
  countryCode
  countryName
  createdAt
  gps {
    lat
    lon
  }
  id
  name
  dashboardImages {
    forYou {
      id
      fileName
      filePath
      links {
        self
      }
    }
    restaurants {
      id
      fileName
      filePath
      links {
        self
      }
    }
    landmarks {
      id
      fileName
      filePath
      links {
        self
      }
    }
    attractions {
      id
      fileName
      filePath
      links {
        self
      }
    }
    events {
      id
      fileName
      filePath
      links {
        self
      }
    }
  }
  isDistrict
  hasDistricts
  borough
  parentPlaceUuid
  stateCode
  stateName
  timezoneCode
  enabled
  info {
    reportsEmail
    about
    aboutHeader
    description
  }
  branding {
    logo {
      id
      fileName
      filePath
      links {
        self
      }
    }
    avatar {
      id
      fileName
      filePath
      links {
        self
      }
    }
    background {
      id
      fileName
      filePath
      links {
        self
      }
    }
    topTenBackground {
      id
      fileName
      filePath
      links {
        self
      }
    }
  }
  featureFlags {
    ...SAPlaceFeatureFlagFragment
  }
  additionalGroupsOfInstitutions
  sharingUrl
  mapLimits {
    announcementRangeInHours
    eventRangeInHours
    centerMapToCityIfDistanceLessThanKm
  }
  visitorWelcomeVideoUrl
  isVisitorModeAvailable
  isItineraryModeAvailable
  isResidentModeAvailable
  isOgCity
  disabledContent
  localCuisines
  badgesImage {
    id
    fileName
    filePath
    links {
      self
    }
  }
  sectionImages {
    attractions {
      id
      fileName
      filePath
      links {
        self
      }
    }
    museums {
      id
      fileName
      filePath
      links {
        self
      }
    }
    theatre {
      id
      fileName
      filePath
      links {
        self
      }
    }
    concerts {
      id
      fileName
      filePath
      links {
        self
      }
    }
    sports {
      id
      fileName
      filePath
      links {
        self
      }
    }
    cell {
      id
      fileName
      filePath
      links {
        self
      }
    }
  }
  welcomeScreenImage {
    id
    fileName
    filePath
    links {
      self
    }
  }
  popularDestination
  trafficAvgSpeed
  mapIntroImage {
    id
    fileName
    filePath
    links {
      self
    }
  }
}
    ${SaPlaceFeatureFlagFragmentFragmentDoc}`;
export const AddMessagingCategoryDocument = `
    mutation addMessagingCategory($data: MessagingCategoryInput!) {
  addMessagingCategory(data: $data) {
    id
    placeUuid
    name
    description
    email
    additionalEmails
  }
}
    `;
export const useAddMessagingCategoryMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AddMessagingCategoryMutation,
    TError,
    AddMessagingCategoryMutationVariables,
    TContext
  >,
) =>
  useMutation<
    AddMessagingCategoryMutation,
    TError,
    AddMessagingCategoryMutationVariables,
    TContext
  >(
    (variables?: AddMessagingCategoryMutationVariables) =>
      fetcher<AddMessagingCategoryMutation, AddMessagingCategoryMutationVariables>(
        AddMessagingCategoryDocument,
        variables,
      )(),
    options,
  );
export const UpdateMessagingCategoryDocument = `
    mutation updateMessagingCategory($id: String!, $data: MessagingCategoryInput!) {
  updateMessagingCategory(id: $id, data: $data) {
    id
    placeUuid
    name
    description
    email
    additionalEmails
  }
}
    `;
export const useUpdateMessagingCategoryMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateMessagingCategoryMutation,
    TError,
    UpdateMessagingCategoryMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateMessagingCategoryMutation,
    TError,
    UpdateMessagingCategoryMutationVariables,
    TContext
  >(
    (variables?: UpdateMessagingCategoryMutationVariables) =>
      fetcher<UpdateMessagingCategoryMutation, UpdateMessagingCategoryMutationVariables>(
        UpdateMessagingCategoryDocument,
        variables,
      )(),
    options,
  );
export const DeleteMessagingCategoryDocument = `
    mutation deleteMessagingCategory($id: String!) {
  deleteMessagingCategory(id: $id) {
    id
    placeUuid
    name
    description
    email
    additionalEmails
  }
}
    `;
export const useDeleteMessagingCategoryMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteMessagingCategoryMutation,
    TError,
    DeleteMessagingCategoryMutationVariables,
    TContext
  >,
) =>
  useMutation<
    DeleteMessagingCategoryMutation,
    TError,
    DeleteMessagingCategoryMutationVariables,
    TContext
  >(
    (variables?: DeleteMessagingCategoryMutationVariables) =>
      fetcher<DeleteMessagingCategoryMutation, DeleteMessagingCategoryMutationVariables>(
        DeleteMessagingCategoryDocument,
        variables,
      )(),
    options,
  );
export const MessagingCategoryDocument = `
    query messagingCategory($id: String!) {
  messagingCategory(id: $id) {
    id
    placeUuid
    name
    description
    email
    additionalEmails
  }
}
    `;
export const useMessagingCategoryQuery = <TData = MessagingCategoryQuery, TError = unknown>(
  variables: MessagingCategoryQueryVariables,
  options?: UseQueryOptions<MessagingCategoryQuery, TError, TData>,
) =>
  useQuery<MessagingCategoryQuery, TError, TData>(
    ['messagingCategory', variables],
    fetcher<MessagingCategoryQuery, MessagingCategoryQueryVariables>(
      MessagingCategoryDocument,
      variables,
    ),
    options,
  );
useMessagingCategoryQuery.document = MessagingCategoryDocument;

useMessagingCategoryQuery.getKey = (variables: MessagingCategoryQueryVariables) => [
  'messagingCategory',
  variables,
];

export const MessagingCategoriesByDocument = `
    query messagingCategoriesBy($filter: MessagingCategoriesByFilterInput!, $nextToken: String) {
  messagingCategoriesBy(filter: $filter, nextToken: $nextToken) {
    messagingCategories {
      id
      placeUuid
      name
      description
      email
      additionalEmails
    }
    nextToken
  }
}
    `;
export const useMessagingCategoriesByQuery = <TData = MessagingCategoriesByQuery, TError = unknown>(
  variables: MessagingCategoriesByQueryVariables,
  options?: UseQueryOptions<MessagingCategoriesByQuery, TError, TData>,
) =>
  useQuery<MessagingCategoriesByQuery, TError, TData>(
    ['messagingCategoriesBy', variables],
    fetcher<MessagingCategoriesByQuery, MessagingCategoriesByQueryVariables>(
      MessagingCategoriesByDocument,
      variables,
    ),
    options,
  );
useMessagingCategoriesByQuery.document = MessagingCategoriesByDocument;

useMessagingCategoriesByQuery.getKey = (variables: MessagingCategoriesByQueryVariables) => [
  'messagingCategoriesBy',
  variables,
];

export const AllMessagingCategoriesDocument = `
    query allMessagingCategories($nextToken: String) {
  allMessagingCategories(nextToken: $nextToken) {
    messagingCategories {
      id
      placeUuid
      name
      description
      email
      additionalEmails
    }
    nextToken
  }
}
    `;
export const useAllMessagingCategoriesQuery = <
  TData = AllMessagingCategoriesQuery,
  TError = unknown,
>(
  variables?: AllMessagingCategoriesQueryVariables,
  options?: UseQueryOptions<AllMessagingCategoriesQuery, TError, TData>,
) =>
  useQuery<AllMessagingCategoriesQuery, TError, TData>(
    ['allMessagingCategories', variables],
    fetcher<AllMessagingCategoriesQuery, AllMessagingCategoriesQueryVariables>(
      AllMessagingCategoriesDocument,
      variables,
    ),
    options,
  );
useAllMessagingCategoriesQuery.document = AllMessagingCategoriesDocument;

useAllMessagingCategoriesQuery.getKey = (variables?: AllMessagingCategoriesQueryVariables) => [
  'allMessagingCategories',
  variables,
];

export const SetPlaceInfoDocument = `
    mutation setPlaceInfo($id: String!, $info: PlaceInfoInput!) {
  setPlaceInfo(id: $id, info: $info) {
    id
  }
}
    `;
export const useSetPlaceInfoMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SetPlaceInfoMutation,
    TError,
    SetPlaceInfoMutationVariables,
    TContext
  >,
) =>
  useMutation<SetPlaceInfoMutation, TError, SetPlaceInfoMutationVariables, TContext>(
    (variables?: SetPlaceInfoMutationVariables) =>
      fetcher<SetPlaceInfoMutation, SetPlaceInfoMutationVariables>(
        SetPlaceInfoDocument,
        variables,
      )(),
    options,
  );
export const PlaceDocument = `
    query Place($id: String!) {
  place(id: $id) {
    ...fullPlaceFragment
  }
}
    ${FullPlaceFragmentFragmentDoc}`;
export const usePlaceQuery = <TData = PlaceQuery, TError = unknown>(
  variables: PlaceQueryVariables,
  options?: UseQueryOptions<PlaceQuery, TError, TData>,
) =>
  useQuery<PlaceQuery, TError, TData>(
    ['Place', variables],
    fetcher<PlaceQuery, PlaceQueryVariables>(PlaceDocument, variables),
    options,
  );
usePlaceQuery.document = PlaceDocument;

usePlaceQuery.getKey = (variables: PlaceQueryVariables) => ['Place', variables];

export const PlaceFeautureFlagsDocument = `
    query PlaceFeautureFlags($id: String!) {
  place(id: $id) {
    featureFlags {
      title
      url
      type
      enabled
    }
  }
}
    `;
export const usePlaceFeautureFlagsQuery = <TData = PlaceFeautureFlagsQuery, TError = unknown>(
  variables: PlaceFeautureFlagsQueryVariables,
  options?: UseQueryOptions<PlaceFeautureFlagsQuery, TError, TData>,
) =>
  useQuery<PlaceFeautureFlagsQuery, TError, TData>(
    ['PlaceFeautureFlags', variables],
    fetcher<PlaceFeautureFlagsQuery, PlaceFeautureFlagsQueryVariables>(
      PlaceFeautureFlagsDocument,
      variables,
    ),
    options,
  );
usePlaceFeautureFlagsQuery.document = PlaceFeautureFlagsDocument;

usePlaceFeautureFlagsQuery.getKey = (variables: PlaceFeautureFlagsQueryVariables) => [
  'PlaceFeautureFlags',
  variables,
];

export const GeneratePostDocument = `
    query GeneratePost($institutionAddress: String!, $institutionName: String!, $postType: String!, $specification: String!) {
  generatePost(
    InstitutionAddress: $institutionAddress
    InstitutionName: $institutionName
    PostType: $postType
    Specification: $specification
  ) {
    PostContent
  }
}
    `;
export const useGeneratePostQuery = <TData = GeneratePostQuery, TError = unknown>(
  variables: GeneratePostQueryVariables,
  options?: UseQueryOptions<GeneratePostQuery, TError, TData>,
) =>
  useQuery<GeneratePostQuery, TError, TData>(
    ['GeneratePost', variables],
    fetcher<GeneratePostQuery, GeneratePostQueryVariables>(GeneratePostDocument, variables),
    options,
  );
useGeneratePostQuery.document = GeneratePostDocument;

useGeneratePostQuery.getKey = (variables: GeneratePostQueryVariables) => [
  'GeneratePost',
  variables,
];

export const AddMessagingProblemDocument = `
    mutation addMessagingProblem($data: MessagingProblemInput!) {
  addMessagingProblem(data: $data)
}
    `;
export const useAddMessagingProblemMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AddMessagingProblemMutation,
    TError,
    AddMessagingProblemMutationVariables,
    TContext
  >,
) =>
  useMutation<AddMessagingProblemMutation, TError, AddMessagingProblemMutationVariables, TContext>(
    (variables?: AddMessagingProblemMutationVariables) =>
      fetcher<AddMessagingProblemMutation, AddMessagingProblemMutationVariables>(
        AddMessagingProblemDocument,
        variables,
      )(),
    options,
  );
export const UpdateMessagingProblemDocument = `
    mutation updateMessagingProblem($data: MessagingProblemInput!, $id: String!) {
  updateMessagingProblem(data: $data, id: $id)
}
    `;
export const useUpdateMessagingProblemMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateMessagingProblemMutation,
    TError,
    UpdateMessagingProblemMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateMessagingProblemMutation,
    TError,
    UpdateMessagingProblemMutationVariables,
    TContext
  >(
    (variables?: UpdateMessagingProblemMutationVariables) =>
      fetcher<UpdateMessagingProblemMutation, UpdateMessagingProblemMutationVariables>(
        UpdateMessagingProblemDocument,
        variables,
      )(),
    options,
  );
export const DeleteMessagingProblemDocument = `
    mutation deleteMessagingProblem($id: String!) {
  deleteMessagingProblem(id: $id)
}
    `;
export const useDeleteMessagingProblemMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteMessagingProblemMutation,
    TError,
    DeleteMessagingProblemMutationVariables,
    TContext
  >,
) =>
  useMutation<
    DeleteMessagingProblemMutation,
    TError,
    DeleteMessagingProblemMutationVariables,
    TContext
  >(
    (variables?: DeleteMessagingProblemMutationVariables) =>
      fetcher<DeleteMessagingProblemMutation, DeleteMessagingProblemMutationVariables>(
        DeleteMessagingProblemDocument,
        variables,
      )(),
    options,
  );
export const MessagingProblemDocument = `
    query messagingProblem($id: String!) {
  messagingProblem(id: $id) {
    createdAt
    id
    geoPoint {
      lat
      lon
    }
    connector {
      type
      status
      statusMessage
      id
    }
    reporter
    lane
    installationUuid
    placeUuid
    categoryUuid
    email
    address
    geoPoint {
      lat
      lon
    }
    description
    imageObjects {
      id
      fileName
      filePath
      blurHash
      links {
        self
        thumbnail
      }
    }
    videoObjects {
      id
      fileName
      filePath
      links {
        self
        thumbnail
      }
    }
    status
    resolution
    commentFromAuthority
    commentAuthorityId
    cityIsNotCompetent
    cityIsNotCompetentTimestamp
  }
}
    `;
export const useMessagingProblemQuery = <TData = MessagingProblemQuery, TError = unknown>(
  variables: MessagingProblemQueryVariables,
  options?: UseQueryOptions<MessagingProblemQuery, TError, TData>,
) =>
  useQuery<MessagingProblemQuery, TError, TData>(
    ['messagingProblem', variables],
    fetcher<MessagingProblemQuery, MessagingProblemQueryVariables>(
      MessagingProblemDocument,
      variables,
    ),
    options,
  );
useMessagingProblemQuery.document = MessagingProblemDocument;

useMessagingProblemQuery.getKey = (variables: MessagingProblemQueryVariables) => [
  'messagingProblem',
  variables,
];

export const MessagingProblemsByDocument = `
    query messagingProblemsBy($filter: MessagingProblemsFilterInput!, $orderBy: MessagingProblemsOrderByInput, $pageOffset: Int, $pageSize: Int) {
  messagingProblemsBy(
    filter: $filter
    orderBy: $orderBy
    pageOffset: $pageOffset
    pageSize: $pageSize
  ) {
    messagingProblems {
      createdAt
      id
      reporter
      installationUuid
      placeUuid
      categoryUuid
      email
      address
      geoPoint {
        lat
        lon
      }
      description
      imageObjects {
        id
        fileName
        filePath
        blurHash
        links {
          self
          thumbnail
        }
      }
      status
      resolution
    }
    totalCount
  }
}
    `;
export const useMessagingProblemsByQuery = <TData = MessagingProblemsByQuery, TError = unknown>(
  variables: MessagingProblemsByQueryVariables,
  options?: UseQueryOptions<MessagingProblemsByQuery, TError, TData>,
) =>
  useQuery<MessagingProblemsByQuery, TError, TData>(
    ['messagingProblemsBy', variables],
    fetcher<MessagingProblemsByQuery, MessagingProblemsByQueryVariables>(
      MessagingProblemsByDocument,
      variables,
    ),
    options,
  );
useMessagingProblemsByQuery.document = MessagingProblemsByDocument;

useMessagingProblemsByQuery.getKey = (variables: MessagingProblemsByQueryVariables) => [
  'messagingProblemsBy',
  variables,
];

export const AllMessagingProblemsDocument = `
    query allMessagingProblems($pageOffset: Int, $pageSize: Int) {
  allMessagingProblems(pageOffset: $pageOffset, pageSize: $pageSize) {
    messagingProblems {
      createdAt
      id
      installationUuid
      placeUuid
      categoryUuid
      email
      address
      geoPoint {
        lat
        lon
      }
      description
      imageObjects {
        id
        fileName
        filePath
        blurHash
        links {
          self
          thumbnail
        }
      }
      status
      resolution
    }
    totalCount
  }
}
    `;
export const useAllMessagingProblemsQuery = <TData = AllMessagingProblemsQuery, TError = unknown>(
  variables?: AllMessagingProblemsQueryVariables,
  options?: UseQueryOptions<AllMessagingProblemsQuery, TError, TData>,
) =>
  useQuery<AllMessagingProblemsQuery, TError, TData>(
    ['allMessagingProblems', variables],
    fetcher<AllMessagingProblemsQuery, AllMessagingProblemsQueryVariables>(
      AllMessagingProblemsDocument,
      variables,
    ),
    options,
  );
useAllMessagingProblemsQuery.document = AllMessagingProblemsDocument;

useAllMessagingProblemsQuery.getKey = (variables?: AllMessagingProblemsQueryVariables) => [
  'allMessagingProblems',
  variables,
];

export const FailedPlaceItinerariesDocument = `
    query failedPlaceItineraries($placeUuid: ID!, $nextToken: String, $pageSize: Float) {
  failedPlaceItineraries(
    placeUuid: $placeUuid
    nextToken: $nextToken
    pageSize: $pageSize
  ) {
    itineraries {
      ...ItineraryOverviewFragment
    }
    nextToken
  }
}
    ${ItineraryOverviewFragmentFragmentDoc}`;
export const useFailedPlaceItinerariesQuery = <
  TData = FailedPlaceItinerariesQuery,
  TError = unknown,
>(
  variables: FailedPlaceItinerariesQueryVariables,
  options?: UseQueryOptions<FailedPlaceItinerariesQuery, TError, TData>,
) =>
  useQuery<FailedPlaceItinerariesQuery, TError, TData>(
    ['failedPlaceItineraries', variables],
    fetcher<FailedPlaceItinerariesQuery, FailedPlaceItinerariesQueryVariables>(
      FailedPlaceItinerariesDocument,
      variables,
    ),
    options,
  );
useFailedPlaceItinerariesQuery.document = FailedPlaceItinerariesDocument;

useFailedPlaceItinerariesQuery.getKey = (variables: FailedPlaceItinerariesQueryVariables) => [
  'failedPlaceItineraries',
  variables,
];

export const PlaceItinerariesDocument = `
    query placeItineraries($placeUuid: ID!, $nextToken: String, $pageSize: Float) {
  placeItineraries(
    placeUuid: $placeUuid
    nextToken: $nextToken
    pageSize: $pageSize
  ) {
    itineraries {
      ...ItineraryOverviewFragment
    }
    nextToken
  }
}
    ${ItineraryOverviewFragmentFragmentDoc}`;
export const usePlaceItinerariesQuery = <TData = PlaceItinerariesQuery, TError = unknown>(
  variables: PlaceItinerariesQueryVariables,
  options?: UseQueryOptions<PlaceItinerariesQuery, TError, TData>,
) =>
  useQuery<PlaceItinerariesQuery, TError, TData>(
    ['placeItineraries', variables],
    fetcher<PlaceItinerariesQuery, PlaceItinerariesQueryVariables>(
      PlaceItinerariesDocument,
      variables,
    ),
    options,
  );
usePlaceItinerariesQuery.document = PlaceItinerariesDocument;

usePlaceItinerariesQuery.getKey = (variables: PlaceItinerariesQueryVariables) => [
  'placeItineraries',
  variables,
];

export const RemoveLandmarkDocument = `
    mutation RemoveLandmark($id: String!) {
  removeLandmark(id: $id)
}
    `;
export const useRemoveLandmarkMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RemoveLandmarkMutation,
    TError,
    RemoveLandmarkMutationVariables,
    TContext
  >,
) =>
  useMutation<RemoveLandmarkMutation, TError, RemoveLandmarkMutationVariables, TContext>(
    (variables?: RemoveLandmarkMutationVariables) =>
      fetcher<RemoveLandmarkMutation, RemoveLandmarkMutationVariables>(
        RemoveLandmarkDocument,
        variables,
      )(),
    options,
  );
export const CreateLandmarkDocument = `
    mutation CreateLandmark($landmark: LandmarkInput!) {
  createLandmark(landmark: $landmark) {
    id
  }
}
    `;
export const useCreateLandmarkMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateLandmarkMutation,
    TError,
    CreateLandmarkMutationVariables,
    TContext
  >,
) =>
  useMutation<CreateLandmarkMutation, TError, CreateLandmarkMutationVariables, TContext>(
    (variables?: CreateLandmarkMutationVariables) =>
      fetcher<CreateLandmarkMutation, CreateLandmarkMutationVariables>(
        CreateLandmarkDocument,
        variables,
      )(),
    options,
  );
export const UpdateLandmarkDocument = `
    mutation UpdateLandmark($id: String!, $landmark: LandmarkInput!) {
  updateLandmark(id: $id, landmark: $landmark) {
    id
  }
}
    `;
export const useUpdateLandmarkMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateLandmarkMutation,
    TError,
    UpdateLandmarkMutationVariables,
    TContext
  >,
) =>
  useMutation<UpdateLandmarkMutation, TError, UpdateLandmarkMutationVariables, TContext>(
    (variables?: UpdateLandmarkMutationVariables) =>
      fetcher<UpdateLandmarkMutation, UpdateLandmarkMutationVariables>(
        UpdateLandmarkDocument,
        variables,
      )(),
    options,
  );
export const SaLandmarksDocument = `
    query SALandmarks($placeUuid: String!, $pageOffset: Int, $pageSize: Int, $query: String) {
  landmarks(
    placeUuid: $placeUuid
    pageOffset: $pageOffset
    pageSize: $pageSize
    query: $query
  ) {
    landmarks {
      id
      title
      additionalType
      rating
      userRatingCount
    }
    nextToken
  }
}
    `;
export const useSaLandmarksQuery = <TData = SaLandmarksQuery, TError = unknown>(
  variables: SaLandmarksQueryVariables,
  options?: UseQueryOptions<SaLandmarksQuery, TError, TData>,
) =>
  useQuery<SaLandmarksQuery, TError, TData>(
    ['SALandmarks', variables],
    fetcher<SaLandmarksQuery, SaLandmarksQueryVariables>(SaLandmarksDocument, variables),
    options,
  );
useSaLandmarksQuery.document = SaLandmarksDocument;

useSaLandmarksQuery.getKey = (variables: SaLandmarksQueryVariables) => ['SALandmarks', variables];

export const SaLandmarkDocument = `
    query SALandmark($id: String!) {
  landmark(id: $id) {
    id
    additionalType
    googlePlacesId
    gpsLocation {
      lat
      lon
    }
    imageObjects {
      id
      links {
        self
        thumbnail
        thumbnail1024x576
        thumbnail420x420
      }
      blurHash
      fileName
      filePath
      metadata {
        mimeType
        height
        width
      }
    }
    initials {
      question
      answer
      followups
    }
    isHiddenGem
    landmarkName
    primaryType
    rating
    title
    types
    userRatingCount
    placeUuid
    description
  }
}
    `;
export const useSaLandmarkQuery = <TData = SaLandmarkQuery, TError = unknown>(
  variables: SaLandmarkQueryVariables,
  options?: UseQueryOptions<SaLandmarkQuery, TError, TData>,
) =>
  useQuery<SaLandmarkQuery, TError, TData>(
    ['SALandmark', variables],
    fetcher<SaLandmarkQuery, SaLandmarkQueryVariables>(SaLandmarkDocument, variables),
    options,
  );
useSaLandmarkQuery.document = SaLandmarkDocument;

useSaLandmarkQuery.getKey = (variables: SaLandmarkQueryVariables) => ['SALandmark', variables];

export const SaMessagingCategoriesByDocument = `
    query SAMessagingCategoriesBy($filter: MessagingCategoriesByFilterInput!, $nextToken: String) {
  messagingCategoriesBy(filter: $filter, nextToken: $nextToken) {
    messagingCategories {
      id
      placeUuid
      name
      description
      email
      additionalEmails
    }
    nextToken
  }
}
    `;
export const useSaMessagingCategoriesByQuery = <
  TData = SaMessagingCategoriesByQuery,
  TError = unknown,
>(
  variables: SaMessagingCategoriesByQueryVariables,
  options?: UseQueryOptions<SaMessagingCategoriesByQuery, TError, TData>,
) =>
  useQuery<SaMessagingCategoriesByQuery, TError, TData>(
    ['SAMessagingCategoriesBy', variables],
    fetcher<SaMessagingCategoriesByQuery, SaMessagingCategoriesByQueryVariables>(
      SaMessagingCategoriesByDocument,
      variables,
    ),
    options,
  );
useSaMessagingCategoriesByQuery.document = SaMessagingCategoriesByDocument;

useSaMessagingCategoriesByQuery.getKey = (variables: SaMessagingCategoriesByQueryVariables) => [
  'SAMessagingCategoriesBy',
  variables,
];

export const SendVisitorPromoNotificationDocument = `
    mutation sendVisitorPromoNotification($data: VisitorPromoNotificationInput!, $placeId: String!) {
  visitorPromoNotification(data: $data, placeID: $placeId)
}
    `;
export const useSendVisitorPromoNotificationMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SendVisitorPromoNotificationMutation,
    TError,
    SendVisitorPromoNotificationMutationVariables,
    TContext
  >,
) =>
  useMutation<
    SendVisitorPromoNotificationMutation,
    TError,
    SendVisitorPromoNotificationMutationVariables,
    TContext
  >(
    (variables?: SendVisitorPromoNotificationMutationVariables) =>
      fetcher<SendVisitorPromoNotificationMutation, SendVisitorPromoNotificationMutationVariables>(
        SendVisitorPromoNotificationDocument,
        variables,
      )(),
    options,
  );
export const SaAddPlaceDocument = `
    mutation SAAddPlace($place: PlaceInput!) {
  addPlace(place: $place) {
    id
  }
}
    `;
export const useSaAddPlaceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<SaAddPlaceMutation, TError, SaAddPlaceMutationVariables, TContext>,
) =>
  useMutation<SaAddPlaceMutation, TError, SaAddPlaceMutationVariables, TContext>(
    (variables?: SaAddPlaceMutationVariables) =>
      fetcher<SaAddPlaceMutation, SaAddPlaceMutationVariables>(SaAddPlaceDocument, variables)(),
    options,
  );
export const SaDeletePlaceDocument = `
    mutation SADeletePlace($id: String!) {
  deletePlace(id: $id) {
    id
  }
}
    `;
export const useSaDeletePlaceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaDeletePlaceMutation,
    TError,
    SaDeletePlaceMutationVariables,
    TContext
  >,
) =>
  useMutation<SaDeletePlaceMutation, TError, SaDeletePlaceMutationVariables, TContext>(
    (variables?: SaDeletePlaceMutationVariables) =>
      fetcher<SaDeletePlaceMutation, SaDeletePlaceMutationVariables>(
        SaDeletePlaceDocument,
        variables,
      )(),
    options,
  );
export const SaHidePlaceDocument = `
    mutation SAHidePlace($id: String!) {
  hidePlace(id: $id) {
    id
  }
}
    `;
export const useSaHidePlaceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<SaHidePlaceMutation, TError, SaHidePlaceMutationVariables, TContext>,
) =>
  useMutation<SaHidePlaceMutation, TError, SaHidePlaceMutationVariables, TContext>(
    (variables?: SaHidePlaceMutationVariables) =>
      fetcher<SaHidePlaceMutation, SaHidePlaceMutationVariables>(SaHidePlaceDocument, variables)(),
    options,
  );
export const SaUpdatePlaceDocument = `
    mutation SAUpdatePlace($id: String!, $place: PlaceInput!) {
  updatePlace(id: $id, place: $place) {
    id
  }
}
    `;
export const useSaUpdatePlaceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaUpdatePlaceMutation,
    TError,
    SaUpdatePlaceMutationVariables,
    TContext
  >,
) =>
  useMutation<SaUpdatePlaceMutation, TError, SaUpdatePlaceMutationVariables, TContext>(
    (variables?: SaUpdatePlaceMutationVariables) =>
      fetcher<SaUpdatePlaceMutation, SaUpdatePlaceMutationVariables>(
        SaUpdatePlaceDocument,
        variables,
      )(),
    options,
  );
export const SaPlaceDocument = `
    query SAPlace($id: String!) {
  place(id: $id) {
    ...SAfullPlaceFragment
  }
}
    ${SAfullPlaceFragmentFragmentDoc}`;
export const useSaPlaceQuery = <TData = SaPlaceQuery, TError = unknown>(
  variables: SaPlaceQueryVariables,
  options?: UseQueryOptions<SaPlaceQuery, TError, TData>,
) =>
  useQuery<SaPlaceQuery, TError, TData>(
    ['SAPlace', variables],
    fetcher<SaPlaceQuery, SaPlaceQueryVariables>(SaPlaceDocument, variables),
    options,
  );
useSaPlaceQuery.document = SaPlaceDocument;

useSaPlaceQuery.getKey = (variables: SaPlaceQueryVariables) => ['SAPlace', variables];

export const SaPlacesDocument = `
    query SAPlaces($pageSize: Int, $pageOffset: Int, $gpsLocation: GeoPointInput, $parentPlaceUuid: String, $includeDistricts: Boolean, $prefix: String, $visitorMode: Boolean) {
  places(
    pageSize: $pageSize
    gpsLocation: $gpsLocation
    parentPlaceUuid: $parentPlaceUuid
    includeDistricts: $includeDistricts
    pageOffset: $pageOffset
    prefix: $prefix
    visitorMode: $visitorMode
  ) {
    places {
      id
      name
      countryName
      stateName
      createdAt
      isDistrict
      hasDistricts
      borough
      parentPlaceUuid
      timezoneCode
      enabled
      branding {
        avatar {
          id
          links {
            self
          }
        }
        logo {
          id
          links {
            self
          }
        }
        background {
          id
          links {
            self
          }
        }
        topTenBackground {
          id
          links {
            self
          }
        }
      }
    }
    totalCount
  }
}
    `;
export const useSaPlacesQuery = <TData = SaPlacesQuery, TError = unknown>(
  variables?: SaPlacesQueryVariables,
  options?: UseQueryOptions<SaPlacesQuery, TError, TData>,
) =>
  useQuery<SaPlacesQuery, TError, TData>(
    ['SAPlaces', variables],
    fetcher<SaPlacesQuery, SaPlacesQueryVariables>(SaPlacesDocument, variables),
    options,
  );
useSaPlacesQuery.document = SaPlacesDocument;

useSaPlacesQuery.getKey = (variables?: SaPlacesQueryVariables) => ['SAPlaces', variables];

export const SaAddSelectorDocument = `
    mutation SAAddSelector($selector: ScrapeSelectorInput!) {
  addScrapeSelector(selector: $selector) {
    code
  }
}
    `;
export const useSaAddSelectorMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaAddSelectorMutation,
    TError,
    SaAddSelectorMutationVariables,
    TContext
  >,
) =>
  useMutation<SaAddSelectorMutation, TError, SaAddSelectorMutationVariables, TContext>(
    (variables?: SaAddSelectorMutationVariables) =>
      fetcher<SaAddSelectorMutation, SaAddSelectorMutationVariables>(
        SaAddSelectorDocument,
        variables,
      )(),
    options,
  );
export const SaDeleteSelectorDocument = `
    mutation SADeleteSelector($code: String!) {
  deleteScrapeSelector(code: $code) {
    code
  }
}
    `;
export const useSaDeleteSelectorMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaDeleteSelectorMutation,
    TError,
    SaDeleteSelectorMutationVariables,
    TContext
  >,
) =>
  useMutation<SaDeleteSelectorMutation, TError, SaDeleteSelectorMutationVariables, TContext>(
    (variables?: SaDeleteSelectorMutationVariables) =>
      fetcher<SaDeleteSelectorMutation, SaDeleteSelectorMutationVariables>(
        SaDeleteSelectorDocument,
        variables,
      )(),
    options,
  );
export const SaDeleteScrapeNewsByCodeDocument = `
    mutation SADeleteScrapeNewsByCode($code: String!) {
  deleteScrapeNewsByCode(code: $code) {
    message
  }
}
    `;
export const useSaDeleteScrapeNewsByCodeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaDeleteScrapeNewsByCodeMutation,
    TError,
    SaDeleteScrapeNewsByCodeMutationVariables,
    TContext
  >,
) =>
  useMutation<
    SaDeleteScrapeNewsByCodeMutation,
    TError,
    SaDeleteScrapeNewsByCodeMutationVariables,
    TContext
  >(
    (variables?: SaDeleteScrapeNewsByCodeMutationVariables) =>
      fetcher<SaDeleteScrapeNewsByCodeMutation, SaDeleteScrapeNewsByCodeMutationVariables>(
        SaDeleteScrapeNewsByCodeDocument,
        variables,
      )(),
    options,
  );
export const SaDeleteScrapeEventsByCodeDocument = `
    mutation SADeleteScrapeEventsByCode($code: String!) {
  deleteScrapeEventsByCode(code: $code) {
    message
  }
}
    `;
export const useSaDeleteScrapeEventsByCodeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaDeleteScrapeEventsByCodeMutation,
    TError,
    SaDeleteScrapeEventsByCodeMutationVariables,
    TContext
  >,
) =>
  useMutation<
    SaDeleteScrapeEventsByCodeMutation,
    TError,
    SaDeleteScrapeEventsByCodeMutationVariables,
    TContext
  >(
    (variables?: SaDeleteScrapeEventsByCodeMutationVariables) =>
      fetcher<SaDeleteScrapeEventsByCodeMutation, SaDeleteScrapeEventsByCodeMutationVariables>(
        SaDeleteScrapeEventsByCodeDocument,
        variables,
      )(),
    options,
  );
export const SaAllSelectorsDocument = `
    query SAAllSelectors($affix: String, $nextToken: String) {
  allScrapeSelectors(affix: $affix, nextToken: $nextToken) {
    selectors {
      code
      nodeSelector
      subpageSelector {
        collect
        remove
        regex
        replace
      }
      subpageUrlFormat
      imports
      variables {
        key
        value
      }
      nodeSelectors {
        field
        selector {
          collect
          remove
          regex
          replace
        }
      }
      subpageSelectors {
        field
        selector {
          collect
          remove
          regex
          replace
        }
      }
      removePageTrash
      nextPageSelector {
        collect
        remove
        regex
        replace
      }
      maxPages
      originalSourceOverride
    }
    nextToken
  }
}
    `;
export const useSaAllSelectorsQuery = <TData = SaAllSelectorsQuery, TError = unknown>(
  variables?: SaAllSelectorsQueryVariables,
  options?: UseQueryOptions<SaAllSelectorsQuery, TError, TData>,
) =>
  useQuery<SaAllSelectorsQuery, TError, TData>(
    ['SAAllSelectors', variables],
    fetcher<SaAllSelectorsQuery, SaAllSelectorsQueryVariables>(SaAllSelectorsDocument, variables),
    options,
  );
useSaAllSelectorsQuery.document = SaAllSelectorsDocument;

useSaAllSelectorsQuery.getKey = (variables?: SaAllSelectorsQueryVariables) => [
  'SAAllSelectors',
  variables,
];

export const SaSelectorByCodeDocument = `
    query SASelectorByCode($code: String!) {
  scrapeSelector(code: $code) {
    code
    nodeSelector
    subpageSelector {
      collect
      remove
      regex
      replace
    }
    subpageUrlFormat
    imports
    variables {
      key
      value
    }
    nodeSelectors {
      field
      selector {
        collect
        remove
        regex
        replace
      }
    }
    subpageSelectors {
      field
      selector {
        collect
        remove
        regex
        replace
      }
    }
    removePageTrash
    nextPageSelector {
      collect
      remove
      regex
      replace
    }
    maxPages
    paginationType
    originalSourceOverride
  }
}
    `;
export const useSaSelectorByCodeQuery = <TData = SaSelectorByCodeQuery, TError = unknown>(
  variables: SaSelectorByCodeQueryVariables,
  options?: UseQueryOptions<SaSelectorByCodeQuery, TError, TData>,
) =>
  useQuery<SaSelectorByCodeQuery, TError, TData>(
    ['SASelectorByCode', variables],
    fetcher<SaSelectorByCodeQuery, SaSelectorByCodeQueryVariables>(
      SaSelectorByCodeDocument,
      variables,
    ),
    options,
  );
useSaSelectorByCodeQuery.document = SaSelectorByCodeDocument;

useSaSelectorByCodeQuery.getKey = (variables: SaSelectorByCodeQueryVariables) => [
  'SASelectorByCode',
  variables,
];

export const UpdateSystemStatusDocument = `
    mutation updateSystemStatus($componentActions: [ComponentActionInput!]!) {
  updateSystemStatus(data: $componentActions)
}
    `;
export const useUpdateSystemStatusMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateSystemStatusMutation,
    TError,
    UpdateSystemStatusMutationVariables,
    TContext
  >,
) =>
  useMutation<UpdateSystemStatusMutation, TError, UpdateSystemStatusMutationVariables, TContext>(
    (variables?: UpdateSystemStatusMutationVariables) =>
      fetcher<UpdateSystemStatusMutation, UpdateSystemStatusMutationVariables>(
        UpdateSystemStatusDocument,
        variables,
      )(),
    options,
  );
export const SystemStatusDocument = `
    query systemStatus($nextToken: String) {
  systemStatus(nextToken: $nextToken) {
    componentActions {
      component
      componentState
    }
  }
}
    `;
export const useSystemStatusQuery = <TData = SystemStatusQuery, TError = unknown>(
  variables?: SystemStatusQueryVariables,
  options?: UseQueryOptions<SystemStatusQuery, TError, TData>,
) =>
  useQuery<SystemStatusQuery, TError, TData>(
    ['systemStatus', variables],
    fetcher<SystemStatusQuery, SystemStatusQueryVariables>(SystemStatusDocument, variables),
    options,
  );
useSystemStatusQuery.document = SystemStatusDocument;

useSystemStatusQuery.getKey = (variables?: SystemStatusQueryVariables) => [
  'systemStatus',
  variables,
];

export const SaAddTaskDocument = `
    mutation SAAddTask($task: ScrapeTaskInput!) {
  allEnvAddScrapeTask(task: $task) {
    code
  }
}
    `;
export const useSaAddTaskMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<SaAddTaskMutation, TError, SaAddTaskMutationVariables, TContext>,
) =>
  useMutation<SaAddTaskMutation, TError, SaAddTaskMutationVariables, TContext>(
    (variables?: SaAddTaskMutationVariables) =>
      fetcher<SaAddTaskMutation, SaAddTaskMutationVariables>(SaAddTaskDocument, variables)(),
    options,
  );
export const SaDeleteTaskDocument = `
    mutation SADeleteTask($code: String!) {
  allEnvDeleteScrapeTask(code: $code) {
    code
  }
}
    `;
export const useSaDeleteTaskMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaDeleteTaskMutation,
    TError,
    SaDeleteTaskMutationVariables,
    TContext
  >,
) =>
  useMutation<SaDeleteTaskMutation, TError, SaDeleteTaskMutationVariables, TContext>(
    (variables?: SaDeleteTaskMutationVariables) =>
      fetcher<SaDeleteTaskMutation, SaDeleteTaskMutationVariables>(
        SaDeleteTaskDocument,
        variables,
      )(),
    options,
  );
export const SaAllTasksDocument = `
    query SAAllTasks($affix: String, $nextToken: String) {
  allScrapeTasks(affix: $affix, nextToken: $nextToken) {
    tasks {
      code
      enabled
      enabledStaging
      enabledTesting
      url
      JSRender
      contentType
      checkCodes
      timeZone
      proxy
      lang
      runSchedule
      categories {
        id
        name
      }
      dataType
      scraperType
      websiteTemplate
      subpageContentType
      visitorScraper
      streamingPlatform
    }
    nextToken
  }
}
    `;
export const useSaAllTasksQuery = <TData = SaAllTasksQuery, TError = unknown>(
  variables?: SaAllTasksQueryVariables,
  options?: UseQueryOptions<SaAllTasksQuery, TError, TData>,
) =>
  useQuery<SaAllTasksQuery, TError, TData>(
    ['SAAllTasks', variables],
    fetcher<SaAllTasksQuery, SaAllTasksQueryVariables>(SaAllTasksDocument, variables),
    options,
  );
useSaAllTasksQuery.document = SaAllTasksDocument;

useSaAllTasksQuery.getKey = (variables?: SaAllTasksQueryVariables) => ['SAAllTasks', variables];

export const SaTaskByCodeDocument = `
    query SATaskByCode($code: String!) {
  scrapeTaskByCode(code: $code) {
    code
    enabled
    enabledStaging
    enabledTesting
    url
    JSRender
    contentType
    checkCodes
    timeZone
    proxy
    lang
    runSchedule
    categories {
      id
      name
    }
    dataType
    scraperType
    websiteTemplate
    subpageContentType
    visitorScraper
    streamingPlatform
  }
}
    `;
export const useSaTaskByCodeQuery = <TData = SaTaskByCodeQuery, TError = unknown>(
  variables: SaTaskByCodeQueryVariables,
  options?: UseQueryOptions<SaTaskByCodeQuery, TError, TData>,
) =>
  useQuery<SaTaskByCodeQuery, TError, TData>(
    ['SATaskByCode', variables],
    fetcher<SaTaskByCodeQuery, SaTaskByCodeQueryVariables>(SaTaskByCodeDocument, variables),
    options,
  );
useSaTaskByCodeQuery.document = SaTaskByCodeDocument;

useSaTaskByCodeQuery.getKey = (variables: SaTaskByCodeQueryVariables) => [
  'SATaskByCode',
  variables,
];

export { fetcher };
