import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from '../utils/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `AWSDate` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 Date](https://en.wikipedia.org/wiki/ISO_8601#Calendar_dates) string. In other words, this scalar type accepts date strings of the form `YYYY-MM-DD`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-05-01**" and "**-9999-01-01**" are both valid dates.  This scalar type can also accept an optional [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators). For example, "**1970-01-01**", "**1970-01-01Z**", "**1970-01-01-07:00**" and "**1970-01-01+05:30**" are all valid dates. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDate: string;
  /** The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) string. In other words, this scalar type accepts datetime strings of the form `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime strings.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  The [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is compulsory for this scalar. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: string;
  /** The `AWSEmail` scalar type provided by AWS AppSync, represents an Email address string that complies with [RFC 822](https://www.ietf.org/rfc/rfc822.txt). For example, "**username@example.com**" is a valid Email address. */
  AWSEmail: string;
  /** The `AWSJSON` scalar type provided by AWS AppSync, represents a JSON string that complies with [RFC 8259](https://tools.ietf.org/html/rfc8259).  Maps like "**{\\"upvotes\\": 10}**", lists like "**[1,2,3]**", and scalar values like "**\\"AWSJSON example string\\"**", "**1**", and "**true**" are accepted as valid JSON and will automatically be parsed and loaded in the resolver mapping templates as Maps, Lists, or Scalar values rather than as the literal input strings.  Invalid JSON strings like "**{a: 1}**", "**{'a': 1}**" and "**Unquoted string**" will throw GraphQL validation errors. */
  AWSJSON: string;
  /** The `AWSTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 Time](https://en.wikipedia.org/wiki/ISO_8601#Times) string. In other words, this scalar type accepts time strings of the form `hh:mm:ss.SSS`.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**12:00:00.2**", "**12:00:00.277**" and "**12:00:00.123456789**" are all valid time strings. The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  This scalar type can also accept an optional [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators). For example, "**12:30**", "**12:30Z**", "**12:30:24-07:00**" and "**12:30:24.500+05:30**" are all valid time strings. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSTime: string;
  /** The `AWSTimestamp` scalar type provided by AWS AppSync, represents the number of seconds that have elapsed since `1970-01-01T00:00Z`. Negative values are also accepted and these represent the number of seconds till `1970-01-01T00:00Z`.  Timestamps are serialized and deserialized as integers. The minimum supported timestamp value is **`-31557014167219200`** which corresponds to `-1000000000-01-01T00:00Z`. The maximum supported timestamp value is **`31556889864403199`** which corresponds to `1000000000-12-31T23:59:59.999999999Z`. */
  AWSTimestamp: number;
  /** The `AWSURL` scalar type provided by AWS AppSync, represents a valid URL string (Ex: <https://www.amazon.com/>). The URL may use any scheme and may also be a local URL (Ex: <http://localhost/>).  URLs without schemes like "**amazon.com**" or "**www.amazon.com**" are considered invalid. URLs which contain double slashes (two consecutive forward slashes) in their path are also considered invalid. */
  AWSURL: string;
};

export type ActivatedCoupon = {
  __typename?: 'ActivatedCoupon';
  id: Scalars['ID'];
  activeFrom: Scalars['AWSDateTime'];
  activeTo: Scalars['AWSDateTime'];
  isInfinite?: Maybe<Scalars['Boolean']>;
  coupon?: Maybe<Coupon>;
};

/**   deprecated, use AdminDashboardAnalytics */
export type AdminDashboard = {
  __typename?: 'AdminDashboard';
  id: Scalars['String'];
  MAU?: Maybe<DashboardDiff>;
  totalOpenedNotifications?: Maybe<DashboardDiff>;
  totalSubscribers?: Maybe<DashboardDiff>;
  totalSentNotifications?: Maybe<DashboardDiff>;
  totalNewsViews?: Maybe<DashboardDiff>;
  totalEventViews?: Maybe<DashboardDiff>;
  totalShares?: Maybe<DashboardDiff>;
};

export type AdminDashboardAnalytics = {
  __typename?: 'AdminDashboardAnalytics';
  placeUuid: Scalars['String'];
  institutionUuid: Scalars['String'];
  reactions?: Maybe<Scalars['AWSJSON']>;
  subscribers?: Maybe<Scalars['AWSJSON']>;
  followers?: Maybe<Scalars['AWSJSON']>;
  reach?: Maybe<Scalars['AWSJSON']>;
};

/**   return type for addAdminUser. It returns admin user details. */
export type AdminUser = {
  __typename?: 'AdminUser';
  username: Scalars['String'];
  email: Scalars['AWSEmail'];
  /**   Institution admin */
  institution?: Maybe<Institution>;
  /**   Place admin */
  place?: Maybe<Place>;
  /**   Institutions Uuids where an user is an admin */
  institutionUuids?: Maybe<Scalars['String']>;
  /**   Institutions where an user is an admin */
  institutions?: Maybe<Array<Institution>>;
  /**   Returned only when the admin is created first time */
  tmpPassword?: Maybe<Scalars['String']>;
};

export type AdminUserInput = {
  username: Scalars['String'];
  email: Scalars['AWSEmail'];
  /**   Puts admin into Admin group (InstitutionAdmin) */
  institutionUuid?: InputMaybe<Scalars['ID']>;
  institutionUuids?: InputMaybe<Array<Scalars['ID']>>;
  /**   Puts admin into PlaceAdmin group */
  placeUuid?: InputMaybe<Scalars['ID']>;
  /**   Force sets a new password */
  forcePassword?: InputMaybe<Scalars['String']>;
};

export type AdminUsersFilterInput = {
  /**   Filter by institution */
  institutionUuid?: InputMaybe<Scalars['ID']>;
  /**   Filter by place */
  placeUuid?: InputMaybe<Scalars['ID']>;
  /**   Cognito group eg. Admin, PlaceAdmin, SuperAdmin */
  group?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type AiMetadata = {
  __typename?: 'AiMetadata';
  hatespeech?: Maybe<Hatespeech>;
};

export type AiMetadataInput = {
  hatespeech?: InputMaybe<HatespeechInput>;
};

export type Announcement = IAnnouncement & {
  __typename?: 'Announcement';
  id: Scalars['ID'];
  placeUuid?: Maybe<Scalars['ID']>;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  updatedByAdmin?: Maybe<Scalars['Boolean']>;
  /** @deprecated Replaces with categories */
  category?: Maybe<Category>;
  categories: Array<Category>;
  institution?: Maybe<Institution>;
  place: Place;
  places: Array<Place>;
  lang: Scalars['String'];
  title: Scalars['String'];
  content: Scalars['String'];
  likeCount: Scalars['Int'];
  /** @deprecated Replaced with feedbacks and then replaced with save */
  like?: Maybe<Like>;
  save?: Maybe<Save>;
  /**   get feedback on announcement. If installationUuid is not specified all feedback are returned else only feedback made by installation */
  installationInteraction: PaginatedAnnouncementFeedbacks;
  publishedAt?: Maybe<Scalars['AWSDateTime']>;
  unpublishedAt?: Maybe<Scalars['AWSDateTime']>;
  subtitle?: Maybe<Scalars['String']>;
  pushContent?: Maybe<Scalars['String']>;
  perex?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['AWSURL']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageObjects?: Maybe<Array<Image>>;
  videoObjects?: Maybe<Array<Video>>;
  createdByAdmin?: Maybe<Scalars['Boolean']>;
  scheduledAt?: Maybe<Scalars['AWSDateTime']>;
  notifyNow?: Maybe<Scalars['Boolean']>;
  publishmentState: State;
  notifications?: Maybe<Array<Scalars['AWSDateTime']>>;
  channelIntegrations?: Maybe<Array<Scalars['String']>>;
  location?: Maybe<EventLocation>;
  gpsLocation?: Maybe<GeoPoint>;
  raffleTitle?: Maybe<Scalars['String']>;
  raffleOptions?: Maybe<RaffleOptions>;
  createdUsingAI?: Maybe<Scalars['Boolean']>;
  AIPrompt?: Maybe<Scalars['String']>;
  aiMetadata?: Maybe<AiMetadata>;
  moderationCategory?: Maybe<ModerationCategories>;
  notifyVolunteersOnly?: Maybe<Scalars['Boolean']>;
  notifyAlsoNonCategoried?: Maybe<Scalars['Boolean']>;
  areaOfInterest?: Maybe<GeoShape>;
  NERLocations?: Maybe<Array<NerLocation>>;
  typename?: Maybe<PostType>;
  coverVideo?: Maybe<Scalars['AWSURL']>;
  subTypename?: Maybe<SubTypename>;
  donationTargetAmount?: Maybe<Scalars['Float']>;
  donationRaisedAmount?: Maybe<Scalars['Float']>;
  donationCurrency?: Maybe<Scalars['String']>;
  donationInitializedPayments?: Maybe<Array<PaymentTransaction>>;
  marketUrl?: Maybe<Scalars['AWSURL']>;
  marketItemType?: Maybe<MarketItemType>;
  marketItemRating?: Maybe<Scalars['Float']>;
  marketItemDuration?: Maybe<Scalars['Int']>;
  marketItemPricing?: Maybe<Pricing>;
  marketItemInclusions?: Maybe<Array<Scalars['String']>>;
  marketItemExclusions?: Maybe<Array<Scalars['String']>>;
  marketItemAdditionalInfo?: Maybe<Array<Scalars['String']>>;
  marketItemCancellationPolicy?: Maybe<MarketItemCancellationPolicy>;
  marketItemLogistics?: Maybe<MarketItemLogistics>;
  marketItemOrder?: Maybe<Scalars['Int']>;
  marketItemViatorProductCode?: Maybe<Scalars['String']>;
  marketItemVisitorInterests?: Maybe<Array<Scalars['String']>>;
  marketItemBookingRequirements?: Maybe<MarketItemBookingRequirements>;
  marketItemBookingQuestions?: Maybe<Array<Scalars['String']>>;
  marketItemProductOptions?: Maybe<Array<MarketItemProductOption>>;
  marketItemExternalId?: Maybe<Scalars['String']>;
  marketItemHotEvent?: Maybe<Scalars['Boolean']>;
  marketItemEventStatus?: Maybe<MarketItemEventStatus>;
  streamingPlatform?: Maybe<StreamingPlatform>;
};

export type AnnouncementInstallationInteractionArgs = {
  filter: InstallationFeedbacksFilter;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type AnnouncementDraft = IAnnouncement & {
  __typename?: 'AnnouncementDraft';
  id: Scalars['ID'];
  placeUuid?: Maybe<Scalars['ID']>;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  institution?: Maybe<Institution>;
  lang: Scalars['String'];
  place: Place;
  places?: Maybe<Array<Place>>;
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  pushContent?: Maybe<Scalars['String']>;
  perex?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['AWSURL']>;
  /** @deprecated Replaces with categories */
  category?: Maybe<Category>;
  categories?: Maybe<Array<Category>>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageObjects?: Maybe<Array<Image>>;
  videoObjects?: Maybe<Array<Video>>;
  createdByAdmin?: Maybe<Scalars['Boolean']>;
  notifyNow?: Maybe<Scalars['Boolean']>;
  scheduledAt?: Maybe<Scalars['AWSDateTime']>;
  publishedAt?: Maybe<Scalars['AWSDateTime']>;
  publishmentState: State;
  notifications?: Maybe<Array<Scalars['AWSDateTime']>>;
  channelIntegrations?: Maybe<Array<Scalars['String']>>;
  location?: Maybe<EventLocation>;
  gpsLocation?: Maybe<GeoPoint>;
  raffleTitle?: Maybe<Scalars['String']>;
  raffleOptions?: Maybe<RaffleOptions>;
  createdUsingAI?: Maybe<Scalars['Boolean']>;
  AIPrompt?: Maybe<Scalars['String']>;
  notifyVolunteersOnly?: Maybe<Scalars['Boolean']>;
  notifyAlsoNonCategoried?: Maybe<Scalars['Boolean']>;
  areaOfInterest?: Maybe<GeoShape>;
  NERLocations?: Maybe<Array<NerLocation>>;
  typename?: Maybe<PostType>;
  coverVideo?: Maybe<Scalars['AWSURL']>;
  subTypename?: Maybe<SubTypename>;
  marketUrl?: Maybe<Scalars['AWSURL']>;
  marketItemType?: Maybe<MarketItemType>;
  marketItemRating?: Maybe<Scalars['Float']>;
  marketItemDuration?: Maybe<Scalars['Int']>;
  marketItemPricing?: Maybe<Pricing>;
  marketItemInclusions?: Maybe<Array<Scalars['String']>>;
  marketItemExclusions?: Maybe<Array<Scalars['String']>>;
  marketItemAdditionalInfo?: Maybe<Array<Scalars['String']>>;
  marketItemCancellationPolicy?: Maybe<MarketItemCancellationPolicy>;
  marketItemLogistics?: Maybe<MarketItemLogistics>;
  marketItemOrder?: Maybe<Scalars['Int']>;
  marketItemViatorProductCode?: Maybe<Scalars['String']>;
  marketItemVisitorInterests?: Maybe<Array<Scalars['String']>>;
  marketItemBookingRequirements?: Maybe<MarketItemBookingRequirements>;
  marketItemBookingQuestions?: Maybe<Array<Scalars['String']>>;
  marketItemProductOptions?: Maybe<Array<MarketItemProductOption>>;
  marketItemExternalId?: Maybe<Scalars['String']>;
  marketItemHotEvent?: Maybe<Scalars['Boolean']>;
  marketItemEventStatus?: Maybe<MarketItemEventStatus>;
  streamingPlatform?: Maybe<StreamingPlatform>;
};

export type AnnouncementDraftInput = {
  id?: InputMaybe<Scalars['ID']>;
  institutionUuid: Scalars['String'];
  placeUuids?: InputMaybe<Array<Scalars['ID']>>;
  /**   places where to save an announcement */
  title: Scalars['String'];
  subtitle?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  pushContent?: InputMaybe<Scalars['String']>;
  perex?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['AWSURL']>;
  lang?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**   set of UploadUrl ids */
  imageObjects?: InputMaybe<Array<ImageInput>>;
  videoObjects?: InputMaybe<Array<VideoInput>>;
  categoryUuid?: InputMaybe<Scalars['String']>;
  categoryUuids?: InputMaybe<Array<Scalars['String']>>;
  publishedAt?: InputMaybe<Scalars['AWSDateTime']>;
  scheduledAt?: InputMaybe<Scalars['AWSDateTime']>;
  notifications?: InputMaybe<Array<Scalars['AWSDateTime']>>;
  notifyNow?: InputMaybe<Scalars['Boolean']>;
  channelIntegrations?: InputMaybe<Array<Scalars['String']>>;
  location?: InputMaybe<EventLocationInput>;
  gpsLocation?: InputMaybe<GeoPointInput>;
  notifyVolunteersOnly?: InputMaybe<Scalars['Boolean']>;
  notifyAlsoNonCategoried?: InputMaybe<Scalars['Boolean']>;
  areaOfInterest?: InputMaybe<GeoShapeInput>;
  NERLocations?: InputMaybe<Array<NerLocationInput>>;
  coverVideo?: InputMaybe<Scalars['AWSURL']>;
  marketUrl?: InputMaybe<Scalars['AWSURL']>;
  marketItemType?: InputMaybe<MarketItemType>;
  marketItemRating?: InputMaybe<Scalars['Float']>;
  marketItemDuration?: InputMaybe<Scalars['Int']>;
  marketItemPricing?: InputMaybe<PricingInput>;
  marketItemInclusions?: InputMaybe<Array<Scalars['String']>>;
  marketItemExclusions?: InputMaybe<Array<Scalars['String']>>;
  marketItemAdditionalInfo?: InputMaybe<Array<Scalars['String']>>;
  marketItemCancellationPolicy?: InputMaybe<MarketItemCancellationPolicyInput>;
  marketItemLogistics?: InputMaybe<MarketItemLogisticsInput>;
  marketItemOrder?: InputMaybe<Scalars['Int']>;
  marketItemViatorProductCode?: InputMaybe<Scalars['String']>;
  marketItemVisitorInterests?: InputMaybe<Array<Scalars['String']>>;
  marketItemBookingRequirements?: InputMaybe<MarketItemBookingRequirementsInput>;
  marketItemBookingQuestions?: InputMaybe<Array<Scalars['String']>>;
  marketItemProductOptions?: InputMaybe<Array<MarketItemProductOptionInput>>;
  marketItemExternalId?: InputMaybe<Scalars['String']>;
  marketItemHotEvent?: InputMaybe<Scalars['Boolean']>;
  marketItemEventStatus?: InputMaybe<MarketItemEventStatus>;
};

export type AnnouncementFeedback = {
  __typename?: 'AnnouncementFeedback';
  id: Scalars['ID'];
  category: FeedbackCategories;
  createdAt: Scalars['AWSDateTime'];
  reaction: AnnouncementFeedbackReaction;
  announcement?: Maybe<Announcement>;
  place?: Maybe<Place>;
  installation?: Maybe<Installation>;
  reactionText?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  save?: Maybe<Save>;
};

export type AnnouncementFeedbackInput = {
  category: FeedbackCategories;
  announcementUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  installationUuid: Scalars['ID'];
  reaction: AnnouncementFeedbackReaction;
  reactionText?: InputMaybe<Scalars['String']>;
};

export enum AnnouncementFeedbackReaction {
  Yes = 'YES',
  No = 'NO',
  Spam = 'SPAM',
  Scam = 'SCAM',
  Fake = 'FAKE',
  Hateful = 'HATEFUL',
  Inappropriate = 'INAPPROPRIATE',
  Other = 'OTHER',
  All = 'ALL',
}

export type AnnouncementInput = {
  id?: InputMaybe<Scalars['ID']>;
  institutionUuid: Scalars['String'];
  placeUuids?: InputMaybe<Array<Scalars['ID']>>;
  /**   places where to save an announcement */
  title: Scalars['String'];
  content: Scalars['String'];
  lang: Scalars['String'];
  subtitle?: InputMaybe<Scalars['String']>;
  pushContent?: InputMaybe<Scalars['String']>;
  perex?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['AWSURL']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**   set of UploadUrl ids */
  imageObjects?: InputMaybe<Array<ImageInput>>;
  publishedAt?: InputMaybe<Scalars['AWSDateTime']>;
  videoObjects?: InputMaybe<Array<VideoInput>>;
  categoryUuid?: InputMaybe<Scalars['String']>;
  categoryUuids?: InputMaybe<Array<Scalars['String']>>;
  scheduledAt?: InputMaybe<Scalars['AWSDateTime']>;
  notifications?: InputMaybe<Array<Scalars['AWSDateTime']>>;
  channelIntegrations?: InputMaybe<Array<Scalars['String']>>;
  notifyNow?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<EventLocationInput>;
  gpsLocation?: InputMaybe<GeoPointInput>;
  raffleTitle?: InputMaybe<Scalars['String']>;
  raffleOptions?: InputMaybe<RaffleOptionsInput>;
  createdUsingAI?: InputMaybe<Scalars['Boolean']>;
  AIPrompt?: InputMaybe<Scalars['String']>;
  notifyVolunteersOnly?: InputMaybe<Scalars['Boolean']>;
  notifyAlsoNonCategoried?: InputMaybe<Scalars['Boolean']>;
  areaOfInterest?: InputMaybe<GeoShapeInput>;
  NERLocations?: InputMaybe<Array<NerLocationInput>>;
  coverVideo?: InputMaybe<Scalars['AWSURL']>;
  typename?: InputMaybe<PostType>;
  donationTargetAmount?: InputMaybe<Scalars['Float']>;
  donationCurrency?: InputMaybe<Scalars['String']>;
  marketUrl?: InputMaybe<Scalars['AWSURL']>;
  marketItemType?: InputMaybe<MarketItemType>;
  marketItemRating?: InputMaybe<Scalars['Float']>;
  marketItemDuration?: InputMaybe<Scalars['Int']>;
  marketItemPricing?: InputMaybe<PricingInput>;
  marketItemInclusions?: InputMaybe<Array<Scalars['String']>>;
  marketItemExclusions?: InputMaybe<Array<Scalars['String']>>;
  marketItemAdditionalInfo?: InputMaybe<Array<Scalars['String']>>;
  marketItemCancellationPolicy?: InputMaybe<MarketItemCancellationPolicyInput>;
  marketItemLogistics?: InputMaybe<MarketItemLogisticsInput>;
  marketItemOrder?: InputMaybe<Scalars['Int']>;
  marketItemViatorProductCode?: InputMaybe<Scalars['String']>;
  marketItemVisitorInterests?: InputMaybe<Array<Scalars['String']>>;
  marketItemBookingRequirements?: InputMaybe<MarketItemBookingRequirementsInput>;
  marketItemBookingQuestions?: InputMaybe<Array<Scalars['String']>>;
  marketItemProductOptions?: InputMaybe<Array<MarketItemProductOptionInput>>;
  marketItemExternalId?: InputMaybe<Scalars['String']>;
  marketItemHotEvent?: InputMaybe<Scalars['Boolean']>;
  marketItemEventStatus?: InputMaybe<MarketItemEventStatus>;
};

export enum AnnouncementSortBy {
  LikeCount = 'LIKE_COUNT',
  LikeCountAndCreatedAt = 'LIKE_COUNT_AND_CREATED_AT',
  CreatedAt = 'CREATED_AT',
  PublishedAt = 'PUBLISHED_AT',
  UpdatedAt = 'UPDATED_AT',
  UnpublishedAt = 'UNPUBLISHED_AT',
  ScheduledAt = 'SCHEDULED_AT',
}

export type AnnouncementsByFilterInput = {
  text?: InputMaybe<Scalars['String']>;
  placeUuid?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<Scalars['String']>>;
  institutions?: InputMaybe<Array<Scalars['String']>>;
  state?: InputMaybe<State>;
  typename?: InputMaybe<PostType>;
};

export type AnnouncementsForInstallationOrderByInput = {
  by: AnnouncementsForInstallationSortBy;
  order: SortOrder;
};

export enum AnnouncementsForInstallationSortBy {
  PublishedAt = 'PUBLISHED_AT',
  LikeCount = 'LIKE_COUNT',
}

export type AnnouncementsOrderByInput = {
  by: AnnouncementSortBy;
  order: SortOrder;
};

export enum App {
  Simplicity = 'Simplicity',
  Travelution = 'Travelution',
}

export type AppleToken = {
  __typename?: 'AppleToken';
  identityToken?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type AppleTokenInput = {
  identityToken?: InputMaybe<Scalars['String']>;
  authorizationCode?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

export enum ApprovalState {
  Pending = 'PENDING',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type AreaOfInterest = {
  __typename?: 'AreaOfInterest';
  circle?: Maybe<GeoShapeCircle>;
  polygon?: Maybe<GeoShapePolygon>;
  geometryCollection?: Maybe<GeoShapeGeometryCollection>;
};

export type AttractionTranslations = {
  __typename?: 'AttractionTranslations';
  title: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<Array<Scalars['String']>>;
  meetingPoint?: Maybe<Scalars['String']>;
};

export type Audio = {
  __typename?: 'Audio';
  id: Scalars['ID'];
  links?: Maybe<Links>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  metadata?: Maybe<AudioMetadata>;
};

export type AudioInput = {
  id: Scalars['ID'];
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<AudioMetadataInput>;
};

export type AudioMetadata = {
  __typename?: 'AudioMetadata';
  voiceType?: Maybe<AudioVoiceType>;
};

export type AudioMetadataInput = {
  voiceType?: InputMaybe<AudioVoiceType>;
};

export enum AudioVoiceType {
  MaleGoogle = 'MALE_GOOGLE',
  FemaleGoogle = 'FEMALE_GOOGLE',
}

export type Badge = {
  __typename?: 'Badge';
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  type: BadgeType;
  rank?: Maybe<BadgeRank>;
  placeUuid: Scalars['ID'];
  place?: Maybe<Place>;
  title: Scalars['String'];
  experienceValue: Scalars['Int'];
  image: Image;
  description?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  landmarkUuid?: Maybe<Scalars['String']>;
  acquirableBy?: Maybe<BadgeAcquirableBy>;
  acquired?: Maybe<Scalars['Boolean']>;
  acquiredAt?: Maybe<Scalars['AWSDateTime']>;
};

export type BadgeAcquirableBy = {
  __typename?: 'BadgeAcquirableBy';
  interactionCount?: Maybe<Scalars['Int']>;
};

export type BadgeAcquirableByInput = {
  interactionCount?: InputMaybe<Scalars['Int']>;
};

export type BadgeInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  type: BadgeType;
  rank?: InputMaybe<BadgeRank>;
  placeUuid: Scalars['ID'];
  title: Scalars['String'];
  experienceValue: Scalars['Int'];
  image: ImageInput;
  description?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  landmarkUuid?: InputMaybe<Scalars['ID']>;
  acquirableBy?: InputMaybe<BadgeAcquirableByInput>;
  acquired?: InputMaybe<Scalars['Boolean']>;
  acquiredAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type BadgeInteractionCounter = {
  __typename?: 'BadgeInteractionCounter';
  type: BadgeType;
  count: Scalars['Int'];
  badge?: Maybe<Badge>;
};

export type BadgePlace = {
  __typename?: 'BadgePlace';
  name: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
};

export enum BadgeRank {
  Blue = 'BLUE',
  Bronze = 'BRONZE',
  Silver = 'SILVER',
  Gold = 'GOLD',
}

export enum BadgeType {
  AppRetention = 'APP_RETENTION',
  ChatMessageCount = 'CHAT_MESSAGE_COUNT',
  Landmark = 'LANDMARK',
  PurchaseCount = 'PURCHASE_COUNT',
  ShareCount = 'SHARE_COUNT',
}

export type BadgesForInstallationFilter = {
  acquired?: InputMaybe<Scalars['Boolean']>;
};

export type BadgesForInstallationResponse = {
  __typename?: 'BadgesForInstallationResponse';
  placeBadges: Array<PlaceBadges>;
  pageSize?: Maybe<Scalars['Int']>;
  pageOffset?: Maybe<Scalars['Int']>;
};

export type BlockUserProfileResponse = {
  __typename?: 'BlockUserProfileResponse';
  installationUuid: Scalars['ID'];
  targetInstallationUuid: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
};

export type Branding = {
  __typename?: 'Branding';
  avatar: Image;
  logo: Image;
};

export type BrandingInput = {
  avatar: ImageInput;
  logo: ImageInput;
};

export type BrandingInputPlace = {
  avatar?: InputMaybe<ImageInput>;
  logo?: InputMaybe<ImageInput>;
  background?: InputMaybe<ImageInput>;
  topTenBackground?: InputMaybe<ImageInput>;
};

export type BrandingPlace = {
  __typename?: 'BrandingPlace';
  avatar?: Maybe<Image>;
  logo?: Maybe<Image>;
  background?: Maybe<Image>;
  topTenBackground?: Maybe<Image>;
};

export type BusinessActiveUsersCounts = {
  __typename?: 'BusinessActiveUsersCounts';
  placeUuid: Scalars['String'];
  name: Scalars['String'];
  today: Scalars['Int'];
  yesterday: Scalars['Int'];
  thirtyDays: Scalars['Int'];
};

export type BusinessActiveUsersCountsFilterInput = {
  placeUuids?: InputMaybe<Array<Scalars['String']>>;
};

export type BusinessMetricsNotificationCounts = {
  __typename?: 'BusinessMetricsNotificationCounts';
  date: Scalars['AWSDate'];
  count: Scalars['Int'];
};

export type BusinessNotificationCounts = {
  __typename?: 'BusinessNotificationCounts';
  placeUuid: Scalars['String'];
  name: Scalars['String'];
  counts: Array<BusinessMetricsNotificationCounts>;
};

export type BusinessNotificationCountsFilterInput = {
  status: NotificationStatus;
  placeUuids?: InputMaybe<Array<Scalars['String']>>;
  startDate: Scalars['AWSDateTime'];
  endDate: Scalars['AWSDateTime'];
};

export type CancelTuiPaymentInput = {
  cancellationReason: CancellationReason;
  cancellationAdditionalInfo?: InputMaybe<Scalars['String']>;
};

export enum CancellationReason {
  ApiIssue = 'API_ISSUE',
  CancelledByCustomer = 'CANCELLED_BY_CUSTOMER',
  GracePeriod = 'GRACE_PERIOD',
  MissingMeetingPointDetails = 'MISSING_MEETING_POINT_DETAILS',
  MissingPassengerInfo = 'MISSING_PASSENGER_INFO',
  RejectedOrder = 'REJECTED_ORDER',
  RejectedScheduleChange = 'REJECTED_SCHEDULE_CHANGE',
  TechnicalIssue = 'TECHNICAL_ISSUE',
  VenueClosed = 'VENUE_CLOSED',
}

export type CategoriesByFilterInput = {
  /**   filter categories for selected type */
  categoryType?: InputMaybe<CategoryType>;
  /**   filter categories for selected types */
  categoryTypes?: InputMaybe<Array<CategoryType>>;
  /**   filter categories used in place - required for category count attribute */
  placeUuid?: InputMaybe<Scalars['String']>;
  /**   include legacy categories */
  includeLegacy?: InputMaybe<Scalars['Boolean']>;
  /**   return visitor categories */
  visitorMode?: InputMaybe<Scalars['Boolean']>;
};

/**   ordering of categories */
export type CategoriesOrderByInput = {
  by: CategoriesSortBy;
  order: SortOrder;
};

/**
 *   COUNT - return only categories with existing posts sorted by count
 *  NAME - return all categories sorted by name
 */
export enum CategoriesSortBy {
  Home = 'HOME',
  PostsCount = 'POSTS_COUNT',
  Name = 'NAME',
  MarketCount = 'MARKET_COUNT',
  CustomOrder = 'CUSTOM_ORDER',
}

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  name: Scalars['String'];
  translations?: Maybe<CategoryTranslations>;
  /**   name translated in different languages */
  nameTranslations?: Maybe<Array<Translation>>;
  images?: Maybe<CategoryImages>;
  categoryTypes: Array<CategoryType>;
  /**   number of post in category. Returned only if place is defined in query filter */
  count?: Maybe<Scalars['Int']>;
  legacy?: Maybe<Scalars['Boolean']>;
  isSystem?: Maybe<Scalars['Boolean']>;
  customOrder?: Maybe<Scalars['Int']>;
  visitorMode?: Maybe<Scalars['Boolean']>;
};

export type CategoryImages = {
  __typename?: 'CategoryImages';
  svgIcon?: Maybe<Scalars['String']>;
};

export type CategoryImagesInput = {
  svgIcon?: InputMaybe<Scalars['String']>;
};

export type CategoryInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  /**   define name translation in different languages */
  nameTranslation?: InputMaybe<Array<TranslationInput>>;
  images?: InputMaybe<CategoryImagesInput>;
  categoryTypes: Array<CategoryType>;
  translations?: InputMaybe<CategoryTranslationsInput>;
  customOrder?: InputMaybe<Scalars['Int']>;
  visitorMode?: InputMaybe<Scalars['Boolean']>;
};

export type CategoryTranslations = {
  __typename?: 'CategoryTranslations';
  sk?: Maybe<Scalars['String']>;
  uk?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  ru?: Maybe<Scalars['String']>;
  fil?: Maybe<Scalars['String']>;
  tl?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  ar?: Maybe<Scalars['String']>;
  ur?: Maybe<Scalars['String']>;
  ko?: Maybe<Scalars['String']>;
  bn?: Maybe<Scalars['String']>;
  zh_TW?: Maybe<Scalars['String']>;
  zh?: Maybe<Scalars['String']>;
  zh_CN?: Maybe<Scalars['String']>;
  pl?: Maybe<Scalars['String']>;
  hc?: Maybe<Scalars['String']>;
};

export type CategoryTranslationsInput = {
  sk?: InputMaybe<Scalars['String']>;
  uk?: InputMaybe<Scalars['String']>;
  es?: InputMaybe<Scalars['String']>;
  ru?: InputMaybe<Scalars['String']>;
  fil?: InputMaybe<Scalars['String']>;
  tl?: InputMaybe<Scalars['String']>;
  fr?: InputMaybe<Scalars['String']>;
  ar?: InputMaybe<Scalars['String']>;
  ur?: InputMaybe<Scalars['String']>;
  ko?: InputMaybe<Scalars['String']>;
  bn?: InputMaybe<Scalars['String']>;
  zh_TW?: InputMaybe<Scalars['String']>;
  zh?: InputMaybe<Scalars['String']>;
  zh_CN?: InputMaybe<Scalars['String']>;
  pl?: InputMaybe<Scalars['String']>;
  hc?: InputMaybe<Scalars['String']>;
};

/**   Possible types for categories */
export enum CategoryType {
  Event = 'EVENT',
  Announcement = 'ANNOUNCEMENT',
  Emergency = 'EMERGENCY',
  AppInterests = 'APP_INTERESTS',
  Poll = 'POLL',
  NonOfficialInstitution = 'NON_OFFICIAL_INSTITUTION',
  Raffle = 'RAFFLE',
  QuickQuestion = 'QUICK_QUESTION',
  Market = 'MARKET',
  Influencer = 'INFLUENCER',
}

export type CelebrityItineraryDayInput = {
  dayNumber: Scalars['Int'];
  entries: Array<CelebrityItineraryEntryInput>;
};

export type CelebrityItineraryEntryInput = {
  entryUuid: Scalars['ID'];
  daytime: Daytime;
  title?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
};

export type CelebrityItineraryResponse = {
  __typename?: 'CelebrityItineraryResponse';
  itineraryUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  dayNumber: Scalars['Int'];
  celebrity: ItineraryCelebrity;
  entries: Array<ItineraryEntryItem>;
};

export enum Channel {
  Alert = 'ALERT',
  News = 'NEWS',
  Announcement = 'ANNOUNCEMENT',
  Event = 'EVENT',
  Emergency = 'EMERGENCY',
  Fund = 'FUND',
  Feedback = 'FEEDBACK',
  FundResults = 'FUND_RESULTS',
  Poll = 'POLL',
  Raffle = 'RAFFLE',
  Coupon = 'COUPON',
  QuickQuestion = 'QUICK_QUESTION',
  Donation = 'DONATION',
  ChatMessage = 'CHAT_MESSAGE',
  Reminders = 'REMINDERS',
  DeepLink = 'DEEP_LINK',
  ReportedProblem = 'REPORTED_PROBLEM',
  MarketItem = 'MARKET_ITEM',
}

export enum ChatAction {
  Question = 'QUESTION',
  MagicButton = 'MAGIC_BUTTON',
}

export type ChatBotActionInput = {
  action: ChatAction;
  data: ChatBotQuestionInput;
};

export type ChatBotMagicButtonInput = {
  action: ChatAction;
  data: ChatBotMagicButtonQuestionInput;
};

export type ChatBotMagicButtonQuestionInput = {
  userUuid: Scalars['ID'];
  answerUuid: Scalars['ID'];
  question: Scalars['String'];
  questionType?: InputMaybe<MagicButtonQuestionFollowUpType>;
  lang?: InputMaybe<SupportedLanguages>;
};

export type ChatBotQuestionInput = {
  installationUuid: Scalars['ID'];
  sessionStart: Scalars['AWSDateTime'];
  question: Scalars['String'];
  gpsLocation?: InputMaybe<GeoPointInput>;
  stream?: InputMaybe<Scalars['Boolean']>;
  topic?: InputMaybe<ChatGptTopic>;
  questionType?: InputMaybe<ChatGptQuestionType>;
  lang?: InputMaybe<Scalars['String']>;
  vectorisation?: InputMaybe<Scalars['Boolean']>;
  chatIdentifier?: InputMaybe<ChatIdentifierInput>;
  imageUrls?: InputMaybe<Array<Scalars['AWSURL']>>;
};

export type ChatBotResponse = {
  __typename?: 'ChatBotResponse';
  status: ChatBotResponseStatus;
  answer: Scalars['String'];
  answerType?: Maybe<ChatGptAnswerType>;
  answerAdditions?: Maybe<ChatGptMessageAdditions>;
};

export enum ChatBotResponseStatus {
  Processing = 'PROCESSING',
  Answering = 'ANSWERING',
  Answered = 'ANSWERED',
  Result = 'RESULT',
}

export type ChatBotSettings = {
  __typename?: 'ChatBotSettings';
  topics?: Maybe<Array<Maybe<ChatGptTopic>>>;
  institutions?: Maybe<Array<ChatGptInstitution>>;
  knowledgeBase?: Maybe<ChatGptKnowledgeBase>;
  locationRadius?: Maybe<Scalars['Float']>;
};

export type ChatBotSettingsInput = {
  topics?: InputMaybe<Array<InputMaybe<ChatGptTopic>>>;
  institutions?: InputMaybe<Array<ChatGptInstitutionInput>>;
  knowledgeBase?: InputMaybe<ChatGptKnowledgeBase>;
  locationRadius?: InputMaybe<Scalars['Float']>;
};

export type ChatContextFeatureFlag = {
  __typename?: 'ChatContextFeatureFlag';
  landmarks?: Maybe<ChatContextTypeFeatureFlag>;
};

export type ChatContextFeatureFlagInput = {
  landmarks?: InputMaybe<ChatContextTypeFeatureFlagInput>;
};

export type ChatContextTypeFeatureFlag = {
  __typename?: 'ChatContextTypeFeatureFlag';
  main?: Maybe<Scalars['Boolean']>;
  map?: Maybe<Scalars['Boolean']>;
};

export type ChatContextTypeFeatureFlagInput = {
  main?: InputMaybe<Scalars['Boolean']>;
  map?: InputMaybe<Scalars['Boolean']>;
};

export enum ChatGptAnswerType {
  Answer = 'ANSWER',
  FollowupAnswer = 'FOLLOWUP_ANSWER',
  PaymentOcrResponse = 'PAYMENT_OCR_RESPONSE',
  MusementPaymentResponse = 'MUSEMENT_PAYMENT_RESPONSE',
}

export type ChatGptAnswerWidgetResponse = {
  __typename?: 'ChatGPTAnswerWidgetResponse';
  sessionStart: Scalars['AWSDateTime'];
  answer: Scalars['String'];
  isCompleted: Scalars['Boolean'];
};

export enum ChatGptInfoType {
  Error = 'ERROR',
  Notification = 'NOTIFICATION',
  PaymentFail = 'PAYMENT_FAIL',
  PaymentStart = 'PAYMENT_START',
  PaymentSuccess = 'PAYMENT_SUCCESS',
  System = 'SYSTEM',
}

export type ChatGptInstitution = {
  __typename?: 'ChatGPTInstitution';
  type: ChatGptInstitutionType;
  id: Scalars['ID'];
};

export type ChatGptInstitutionInput = {
  type: ChatGptInstitutionType;
  id: Scalars['ID'];
};

export enum ChatGptInstitutionType {
  Museum = 'MUSEUM',
}

export enum ChatGptKnowledgeBase {
  Datafeed = 'DATAFEED',
  DatafeedPosts = 'DATAFEED_POSTS',
  DatafeedPostsOpenai = 'DATAFEED_POSTS_OPENAI',
}

/**   @deprecated(reason: "Replaced with ChatMessage") */
export type ChatGptMessage = {
  __typename?: 'ChatGPTMessage';
  createdAt: Scalars['AWSDateTime'];
  installationUuid: Scalars['ID'];
  visitorMode: Scalars['Boolean'];
  placeUuid: Scalars['ID'];
  parentPlaceUuid?: Maybe<Scalars['ID']>;
  lang: Scalars['String'];
  topic: ChatGptTopic;
  /** @deprecated Replaced with chatIdentifier */
  institutionUuid?: Maybe<Scalars['ID']>;
  /** @deprecated Replaced with chatIdentifier */
  landmarkUuid?: Maybe<Scalars['ID']>;
  chatIdentifier?: Maybe<ChatIdentifier>;
  type: ChatGptMessageType;
  text: Scalars['String'];
  additions?: Maybe<ChatGptMessageAdditions>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  processingTime?: Maybe<Scalars['Int']>;
  tokensUsage?: Maybe<ChatGptMessageTokensUsage>;
  context?: Maybe<Scalars['String']>;
};

export type ChatGptMessageTokensUsage = {
  __typename?: 'ChatGPTMessageTokensUsage';
  promptTokens?: Maybe<Scalars['Int']>;
  completionTokens?: Maybe<Scalars['Int']>;
  totalTokens?: Maybe<Scalars['Int']>;
};

export enum ChatGptMessageType {
  Answer = 'ANSWER',
  ContextCheckQuestion = 'CONTEXT_CHECK_QUESTION',
  Error = 'ERROR',
  FollowupAnswer = 'FOLLOWUP_ANSWER',
  FollowupQuestion = 'FOLLOWUP_QUESTION',
  MusementFlow = 'MUSEMENT_FLOW',
  Notification = 'NOTIFICATION',
  PaymentFail = 'PAYMENT_FAIL',
  PaymentOcrRequest = 'PAYMENT_OCR_REQUEST',
  PaymentOcrResponse = 'PAYMENT_OCR_RESPONSE',
  PaymentStart = 'PAYMENT_START',
  PaymentSuccess = 'PAYMENT_SUCCESS',
  Question = 'QUESTION',
  System = 'SYSTEM',
  ViatorPaymentResponse = 'VIATOR_PAYMENT_RESPONSE',
  MusementPaymentResponse = 'MUSEMENT_PAYMENT_RESPONSE',
  ToolCallResponse = 'TOOL_CALL_RESPONSE',
  ImageQuestion = 'IMAGE_QUESTION',
}

export enum ChatGptQuestionType {
  ContextCheckQuestion = 'CONTEXT_CHECK_QUESTION',
  FollowupQuestion = 'FOLLOWUP_QUESTION',
  PaymentOcrRequest = 'PAYMENT_OCR_REQUEST',
  Question = 'QUESTION',
  ImageQuestion = 'IMAGE_QUESTION',
}

export type ChatGptSuggestionsResponse = {
  __typename?: 'ChatGPTSuggestionsResponse';
  sessionStart: Scalars['AWSDateTime'];
  suggestions: Array<Scalars['String']>;
};

export type ChatGptSuggestionsWidgetResponse = {
  __typename?: 'ChatGPTSuggestionsWidgetResponse';
  sessionStart: Scalars['AWSDateTime'];
  suggestions: Array<Scalars['String']>;
  isCompleted: Scalars['Boolean'];
};

export enum ChatGptTopic {
  History = 'HISTORY',
  Info = 'INFO',
  Institutions = 'INSTITUTIONS',
  Landmarks = 'LANDMARKS',
  Payments = 'PAYMENTS',
  ReportAProblem = 'REPORT_A_PROBLEM',
  Map = 'MAP',
}

export type ChatGptWidgetMessage = {
  __typename?: 'ChatGPTWidgetMessage';
  createdAt: Scalars['AWSDateTime'];
  installationUuid: Scalars['ID'];
  placeName: Scalars['String'];
  type: ChatGptWidgetMessageType;
  text: Scalars['String'];
  isCompleted?: Maybe<Scalars['Boolean']>;
  processingTime?: Maybe<Scalars['Int']>;
  tokensUsage?: Maybe<ChatGptMessageTokensUsage>;
  context?: Maybe<Scalars['String']>;
};

export enum ChatGptWidgetMessageType {
  System = 'SYSTEM',
  FollowupQuestion = 'FOLLOWUP_QUESTION',
  Question = 'QUESTION',
  FollowupAnswer = 'FOLLOWUP_ANSWER',
  Answer = 'ANSWER',
  Error = 'ERROR',
}

export type ChatGptAnswerResponse = {
  __typename?: 'ChatGptAnswerResponse';
  sessionStart: Scalars['AWSDateTime'];
  answerType?: Maybe<ChatGptAnswerType>;
  answer: Scalars['String'];
  answerAdditions?: Maybe<ChatGptMessageAdditions>;
  isCompleted?: Maybe<Scalars['Boolean']>;
};

export type ChatGptAnswerStreamResponse = {
  __typename?: 'ChatGptAnswerStreamResponse';
  installationUuid: Scalars['ID'];
  topic: ChatGptTopic;
  /** @deprecated Replaced with chatIdentifier */
  institutionUuid?: Maybe<Scalars['ID']>;
  /** @deprecated Replaced with chatIdentifier */
  landmarkUuid?: Maybe<Scalars['ID']>;
  chatIdentifier?: Maybe<ChatIdentifier>;
  text: Scalars['String'];
  isCompleted: Scalars['Boolean'];
  order: Scalars['Int'];
};

export type ChatGptAnswerStreamWidgetResponse = {
  __typename?: 'ChatGptAnswerStreamWidgetResponse';
  installationUuid: Scalars['ID'];
  text: Scalars['String'];
  isCompleted: Scalars['Boolean'];
  order: Scalars['Int'];
};

export type ChatGptInfoResponse = {
  __typename?: 'ChatGptInfoResponse';
  sessionStart: Scalars['AWSDateTime'];
};

export type ChatGptMessageAdditions = {
  __typename?: 'ChatGptMessageAdditions';
  images?: Maybe<Array<Scalars['String']>>;
  marketItems?: Maybe<Array<MarketItem>>;
  marketItemUuids?: Maybe<Array<Scalars['ID']>>;
  events?: Maybe<Array<Event>>;
  eventUuids?: Maybe<Array<Scalars['ID']>>;
  landmarks?: Maybe<Array<Landmark>>;
  landmarkUuids?: Maybe<Array<Scalars['ID']>>;
  restaurants?: Maybe<Array<Restaurant>>;
  restaurantUuids?: Maybe<Array<Scalars['ID']>>;
  paymentMetadata?: Maybe<Scalars['AWSJSON']>;
  paymentTransactionUuid?: Maybe<Scalars['String']>;
  paymentTransaction?: Maybe<PaymentTransaction>;
};

export type ChatIdentifier = {
  __typename?: 'ChatIdentifier';
  type: ChatIdentifierType;
  id: Scalars['ID'];
};

export type ChatIdentifierInput = {
  type: ChatIdentifierType;
  id: Scalars['ID'];
};

export enum ChatIdentifierType {
  TuiMarketItem = 'TUIMarketItem',
  Restaurant = 'Restaurant',
  Landmark = 'Landmark',
  Institution = 'Institution',
}

export type ChatKnowledgeBaseInformation = {
  __typename?: 'ChatKnowledgeBaseInformation';
  id: Scalars['ID'];
  placeUuid?: Maybe<Scalars['ID']>;
  postContent: Scalars['String'];
};

export enum ChatKnowledgeIntent {
  Tours = 'TOURS',
  Nearby = 'NEARBY',
}

export type ChatMessage = {
  __typename?: 'ChatMessage';
  createdAt: Scalars['AWSDateTime'];
  type: ChatGptMessageType;
  text: Scalars['String'];
  additions?: Maybe<ChatGptMessageAdditions>;
};

export type ChatMessagesResponse = {
  __typename?: 'ChatMessagesResponse';
  messages: Array<ChatMessage>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum CircleType {
  Circle = 'Circle',
}

export type ClearHistoryResponse = {
  __typename?: 'ClearHistoryResponse';
  success: Scalars['Boolean'];
};

export enum Code {
  Ok = 'OK',
  Error = 'ERROR',
}

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID'];
  installationUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  type: CommentType;
  reportUuid?: Maybe<Scalars['ID']>;
  text: Scalars['String'];
  profile?: Maybe<CommentProfile>;
  createdAt: Scalars['AWSDateTime'];
};

export type CommentProfile = {
  __typename?: 'CommentProfile';
  name?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export enum CommentType {
  Report = 'REPORT',
}

export type CommentsOrderByInput = {
  by: CommentsOrderByProperty;
  order: SortOrder;
};

export enum CommentsOrderByProperty {
  CreatedAt = 'CREATED_AT',
}

export type CommentsResponse = {
  __typename?: 'CommentsResponse';
  comments: Array<Comment>;
  pageOffset?: Maybe<Scalars['Int']>;
};

export enum CompanionType {
  Alone = 'ALONE',
  Family = 'FAMILY',
  Friends = 'FRIENDS',
}

export enum Component {
  Notifications = 'NOTIFICATIONS',
  Posts = 'POSTS',
}

export type ComponentAction = {
  __typename?: 'ComponentAction';
  componentState: ComponentState;
  component: Component;
};

export type ComponentActionInput = {
  componentState: ComponentState;
  component: Component;
};

export enum ComponentState {
  TurnOff = 'TURN_OFF',
  TurnOn = 'TURN_ON',
}

export type ConfigPath = {
  __typename?: 'ConfigPath';
  /**   same code as in ReminderCategory */
  code: Scalars['String'];
  configPathType: ConfigPathType;
  /**   jsonAta path */
  jsonPath: Scalars['String'];
};

export type ConfigPathInput = {
  /**   same code as in ReminderCategory */
  code: Scalars['String'];
  configPathType: ConfigPathType;
  /**   jsonAta path */
  jsonPath: Scalars['String'];
};

export enum ConfigPathType {
  Json = 'JSON',
}

export type ContentInput = {
  from: Scalars['String'];
  subject: Scalars['String'];
  html: Scalars['String'];
};

export enum CountryCode {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  An = 'AN',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  St = 'ST',
  Sv = 'SV',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW',
}

export type Coupon = {
  __typename?: 'Coupon';
  id: Scalars['ID'];
  enabled?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  publishedAt?: Maybe<Scalars['AWSDateTime']>;
  institution: Institution;
  /** @deprecated Replaced with 'institution' field */
  institutionUuid?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Replaced with 'image' field */
  imageObjects?: Maybe<Array<Image>>;
  image?: Maybe<Image>;
  imageRedeem?: Maybe<Image>;
  validity: CouponValidity;
  publishmentState: State;
  scheduledAt?: Maybe<Scalars['AWSDateTime']>;
  notifyNow?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<Array<Scalars['AWSDateTime']>>;
  /**   get activities on coupon. If installationUuid is not specified all activities are returned else only activities made by installation */
  userActivities: PaginatedCouponUserActivities;
  locations?: Maybe<Array<Maybe<GeoPoint>>>;
};

export type CouponUserActivitiesArgs = {
  filter?: InputMaybe<CouponActivityFilterInput>;
  orderBy?: InputMaybe<CouponActivitiesOrderByInput>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type CouponActivitiesOrderByInput = {
  by: CouponActivitiesSortBy;
  order: SortOrder;
};

export enum CouponActivitiesSortBy {
  CreatedAt = 'CREATED_AT',
}

export type CouponActivityFilterInput = {
  installationUuid?: InputMaybe<Scalars['ID']>;
};

export type CouponInput = {
  id?: InputMaybe<Scalars['ID']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  institutionUuid: Scalars['ID'];
  title: Scalars['String'];
  subtitle?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  imageObjects?: InputMaybe<Array<ImageInput>>;
  image?: InputMaybe<ImageInput>;
  imageRedeem?: InputMaybe<ImageInput>;
  validity: CouponValidityInput;
  scheduledAt?: InputMaybe<Scalars['AWSDateTime']>;
  notifyNow?: InputMaybe<Scalars['Boolean']>;
  notifyVolunteersOnly?: InputMaybe<Scalars['Boolean']>;
  notifyAlsoNonCategoried?: InputMaybe<Scalars['Boolean']>;
  notifications?: InputMaybe<Array<Scalars['AWSDateTime']>>;
};

export enum CouponUserAction {
  Activate = 'ACTIVATE',
}

export type CouponUserActivity = {
  __typename?: 'CouponUserActivity';
  createdAt: Scalars['AWSDateTime'];
  action: CouponUserAction;
  couponUuid: Scalars['ID'];
  installationUuid: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
};

export type CouponUserActivityInput = {
  action: CouponUserAction;
  couponUuid: Scalars['ID'];
  installationUuid: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
};

export type CouponValidity = {
  __typename?: 'CouponValidity';
  isInfinite?: Maybe<Scalars['Boolean']>;
  interval?: Maybe<IntervalValidity>;
  recurring?: Maybe<RecurringValidity>;
};

export type CouponValidityInput = {
  isInfinite?: InputMaybe<Scalars['Boolean']>;
  interval?: InputMaybe<IntervalValidityInput>;
  recurring?: InputMaybe<RecurringValidityInput>;
};

export type CouponsByFilterInput = {
  institutionUuid?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<State>;
  map?: InputMaybe<MapFilterInput>;
  interval?: InputMaybe<IntervalValidityFilterInput>;
};

export type CouponsForInstallation = {
  __typename?: 'CouponsForInstallation';
  followedInstitutionsActiveCoupons: Array<Coupon>;
  placeActiveCoupons: Array<Coupon>;
};

export type CouponsOrderByInput = {
  by: CouponsSortBy;
  order: SortOrder;
};

export enum CouponsSortBy {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
  PublishedAt = 'PUBLISHED_AT',
}

export type CreateCelebrityItinerariesInput = {
  celebrityUuid: Scalars['ID'];
  celebrityName: Scalars['String'];
  placeUuid: Scalars['ID'];
  imageObjects: Array<ImageInput>;
  itineraries: Array<CelebrityItineraryDayInput>;
};

export type CreatePaymentTransactionInput = {
  installationUuid: Scalars['ID'];
  receiver: Scalars['String'];
  amount: Scalars['String'];
  currency: Currency;
  title?: InputMaybe<Scalars['String']>;
  document?: InputMaybe<ImageInput>;
  paymentType?: InputMaybe<PaymentType>;
  paymentForm?: InputMaybe<PaymentForm>;
  paymentDetails?: InputMaybe<PaymentDetailsInput>;
  stripePaymentId?: InputMaybe<Scalars['ID']>;
  note?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['AWSJSON']>;
  marketPaymentDetails?: InputMaybe<MarketPaymentDetailsInput>;
};

export type CreateScrapePaymentTransactionInput = {
  installationUuid: Scalars['ID'];
  receiver: Scalars['String'];
  amount: Scalars['String'];
  currency: Currency;
  title?: InputMaybe<Scalars['String']>;
  document?: InputMaybe<ImageInput>;
  paymentForm?: InputMaybe<PaymentForm>;
  paymentDetails?: InputMaybe<PaymentDetailsInput>;
  paymentType?: InputMaybe<PaymentType>;
  note?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['AWSJSON']>;
  marketPaymentDetails?: InputMaybe<MarketPaymentDetailsInput>;
};

export type CreateVmResponse = {
  __typename?: 'CreateVMResponse';
  status: Code;
  msg?: Maybe<Scalars['String']>;
};

export type CreatedTuiPayment = {
  __typename?: 'CreatedTUIPayment';
  paymentTransaction: PaymentTransaction;
  paymentSheetParams: PaymentSheetParams;
};

export enum Currency {
  Aed = 'AED',
  Aud = 'AUD',
  Cad = 'CAD',
  Chf = 'CHF',
  Czk = 'CZK',
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Mxn = 'MXN',
  Nok = 'NOK',
  Nzd = 'NZD',
  Pln = 'PLN',
  Sek = 'SEK',
  Usd = 'USD',
  Zar = 'ZAR',
  Sar = 'SAR',
  Qar = 'QAR',
  Brl = 'BRL',
  Huf = 'HUF',
}

export type CurrentWeather = {
  __typename?: 'CurrentWeather';
  timezone: Scalars['String'];
  label: Scalars['String'];
  description: Scalars['String'];
  tempValue: Scalars['Float'];
  tempUnit: Scalars['String'];
  feelsLike: Scalars['Float'];
  windSpeed: Scalars['Float'];
  windUnit: Scalars['String'];
  alerts?: Maybe<Array<Maybe<WeatherAlert>>>;
};

export type CustomMarketItem = IMarketItem & {
  __typename?: 'CustomMarketItem';
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  createdByAdmin?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  placeUuid: Scalars['ID'];
  simplicityDiscountPercentage: Scalars['Float'];
  categories: Array<Category>;
  lang: Scalars['String'];
  title: Scalars['String'];
  typename: MarketItemType;
  content?: Maybe<Scalars['String']>;
  imageObjects?: Maybe<Array<Image>>;
  duration?: Maybe<Scalars['Int']>;
  inclusions?: Maybe<Array<Scalars['String']>>;
  exclusions?: Maybe<Array<Scalars['String']>>;
  additionalInfo?: Maybe<Array<Scalars['String']>>;
  gpsLocation?: Maybe<GeoPoint>;
  textLocation?: Maybe<Scalars['String']>;
  meetingPoint?: Maybe<Scalars['String']>;
  price?: Maybe<MarketItemPrice>;
  marketUrl?: Maybe<Scalars['AWSURL']>;
  externalId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  reviewsCount?: Maybe<Scalars['Int']>;
  externalCategories: Array<Scalars['String']>;
  cancellationPolicy?: Maybe<MarketItemCancellationPolicy>;
  save?: Maybe<Save>;
  /** @deprecated For app compatibility */
  bookingType?: Maybe<TuiBookingType>;
  ignore?: Maybe<Scalars['Boolean']>;
  topPick?: Maybe<Scalars['Boolean']>;
  travelDistanceDuration?: Maybe<TravelDistanceDuration>;
  translations?: Maybe<AttractionTranslations>;
  internalLabels?: Maybe<Array<InternalLabel>>;
};

export type CustomMarketItemTravelDistanceDurationArgs = {
  startLocation?: InputMaybe<GeoPointInput>;
};

export type CustomMarketItemTranslationsArgs = {
  lang?: InputMaybe<Scalars['String']>;
};

export type CustomMarketItemInput = {
  createdByAdmin?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  placeUuid: Scalars['ID'];
  simplicityDiscountPercentage?: InputMaybe<Scalars['Float']>;
  categoryUuids: Array<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  typename: MarketItemType;
  content?: InputMaybe<Scalars['String']>;
  imageObjects?: InputMaybe<Array<ImageInput>>;
  duration?: InputMaybe<Scalars['Int']>;
  inclusions?: InputMaybe<Array<Scalars['String']>>;
  exclusions?: InputMaybe<Array<Scalars['String']>>;
  additionalInfo?: InputMaybe<Array<Scalars['String']>>;
  gpsLocation?: InputMaybe<GeoPointInput>;
  textLocation?: InputMaybe<Scalars['String']>;
  meetingPoint?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<MarketItemPriceInput>;
  marketUrl?: InputMaybe<Scalars['AWSURL']>;
  externalId?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Float']>;
  reviewsCount?: InputMaybe<Scalars['Int']>;
  externalCategories: Array<Scalars['String']>;
  cancellationPolicy?: InputMaybe<MarketItemCancellationPolicyInput>;
  bookingType?: InputMaybe<TuiBookingType>;
  ignore?: InputMaybe<Scalars['Boolean']>;
  topPick?: InputMaybe<Scalars['Boolean']>;
  weightedAverageRating?: InputMaybe<Scalars['Float']>;
  internalLabels?: InputMaybe<Array<InternalLabel>>;
};

export enum Day_Of_Week {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

export type DashboardAnalyticsFilterInput = {
  placeUuid: Scalars['String'];
  institutionUuid: Scalars['String'];
};

export type DashboardAnalyticsTotalsDashboard = {
  __typename?: 'DashboardAnalyticsTotalsDashboard';
  placeUuid: Scalars['String'];
  institutionUuid: Scalars['String'];
  Followers?: Maybe<DashboardDiff>;
  Subscribers?: Maybe<DashboardDiff>;
  Reach?: Maybe<DashboardDiff>;
  Reactions?: Maybe<DashboardDiff>;
};

export type DashboardDiff = {
  __typename?: 'DashboardDiff';
  actual?: Maybe<Scalars['Int']>;
  diff?: Maybe<Scalars['Float']>;
};

export type DashboardFilterInput = {
  placeUuid?: InputMaybe<Scalars['String']>;
};

export type DashboardForYouItem = Event | Landmark | TuiMarketItem | Restaurant;

export type DashboardForYouResponse = {
  __typename?: 'DashboardForYouResponse';
  items: Array<DashboardForYouItem>;
  pageOffset?: Maybe<Scalars['Int']>;
};

export type DashboardImages = {
  __typename?: 'DashboardImages';
  forYou: Array<Image>;
  restaurants: Array<Image>;
  landmarks: Array<Image>;
  attractions: Array<Image>;
  events: Array<Image>;
  services: Array<Image>;
};

export type DashboardImagesInput = {
  forYou?: InputMaybe<Array<ImageInput>>;
  restaurants?: InputMaybe<Array<ImageInput>>;
  landmarks?: InputMaybe<Array<ImageInput>>;
  attractions?: InputMaybe<Array<ImageInput>>;
  events?: InputMaybe<Array<ImageInput>>;
  services?: InputMaybe<Array<ImageInput>>;
};

export type DateRange = {
  fromDate: Scalars['AWSDateTime'];
  toDate: Scalars['AWSDateTime'];
};

export type DatetimeFilterInput = {
  daytime?: InputMaybe<Daytime>;
  fromDatetime?: InputMaybe<Scalars['AWSDateTime']>;
  toDatetime?: InputMaybe<Scalars['AWSDateTime']>;
  timezoneCode?: InputMaybe<Scalars['String']>;
};

export type DayItinerary = {
  __typename?: 'DayItinerary';
  itineraryUuid: Scalars['ID'];
  status: ItineraryCreationStatus;
  day: Scalars['Int'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  entries: Array<DayItineraryEntry>;
};

export type DayItineraryEntry = {
  __typename?: 'DayItineraryEntry';
  item: DayItineraryItem;
  daytime: WeekItineraryDaytime;
};

export type DayItineraryEntryInput = {
  entryUuid: Scalars['ID'];
  typename: PostType;
  daytime: WeekItineraryDaytime;
};

export type DayItineraryInput = {
  day: Scalars['Int'];
  entries: Array<DayItineraryEntryInput>;
};

export type DayItineraryItem = GooglePlaceDetail | Landmark | Restaurant | Event | TuiMarketItem;

export enum Daytime {
  Morning = 'MORNING',
  Midday = 'MIDDAY',
  Afternoon = 'AFTERNOON',
  Evening = 'EVENING',
  Night = 'NIGHT',
}

export type DelightedSettings = {
  __typename?: 'DelightedSettings';
  delightedID?: Maybe<Scalars['String']>;
};

export type DelightedSettingsInput = {
  delightedID?: InputMaybe<Scalars['String']>;
};

export type DeviceInfo = {
  __typename?: 'DeviceInfo';
  platform?: Maybe<PlatformEnum>;
  osVersion?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
};

export type DeviceInfoInput = {
  platform?: InputMaybe<PlatformEnum>;
  osVersion?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
};

export type DeviceOutOfHomeCity = {
  __typename?: 'DeviceOutOfHomeCity';
  isOutOfHomeCity: Scalars['Boolean'];
  destinationPlace?: Maybe<Place>;
};

export type DiscoverTopTenFilterInput = {
  mostViewed?: InputMaybe<Scalars['Boolean']>;
  placeUuid?: InputMaybe<Scalars['ID']>;
  installationUuid?: InputMaybe<Scalars['ID']>;
  followingsOnly?: InputMaybe<Scalars['Boolean']>;
  createdAfter?: InputMaybe<Scalars['AWSDateTime']>;
};

export type EmailInput = {
  options: OptionsInput;
  content: ContentInput;
  recipients: Array<RecipientInput>;
};

export type Entity = Institution | Place;

export enum EntityInput {
  Institution = 'INSTITUTION',
  Place = 'PLACE',
}

export enum EntryMood {
  Adventurous = 'ADVENTUROUS',
  Cultural = 'CULTURAL',
  Relaxed = 'RELAXED',
  Foodie = 'FOODIE',
  Romantic = 'ROMANTIC',
  FamilyFriendly = 'FAMILY_FRIENDLY',
  Educational = 'EDUCATIONAL',
  Nightlife = 'NIGHTLIFE',
  Wellness = 'WELLNESS',
  Offbeat = 'OFFBEAT',
  Sporty = 'SPORTY',
}

export type Error = {
  __typename?: 'Error';
  message: Scalars['String'];
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['ID'];
  placeUuid?: Maybe<Scalars['ID']>;
  createdAt: Scalars['AWSDateTime'];
  publishedAt?: Maybe<Scalars['AWSDateTime']>;
  publishmentState: State;
  likeCount: Scalars['Int'];
  like?: Maybe<Like>;
  save?: Maybe<Save>;
  place: Place;
  places: Array<Place>;
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  perex?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  pushContent?: Maybe<Scalars['String']>;
  datetimeFrom?: Maybe<Scalars['AWSDateTime']>;
  datetimeTo?: Maybe<Scalars['AWSDateTime']>;
  location?: Maybe<EventLocation>;
  gpsLocation?: Maybe<GeoPoint>;
  institution?: Maybe<Institution>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageObjects?: Maybe<Array<Image>>;
  videoObjects?: Maybe<Array<Video>>;
  source?: Maybe<Scalars['AWSURL']>;
  /** @deprecated Replaces with categories */
  category?: Maybe<Category>;
  categories: Array<Category>;
  lang: Scalars['String'];
  createdByAdmin?: Maybe<Scalars['Boolean']>;
  aiMetadata?: Maybe<AiMetadata>;
  moderationCategory?: Maybe<ModerationCategories>;
  installationInteraction: PaginatedEventFeedbacks;
  notifyVolunteersOnly?: Maybe<Scalars['Boolean']>;
  notifyAlsoNonCategoried?: Maybe<Scalars['Boolean']>;
  areaOfInterest?: Maybe<GeoShape>;
  NERLocations?: Maybe<Array<NerLocation>>;
  typename?: Maybe<PostType>;
  coverVideo?: Maybe<Scalars['AWSURL']>;
  subTypename?: Maybe<SubTypename>;
  marketUrl?: Maybe<Scalars['AWSURL']>;
  marketItemPricing?: Maybe<Pricing>;
  marketItemType?: Maybe<MarketItemType>;
  marketItemExternalId?: Maybe<Scalars['String']>;
  marketItemHotEvent?: Maybe<Scalars['Boolean']>;
  marketItemEventStatus?: Maybe<MarketItemEventStatus>;
  marketItemVisitorInterests?: Maybe<Array<Scalars['String']>>;
  taskCode?: Maybe<Scalars['String']>;
  streamingPlatform?: Maybe<StreamingPlatform>;
  travelDistanceDuration?: Maybe<TravelDistanceDuration>;
  timezone?: Maybe<Scalars['String']>;
  translations?: Maybe<EventTranslations>;
};

export type EventInstallationInteractionArgs = {
  filter: InstallationFeedbacksFilter;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type EventTravelDistanceDurationArgs = {
  startLocation?: InputMaybe<GeoPointInput>;
};

export type EventTranslationsArgs = {
  lang?: InputMaybe<Scalars['String']>;
};

export enum EventCategory {
  EntertainmentVisitorEvent = 'ENTERTAINMENT_VISITOR_EVENT',
  FamilyEvent = 'FAMILY_EVENT',
  LiveShowsVisitorEvent = 'LIVE_SHOWS_VISITOR_EVENT',
  MusicCinemaVisitorEvent = 'MUSIC_CINEMA_VISITOR_EVENT',
  SportsVisitorEvent = 'SPORTS_VISITOR_EVENT',
  TheatreVisitorEvent = 'THEATRE_VISITOR_EVENT',
}

export type EventDate = {
  __typename?: 'EventDate';
  id: Scalars['ID'];
  date: Scalars['AWSDateTime'];
};

export enum EventDatePeriod {
  Today = 'TODAY',
  Tomorrow = 'TOMORROW',
  ThisWeek = 'THIS_WEEK',
  NextWeek = 'NEXT_WEEK',
}

export type EventDraft = {
  __typename?: 'EventDraft';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  publishedAt?: Maybe<Scalars['AWSDateTime']>;
  place: Place;
  places: Array<Place>;
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  perex?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  pushContent?: Maybe<Scalars['String']>;
  datetimeFrom?: Maybe<Scalars['AWSDateTime']>;
  datetimeTo?: Maybe<Scalars['AWSDateTime']>;
  location?: Maybe<EventLocation>;
  gpsLocation?: Maybe<GeoPoint>;
  institution?: Maybe<Institution>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageObjects?: Maybe<Array<Image>>;
  videoObjects?: Maybe<Array<Video>>;
  source?: Maybe<Scalars['AWSURL']>;
  /** @deprecated Replaces with categories */
  category?: Maybe<Category>;
  categories?: Maybe<Array<Category>>;
  lang?: Maybe<Scalars['String']>;
  createdByAdmin?: Maybe<Scalars['Boolean']>;
  notifyVolunteersOnly?: Maybe<Scalars['Boolean']>;
  notifyAlsoNonCategoried?: Maybe<Scalars['Boolean']>;
  areaOfInterest?: Maybe<GeoShape>;
  coverVideo?: Maybe<Scalars['AWSURL']>;
  subTypename?: Maybe<SubTypename>;
  marketUrl?: Maybe<Scalars['AWSURL']>;
  marketItemPricing?: Maybe<Pricing>;
  marketItemType?: Maybe<MarketItemType>;
  marketItemExternalId?: Maybe<Scalars['String']>;
  marketItemHotEvent?: Maybe<Scalars['Boolean']>;
  marketItemEventStatus?: Maybe<MarketItemEventStatus>;
  marketItemVisitorInterests?: Maybe<Array<Scalars['String']>>;
  streamingPlatform?: Maybe<StreamingPlatform>;
  timezone?: Maybe<Scalars['String']>;
};

export type EventDraftInput = {
  institutionUuid: Scalars['String'];
  placeUuids?: InputMaybe<Array<Scalars['ID']>>;
  /**   places where to save an event */
  title: Scalars['String'];
  subtitle?: InputMaybe<Scalars['String']>;
  perex?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  pushContent?: InputMaybe<Scalars['String']>;
  dateTimeFrom?: InputMaybe<Scalars['AWSDateTime']>;
  datetimeTo?: InputMaybe<Scalars['AWSDateTime']>;
  location?: InputMaybe<EventLocationInput>;
  gpsLocation?: InputMaybe<GeoPointInput>;
  images?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageObjects?: InputMaybe<Array<ImageInput>>;
  videoObjects?: InputMaybe<Array<VideoInput>>;
  source?: InputMaybe<Scalars['AWSURL']>;
  lang?: InputMaybe<Scalars['String']>;
  categoryUuid?: InputMaybe<Scalars['String']>;
  categoryUuids?: InputMaybe<Array<Scalars['String']>>;
  notifyVolunteersOnly?: InputMaybe<Scalars['Boolean']>;
  notifyAlsoNonCategoried?: InputMaybe<Scalars['Boolean']>;
  areaOfInterest?: InputMaybe<GeoShapeInput>;
  subTypename?: InputMaybe<SubTypename>;
  NERLocations?: InputMaybe<Array<NerLocationInput>>;
  coverVideo?: InputMaybe<Scalars['AWSURL']>;
  marketUrl?: InputMaybe<Scalars['AWSURL']>;
  marketItemPricing?: InputMaybe<PricingInput>;
  marketItemType?: InputMaybe<MarketItemType>;
  marketItemExternalId?: InputMaybe<Scalars['String']>;
  marketItemHotEvent?: InputMaybe<Scalars['Boolean']>;
  marketItemEventStatus?: InputMaybe<MarketItemEventStatus>;
  marketItemVisitorInterests?: InputMaybe<Array<Scalars['String']>>;
  streamingPlatform?: InputMaybe<StreamingPlatform>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type EventEntry = {
  __typename?: 'EventEntry';
  id: Scalars['ID'];
  type: MainItineraryEntryType;
  placeUuid: Scalars['ID'];
  itemUuid: Scalars['ID'];
  title: Scalars['String'];
  content: Scalars['String'];
  name: Scalars['String'];
  imageObjects?: Maybe<Array<Image>>;
  address?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  gpsLocation: GeoPoint;
  datetimeFrom: Scalars['AWSDateTime'];
  datetimeTo?: Maybe<Scalars['AWSDateTime']>;
  marketUrl?: Maybe<Scalars['AWSURL']>;
  popular?: Maybe<Scalars['Boolean']>;
  additionalCategories?: Maybe<Array<Scalars['String']>>;
  company?: Maybe<Array<ItineraryCompany>>;
  mood?: Maybe<Array<EntryMood>>;
  priceLevel?: Maybe<ItineraryPriceLevel>;
  streamingPlatform?: Maybe<StreamingPlatform>;
  admission?: Maybe<LandmarkEntryAdmission>;
  timezone?: Maybe<Scalars['String']>;
  save?: Maybe<Save>;
  importance: EventEntryImportance;
  relevance: Scalars['String'];
};

export type EventEntryImportance = {
  __typename?: 'EventEntryImportance';
  isImportant: Scalars['Boolean'];
  reason: Scalars['String'];
};

export type EventFeedback = {
  __typename?: 'EventFeedback';
  id: Scalars['ID'];
  category: FeedbackCategories;
  createdAt: Scalars['AWSDateTime'];
  reaction: AnnouncementFeedbackReaction;
  event?: Maybe<Event>;
  place?: Maybe<Place>;
  installation?: Maybe<Installation>;
  reactionText?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type EventInput = {
  id?: InputMaybe<Scalars['ID']>;
  institutionUuid: Scalars['String'];
  placeUuids?: InputMaybe<Array<Scalars['ID']>>;
  /**   places where to save an event */
  title: Scalars['String'];
  subtitle?: InputMaybe<Scalars['String']>;
  perex?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  pushContent?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['AWSDateTime']>;
  publishmentState?: InputMaybe<State>;
  dateTimeFrom: Scalars['AWSDateTime'];
  datetimeTo?: InputMaybe<Scalars['AWSDateTime']>;
  location?: InputMaybe<EventLocationInput>;
  gpsLocation?: InputMaybe<GeoPointInput>;
  images?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageObjects?: InputMaybe<Array<ImageInput>>;
  videoObjects?: InputMaybe<Array<VideoInput>>;
  source?: InputMaybe<Scalars['AWSURL']>;
  lang: Scalars['String'];
  categoryUuid?: InputMaybe<Scalars['String']>;
  categoryUuids?: InputMaybe<Array<Scalars['String']>>;
  notifyVolunteersOnly?: InputMaybe<Scalars['Boolean']>;
  notifyAlsoNonCategoried?: InputMaybe<Scalars['Boolean']>;
  areaOfInterest?: InputMaybe<GeoShapeInput>;
  subTypename?: InputMaybe<SubTypename>;
  NERLocations?: InputMaybe<Array<NerLocationInput>>;
  coverVideo?: InputMaybe<Scalars['AWSURL']>;
  marketUrl?: InputMaybe<Scalars['AWSURL']>;
  marketItemPricing?: InputMaybe<PricingInput>;
  marketItemType?: InputMaybe<MarketItemType>;
  marketItemExternalId?: InputMaybe<Scalars['String']>;
  marketItemHotEvent?: InputMaybe<Scalars['Boolean']>;
  marketItemEventStatus?: InputMaybe<MarketItemEventStatus>;
  marketItemVisitorInterests?: InputMaybe<Array<Scalars['String']>>;
  createdByAdmin?: InputMaybe<Scalars['Boolean']>;
  streamingPlatform?: InputMaybe<StreamingPlatform>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type EventItineraryEntry = {
  __typename?: 'EventItineraryEntry';
  id: Scalars['ID'];
  type: MainItineraryEntryType;
  placeUuid: Scalars['ID'];
  itemUuid: Scalars['ID'];
  title: Scalars['String'];
  content: Scalars['String'];
  name: Scalars['String'];
  daytime: Daytime;
  customDaytime?: Maybe<Daytime>;
  imageObjects?: Maybe<Array<Image>>;
  address: Scalars['String'];
  gpsLocation: GeoPoint;
  startTime: Scalars['AWSDateTime'];
  endTime: Scalars['AWSDateTime'];
  streamingPlatform?: Maybe<StreamingPlatform>;
  travelDistanceDuration?: Maybe<TravelDistanceDuration>;
  timezone?: Maybe<Scalars['String']>;
  marketUrl?: Maybe<Scalars['AWSURL']>;
  save?: Maybe<Save>;
};

export type EventItineraryEntryTravelDistanceDurationArgs = {
  startLocation?: InputMaybe<GeoPointInput>;
};

export type EventLocation = {
  __typename?: 'EventLocation';
  gps?: Maybe<GeoPoint>;
  name: Scalars['String'];
  online?: Maybe<Scalars['AWSURL']>;
};

export type EventLocationInput = {
  gps?: InputMaybe<GeoPointInput>;
  name: Scalars['String'];
  online?: InputMaybe<Scalars['AWSURL']>;
};

export enum EventSortBy {
  LikeCount = 'LIKE_COUNT',
  CreatedAt = 'CREATED_AT',
  PublishedAt = 'PUBLISHED_AT',
  UpdatedAt = 'UPDATED_AT',
  UnpublishedAt = 'UNPUBLISHED_AT',
  FromDatetime = 'FROM_DATETIME',
  FromDatetimePublished = 'FROM_DATETIME_PUBLISHED',
  ScheduledAt = 'SCHEDULED_AT',
}

export enum EventTimeframe {
  Today = 'today',
  Tomorrow = 'tomorrow',
  ThisWeek = 'this_week',
  ThisWeekend = 'this_weekend',
  NextWeek = 'next_week',
  ThisMonth = 'this_month',
  NextMonth = 'next_month',
}

export type EventTranslations = {
  __typename?: 'EventTranslations';
  title: Scalars['String'];
  content: Scalars['String'];
};

export type EventsByDate = {
  __typename?: 'EventsByDate';
  date: Scalars['AWSDateTime'];
  events: Array<Scalars['ID']>;
};

export type EventsByFilterInput = {
  text?: InputMaybe<Scalars['String']>;
  placeUuid?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<Scalars['String']>>;
  institutions?: InputMaybe<Array<Scalars['String']>>;
  fromDatetime?: InputMaybe<Scalars['AWSDateTime']>;
  state?: InputMaybe<State>;
  marketItemsOnly?: InputMaybe<Scalars['Boolean']>;
};

export type EventsByFilterInputV2 = {
  text?: InputMaybe<Scalars['String']>;
  placeUuid?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<Scalars['String']>>;
  institutions?: InputMaybe<Array<Scalars['String']>>;
  fromDatetime?: InputMaybe<Scalars['AWSDateTime']>;
  toDateTime?: InputMaybe<Scalars['AWSDateTime']>;
  marketItemsOnly?: InputMaybe<Scalars['Boolean']>;
};

export type EventsDates = {
  __typename?: 'EventsDates';
  dates: Array<EventsByDate>;
};

export type EventsDatesFilter = {
  placeUuid?: InputMaybe<Scalars['String']>;
  installationUuid?: InputMaybe<Scalars['String']>;
};

export type EventsForInstallationOrderByInput = {
  by: EventsForInstallationSortBy;
  order: SortOrder;
};

export enum EventsForInstallationSortBy {
  LikeCount = 'LIKE_COUNT',
  FromDatetime = 'FROM_DATETIME',
}

export type EventsOrderByInput = {
  by: EventSortBy;
  order: SortOrder;
};

export type ExploreAllBySearchFilterInput = {
  text?: InputMaybe<Scalars['String']>;
  placeUuid?: InputMaybe<Scalars['ID']>;
  parentPlaceUuid?: InputMaybe<Scalars['ID']>;
  institutionsWithPlaceUuid?: InputMaybe<Scalars['ID']>;
};

export type ExploreItem = Landmark | Restaurant;

export enum ExploreType {
  Landmark = 'LANDMARK',
  Restaurant = 'RESTAURANT',
  Attraction = 'ATTRACTION',
  Event = 'EVENT',
}

export type ExportFile = {
  __typename?: 'ExportFile';
  links?: Maybe<Links>;
  fileName?: Maybe<Scalars['String']>;
};

export type ExternalItem = Landmark | Restaurant | GoogleItem;

export enum ExternalMarketItemCategory {
  AttractionsMarket = 'ATTRACTIONS_MARKET',
  MustSeeMarket = 'MUST_SEE_MARKET',
  MuseumsMarket = 'MUSEUMS_MARKET',
  TourMarket = 'TOUR_MARKET',
  FamilyFriendlyMarket = 'FAMILY_FRIENDLY_MARKET',
  AdventureMarket = 'ADVENTURE_MARKET',
}

export type FbToken = {
  __typename?: 'FbToken';
  identityToken?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type FbTokenInput = {
  identityToken?: InputMaybe<Scalars['String']>;
  authorizationCode?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

export enum FeatureFlagInternalType {
  AnyPayments = 'ANY_PAYMENTS',
  BuiltIn = 'BUILT_IN',
  Chatbot = 'CHATBOT',
  Default = 'DEFAULT',
  Delighted = 'DELIGHTED',
  Iframe = 'IFRAME',
  PlateChecker = 'PLATE_CHECKER',
  Reminder = 'REMINDER',
  VolunteeringMode = 'VOLUNTEERING_MODE',
  DisableSimplichat = 'DISABLE_SIMPLICHAT',
  PayParking = 'PAY_PARKING',
  TuiCityId = 'TUI_CITY_ID',
  TicketmasterEvents = 'TICKETMASTER_EVENTS',
  TheInfatuationRestaurants = 'THE_INFATUATION_RESTAURANTS',
  ChatContextEnabled = 'CHAT_CONTEXT_ENABLED',
  PayWall = 'PAY_WALL',
  ViatorDestination = 'VIATOR_DESTINATION',
  Itinerary = 'ITINERARY',
  PlanMyTrip = 'PLAN_MY_TRIP',
  CelebrityItineraries = 'CELEBRITY_ITINERARIES',
  Services = 'SERVICES',
}

export type Feedback = {
  __typename?: 'Feedback';
  id: Scalars['ID'];
  announcementUuid: Scalars['String'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  reaction: Reaction;
  reactionText?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  quickQuestionOptionsUuids?: Maybe<Array<Scalars['String']>>;
};

export enum FeedbackCategories {
  Like = 'LIKE',
  Feedback = 'FEEDBACK',
  HarmfulContentReport = 'HARMFUL_CONTENT_REPORT',
  Kudos = 'KUDOS',
  Moderation = 'MODERATION',
  QuickQuestion = 'QUICK_QUESTION',
}

export enum FeedbackSentiment {
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE',
}

export type FeedbacksFilter = {
  announcementUuid: Scalars['String'];
  reaction?: InputMaybe<ReactionInFilter>;
};

export type FieldTranslations = {
  __typename?: 'FieldTranslations';
  sk?: Maybe<Scalars['String']>;
  uk?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  ru?: Maybe<Scalars['String']>;
  fil?: Maybe<Scalars['String']>;
  tl?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  ar?: Maybe<Scalars['String']>;
  ur?: Maybe<Scalars['String']>;
  ko?: Maybe<Scalars['String']>;
  bn?: Maybe<Scalars['String']>;
  zh_TW?: Maybe<Scalars['String']>;
  zh?: Maybe<Scalars['String']>;
  zh_CN?: Maybe<Scalars['String']>;
  pl?: Maybe<Scalars['String']>;
  hc?: Maybe<Scalars['String']>;
};

export type FileUploadInput = {
  fileName: Scalars['String'];
  filePath?: InputMaybe<Scalars['String']>;
  contentType?: InputMaybe<Scalars['String']>;
  uploadType: UploadType;
  imageUrl?: InputMaybe<Scalars['String']>;
  base64Image?: InputMaybe<Scalars['String']>;
};

export type Filter = {
  __typename?: 'Filter';
  placeUuid?: Maybe<Scalars['String']>;
  institutionUuid?: Maybe<Scalars['String']>;
  buildId?: Maybe<Scalars['String']>;
  installationUuid?: Maybe<Scalars['String']>;
  interest?: Maybe<Scalars['String']>;
};

export enum FollowStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type FollowingEntryItemResponse = {
  __typename?: 'FollowingEntryItemResponse';
  installationUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  placeTimeZoneCode: Scalars['String'];
  entry?: Maybe<ItineraryEntryItem>;
};

export type Followup = {
  __typename?: 'Followup';
  followupAt: Scalars['AWSDateTime'];
  installationUuid: Scalars['String'];
  postId: Scalars['ID'];
  channel: Scalars['String'];
  notification: Scalars['String'];
  eventUuid?: Maybe<Scalars['String']>;
  placeUuid: Scalars['String'];
};

export type FollowupByFilterInput = {
  placeUuid: Scalars['String'];
};

export type GeoBoundingBox = {
  __typename?: 'GeoBoundingBox';
  topLeft: GeoPoint;
  topRight: GeoPoint;
  bottomLeft: GeoPoint;
  bottomRight: GeoPoint;
};

export type GeoBoundingBoxInput = {
  topLeft: GeoPointInput;
  topRight?: InputMaybe<GeoPointInput>;
  bottomLeft?: InputMaybe<GeoPointInput>;
  bottomRight: GeoPointInput;
};

export type GeoPoint = {
  __typename?: 'GeoPoint';
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};

export type GeoPointBoundingBox = {
  __typename?: 'GeoPointBoundingBox';
  topLeft: GeoPoint;
  bottomRight: GeoPoint;
};

export type GeoPointDelta = {
  __typename?: 'GeoPointDelta';
  latDelta: Scalars['Float'];
  lonDelta: Scalars['Float'];
  padding?: Maybe<Scalars['Int']>;
};

export type GeoPointDeltaInput = {
  latDelta: Scalars['Float'];
  lonDelta: Scalars['Float'];
  padding?: InputMaybe<Scalars['Int']>;
};

export type GeoPointInput = {
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};

export type GeoPolygon = {
  __typename?: 'GeoPolygon';
  coordinates: Array<GeoPoint>;
};

export type GeoPolygonInput = {
  coordinates: Array<GeoPointInput>;
};

export type GeoRangesFilterInput = {
  month?: InputMaybe<Scalars['AWSDateTime']>;
};

export type GeoShape = GeoShapePolygon | GeoShapeCircle | GeoShapeGeometryCollection;

export type GeoShapeCircle = {
  __typename?: 'GeoShapeCircle';
  type: CircleType;
  coordinates: Array<Scalars['Float']>;
  radius: Scalars['String'];
};

export type GeoShapeCircleInput = {
  type: CircleType;
  coordinates: Array<Scalars['Float']>;
  radius: Scalars['String'];
};

export type GeoShapeGeometries = GeoShapePolygon | GeoShapeCircle | GeoShapePoint;

export type GeoShapeGeometriesInput = {
  circle?: InputMaybe<GeoShapeCircleInput>;
  polygon?: InputMaybe<GeoShapePolygonInput>;
  point?: InputMaybe<GeoShapePointInput>;
};

export type GeoShapeGeometryCollection = {
  __typename?: 'GeoShapeGeometryCollection';
  type: GeometryCollectionType;
  geometries: Array<GeoShapeGeometries>;
};

export type GeoShapeGeometryCollectionInput = {
  type: GeometryCollectionType;
  geometries: Array<GeoShapeGeometriesInput>;
};

export type GeoShapeInput = {
  circle?: InputMaybe<GeoShapeCircleInput>;
  polygon?: InputMaybe<GeoShapePolygonInput>;
  geometryCollection?: InputMaybe<GeoShapeGeometryCollectionInput>;
};

export type GeoShapePoint = {
  __typename?: 'GeoShapePoint';
  type: PointType;
  coordinates: Array<Scalars['Float']>;
};

export type GeoShapePointInput = {
  type: PointType;
  coordinates: Array<Scalars['Float']>;
};

export type GeoShapePolygon = {
  __typename?: 'GeoShapePolygon';
  type: PolygonType;
  coordinates: Array<Array<Array<Scalars['Float']>>>;
};

export type GeoShapePolygonInput = {
  type: PolygonType;
  coordinates: Array<Array<Array<Scalars['Float']>>>;
};

export enum GeometryCollectionType {
  GeometryCollection = 'GeometryCollection',
}

export type GoogleItem = {
  __typename?: 'GoogleItem';
  typename?: Maybe<PostType>;
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  placeUuid: Scalars['ID'];
  gpsLocation?: Maybe<GeoPoint>;
  imageUrl?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['AWSURL']>;
  photoUrls?: Maybe<Array<Scalars['AWSURL']>>;
  rating?: Maybe<Scalars['Float']>;
  userRatingCount?: Maybe<Scalars['Int']>;
  priceLevel?: Maybe<RestaurantPriceLevel>;
  cuisines?: Maybe<Array<RestaurantCuisine>>;
  bookTable?: Maybe<RestaurantBookTable>;
  save?: Maybe<Save>;
  openingHours?: Maybe<Array<OpeningHours>>;
  /** @deprecated Replaced by imageObjects */
  image?: Maybe<Image>;
  imageObjects?: Maybe<Array<Image>>;
  locationAttributes?: Maybe<Array<LocationAttribute>>;
  reviews?: Maybe<Array<LocationReview>>;
  travelDistanceDuration?: Maybe<TravelDistanceDuration>;
  isNew?: Maybe<Scalars['Boolean']>;
  googleId?: Maybe<Scalars['String']>;
  additionalType?: Maybe<Array<LandmarkAdditionalType>>;
  primaryType?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Scalars['String']>>;
  translations?: Maybe<GoogleItemTranslations>;
};

export type GoogleItemTravelDistanceDurationArgs = {
  startLocation?: InputMaybe<GeoPointInput>;
};

export type GoogleItemTranslationsArgs = {
  lang?: InputMaybe<Scalars['String']>;
};

export type GoogleItemTranslations = {
  __typename?: 'GoogleItemTranslations';
  title: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  reviews?: Maybe<Array<LocationReview>>;
};

export type GooglePlaceDetail = {
  __typename?: 'GooglePlaceDetail';
  typename?: Maybe<PostType>;
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  placeUuid: Scalars['ID'];
  gpsLocation?: Maybe<GeoPoint>;
  imageUrl?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['AWSURL']>;
  photoUrls?: Maybe<Array<Scalars['AWSURL']>>;
  rating?: Maybe<Scalars['Float']>;
  userRatingCount?: Maybe<Scalars['Int']>;
  priceLevel?: Maybe<RestaurantPriceLevel>;
  cuisines?: Maybe<Array<RestaurantCuisine>>;
  bookTable?: Maybe<RestaurantBookTable>;
  save?: Maybe<Save>;
  openingHours?: Maybe<Array<OpeningHours>>;
  imageObjects?: Maybe<Array<Image>>;
  locationAttributes?: Maybe<Array<LocationAttribute>>;
  reviews?: Maybe<Array<LocationReview>>;
  isNew?: Maybe<Scalars['Boolean']>;
  googleId?: Maybe<Scalars['String']>;
  additionalType?: Maybe<Array<LandmarkAdditionalType>>;
  primaryType?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Scalars['String']>>;
  translations?: Maybe<GooglePlaceDetailTranslations>;
};

export type GooglePlaceDetailTranslationsArgs = {
  lang?: InputMaybe<Scalars['String']>;
};

export type GooglePlaceDetailTranslations = {
  __typename?: 'GooglePlaceDetailTranslations';
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  reviews?: Maybe<Array<LocationReview>>;
};

export type GoogleSpreadsheetKeyValue = {
  __typename?: 'GoogleSpreadsheetKeyValue';
  rows?: Maybe<Array<Maybe<GoogleSpreadsheetKeyValueRow>>>;
};

export type GoogleSpreadsheetKeyValueRow = {
  __typename?: 'GoogleSpreadsheetKeyValueRow';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type GoogleToken = {
  __typename?: 'GoogleToken';
  identityToken?: Maybe<Scalars['String']>;
  authorizationCode?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type GoogleTokenInput = {
  identityToken?: InputMaybe<Scalars['String']>;
  authorizationCode?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

export enum HarmfulContentReason {
  Spam = 'SPAM',
  Scam = 'SCAM',
  Fake = 'FAKE',
  Hateful = 'HATEFUL',
  Inappropriate = 'INAPPROPRIATE',
  Other = 'OTHER',
}

export enum HarmfulReasonInFilter {
  Spam = 'SPAM',
  Scam = 'SCAM',
  Fake = 'FAKE',
  Hateful = 'HATEFUL',
  Inappropriate = 'INAPPROPRIATE',
  Other = 'OTHER',
  All = 'ALL',
}

export type HarmfulReasonReport = {
  __typename?: 'HarmfulReasonReport';
  id: Scalars['ID'];
  announcementUuid: Scalars['String'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  reason: HarmfulContentReason;
  reasonText?: Maybe<Scalars['String']>;
};

export type HarmfulReasonReportsFilter = {
  announcementUuid: Scalars['String'];
  reason?: InputMaybe<HarmfulContentReason>;
};

export type Hatespeech = {
  __typename?: 'Hatespeech';
  probability?: Maybe<Scalars['Float']>;
  status?: Maybe<InferenceStatus>;
};

export type HatespeechInput = {
  probability: Scalars['Float'];
  status: InferenceStatus;
};

export type IAnnouncement = {
  id: Scalars['ID'];
  placeUuid?: Maybe<Scalars['ID']>;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  institution?: Maybe<Institution>;
  title: Scalars['String'];
  lang: Scalars['String'];
  place?: Maybe<Place>;
  places?: Maybe<Array<Place>>;
  category?: Maybe<Category>;
  categories?: Maybe<Array<Category>>;
  subtitle?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  pushContent?: Maybe<Scalars['String']>;
  perex?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['AWSURL']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageObjects?: Maybe<Array<Image>>;
  videoObjects?: Maybe<Array<Video>>;
  createdByAdmin?: Maybe<Scalars['Boolean']>;
  notifyNow?: Maybe<Scalars['Boolean']>;
  scheduledAt?: Maybe<Scalars['AWSDateTime']>;
  publishedAt?: Maybe<Scalars['AWSDateTime']>;
  publishmentState: State;
  notifications?: Maybe<Array<Scalars['AWSDateTime']>>;
  channelIntegrations?: Maybe<Array<Scalars['String']>>;
  location?: Maybe<EventLocation>;
  raffleTitle?: Maybe<Scalars['String']>;
  raffleOptions?: Maybe<RaffleOptions>;
  createdUsingAI?: Maybe<Scalars['Boolean']>;
  AIPrompt?: Maybe<Scalars['String']>;
  notifyVolunteersOnly?: Maybe<Scalars['Boolean']>;
  notifyAlsoNonCategoried?: Maybe<Scalars['Boolean']>;
  areaOfInterest?: Maybe<GeoShape>;
  NERLocations?: Maybe<Array<NerLocation>>;
  typename?: Maybe<PostType>;
  coverVideo?: Maybe<Scalars['AWSURL']>;
  subTypename?: Maybe<SubTypename>;
  gpsLocation?: Maybe<GeoPoint>;
  marketUrl?: Maybe<Scalars['AWSURL']>;
  marketItemType?: Maybe<MarketItemType>;
  marketItemRating?: Maybe<Scalars['Float']>;
  marketItemDuration?: Maybe<Scalars['Int']>;
  marketItemPricing?: Maybe<Pricing>;
  marketItemInclusions?: Maybe<Array<Scalars['String']>>;
  marketItemExclusions?: Maybe<Array<Scalars['String']>>;
  marketItemAdditionalInfo?: Maybe<Array<Scalars['String']>>;
  marketItemCancellationPolicy?: Maybe<MarketItemCancellationPolicy>;
  marketItemLogistics?: Maybe<MarketItemLogistics>;
  marketItemOrder?: Maybe<Scalars['Int']>;
  marketItemViatorProductCode?: Maybe<Scalars['String']>;
  marketItemVisitorInterests?: Maybe<Array<Scalars['String']>>;
  marketItemBookingRequirements?: Maybe<MarketItemBookingRequirements>;
  marketItemBookingQuestions?: Maybe<Array<Scalars['String']>>;
  marketItemProductOptions?: Maybe<Array<MarketItemProductOption>>;
  marketItemExternalId?: Maybe<Scalars['String']>;
  marketItemHotEvent?: Maybe<Scalars['Boolean']>;
  marketItemEventStatus?: Maybe<MarketItemEventStatus>;
  streamingPlatform?: Maybe<StreamingPlatform>;
};

export type IMarketItem = {
  id: Scalars['ID'];
  placeUuid: Scalars['ID'];
  simplicityDiscountPercentage: Scalars['Float'];
  categories: Array<Category>;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  lang: Scalars['String'];
  title: Scalars['String'];
  typename: MarketItemType;
  content?: Maybe<Scalars['String']>;
  imageObjects?: Maybe<Array<Image>>;
  duration?: Maybe<Scalars['Int']>;
  inclusions?: Maybe<Array<Scalars['String']>>;
  exclusions?: Maybe<Array<Scalars['String']>>;
  additionalInfo?: Maybe<Array<Scalars['String']>>;
  gpsLocation?: Maybe<GeoPoint>;
  textLocation?: Maybe<Scalars['String']>;
  meetingPoint?: Maybe<Scalars['String']>;
  price?: Maybe<MarketItemPrice>;
  marketUrl?: Maybe<Scalars['AWSURL']>;
  externalId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  reviewsCount?: Maybe<Scalars['Int']>;
  externalCategories: Array<Scalars['String']>;
  cancellationPolicy?: Maybe<MarketItemCancellationPolicy>;
  createdByAdmin?: Maybe<Scalars['Boolean']>;
  ignore?: Maybe<Scalars['Boolean']>;
  topPick?: Maybe<Scalars['Boolean']>;
  internalLabels?: Maybe<Array<InternalLabel>>;
};

export type IQuickQuestion = {
  id: Scalars['ID'];
  question: Scalars['String'];
  questionOptions?: Maybe<Scalars['AWSJSON']>;
  privateResults?: Maybe<Scalars['Boolean']>;
  scheduledAt?: Maybe<Scalars['AWSDateTime']>;
  publishedAt?: Maybe<Scalars['AWSDateTime']>;
  quickQuestionEndDate?: Maybe<Scalars['AWSDateTime']>;
  placeUuid?: Maybe<Scalars['ID']>;
  placeUuids?: Maybe<Array<Scalars['ID']>>;
  createdAt: Scalars['AWSDateTime'];
  likeCount: Scalars['Int'];
  like?: Maybe<Like>;
  save?: Maybe<Save>;
  quickQuestionFeedback?: Maybe<QuickQuestionFeedback>;
  institution?: Maybe<Institution>;
  categories: Array<Category>;
  lang: Scalars['String'];
  createdByAdmin?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedByAdmin?: Maybe<Scalars['Boolean']>;
  notifyNow?: Maybe<Scalars['Boolean']>;
  publishmentState: State;
  notifications?: Maybe<Array<Scalars['AWSDateTime']>>;
  aiMetadata?: Maybe<AiMetadata>;
  moderationCategory?: Maybe<ModerationCategories>;
  isChat?: Maybe<Scalars['Boolean']>;
  notifyVolunteersOnly?: Maybe<Scalars['Boolean']>;
  notifyAlsoNonCategoried?: Maybe<Scalars['Boolean']>;
  typename?: Maybe<PostType>;
  NERLocations?: Maybe<Array<NerLocation>>;
  gpsLocation?: Maybe<GeoPoint>;
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID'];
  links?: Maybe<Links>;
  blurHash?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  imageVerification?: Maybe<ImageVerification>;
  metadata?: Maybe<ImageMetadata>;
  hasThumbnail?: Maybe<Scalars['Boolean']>;
};

export type ImageInput = {
  id: Scalars['ID'];
  blurHash?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<ImageMetadataInput>;
};

export type ImageMetadata = {
  __typename?: 'ImageMetadata';
  mimeType?: Maybe<Scalars['String']>;
  /**  https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types */
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type ImageMetadataInput = {
  mimeType?: InputMaybe<Scalars['String']>;
  /**  https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types */
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type InboxByFilterInput = {
  installationUuid: Scalars['String'];
  placeUuid?: InputMaybe<Scalars['String']>;
};

export type InboxMessage = {
  __typename?: 'InboxMessage';
  id: Scalars['ID'];
  category: InboxMessageCategory;
  createdAt: Scalars['AWSDateTime'];
  title: Scalars['String'];
  institutionUuid?: Maybe<Scalars['String']>;
  placeUuid?: Maybe<Scalars['String']>;
  deeplink?: Maybe<Scalars['String']>;
  /** @deprecated Replaced with postCategories */
  postCategory?: Maybe<Category>;
  postCategories?: Maybe<Array<Maybe<Category>>>;
};

export enum InboxMessageCategory {
  Event = 'EVENT',
  News = 'NEWS',
  Announcement = 'ANNOUNCEMENT',
  QuickQuestion = 'QUICK_QUESTION',
  ChatMessage = 'CHAT_MESSAGE',
  Feedback = 'FEEDBACK',
  FundResults = 'FUND_RESULTS',
  Poll = 'POLL',
  Raffle = 'RAFFLE',
  Emergency = 'EMERGENCY',
  Alert = 'ALERT',
  Fund = 'FUND',
  ReminderWasteManagement = 'REMINDER_WASTE_MANAGEMENT',
  ReminderStreetSweeping = 'REMINDER_STREET_SWEEPING',
  Coupon = 'COUPON',
  DeepLink = 'DEEP_LINK',
  ReportedProblem = 'REPORTED_PROBLEM',
  Donation = 'DONATION',
  MarketItem = 'MARKET_ITEM',
}

export enum InferenceStatus {
  Done = 'DONE',
  DoneNotEligible = 'DONE_NOT_ELIGIBLE',
  InProgress = 'IN_PROGRESS',
}

export type Influencer = {
  __typename?: 'Influencer';
  id: Scalars['ID'];
  avatar?: Maybe<Image>;
  background?: Maybe<Image>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  socials: Array<InfluencerSocials>;
  properties: Array<InfluencerProperty>;
  places: Array<Place>;
};

export type InfluencerInput = {
  avatar?: InputMaybe<ImageInput>;
  background?: InputMaybe<ImageInput>;
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  socials: Array<InfluencerSocialsInput>;
  properties: Array<InfluencerPropertyInput>;
  placeUuids: Array<Scalars['String']>;
};

export type InfluencerItinerariesByFilter = {
  placeUuid?: InputMaybe<Scalars['String']>;
  influencerUuid: Scalars['String'];
};

export type InfluencerItinerary = {
  __typename?: 'InfluencerItinerary';
  id: Scalars['ID'];
  influencerUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  title: Scalars['String'];
  subTitle?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  itineraryEntries: Array<InfluencerItineraryEntry>;
};

export type InfluencerItineraryEntry = {
  __typename?: 'InfluencerItineraryEntry';
  spot: InfluencerSpot;
  itineraryUuid: Scalars['ID'];
  day: Scalars['Int'];
  time: Daytime;
};

export type InfluencerItineraryEntryInput = {
  spotUuid: Scalars['ID'];
  day: Scalars['Int'];
  time: Daytime;
};

export type InfluencerItineraryInput = {
  influencerUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  title: Scalars['String'];
  subTitle?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<ImageInput>;
  itineraryEntries: Array<InfluencerItineraryEntryInput>;
};

export type InfluencerProperty = {
  __typename?: 'InfluencerProperty';
  name: PropertyType;
  value: Scalars['String'];
};

export type InfluencerPropertyInput = {
  name: PropertyType;
  value: Scalars['String'];
};

export type InfluencerSocials = {
  __typename?: 'InfluencerSocials';
  type: SocialType;
  url: Scalars['String'];
};

export type InfluencerSocialsInput = {
  type: SocialType;
  url: Scalars['String'];
};

export type InfluencerSpot = {
  __typename?: 'InfluencerSpot';
  id: Scalars['ID'];
  placeUuid: Scalars['ID'];
  influencerUuid: Scalars['ID'];
  title: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  location?: Maybe<Location>;
  startTime?: Maybe<Scalars['AWSTime']>;
  endTime?: Maybe<Scalars['AWSTime']>;
  duration?: Maybe<Scalars['Int']>;
  marketItem?: Maybe<MarketItem>;
  price?: Maybe<Price>;
  category?: Maybe<Category>;
  favourite: Scalars['Boolean'];
};

export type InfluencerSpotInput = {
  placeUuid: Scalars['ID'];
  influencerUuid: Scalars['ID'];
  title: Scalars['String'];
  content?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<ImageInput>;
  location?: InputMaybe<LocationInput>;
  startTime?: InputMaybe<Scalars['AWSTime']>;
  endTime?: InputMaybe<Scalars['AWSTime']>;
  duration?: InputMaybe<Scalars['Int']>;
  marketItemUuid?: InputMaybe<Scalars['ID']>;
  price?: InputMaybe<PriceInput>;
  categoryUuid?: InputMaybe<Scalars['String']>;
  favourite: Scalars['Boolean'];
};

export type InfluencerSpotsByFilter = {
  placeUuid?: InputMaybe<Scalars['String']>;
  influencerUuid: Scalars['String'];
  categoryUuids?: InputMaybe<Array<Scalars['String']>>;
};

export type InfluencersByFilterInput = {
  placeUuid?: InputMaybe<Scalars['String']>;
};

export type Insight = {
  __typename?: 'Insight';
  id: Scalars['String'];
  title: Scalars['String'];
  notifDelivered: Scalars['Int'];
  notifOpen: Scalars['Int'];
  openRate: Scalars['Float'];
  openOverall: Scalars['Int'];
  shares: Scalars['Int'];
  publishedAt?: Maybe<Scalars['AWSDateTime']>;
  reactionCount: Scalars['Int'];
  harmfulContentReportsCount: Scalars['Int'];
};

export enum InsightSortBy {
  Title = 'TITLE',
  NotifDelivered = 'NOTIF_DELIVERED',
  NotifOpen = 'NOTIF_OPEN',
  OpenRate = 'OPEN_RATE',
  OpenOverall = 'OPEN_OVERALL',
  Shares = 'SHARES',
  PublishedAt = 'PUBLISHED_AT',
}

export enum InsightType {
  Announcement = 'ANNOUNCEMENT',
  Event = 'EVENT',
}

export type InsightsFilterInput = {
  placeUuid: Scalars['String'];
  institutionUuid: Scalars['String'];
  type: InsightType;
};

export type InsightsOrderByInput = {
  by: InsightSortBy;
  order: SortOrder;
};

export type Installation = {
  __typename?: 'Installation';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  place?: Maybe<Place>;
  /** @deprecated Replaced with preferences */
  institutionInterests?: Maybe<Array<Institution>>;
  preferences?: Maybe<Array<InstallationInterest>>;
  /** @deprecated Replaced with preferences */
  interests?: Maybe<Array<Category>>;
  metadata?: Maybe<Metadata>;
  /** @deprecated User cluster logic is obsolete */
  userClusterUuid?: Maybe<Scalars['ID']>;
  coupons?: Maybe<PaginatedActivatedCoupons>;
  profile?: Maybe<Profile>;
  volunteeringMode?: Maybe<Scalars['Boolean']>;
  notificationsDisabled?: Maybe<Scalars['Boolean']>;
  notificationsDisableTimestamp?: Maybe<Scalars['AWSDateTime']>;
  deviceInfo?: Maybe<DeviceInfo>;
  appVersion?: Maybe<Scalars['String']>;
  installationReminder?: Maybe<Array<InstallationReminder>>;
  lastAppOpenTimestamp?: Maybe<Scalars['AWSDateTime']>;
  lastNotificationSentTimestamp?: Maybe<Scalars['AWSDateTime']>;
  lastNotificationDeliveredTimestamp?: Maybe<Scalars['AWSDateTime']>;
  raffleEntries?: Maybe<Array<RaffleEntry>>;
  licensePlates?: Maybe<Array<LicensePlate>>;
  whitelisted?: Maybe<Scalars['Boolean']>;
  lastUpdatedTimestamps?: Maybe<LastTimestamps>;
  referringLink?: Maybe<Scalars['String']>;
  isVisitorModeEnabled?: Maybe<Scalars['Boolean']>;
  visitorInterests?: Maybe<VisitorInterests>;
  itineraryUuid?: Maybe<Scalars['ID']>;
  badges?: Maybe<Array<InstallationBadge>>;
  numberOfBadgePlaces?: Maybe<Scalars['Int']>;
  experiencePoints?: Maybe<Scalars['Int']>;
  isSubscribed?: Maybe<Scalars['Boolean']>;
  mainItineraryTopic?: Maybe<MainItineraryTopic>;
  itineraryPreferences?: Maybe<ItineraryPreferences>;
};

export type InstallationBadge = {
  __typename?: 'InstallationBadge';
  badgeUuid: Scalars['ID'];
  placeUuid?: Maybe<Scalars['ID']>;
  acquiredAt: Scalars['AWSDateTime'];
};

export type InstallationBadgeInput = {
  badgeUuid: Scalars['ID'];
  placeUuid?: InputMaybe<Scalars['ID']>;
  acquiredAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type InstallationFeedbacksFilter = {
  installationUuid?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<FeedbackCategories>;
  reaction?: InputMaybe<AnnouncementFeedbackReaction>;
};

export type InstallationFollow = {
  __typename?: 'InstallationFollow';
  installationUuid: Scalars['ID'];
  targetInstallationUuid: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  status: FollowStatus;
};

export type InstallationGeoMetadata = {
  __typename?: 'InstallationGeoMetadata';
  installationUuid: Scalars['ID'];
  placeUuid?: Maybe<Scalars['ID']>;
  geoHomeAddress?: Maybe<GeoPoint>;
  computedGeoHomeAddress?: Maybe<GeoPoint>;
  computedAt?: Maybe<Scalars['AWSDateTime']>;
  geoHomeAddressSetAt?: Maybe<Scalars['AWSDateTime']>;
  createdFrom?: Maybe<InstallationHomeAddressOrigin>;
};

export type InstallationGeoMetadataInput = {
  placeUuid?: InputMaybe<Scalars['ID']>;
  geoHomeAddress?: InputMaybe<GeoPointInput>;
  computedGeoHomeAddress?: InputMaybe<GeoPointInput>;
  createdFrom: InstallationHomeAddressOrigin;
};

export type InstallationGeoPoint = {
  __typename?: 'InstallationGeoPoint';
  geo: GeoPoint;
  time: Scalars['AWSDateTime'];
  localtime?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  accuracy?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  heading?: Maybe<Scalars['Float']>;
};

export type InstallationGeoPointInput = {
  geo: GeoPointInput;
  time: Scalars['AWSDateTime'];
  localtime?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  accuracy?: InputMaybe<Scalars['Float']>;
  speed?: InputMaybe<Scalars['Float']>;
  heading?: InputMaybe<Scalars['Float']>;
};

export type InstallationGeoPointResult = {
  __typename?: 'InstallationGeoPointResult';
  result: Scalars['String'];
  geo: GeoPoint;
  time: Scalars['AWSDateTime'];
  localtime?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  accuracy?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  heading?: Maybe<Scalars['Float']>;
};

export type InstallationGeoRange = {
  __typename?: 'InstallationGeoRange';
  installationUuid: Scalars['ID'];
  placeUuid?: Maybe<Scalars['ID']>;
  points: Array<InstallationGeoPoint>;
};

export type InstallationGeoRangeInput = {
  installationUuid: Scalars['ID'];
  placeUuid?: InputMaybe<Scalars['ID']>;
  points: Array<InstallationGeoPointInput>;
};

export type InstallationGeoRangeKeyInput = {
  installationUuid: Scalars['ID'];
  date: Scalars['AWSDateTime'];
};

export type InstallationGeoRangeResult = {
  __typename?: 'InstallationGeoRangeResult';
  installationUuid: Scalars['ID'];
  placeUuid?: Maybe<Scalars['ID']>;
  points: Array<InstallationGeoPointResult>;
};

export enum InstallationHomeAddressOrigin {
  UserInput = 'USER_INPUT',
  Computed = 'COMPUTED',
  Reminder = 'REMINDER',
}

export type InstallationInput = {
  id?: InputMaybe<Scalars['ID']>;
  placeUuid?: InputMaybe<Scalars['String']>;
  interests?: InputMaybe<Array<Scalars['String']>>;
  interestsList?: InputMaybe<Array<InterestInput>>;
  preferences?: InputMaybe<Array<InstallationInterestInput>>;
  metadata?: InputMaybe<InstallationMetadataInput>;
  /**   pinned institution for coupons */
  profile?: InputMaybe<InstallationProfileInput>;
  optionalData?: InputMaybe<Scalars['AWSJSON']>;
  volunteeringMode?: InputMaybe<Scalars['Boolean']>;
  notificationsDisabled?: InputMaybe<Scalars['Boolean']>;
  deviceInfo?: InputMaybe<DeviceInfoInput>;
  appVersion?: InputMaybe<Scalars['String']>;
  installationReminder?: InputMaybe<Array<InstallationReminderInput>>;
  lastAppOpenTimestamp?: InputMaybe<Scalars['AWSDateTime']>;
  lastNotificationSentTimestamp?: InputMaybe<Scalars['AWSDateTime']>;
  lastNotificationDeliveredTimestamp?: InputMaybe<Scalars['AWSDateTime']>;
  licensePlates?: InputMaybe<Array<LicensePlateInput>>;
  whitelisted?: InputMaybe<Scalars['Boolean']>;
  referringLink?: InputMaybe<Scalars['String']>;
  isVisitorModeEnabled?: InputMaybe<Scalars['Boolean']>;
  visitorInterests?: InputMaybe<VisitorInterestsInput>;
  itineraryUuid?: InputMaybe<Scalars['ID']>;
  badges?: InputMaybe<Array<InstallationBadgeInput>>;
  isSubscribed?: InputMaybe<Scalars['Boolean']>;
  mainItineraryTopic?: InputMaybe<MainItineraryTopic>;
  itineraryPreferences?: InputMaybe<ItineraryPreferencesInput>;
};

export type InstallationInterest = {
  __typename?: 'InstallationInterest';
  id: Scalars['ID'];
  type: InterestEnum;
  notification?: Maybe<Scalars['Boolean']>;
  institution?: Maybe<Institution>;
};

export type InstallationInterestInput = {
  id: Scalars['ID'];
  type: InterestEnum;
  notification?: InputMaybe<Scalars['Boolean']>;
};

export type InstallationLikesFilter = {
  installationUuid: Scalars['String'];
};

export type InstallationMetadataInput = {
  expoToken: Scalars['String'];
  admToken?: InputMaybe<Scalars['String']>;
  apnsToken?: InputMaybe<Scalars['String']>;
  baiduToken?: InputMaybe<Scalars['String']>;
  fcmToken?: InputMaybe<Scalars['String']>;
  mpnsToken?: InputMaybe<Scalars['String']>;
  wnsToken?: InputMaybe<Scalars['String']>;
  buildId?: InputMaybe<Scalars['String']>;
};

export type InstallationProfileInput = {
  username?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  socialLinks?: InputMaybe<Array<SocialLinkInput>>;
  imageObjects?: InputMaybe<Array<ImageInput>>;
  name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  homeAddress?: InputMaybe<Scalars['String']>;
  homeAddressCoordinates?: InputMaybe<GeoPointInput>;
  workAddress?: InputMaybe<Scalars['String']>;
  workAddressCoordinates?: InputMaybe<GeoPointInput>;
  appleToken?: InputMaybe<AppleTokenInput>;
  googleToken?: InputMaybe<GoogleTokenInput>;
  fbToken?: InputMaybe<FbTokenInput>;
  age?: InputMaybe<Scalars['Int']>;
  tripProps?: InputMaybe<TripPropsInput>;
};

export type InstallationReminder = {
  __typename?: 'InstallationReminder';
  installationReminderUuid: Scalars['ID'];
  deliveryBeforeDateOption?: Maybe<Array<ReminderDeliveryOption>>;
  deliveryTime?: Maybe<Scalars['AWSTime']>;
  reminderType?: Maybe<ReminderType>;
  location?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  /**   setup installation notifications */
  reminderCategoryCodes?: Maybe<Array<Scalars['String']>>;
  reminderReady: Scalars['Boolean'];
};

export type InstallationReminderInput = {
  installationReminderUuid?: InputMaybe<Scalars['ID']>;
  deliveryBeforeDateOption?: InputMaybe<Array<ReminderDeliveryOption>>;
  deliveryTime?: InputMaybe<Scalars['AWSTime']>;
  reminderType?: InputMaybe<ReminderType>;
  location?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  reminderCategoryCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type InstallationSavesFilter = {
  installationUuid: Scalars['String'];
};

export type InstallationScheduledReminder = {
  __typename?: 'InstallationScheduledReminder';
  id: Scalars['ID'];
  installationUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  institutionUuid: Scalars['ID'];
  type: ReminderType;
  reminderCategoryCode?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  /**   timestamp of notification */
  timestamp: Scalars['AWSDateTime'];
  /**   timestamp of activity */
  originalTimestamp: Scalars['AWSDateTime'];
  status: ReminderStatus;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type InstallationUuids = {
  __typename?: 'InstallationUuids';
  installationUuids: Array<Maybe<Scalars['ID']>>;
};

export type InstallationsFilterInput = {
  placeUuid?: InputMaybe<Scalars['String']>;
  buildId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  interest?: InputMaybe<Scalars['String']>;
  installationUuid?: InputMaybe<Scalars['String']>;
  interestType?: InputMaybe<InterestEnum>;
  whitelisted?: InputMaybe<Scalars['Boolean']>;
};

export type Institution = {
  __typename?: 'Institution';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  places?: Maybe<Array<Place>>;
  enabled?: Maybe<Scalars['String']>;
  isRecommended?: Maybe<Scalars['Boolean']>;
  isChatEnabled?: Maybe<Scalars['Boolean']>;
  isDisabledFromMap?: Maybe<Scalars['Boolean']>;
  isEnabledInstitutionInAllPlacesOnlyDashboardAnalytics?: Maybe<Scalars['Boolean']>;
  visitorMode?: Maybe<Scalars['Boolean']>;
  /**   Information about institution in given place */
  inPlace?: Maybe<InstitutionInPlace>;
  inPlaces?: Maybe<Array<InstitutionInPlace>>;
  name: Scalars['String'];
  category: Category;
  preferences?: Maybe<Array<Scalars['String']>>;
  countryCode?: Maybe<Scalars['String']>;
  branding?: Maybe<Branding>;
  info?: Maybe<InstitutionInfo>;
  lang?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  description?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  logo?: Maybe<Scalars['AWSURL']>;
  /** @deprecated No longer supported */
  webSite?: Maybe<Scalars['AWSURL']>;
  taskCodes?: Maybe<Array<Scalars['String']>>;
  /**   get coupon for institution */
  coupons: PaginatedCoupons;
  /**   statistics */
  newsCount?: Maybe<Scalars['Int']>;
  eventsCount?: Maybe<Scalars['Int']>;
  publishedNewsCount?: Maybe<Scalars['Int']>;
  publishedEventsCount?: Maybe<Scalars['Int']>;
  publishedPollNewsCount?: Maybe<Scalars['Int']>;
  unpublishedEventsCount?: Maybe<Scalars['Int']>;
  unpublishedNewsCount?: Maybe<Scalars['Int']>;
  unpublishedPollNewsCount?: Maybe<Scalars['Int']>;
  draftsEventsCount?: Maybe<Scalars['Int']>;
  draftsNewsCount?: Maybe<Scalars['Int']>;
  draftsPollNewsCount?: Maybe<Scalars['Int']>;
  scheduledNewsCount?: Maybe<Scalars['Int']>;
  scheduledEventsCount?: Maybe<Scalars['Int']>;
  scheduledPollNewsCount?: Maybe<Scalars['Int']>;
  publishedRaffleNewsCount?: Maybe<Scalars['Int']>;
  unpublishedRaffleNewsCount?: Maybe<Scalars['Int']>;
  draftsRaffleNewsCount?: Maybe<Scalars['Int']>;
  scheduledRaffleNewsCount?: Maybe<Scalars['Int']>;
  /**  return only if filter with placeUuid */
  followersCount?: Maybe<Scalars['Int']>;
  oldAdminDashboardInitialValue?: Maybe<Scalars['Int']>;
};

export type InstitutionInPlaceArgs = {
  placeUuid: Scalars['ID'];
};

export type InstitutionCouponsArgs = {
  orderBy?: InputMaybe<CouponsOrderByInput>;
  nextToken?: InputMaybe<Scalars['String']>;
  interval?: InputMaybe<IntervalValidityFilterInput>;
};

export enum InstitutionFeIconEnum {
  Bin = 'BIN',
  Car = 'CAR',
  Earth = 'EARTH',
  Form = 'FORM',
  Gps = 'GPS',
  PaperPlane = 'PAPER_PLANE',
  Payment = 'PAYMENT',
  Phone = 'PHONE',
}

export type InstitutionFeatureFlag = {
  __typename?: 'InstitutionFeatureFlag';
  type: InstitutionFeatureFlagEnum;
  title: Scalars['String'];
  titleLocalizations?: Maybe<Array<TitleLocalization>>;
  icon?: Maybe<Image>;
  value: Scalars['String'];
  feIcon?: Maybe<InstitutionFeIconEnum>;
  benefitsEnabled?: Maybe<Scalars['Boolean']>;
};

export enum InstitutionFeatureFlagEnum {
  Email = 'EMAIL',
  Website = 'WEBSITE',
  Phone = 'PHONE',
  Address = 'ADDRESS',
  FeatureFlag = 'FEATURE_FLAG',
  DisableNer = 'DISABLE_NER',
}

export type InstitutionFeatureFlagInput = {
  type: InstitutionFeatureFlagEnum;
  title: Scalars['String'];
  titleLocalizations?: InputMaybe<Array<TitleLocalizationInput>>;
  icon?: InputMaybe<ImageInput>;
  value: Scalars['String'];
  feIcon?: InputMaybe<InstitutionFeIconEnum>;
  benefitsEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type InstitutionInPlace = {
  __typename?: 'InstitutionInPlace';
  placeId: Scalars['ID'];
  place: Place;
  role?: Maybe<InstitutionPlaceRole>;
};

export type InstitutionInPlaceInput = {
  placeId: Scalars['ID'];
  /**   Used to set the role of institution in place */
  role?: InputMaybe<InstitutionPlaceRole>;
};

export type InstitutionInfo = {
  __typename?: 'InstitutionInfo';
  /** @deprecated Use Place->info->about instead. */
  aboutPlace?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use Place->info->reportsEmail instead. */
  reportsEmail?: Maybe<Scalars['String']>;
  featureFlags?: Maybe<Array<InstitutionFeatureFlag>>;
  socials?: Maybe<Array<SocialLink>>;
  /** @deprecated Replaced by featureFlags Array */
  website?: Maybe<Scalars['String']>;
  /** @deprecated Replaced by featureFlags Array */
  contactEmail?: Maybe<Scalars['String']>;
  /** @deprecated Replaced by featureFlags Array */
  contactPhone?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
};

export type InstitutionInfoInput = {
  aboutPlace?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  reportsEmail?: InputMaybe<Scalars['String']>;
  featureFlags?: InputMaybe<Array<InstitutionFeatureFlagInput>>;
  socials?: InputMaybe<Array<SocialLinkInput>>;
  website?: InputMaybe<Scalars['String']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<LocationInput>;
};

export type InstitutionInput = {
  id?: InputMaybe<Scalars['ID']>;
  isRecommended?: InputMaybe<Scalars['Boolean']>;
  isChatEnabled?: InputMaybe<Scalars['Boolean']>;
  isDisabledFromMap?: InputMaybe<Scalars['Boolean']>;
  visitorMode?: InputMaybe<Scalars['Boolean']>;
  isEnabledInstitutionInAllPlacesOnlyDashboardAnalytics?: InputMaybe<Scalars['Boolean']>;
  places?: InputMaybe<Array<InstitutionInPlaceInput>>;
  taskCodes?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  categoryUuid: Scalars['String'];
  preferences?: InputMaybe<Array<Scalars['String']>>;
  logo?: InputMaybe<Scalars['AWSURL']>;
  description?: InputMaybe<Scalars['String']>;
  webSite?: InputMaybe<Scalars['AWSURL']>;
  branding?: InputMaybe<BrandingInput>;
  info?: InputMaybe<InstitutionInfoInput>;
  lang?: InputMaybe<Scalars['String']>;
  oldAdminDashboardInitialValue?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<Scalars['String']>;
};

export enum InstitutionPlaceRole {
  City = 'CITY',
  Official = 'OFFICIAL',
  Unofficial = 'UNOFFICIAL',
  LocalBusiness = 'LOCAL_BUSINESS',
  Religional = 'RELIGIONAL',
  ElectedOfficials = 'ELECTED_OFFICIALS',
  Unions = 'UNIONS',
}

/**   FOLLOWERS_COUNT work only if placeUuid is defined in filter */
export enum InstitutionSortBy {
  FollowersCount = 'FOLLOWERS_COUNT',
  Name = 'NAME',
  Geo = 'GEO',
  All = 'ALL',
}

export type InstitutionsByFilterInput = {
  prefix?: InputMaybe<Scalars['String']>;
  placeUuid?: InputMaybe<Scalars['String']>;
  taskCode?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<InstitutionPlaceRole>;
};

export type InstitutionsBySearchFilterInput = {
  text?: InputMaybe<Scalars['String']>;
  placeUuid?: InputMaybe<Scalars['String']>;
};

export type InstitutionsOrderByInput = {
  by: InstitutionSortBy;
  order: SortOrder;
};

export type InstitutionsPlacesBySearchFilterInput = {
  text: Scalars['String'];
  entity?: InputMaybe<EntityInput>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  placeUuid?: InputMaybe<Scalars['String']>;
  parentPlaceUuid?: InputMaybe<Scalars['ID']>;
  geo?: InputMaybe<GeoPointInput>;
  /**   if you use role you must define placeUuid, does not work with Place entity */
  roles?: InputMaybe<Array<InputMaybe<InstitutionPlaceRole>>>;
  /**   return only recommended institutions if set to true, otherwise, return all institutions */
  onlyRecommended?: InputMaybe<Scalars['Boolean']>;
  visitorMode?: InputMaybe<Scalars['Boolean']>;
  preferences?: InputMaybe<Array<Scalars['String']>>;
};

export type Integration = {
  __typename?: 'Integration';
  id: Scalars['ID'];
  institutionId: Scalars['ID'];
  type: IntegrationType;
  channelId: Scalars['String'];
  channelName: Scalars['String'];
  channelAvatar: Scalars['AWSURL'];
  channelAccessToken: Scalars['String'];
  channelAccessVerifier?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
};

export type IntegrationInput = {
  institutionId: Scalars['ID'];
  type: IntegrationType;
  channelAccessToken: Scalars['String'];
  channelAccessVerifier?: InputMaybe<Scalars['String']>;
  channelId?: InputMaybe<Scalars['String']>;
  channelName?: InputMaybe<Scalars['String']>;
  channelAvatar?: InputMaybe<Scalars['AWSURL']>;
  enabled: Scalars['Boolean'];
};

export enum IntegrationType {
  Facebook = 'FACEBOOK',
  Twitter = 'TWITTER',
}

export enum InterestEnum {
  Category = 'CATEGORY',
  Institution = 'INSTITUTION',
}

export type InterestInput = {
  id: Scalars['String'];
  type: InterestEnum;
  notification?: InputMaybe<Scalars['Boolean']>;
};

export enum InternalLabel {
  SellingFast = 'SELLING_FAST',
  SpecialOffer = 'SPECIAL_OFFER',
  FamilyFavorite = 'FAMILY_FAVORITE',
  BestSeller = 'BEST_SELLER',
}

export type IntervalValidity = {
  __typename?: 'IntervalValidity';
  fromDate: Scalars['AWSDate'];
  toDate?: Maybe<Scalars['AWSDate']>;
  fromTime?: Maybe<Scalars['AWSTime']>;
  toTime?: Maybe<Scalars['AWSTime']>;
};

export type IntervalValidityFilterInput = {
  toDate?: InputMaybe<Scalars['AWSDate']>;
};

export type IntervalValidityInput = {
  fromDate: Scalars['AWSDate'];
  toDate?: InputMaybe<Scalars['AWSDate']>;
  fromTime?: InputMaybe<Scalars['AWSTime']>;
  toTime?: InputMaybe<Scalars['AWSTime']>;
};

export type Itinerary = {
  __typename?: 'Itinerary';
  id: Scalars['ID'];
  installationUuid: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  entries: Array<ItineraryEntry>;
  placeUuid: Scalars['ID'];
  startDate?: Maybe<Scalars['AWSDate']>;
  endDate?: Maybe<Scalars['AWSDate']>;
};

export type ItineraryCategoriesFilterInput = {
  gpsLocation: GeoPointInput;
  datetimeFilter?: InputMaybe<DatetimeFilterInput>;
  maxDistance?: InputMaybe<Scalars['Int']>;
};

export type ItineraryCategoriesResponse = {
  __typename?: 'ItineraryCategoriesResponse';
  events: Array<ItineraryEventCategories>;
  landmarks: Array<ItineraryLandmarkCategories>;
  tours: Array<ItineraryTourCategories>;
  restaurants: Array<ItineraryRestaurantCategories>;
};

export type ItineraryCelebrity = {
  __typename?: 'ItineraryCelebrity';
  id: Scalars['ID'];
  name: Scalars['String'];
  imageObjects?: Maybe<Array<Image>>;
};

export enum ItineraryCompany {
  Solo = 'SOLO',
  Family = 'FAMILY',
  Couple = 'COUPLE',
  Friends = 'FRIENDS',
}

export enum ItineraryCreationStatus {
  Creating = 'CREATING',
  Created = 'CREATED',
  Failed = 'FAILED',
  Regenerating = 'REGENERATING',
}

export type ItineraryEntry = {
  __typename?: 'ItineraryEntry';
  id: Scalars['ID'];
  itineraryUuid: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  content: Scalars['String'];
  date: Scalars['AWSDateTime'];
  dayNumber: Scalars['Int'];
  daytime: Daytime;
  location?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  tourLink?: Maybe<Scalars['AWSURL']>;
};

export type ItineraryEntryInput = {
  id?: InputMaybe<Scalars['ID']>;
  itineraryUuid?: InputMaybe<Scalars['ID']>;
  content: Scalars['String'];
  date: Scalars['AWSDateTime'];
  dayNumber: Scalars['Int'];
  daytime: Daytime;
  location?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  tourLink?: InputMaybe<Scalars['AWSURL']>;
};

export type ItineraryEntryItem =
  | LandmarkItineraryEntry
  | EventItineraryEntry
  | RestaurantItineraryEntry
  | TourItineraryEntry;

export enum ItineraryEventCategories {
  Music = 'MUSIC',
  TheaterAndArts = 'THEATER_AND_ARTS',
  Comedy = 'COMEDY',
  Sports = 'SPORTS',
  Family = 'FAMILY',
  Festivals = 'FESTIVALS',
  Nightlife = 'NIGHTLIFE',
  ToursAndTravel = 'TOURS_AND_TRAVEL',
  Food = 'FOOD',
  Business = 'BUSINESS',
}

export type ItineraryInput = {
  id?: InputMaybe<Scalars['ID']>;
  installationUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  startDate?: InputMaybe<Scalars['AWSDate']>;
  endDate?: InputMaybe<Scalars['AWSDate']>;
};

export enum ItineraryInterest {
  Landmarks = 'LANDMARKS',
  Galleries = 'GALLERIES',
  GreatFood = 'GREAT_FOOD',
  Museums = 'MUSEUMS',
  MusicEvents = 'MUSIC_EVENTS',
  Nightlife = 'NIGHTLIFE',
  SportGames = 'SPORT_GAMES',
  Shopping = 'SHOPPING',
}

export enum ItineraryLandmarkCategories {
  HistoricalAndCultural = 'HISTORICAL_AND_CULTURAL',
  NaturalAndScenic = 'NATURAL_AND_SCENIC',
  Family = 'FAMILY',
  ArtAndPerformance = 'ART_AND_PERFORMANCE',
  Educational = 'EDUCATIONAL',
  RecreationAndSports = 'RECREATION_AND_SPORTS',
  Nightlife = 'NIGHTLIFE',
  RelaxAndWellness = 'RELAX_AND_WELLNESS',
  ShoppingAndDining = 'SHOPPING_AND_DINING',
  TouristAndMustSee = 'TOURIST_AND_MUST_SEE',
}

export enum ItineraryMode {
  Local = 'LOCAL',
  Tourist = 'TOURIST',
}

export type ItineraryNotificationDetails = {
  title: Scalars['String'];
  body?: InputMaybe<Scalars['String']>;
  deepLink?: InputMaybe<Scalars['String']>;
};

export type ItineraryNotificationResponse = {
  __typename?: 'ItineraryNotificationResponse';
  success: Scalars['Boolean'];
  count?: Maybe<Scalars['Int']>;
};

export type ItineraryPreferences = {
  __typename?: 'ItineraryPreferences';
  mode: ItineraryMode;
  company: ItineraryCompany;
  priceLevel: ItineraryPriceLevel;
  interestedIn?: Maybe<Array<ItineraryInterest>>;
};

export type ItineraryPreferencesInput = {
  mode: ItineraryMode;
  company: ItineraryCompany;
  priceLevel: ItineraryPriceLevel;
  interestedIn?: InputMaybe<Array<ItineraryInterest>>;
};

export enum ItineraryPriceLevel {
  Free = 'FREE',
  Cheap = 'CHEAP',
  Moderate = 'MODERATE',
  Expensive = 'EXPENSIVE',
  VeryExpensive = 'VERY_EXPENSIVE',
}

export type ItineraryResponse = {
  __typename?: 'ItineraryResponse';
  itineraryUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  installationUuid?: Maybe<Scalars['ID']>;
  day: MainItineraryDay;
  date: Scalars['AWSDate'];
  entries: Array<ItineraryEntryItem>;
};

export enum ItineraryRestaurantCategories {
  American = 'AMERICAN',
  Asian = 'ASIAN',
  CoffeeShop = 'COFFEE_SHOP',
  Indian = 'INDIAN',
  Italian = 'ITALIAN',
  Seafood = 'SEAFOOD',
  Mexican = 'MEXICAN',
  Vegan = 'VEGAN',
  Bar = 'BAR',
  Sushi = 'SUSHI',
}

export type ItineraryRoute = {
  __typename?: 'ItineraryRoute';
  startTime: Scalars['AWSTime'];
  endTime: Scalars['AWSTime'];
  totalDistance: Scalars['Float'];
  totalDuration: Scalars['Float'];
  travelDuration: Scalars['Float'];
  serviceDuration: Scalars['Float'];
  waitingDuration: Scalars['Float'];
  prizesCollected: Scalars['Float'];
  isFeasible: Scalars['Boolean'];
  timeWarp?: Maybe<Scalars['String']>;
  visits: Array<ItineraryVisit>;
  breaks?: Maybe<Array<ItineraryRouteBreak>>;
};

export type ItineraryRouteBreak = {
  __typename?: 'ItineraryRouteBreak';
  startTime: Scalars['AWSTime'];
  endTime: Scalars['AWSTime'];
  duration: Scalars['Float'];
  locationRadius: LocationRadius;
};

export type ItineraryStatusResponse = {
  __typename?: 'ItineraryStatusResponse';
  status: ItineraryCreationStatus;
};

export enum ItineraryTourCategories {
  MustDo = 'MUST_DO',
  Activities = 'ACTIVITIES',
  AttractionsAndGuidedTours = 'ATTRACTIONS_AND_GUIDED_TOURS',
  ExcursionsAndDayTrips = 'EXCURSIONS_AND_DAY_TRIPS',
  ThemeParksAndEntertainment = 'THEME_PARKS_AND_ENTERTAINMENT',
  OutdoorAdventures = 'OUTDOOR_ADVENTURES',
  TransfersAndTransport = 'TRANSFERS_AND_TRANSPORT',
  ClassesAndWorkshops = 'CLASSES_AND_WORKSHOPS',
  SpecializedServices = 'SPECIALIZED_SERVICES',
  UniquesExperiences = 'UNIQUES_EXPERIENCES',
}

export type ItineraryVisit = {
  __typename?: 'ItineraryVisit';
  id: Scalars['ID'];
  title: Scalars['String'];
  index: Scalars['Int'];
  arrivalTime: Scalars['AWSTime'];
  timeSpent: Scalars['Float'];
  travelTimeToNext?: Maybe<Scalars['Float']>;
  distanceToNext?: Maybe<Scalars['Float']>;
  travelTimeFromPrev?: Maybe<Scalars['Float']>;
  distanceFromPrev?: Maybe<Scalars['Float']>;
  gpsLocation: GeoPoint;
};

export type Landmark = {
  __typename?: 'Landmark';
  typename?: Maybe<PostType>;
  id: Scalars['ID'];
  additionalType?: Maybe<Array<LandmarkAdditionalType>>;
  attractions?: Maybe<Array<MarketItem>>;
  badgeUuid?: Maybe<Scalars['ID']>;
  badge?: Maybe<Badge>;
  googlePlacesId?: Maybe<Scalars['String']>;
  gpsLocation?: Maybe<GeoPoint>;
  /** @deprecated Replaced by imageObjects */
  image?: Maybe<Image>;
  imageObjects?: Maybe<Array<Image>>;
  imageUrl?: Maybe<Scalars['String']>;
  initials: LandmarkInitials;
  isHiddenGem?: Maybe<Scalars['Boolean']>;
  /** @deprecated Replaced with title */
  landmarkName: Scalars['String'];
  placeUuid: Scalars['ID'];
  poiType: PoiType;
  primaryType?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  save?: Maybe<Save>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Scalars['String']>>;
  userRatingCount?: Maybe<Scalars['Int']>;
  videoUrl?: Maybe<Scalars['AWSURL']>;
  audioObjects?: Maybe<Array<Audio>>;
  locationAttributes?: Maybe<Array<LocationAttribute>>;
  address?: Maybe<Scalars['String']>;
  admission?: Maybe<LandmarkAdmission>;
  openingHours?: Maybe<Array<OpeningHours>>;
  reviews?: Maybe<Array<LocationReview>>;
  travelDistanceDuration?: Maybe<TravelDistanceDuration>;
  market?: Maybe<MarketField>;
  translations?: Maybe<LandmarkTranslations>;
};

export type LandmarkBadgeArgs = {
  installationUuid?: InputMaybe<Scalars['ID']>;
};

export type LandmarkTravelDistanceDurationArgs = {
  startLocation?: InputMaybe<GeoPointInput>;
};

export type LandmarkTranslationsArgs = {
  lang?: InputMaybe<Scalars['String']>;
};

export enum LandmarkAdditionalType {
  MustSee = 'MUST_SEE',
  Museum = 'MUSEUM',
  HiddenGem = 'HIDDEN_GEM',
  PhotoWorthy = 'PHOTO_WORTHY',
}

export type LandmarkAdmission = {
  __typename?: 'LandmarkAdmission';
  price: Scalars['Float'];
  marketUrl?: Maybe<Scalars['String']>;
};

export type LandmarkEntry = {
  __typename?: 'LandmarkEntry';
  id: Scalars['ID'];
  type: MainItineraryEntryType;
  placeUuid: Scalars['ID'];
  itemUuid: Scalars['ID'];
  title: Scalars['String'];
  content: Scalars['String'];
  name: Scalars['String'];
  imageObjects?: Maybe<Array<Image>>;
  address?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Scalars['String']>>;
  gpsLocation: GeoPoint;
  rating?: Maybe<Scalars['Float']>;
  userRatingCount?: Maybe<Scalars['Int']>;
  duration: Scalars['Int'];
  priceLevel?: Maybe<ItineraryPriceLevel>;
  company?: Maybe<Array<ItineraryCompany>>;
  mood?: Maybe<Array<EntryMood>>;
  suitableWeather?: Maybe<Array<WeatherType>>;
  locationAttributes?: Maybe<Array<LocationAttribute>>;
  additionalCategories?: Maybe<Array<Scalars['String']>>;
  admission?: Maybe<LandmarkEntryAdmission>;
  openingHours?: Maybe<Array<OpeningHours>>;
};

export type LandmarkEntryAdmission = {
  __typename?: 'LandmarkEntryAdmission';
  price: Scalars['Float'];
  marketUrl?: Maybe<Scalars['AWSURL']>;
  marketUuid?: Maybe<Scalars['ID']>;
  currency?: Maybe<Currency>;
  simplicityDiscountPercentage?: Maybe<Scalars['Float']>;
};

export type LandmarkInitials = {
  __typename?: 'LandmarkInitials';
  question: Scalars['String'];
  answer: Scalars['String'];
  followups: Array<Scalars['String']>;
};

export type LandmarkInitialsTranslation = {
  __typename?: 'LandmarkInitialsTranslation';
  followups: Array<Scalars['String']>;
  answer: Scalars['String'];
};

export type LandmarkItineraryEntry = {
  __typename?: 'LandmarkItineraryEntry';
  id: Scalars['ID'];
  type: MainItineraryEntryType;
  placeUuid: Scalars['ID'];
  itemUuid: Scalars['ID'];
  title: Scalars['String'];
  content: Scalars['String'];
  name: Scalars['String'];
  daytime: Daytime;
  customDaytime?: Maybe<Daytime>;
  imageObjects?: Maybe<Array<Image>>;
  address: Scalars['String'];
  gpsLocation: GeoPoint;
  rating?: Maybe<Scalars['Float']>;
  userRatingCount?: Maybe<Scalars['Int']>;
  priceLevel?: Maybe<ItineraryPriceLevel>;
  startTime: Scalars['AWSDateTime'];
  endTime: Scalars['AWSDateTime'];
  admission?: Maybe<LandmarkEntryAdmission>;
  travelDistanceDuration?: Maybe<TravelDistanceDuration>;
  market?: Maybe<MarketField>;
  save?: Maybe<Save>;
};

export type LandmarkItineraryEntryTravelDistanceDurationArgs = {
  startLocation?: InputMaybe<GeoPointInput>;
};

export type LandmarkTranslations = {
  __typename?: 'LandmarkTranslations';
  title: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  initials: LandmarkInitialsTranslation;
  reviews?: Maybe<Array<LocationReview>>;
};

export type LandmarksByFilterInput = {
  placeUuid?: InputMaybe<Scalars['ID']>;
  map?: InputMaybe<MapFilterInput>;
  forYou?: InputMaybe<Scalars['Boolean']>;
  lang?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  hiddenGems?: InputMaybe<Scalars['Boolean']>;
  /**   null = both / false = only classic landmarks / true = only hidden gems */
  additionalType?: InputMaybe<Array<LandmarkAdditionalType>>;
  gpsLocation?: InputMaybe<GeoPointInput>;
  best?: InputMaybe<Scalars['Boolean']>;
};

export type LandmarksForInstallationFilterInput = {
  lang?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  map?: InputMaybe<MapFilterInput>;
  hiddenGems?: InputMaybe<Scalars['Boolean']>;
  /**   null = both / false = only classic landmarks / true = only hidden gems */
  forYou?: InputMaybe<Scalars['Boolean']>;
};

export type LastTimestamps = {
  __typename?: 'LastTimestamps';
  lastPaymentTimestamp?: Maybe<Scalars['AWSDateTime']>;
  lastProblemTimestamp?: Maybe<Scalars['AWSDateTime']>;
};

export type LicensePlate = {
  __typename?: 'LicensePlate';
  licenseNumber: Scalars['String'];
  stateCode: Scalars['String'];
};

export type LicensePlateInput = {
  licenseNumber: Scalars['String'];
  stateCode: Scalars['String'];
};

export type Like = {
  __typename?: 'Like';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
};

export type Limits = {
  __typename?: 'Limits';
  place?: Maybe<Place>;
  perDay: Scalars['Int'];
  perHour: Scalars['Int'];
  notificationWindows?: Maybe<Array<NotificationWindow>>;
  perSource?: Maybe<Scalars['Int']>;
};

export type Links = {
  __typename?: 'Links';
  self: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  thumbnail1024x576?: Maybe<Scalars['String']>;
  thumbnail420x420?: Maybe<Scalars['String']>;
  thumbnailSelf?: Maybe<Scalars['String']>;
};

export type ListInformationFromChatKnowledgeBaseFilterInput = {
  placeUuid?: InputMaybe<Scalars['ID']>;
  landmarkUuid?: InputMaybe<Scalars['ID']>;
  institutionUuid?: InputMaybe<Scalars['ID']>;
};

export type Location = {
  __typename?: 'Location';
  address: Scalars['String'];
  geo: GeoPoint;
};

export enum LocationAttribute {
  DineIn = 'DINE_IN',
  Reservable = 'RESERVABLE',
  ServesBreakfast = 'SERVES_BREAKFAST',
  ServesLunch = 'SERVES_LUNCH',
  ServesDinner = 'SERVES_DINNER',
  ServesBeer = 'SERVES_BEER',
  ServesWine = 'SERVES_WINE',
  ServesBrunch = 'SERVES_BRUNCH',
  ServesVegetarianFood = 'SERVES_VEGETARIAN_FOOD',
  OutdoorSeating = 'OUTDOOR_SEATING',
  LiveMusic = 'LIVE_MUSIC',
  MenuForChildren = 'MENU_FOR_CHILDREN',
  ServesCocktails = 'SERVES_COCKTAILS',
  ServesDessert = 'SERVES_DESSERT',
  ServesCoffee = 'SERVES_COFFEE',
  GoodForChildren = 'GOOD_FOR_CHILDREN',
  GoodForGroups = 'GOOD_FOR_GROUPS',
  GoodForWatchingSports = 'GOOD_FOR_WATCHING_SPORTS',
}

export type LocationGeocodedAddress = {
  __typename?: 'LocationGeocodedAddress';
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  subregion?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  isoCountryCode?: Maybe<Scalars['String']>;
  formattedAddress?: Maybe<Scalars['String']>;
};

export type LocationGeocodedAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  subregion?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  isoCountryCode?: InputMaybe<Scalars['String']>;
  formattedAddress?: InputMaybe<Scalars['String']>;
};

export type LocationInput = {
  address: Scalars['String'];
  geo: GeoPointInput;
};

export type LocationReview = {
  __typename?: 'LocationReview';
  rating: Scalars['Float'];
  text?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
};

export type MagicButtonAdvancedInput = {
  searchType: MagicButtonSearchType;
  restaurantCategory?: InputMaybe<MagicButtonRestaurantCategories>;
  landmarkCategory?: InputMaybe<MagicButtonLandmarkCategories>;
  eventCategory?: InputMaybe<MagicButtonEventCategories>;
  eventTimeFrame?: InputMaybe<EventTimeframe>;
  dayTime?: InputMaybe<Daytime>;
};

export type MagicButtonArgsData = {
  __typename?: 'MagicButtonArgsData';
  placeUuid?: Maybe<Scalars['ID']>;
  timezone: Scalars['String'];
  gpsLocation: GeoPoint;
  geocodedLocation?: Maybe<LocationGeocodedAddress>;
};

export type MagicButtonBookmark = {
  __typename?: 'MagicButtonBookmark';
  userUuid: Scalars['ID'];
  answerUuid: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
};

export type MagicButtonBookmarksResponse = {
  __typename?: 'MagicButtonBookmarksResponse';
  bookmarks: Array<MagicButtonSearch>;
  nextToken?: Maybe<Scalars['String']>;
};

export type MagicButtonChatMessage = MagicButtonRelatedQuestion | MagicButtonRelatedAnswer;

export enum MagicButtonChatType {
  Question = 'QUESTION',
  Answer = 'ANSWER',
}

export type MagicButtonDataInput = {
  placeUuid?: InputMaybe<Scalars['ID']>;
  timezone: Scalars['String'];
  gpsLocation: GeoPointInput;
  geocodedLocation?: InputMaybe<LocationGeocodedAddressInput>;
};

export type MagicButtonDynamicQuestion = {
  __typename?: 'MagicButtonDynamicQuestion';
  type: MagicButtonQuestionType;
  text: Scalars['String'];
  questions: Array<Scalars['String']>;
};

export type MagicButtonDynamicQuestionsResponse = {
  __typename?: 'MagicButtonDynamicQuestionsResponse';
  suggestions: Array<MagicButtonDynamicQuestion>;
};

export type MagicButtonEventAttributes = {
  __typename?: 'MagicButtonEventAttributes';
  datetimeFrom: Scalars['AWSDateTime'];
  datetimeTo?: Maybe<Scalars['AWSDateTime']>;
  ticketsUrl?: Maybe<Scalars['String']>;
};

export enum MagicButtonEventCategories {
  Business = 'BUSINESS',
  Comedy = 'COMEDY',
  Family = 'FAMILY',
  Festivals = 'FESTIVALS',
  Food = 'FOOD',
  Music = 'MUSIC',
  Nightlife = 'NIGHTLIFE',
  Sports = 'SPORTS',
  Theater = 'THEATER',
  Art = 'ART',
}

export type MagicButtonFeedback = {
  __typename?: 'MagicButtonFeedback';
  type?: Maybe<MagicButtonFeedbackType>;
  feedback?: Maybe<Scalars['String']>;
  sentiment: FeedbackSentiment;
};

export type MagicButtonFeedbackInput = {
  type?: InputMaybe<MagicButtonFeedbackType>;
  feedback?: InputMaybe<Scalars['String']>;
  sentiment: FeedbackSentiment;
};

export enum MagicButtonFeedbackType {
  NotRelevant = 'NOT_RELEVANT',
  OtherPlans = 'OTHER_PLANS',
  BadQuality = 'BAD_QUALITY',
  BadOptions = 'BAD_OPTIONS',
  Other = 'OTHER',
}

export type MagicButtonFilterInput = {
  gpsLocation: GeoPointInput;
  maxDistance?: InputMaybe<Scalars['Float']>;
  entryType?: InputMaybe<MainItineraryEntryType>;
  category?: InputMaybe<Scalars['String']>;
  datetimeFilter?: InputMaybe<DatetimeFilterInput>;
};

export type MagicButtonFollowUpQuestion = {
  __typename?: 'MagicButtonFollowUpQuestion';
  type: MagicButtonQuestionFollowUpType;
  text: Scalars['String'];
};

export type MagicButtonInput = {
  text?: InputMaybe<Scalars['String']>;
  type: MagicButtonQuestionType;
  advanced?: InputMaybe<MagicButtonAdvancedInput>;
};

export enum MagicButtonLandmarkCategories {
  ArtAndPerformance = 'ART_AND_PERFORMANCE',
  Educational = 'EDUCATIONAL',
  Family = 'FAMILY',
  HistoricalAndCultural = 'HISTORICAL_AND_CULTURAL',
  NaturalAndScenic = 'NATURAL_AND_SCENIC',
  Nightlife = 'NIGHTLIFE',
  RecreationAndSports = 'RECREATION_AND_SPORTS',
  RelaxAndWellness = 'RELAX_AND_WELLNESS',
  Shopping = 'SHOPPING',
  MustSee = 'MUST_SEE',
  OutdoorAdventures = 'OUTDOOR_ADVENTURES',
  ThemeParksAndEntertainment = 'THEME_PARKS_AND_ENTERTAINMENT',
}

export type MagicButtonParkingOptions = {
  __typename?: 'MagicButtonParkingOptions';
  content: Scalars['String'];
  spots: Array<ParkingSpot>;
};

export type MagicButtonPersonalizedResponse = {
  __typename?: 'MagicButtonPersonalizedResponse';
  content: Scalars['String'];
  highlights: Array<Scalars['String']>;
};

export enum MagicButtonQuestionFollowUpType {
  Unknown = 'UNKNOWN',
  Parking = 'PARKING',
}

export enum MagicButtonQuestionType {
  Surprise = 'SURPRISE',
  Brunch = 'BRUNCH',
  Walk = 'WALK',
  Coffee = 'COFFEE',
  BirdsFeeding = 'BIRDS_FEEDING',
  Yoga = 'YOGA',
  Run = 'RUN',
  Market = 'MARKET',
  Adventure = 'ADVENTURE',
  LocalEvent = 'LOCAL_EVENT',
  FrenchBakery = 'FRENCH_BAKERY',
  VeganLunch = 'VEGAN_LUNCH',
  FleaMarket = 'FLEA_MARKET',
  BookstoreCafe = 'BOOKSTORE_CAFE',
  Museum = 'MUSEUM',
  PopUpGallery = 'POP_UP_GALLERY',
  HistoricalLandmark = 'HISTORICAL_LANDMARK',
  RomanticDinner = 'ROMANTIC_DINNER',
  OutdoorTerrace = 'OUTDOOR_TERRACE',
  RooftopBar = 'ROOFTOP_BAR',
  Hike = 'HIKE',
  WinePairing = 'WINE_PAIRING',
  LocalBrewery = 'LOCAL_BREWERY',
  LiveMusicRestaurant = 'LIVE_MUSIC_RESTAURANT',
  PetFriendlyCafe = 'PET_FRIENDLY_CAFE',
  WaterfrontDining = 'WATERFRONT_DINING',
  CelebritySpot = 'CELEBRITY_SPOT',
  StreetArt = 'STREET_ART',
  ScenicPicnics = 'SCENIC_PICNICS',
  FoodTruck = 'FOOD_TRUCK',
  SpaWellness = 'SPA_WELLNESS',
  ChefsTable = 'CHEFS_TABLE',
  KaraokeBar = 'KARAOKE_BAR',
  Nightclub = 'NIGHTCLUB',
  LateNightEats = 'LATE_NIGHT_EATS',
  IceCreamGelato = 'ICE_CREAM_GELATO',
  Poke = 'POKE',
  StreetFood = 'STREET_FOOD',
  BusinessDining = 'BUSINESS_DINING',
  RemoteWorkCoffee = 'REMOTE_WORK_COFFEE',
  FarmersMarket = 'FARMERS_MARKET',
  OutdoorAdventure = 'OUTDOOR_ADVENTURE',
  Sushi = 'SUSHI',
}

export type MagicButtonRecentSearchesResponse = {
  __typename?: 'MagicButtonRecentSearchesResponse';
  searches: Array<MagicButtonSearch>;
  pageSize?: Maybe<Scalars['Int']>;
  pageOffset?: Maybe<Scalars['Int']>;
};

export type MagicButtonRelatedAnswer = {
  __typename?: 'MagicButtonRelatedAnswer';
  type: MagicButtonChatType;
  text: Scalars['String'];
  followUpQuestions?: Maybe<Array<MagicButtonFollowUpQuestion>>;
  additions?: Maybe<MagicButtonRelatedAnswerAdditions>;
  createdAt: Scalars['AWSDateTime'];
};

export type MagicButtonRelatedAnswerAdditions = {
  __typename?: 'MagicButtonRelatedAnswerAdditions';
  parkingSpots?: Maybe<Array<ParkingSpot>>;
};

export type MagicButtonRelatedQuestion = {
  __typename?: 'MagicButtonRelatedQuestion';
  type: MagicButtonChatType;
  text: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
};

export type MagicButtonRelatedQuestionsResponse = {
  __typename?: 'MagicButtonRelatedQuestionsResponse';
  messages: Array<MagicButtonChatMessage>;
  pageSize?: Maybe<Scalars['Int']>;
  pageOffset?: Maybe<Scalars['Int']>;
};

export type MagicButtonResponse = {
  __typename?: 'MagicButtonResponse';
  id: Scalars['ID'];
  answerUuid: Scalars['ID'];
  type: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
  content: Scalars['String'];
  imageObjects?: Maybe<Array<Image>>;
  gpsLocation: GeoPoint;
  highlights?: Maybe<Array<Scalars['String']>>;
  openingHours?: Maybe<Array<OpeningHours>>;
  reviews?: Maybe<Array<LocationReview>>;
  address: Scalars['String'];
  rating?: Maybe<Scalars['Float']>;
  userRatingCount?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  parkingOptions?: Maybe<MagicButtonParkingOptions>;
  eventAttributes?: Maybe<MagicButtonEventAttributes>;
  bookmarked: Scalars['Boolean'];
  argsData?: Maybe<MagicButtonArgsData>;
};

export enum MagicButtonRestaurantCategories {
  American = 'AMERICAN',
  Asian = 'ASIAN',
  Bar = 'BAR',
  CoffeeShop = 'COFFEE_SHOP',
  Indian = 'INDIAN',
  Italian = 'ITALIAN',
  Mexican = 'MEXICAN',
  Seafood = 'SEAFOOD',
  Sushi = 'SUSHI',
  Vegan = 'VEGAN',
}

export type MagicButtonSearch = {
  __typename?: 'MagicButtonSearch';
  id: Scalars['ID'];
  title: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
};

export enum MagicButtonSearchType {
  Landmark = 'LANDMARK',
  Restaurant = 'RESTAURANT',
  Event = 'EVENT',
}

export type MainItinerary = {
  __typename?: 'MainItinerary';
  entries: Array<MainItineraryEntry>;
};

export enum MainItineraryDay {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

export type MainItineraryEntry = {
  __typename?: 'MainItineraryEntry';
  id: Scalars['ID'];
  type: MainItineraryEntryType;
  itemUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  title: Scalars['String'];
  content: Scalars['String'];
  imageObjects: Array<Image>;
  address: Scalars['String'];
  gpsLocation: GeoPoint;
  startTime: Scalars['AWSDateTime'];
  endTime: Scalars['AWSDateTime'];
  daytime: Daytime;
  marketUuid?: Maybe<Scalars['ID']>;
  bookTable?: Maybe<RestaurantBookTable>;
  marketUrl?: Maybe<Scalars['AWSURL']>;
  price?: Maybe<Scalars['Float']>;
};

export type MainItineraryEntryInput = {
  id: Scalars['ID'];
  type: MainItineraryEntryType;
  topic?: InputMaybe<MainItineraryTopic>;
  day?: InputMaybe<MainItineraryDay>;
  itemUuid: Scalars['ID'];
  placeUuid?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
  content: Scalars['String'];
  imageObjects: Array<ImageInput>;
  address: Scalars['String'];
  gpsLocation: GeoPointInput;
  startTime: Scalars['AWSDateTime'];
  endTime: Scalars['AWSDateTime'];
  daytime: Daytime;
  marketUuid?: InputMaybe<Scalars['ID']>;
  bookTable?: InputMaybe<RestaurantBookTableInput>;
  marketUrl?: InputMaybe<Scalars['AWSURL']>;
  price?: InputMaybe<Scalars['Float']>;
};

export enum MainItineraryEntryType {
  Event = 'EVENT',
  Restaurant = 'RESTAURANT',
  Landmark = 'LANDMARK',
  Tour = 'TOUR',
  Bar = 'BAR',
}

export enum MainItineraryTopic {
  Solo = 'SOLO',
  Family = 'FAMILY',
  Newbie = 'NEWBIE',
  Romantic = 'ROMANTIC',
  Adventure = 'ADVENTURE',
  CulturalDive = 'CULTURAL_DIVE',
  Nature = 'NATURE',
  Foodie = 'FOODIE',
}

export type MapFilter = {
  __typename?: 'MapFilter';
  installationUuid?: Maybe<Scalars['ID']>;
  coordinates: GeoPoint;
  coordinatesDelta: GeoPointDelta;
  boundingBox?: Maybe<GeoBoundingBox>;
  polygon?: Maybe<Array<Array<Array<Scalars['Float']>>>>;
};

export type MapFilterInput = {
  installationUuid?: InputMaybe<Scalars['ID']>;
  coordinates: GeoPointInput;
  coordinatesDelta: GeoPointDeltaInput;
  polygon?: InputMaybe<GeoPolygonInput>;
};

export type MapLandmarkFilterInput = {
  additionalType?: InputMaybe<Array<LandmarkAdditionalType>>;
  withBadge?: InputMaybe<Scalars['Boolean']>;
};

export type MapLimits = {
  __typename?: 'MapLimits';
  announcementRangeInHours?: Maybe<Scalars['Int']>;
  eventRangeInHours?: Maybe<Scalars['Int']>;
  /**   number (in km) defines area limit for map centering, default should be 30km */
  centerMapToCityIfDistanceLessThanKm?: Maybe<Scalars['Float']>;
};

export type MapLimitsInput = {
  announcementRangeInHours?: InputMaybe<Scalars['Int']>;
  eventRangeInHours?: InputMaybe<Scalars['Int']>;
  /**   number (in km) defines area limit for map centering, default should be 30km */
  centerMapToCityIfDistanceLessThanKm?: InputMaybe<Scalars['Float']>;
};

export type MapPost = Announcement | Event | QuickQuestion | UserPost;

export type MapPostCluster = {
  __typename?: 'MapPostCluster';
  id: Scalars['ID'];
  gpsLocation?: Maybe<GeoPoint>;
  postCount: Scalars['Int'];
};

export enum MapSelection {
  All = 'ALL',
  ForYou = 'FOR_YOU',
}

export type MarketField = {
  __typename?: 'MarketField';
  price: Scalars['Float'];
  currency: Currency;
  marketItem: MarketItem;
  simplicityDiscountPercentage: Scalars['Float'];
};

export type MarketFilterCount = {
  __typename?: 'MarketFilterCount';
  filter: MarketFilters;
  count: Scalars['Int'];
};

export enum MarketFilters {
  AttractionsMarket = 'ATTRACTIONS_MARKET',
  BestDealsMarket = 'BEST_DEALS_MARKET',
  MuseumsMarket = 'MUSEUMS_MARKET',
  ToursMarket = 'TOURS_MARKET',
  WithKidsMarket = 'WITH_KIDS_MARKET',
}

export type MarketItem = TuiMarketItem | CustomMarketItem | ViatorMarketItem;

export type MarketItemBookingRequirements = {
  __typename?: 'MarketItemBookingRequirements';
  minTravelersPerBooking: Scalars['Int'];
  maxTravelersPerBooking: Scalars['Int'];
  requiresAdultForBooking: Scalars['Boolean'];
};

export type MarketItemBookingRequirementsInput = {
  minTravelersPerBooking: Scalars['Int'];
  maxTravelersPerBooking: Scalars['Int'];
  requiresAdultForBooking: Scalars['Boolean'];
};

export type MarketItemCancellationPolicy = {
  __typename?: 'MarketItemCancellationPolicy';
  description?: Maybe<Scalars['String']>;
  isFree?: Maybe<Scalars['Boolean']>;
};

export type MarketItemCancellationPolicyInput = {
  description?: InputMaybe<Scalars['String']>;
  isFree?: InputMaybe<Scalars['Boolean']>;
};

export enum MarketItemCategory {
  AllMarket = 'ALL_MARKET',
  AttractionsMarket = 'ATTRACTIONS_MARKET',
  FamilyFriendlyMarket = 'FAMILY_FRIENDLY_MARKET',
  MuseumsMarket = 'MUSEUMS_MARKET',
  SportMarket = 'SPORT_MARKET',
  TheatreMarket = 'THEATRE_MARKET',
}

export enum MarketItemEventStatus {
  Onsale = 'ONSALE',
  Offsale = 'OFFSALE',
  Rescheduled = 'RESCHEDULED',
  Cancelled = 'CANCELLED',
  Postponed = 'POSTPONED',
}

export type MarketItemLanguageGuide = {
  __typename?: 'MarketItemLanguageGuide';
  type: Scalars['String'];
  language: Scalars['String'];
  legacyGuide?: Maybe<Scalars['String']>;
};

export type MarketItemLanguageGuideInput = {
  type: Scalars['String'];
  language: Scalars['String'];
  legacyGuide?: InputMaybe<Scalars['String']>;
};

export type MarketItemLogistics = {
  __typename?: 'MarketItemLogistics';
  startDescriptions?: Maybe<Array<Scalars['String']>>;
  endDescriptions?: Maybe<Array<Scalars['String']>>;
  travelerPickup?: Maybe<MarketItemTravelerPickup>;
};

export type MarketItemLogisticsInput = {
  startDescriptions?: InputMaybe<Array<Scalars['String']>>;
  endDescriptions?: InputMaybe<Array<Scalars['String']>>;
  travelerPickup?: InputMaybe<MarketItemTravelerPickupInput>;
};

export type MarketItemPrice = {
  __typename?: 'MarketItemPrice';
  currency: Currency;
  startingPrice: Scalars['Float'];
  merchantPrice?: Maybe<Scalars['Float']>;
  retailPrice?: Maybe<Scalars['Float']>;
  childRetailPrice?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
};

export type MarketItemPriceInput = {
  currency: Currency;
  startingPrice: Scalars['Float'];
  merchantPrice?: InputMaybe<Scalars['Float']>;
  retailPrice?: InputMaybe<Scalars['Float']>;
  childRetailPrice?: InputMaybe<Scalars['Float']>;
  serviceFee?: InputMaybe<Scalars['Float']>;
};

export type MarketItemProductOption = {
  __typename?: 'MarketItemProductOption';
  productOptionCode: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  languageGuides?: Maybe<Array<MarketItemLanguageGuide>>;
};

export type MarketItemProductOptionInput = {
  productOptionCode: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  languageGuides?: InputMaybe<Array<MarketItemLanguageGuideInput>>;
};

export type MarketItemTravelerPickup = {
  __typename?: 'MarketItemTravelerPickup';
  pickupOptionType: Scalars['String'];
  allowCustomTravelerPickup: Scalars['Boolean'];
  minutesBeforeDepartureTimeForPickup?: Maybe<Scalars['Int']>;
  additionalInfo?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<MarketItemTravelerPickupLocation>>;
};

export type MarketItemTravelerPickupInput = {
  pickupOptionType: Scalars['String'];
  allowCustomTravelerPickup: Scalars['Boolean'];
  minutesBeforeDepartureTimeForPickup?: InputMaybe<Scalars['Int']>;
  additionalInfo?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Array<MarketItemTravelerPickupLocationInput>>;
};

export type MarketItemTravelerPickupLocation = {
  __typename?: 'MarketItemTravelerPickupLocation';
  pickupType?: Maybe<Scalars['String']>;
};

export type MarketItemTravelerPickupLocationInput = {
  pickupType?: InputMaybe<Scalars['String']>;
};

export enum MarketItemType {
  Memberdeals = 'MEMBERDEALS',
  Ticketmaster = 'TICKETMASTER',
  Tui = 'TUI',
  Viator = 'VIATOR',
  Custom = 'CUSTOM',
}

export type MarketItemsByFilterDurationInput = {
  from?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['Int']>;
};

export type MarketItemsByFilterInput = {
  createdByAdmin?: InputMaybe<Scalars['Boolean']>;
  placeUuid?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<Scalars['ID']>>;
  section?: InputMaybe<MarketSection>;
  forYou?: InputMaybe<Scalars['Boolean']>;
  lang?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<MarketItemsByFilterDurationInput>;
  price?: InputMaybe<MarketItemsByFilterPriceInput>;
  includeIgnored?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MarketFilters>;
  best?: InputMaybe<Scalars['Boolean']>;
};

export type MarketItemsByFilterPriceInput = {
  from?: InputMaybe<Scalars['Float']>;
  to?: InputMaybe<Scalars['Float']>;
  currency?: InputMaybe<Currency>;
};

export type MarketItemsBySortByInput = {
  gps?: InputMaybe<GeoPointInput>;
};

export type MarketItemsForInstallationFilterInput = {
  institutions: Array<Scalars['ID']>;
  categories: Array<Scalars['ID']>;
  section?: InputMaybe<MarketSection>;
  lang?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type MarketPaymentDetails = {
  __typename?: 'MarketPaymentDetails';
  activityUuid: Scalars['String'];
  merchantPrice?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['String']>;
  cartUuid: Scalars['String'];
  order?: Maybe<MarketPaymentOrder>;
  date?: Maybe<Scalars['String']>;
  products: Array<MarketPaymentProduct>;
  time?: Maybe<Scalars['String']>;
  isCustomerInfoProvided?: Maybe<Scalars['Boolean']>;
};

export type MarketPaymentDetailsInput = {
  activityUuid: Scalars['String'];
  merchantPrice?: InputMaybe<Scalars['String']>;
  totalPrice?: InputMaybe<Scalars['String']>;
  cartUuid: Scalars['String'];
  date?: InputMaybe<Scalars['String']>;
  products: Array<MarketPaymentProductInput>;
  time?: InputMaybe<Scalars['String']>;
  isCustomerInfoProvided?: InputMaybe<Scalars['Boolean']>;
};

export type MarketPaymentOrder = {
  __typename?: 'MarketPaymentOrder';
  uuid?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  identifier: Scalars['String'];
  totalPrice?: Maybe<MarketPaymentPrice>;
  items: Array<MarketPaymentOrderItem>;
};

export type MarketPaymentOrderItem = {
  __typename?: 'MarketPaymentOrderItem';
  uuid?: Maybe<Scalars['String']>;
};

export type MarketPaymentPrice = {
  __typename?: 'MarketPaymentPrice';
  currency: Currency;
  value: Scalars['Float'];
  formattedValue?: Maybe<Scalars['String']>;
  formattedIsoValue?: Maybe<Scalars['String']>;
};

export type MarketPaymentPriceInput = {
  currency: Currency;
  value: Scalars['Float'];
  formattedValue?: InputMaybe<Scalars['String']>;
  formattedIsoValue?: InputMaybe<Scalars['String']>;
};

export type MarketPaymentProduct = {
  __typename?: 'MarketPaymentProduct';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  ageRange?: Maybe<Scalars['String']>;
  retailPrice?: Maybe<MarketPaymentPrice>;
  merchantPrice?: Maybe<MarketPaymentPrice>;
  cartItemUuid?: Maybe<Scalars['String']>;
};

export type MarketPaymentProductInput = {
  id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  ageRange?: InputMaybe<Scalars['String']>;
  retailPrice?: InputMaybe<MarketPaymentPriceInput>;
  merchantPrice?: InputMaybe<MarketPaymentPriceInput>;
  cartItemUuid?: InputMaybe<Scalars['String']>;
};

export enum MarketSection {
  AttractionsMarket = 'ATTRACTIONS_MARKET',
  SportMarket = 'SPORT_MARKET',
  TheatreMarket = 'THEATRE_MARKET',
  MuseumsMarket = 'MUSEUMS_MARKET',
  ConcertsMarket = 'CONCERTS_MARKET',
  BestDealsMarket = 'BEST_DEALS_MARKET',
}

export type Message = {
  __typename?: 'Message';
  id: Scalars['ID'];
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  publishedAt: Scalars['AWSDateTime'];
  institutionUuid: Scalars['String'];
  institutionPlaceRole: InstitutionPlaceRole;
  categoryUuid: Scalars['String'];
  categoryUuids?: Maybe<Array<Scalars['String']>>;
  deeplink?: Maybe<Scalars['String']>;
  typename?: Maybe<PostTypename>;
  areaOfInterest?: Maybe<AreaOfInterest>;
};

export type MessageInput = {
  id: Scalars['ID'];
  title: Scalars['String'];
  subtitle?: InputMaybe<Scalars['String']>;
  body: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  publishedAt: Scalars['AWSDateTime'];
  solvedAt?: InputMaybe<Scalars['AWSDateTime']>;
  institutionUuid: Scalars['String'];
  institutionPlaceRole: InstitutionPlaceRole;
  location?: InputMaybe<EventLocationInput>;
  categoryUuid: Scalars['String'];
  categoryUuids: Array<Scalars['String']>;
  notifyVolunteersOnly?: InputMaybe<Scalars['Boolean']>;
  notifyAlsoNonCategoried?: InputMaybe<Scalars['Boolean']>;
  areaOfInterest?: InputMaybe<GeoShapeInput>;
  deeplink?: InputMaybe<Scalars['String']>;
  geoPoint?: InputMaybe<GeoPointInput>;
  reporter?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  lane?: InputMaybe<Scalars['String']>;
  typename?: InputMaybe<PostTypename>;
};

export type MessagingCategoriesByFilterInput = {
  placeUuid: Scalars['String'];
};

export type MessagingCategory = {
  __typename?: 'MessagingCategory';
  id: Scalars['ID'];
  placeUuid: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  additionalEmails?: Maybe<Array<Scalars['String']>>;
};

export type MessagingProblem = {
  __typename?: 'MessagingProblem';
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  solvedAt?: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  installationUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  categoryUuid?: Maybe<Scalars['ID']>;
  reporter?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  geoPoint?: Maybe<GeoPoint>;
  originalAddress?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  lane?: Maybe<MessagingProblemLane>;
  description?: Maybe<Scalars['String']>;
  imageObjects?: Maybe<Array<Image>>;
  videoObjects?: Maybe<Array<Video>>;
  status: MessagingProblemStatus;
  resolution?: Maybe<Scalars['String']>;
  connector?: Maybe<MessagingProblemConnector>;
  commentFromAuthority?: Maybe<Scalars['String']>;
  commentAuthorityId?: Maybe<Scalars['ID']>;
  cityIsNotCompetent?: Maybe<Scalars['Boolean']>;
  cityIsNotCompetentTimestamp?: Maybe<Scalars['AWSDateTime']>;
};

export type MessagingProblemConnector = {
  __typename?: 'MessagingProblemConnector';
  type: MessagingProblemConnectorType;
  status: MessagingProblemConnectorStatus;
  statusMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type MessagingProblemConnectorInput = {
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MessagingProblemConnectorStatus>;
  statusMessage?: InputMaybe<Scalars['String']>;
  type: MessagingProblemConnectorType;
};

export enum MessagingProblemConnectorStatus {
  WaitingForUpload = 'WAITING_FOR_UPLOAD',
  InputError = 'INPUT_ERROR',
  UploadError = 'UPLOAD_ERROR',
  Uploaded = 'UPLOADED',
  Rejected = 'REJECTED',
  Solved = 'SOLVED',
}

export enum MessagingProblemConnectorType {
  IndianHeadPark = 'INDIAN_HEAD_PARK',
  NycPotholes = 'NYC_POTHOLES',
}

export type MessagingProblemInput = {
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  installationUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  categoryUuid?: InputMaybe<Scalars['ID']>;
  reporter?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  geoPoint?: InputMaybe<GeoPointInput>;
  address?: InputMaybe<Scalars['String']>;
  lane?: InputMaybe<MessagingProblemLane>;
  description?: InputMaybe<Scalars['String']>;
  imageObjects?: InputMaybe<Array<ImageInput>>;
  videoObjects?: InputMaybe<Array<VideoInput>>;
  status?: InputMaybe<MessagingProblemStatus>;
  resolution?: InputMaybe<Scalars['String']>;
  connector?: InputMaybe<MessagingProblemConnectorInput>;
  commentFromAuthority?: InputMaybe<Scalars['String']>;
  commentAuthorityId?: InputMaybe<Scalars['ID']>;
  cityIsNotCompetent?: InputMaybe<Scalars['Boolean']>;
  cityIsNotCompetentTimestamp?: InputMaybe<Scalars['AWSDateTime']>;
};

export enum MessagingProblemLane {
  BikeLane = 'BIKE_LANE',
  DrivingLane = 'DRIVING_LANE',
  ParkingLane = 'PARKING_LANE',
  Crosswalk = 'CROSSWALK',
}

export enum MessagingProblemStatus {
  New = 'NEW',
  InProgress = 'IN_PROGRESS',
  Rejected = 'REJECTED',
  Solved = 'SOLVED',
}

export type MessagingProblemsFilterInput = {
  installationUuid?: InputMaybe<Scalars['String']>;
  whitelistedInstallationUuids?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
  map?: InputMaybe<MapFilterInput>;
};

export type MessagingProblemsOrderByInput = {
  by: MessagingProblemsSortBy;
  order: SortOrder;
  installationUuid: Scalars['String'];
  text?: InputMaybe<Scalars['String']>;
};

export enum MessagingProblemsSortBy {
  CreatedAt = 'CREATED_AT',
}

export type Metadata = {
  __typename?: 'Metadata';
  expoToken?: Maybe<Scalars['String']>;
  admToken?: Maybe<Scalars['String']>;
  apnsToken?: Maybe<Scalars['String']>;
  baiduToken?: Maybe<Scalars['String']>;
  fcmToken?: Maybe<Scalars['String']>;
  mpnsToken?: Maybe<Scalars['String']>;
  wnsToken?: Maybe<Scalars['String']>;
  buildId?: Maybe<Scalars['String']>;
  userRatingLimits?: Maybe<UserRatingLimits>;
};

export type Moderation = {
  __typename?: 'Moderation';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  postUuid: Scalars['String'];
  placeUuid: Scalars['String'];
  reaction: ModerationCategories;
  specificReaction?: Maybe<HarmfulContentReason>;
  reactionText?: Maybe<Scalars['String']>;
};

export enum ModerationCategories {
  ToValidate = 'TO_VALIDATE',
  Positive = 'POSITIVE',
  EdgePositive = 'EDGE_POSITIVE',
  Negative = 'NEGATIVE',
}

export type ModerationFeedbackInput = {
  postUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  reaction: ModerationCategories;
  specificReaction?: InputMaybe<HarmfulContentReason>;
  reactionText?: InputMaybe<Scalars['String']>;
  typename: ModerationPostType;
};

export enum ModerationPostType {
  Announcement = 'ANNOUNCEMENT',
  Event = 'EVENT',
  Quickquestion = 'QUICKQUESTION',
}

export type ModerationPosts = {
  __typename?: 'ModerationPosts';
  totalCount: Scalars['Int'];
  posts: Array<Post>;
};

export type ModerationPostsByFilterInput = {
  lang?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  placeUuids?: InputMaybe<Array<Scalars['String']>>;
  categories?: InputMaybe<Array<Scalars['String']>>;
  dateRange?: InputMaybe<DateRange>;
  institutionUuids?: InputMaybe<Array<Scalars['String']>>;
  typename?: InputMaybe<ModerationPostType>;
  moderationCategories?: InputMaybe<Array<ModerationCategories>>;
};

export type ModerationVersionsFilter = {
  announcementUuid: Scalars['ID'];
  placeUuid: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createUser: User;
  updateUser: User;
  addPlace: Place;
  updatePlace: Place;
  setPlaceInfo?: Maybe<Place>;
  setPlaceBranding?: Maybe<Place>;
  hidePlace?: Maybe<Place>;
  deletePlace?: Maybe<Place>;
  createPaymentTransaction: PaymentTransaction;
  createScrapePaymentTransaction: PaymentTransaction;
  updatePaymentTransactionProcessingStatus: PaymentTransaction;
  setStripePaymentId: PaymentTransaction;
  cancelStripePaymentId: PaymentTransaction;
  cancelTUIPaymentTransaction: PaymentTransaction;
  completeTUIPaymentTransaction: PaymentTransaction;
  createTUIPayment: CreatedTuiPayment;
  addInstallation: Installation;
  updateInstallation?: Maybe<Installation>;
  /**   Overwrites existing interests if the given category is in the input */
  setInstallationInterests?: Maybe<Installation>;
  putInstallationInterest?: Maybe<Installation>;
  putInstallationInterests?: Maybe<Installation>;
  removeInstallationInterest?: Maybe<Installation>;
  removeInstallationInterests?: Maybe<Installation>;
  putInstallationReminder?: Maybe<Installation>;
  removeInstallationReminder?: Maybe<Installation>;
  pinInstallationCouponInstitution?: Maybe<PinInstallationCouponInstitutionResponse>;
  unpinInstallationCouponInstitution?: Maybe<PinInstallationCouponInstitutionResponse>;
  setInstallationMetadata?: Maybe<Installation>;
  updateInstallationProfile?: Maybe<Installation>;
  syncInstallationProfile?: Maybe<Installation>;
  deleteInstallationProfile?: Maybe<Installation>;
  deleteInstallation?: Maybe<Installation>;
  /**   Submit installation geo data */
  addInstallationGeoRange?: Maybe<InstallationGeoRangeResult>;
  addInstallationGeoMetadata: InstallationGeoMetadata;
  updateInstallationGeoMetadata: InstallationGeoMetadata;
  deleteInstallationGeoMetadata: InstallationGeoMetadata;
  computeInstallationHomeAddress: InstallationGeoMetadata;
  /**   Installation follows */
  follow: InstallationFollow;
  unfollow: InstallationFollow;
  addNotification?: Maybe<Notification>;
  deleteNotification?: Maybe<Notification>;
  deliverNotification?: Maybe<Notification>;
  sendNowPostNotification?: Maybe<Scalars['String']>;
  sendNowMessagingProblemNotification?: Maybe<Scalars['String']>;
  sendSuperAdminNotification?: Maybe<Scalars['String']>;
  pushNotification?: Maybe<Scalars['String']>;
  setNotificationLimits: Limits;
  /** @deprecated No longer supported */
  sendEmail: Response;
  addReport: Report;
  /** @deprecated No longer supported */
  deleteReport?: Maybe<Report>;
  /** @deprecated No longer supported */
  likeReport?: Maybe<Report>;
  /** @deprecated No longer supported */
  unlikeReport?: Maybe<Report>;
  generateUploadUrls: Array<UploadUrl>;
  /**   use http method POST instead of PUT */
  generateNamedUploadUrls: Array<UploadUrl>;
  uploadNamedImages: Array<UploadUrl>;
  uploadImages: Array<UploadUrl>;
  createVideoMessage: CreateVmResponse;
  addInstitution: Institution;
  /**   Update of institution. You can update name, places, category, taskcodes, website, report email, description and branding. */
  updateInstitution: Institution;
  setInstitutionBranding?: Maybe<Institution>;
  setInstitutionInfo?: Maybe<Institution>;
  putInstitutionTaskCode?: Maybe<Institution>;
  removeInstitutionTaskCode?: Maybe<Institution>;
  putInstitutionPlaces?: Maybe<Institution>;
  removeInstitutionPlace?: Maybe<Institution>;
  /**   Destroys institution entity (WARNING use only when no other entities reference this institution) */
  deleteInstitution?: Maybe<Institution>;
  /**   Hides the institution from the list of institutions in all places */
  hideInstitution?: Maybe<Institution>;
  addCategory: Category;
  updateCategory?: Maybe<Category>;
  deleteCategory?: Maybe<Category>;
  addAnnouncement: Announcement;
  updateAnnouncement?: Maybe<Announcement>;
  updateAnnouncementAiMetadata?: Maybe<Announcement>;
  deleteAnnouncement?: Maybe<IAnnouncement>;
  publishAnnouncement?: Maybe<Announcement>;
  /**   When unpublishing in batch, returns the 1st post */
  unpublishAnnouncement?: Maybe<Announcement>;
  addUserMapPost: UserPost;
  updateUserPost: UserPost;
  deleteUserPost: UserPost;
  publishQuickQuestion?: Maybe<QuickQuestion>;
  /**   When unpublishing in batch, returns the 1st post */
  unpublishQuickQuestion?: Maybe<QuickQuestion>;
  updateQuickQuestionAiMetadata?: Maybe<QuickQuestion>;
  updateAnnouncementCategories?: Maybe<Announcement>;
  /**   sets publishmentState to "PINNED" */
  pinAnnouncement?: Maybe<Announcement>;
  /**   sets publishmentState to "PUBLISHED" */
  unpinAnnouncement?: Maybe<Announcement>;
  likeAnnouncement?: Maybe<Announcement>;
  unlikeAnnouncement?: Maybe<Announcement>;
  likeQuickQuestion?: Maybe<QuickQuestion>;
  unlikeQuickQuestion?: Maybe<QuickQuestion>;
  addAnnouncementDraft: AnnouncementDraft;
  updateAnnouncementDraft?: Maybe<AnnouncementDraft>;
  /**   cancels scheduled announcement and returns it back to "DRAFT" state */
  cancelScheduledAnnouncement?: Maybe<AnnouncementDraft>;
  cancelScheduledQuickQuestion?: Maybe<QuickQuestionDraft>;
  addQuickQuestion: QuickQuestion;
  deleteQuickQuestion: IQuickQuestion;
  updateQuickQuestion: QuickQuestion;
  addQuickQuestionDraft: QuickQuestionDraft;
  updateQuickQuestionDraft?: Maybe<QuickQuestionDraft>;
  addEvent: Event;
  updateEvent?: Maybe<Event>;
  updateEventAiMetadata?: Maybe<Event>;
  deleteEvent?: Maybe<Event>;
  publishEvent?: Maybe<Event>;
  /**   When unpublishing in batch, returns the 1st post */
  unpublishEvent?: Maybe<Event>;
  likeEvent?: Maybe<Event>;
  unlikeEvent?: Maybe<Event>;
  addEventDraft: EventDraft;
  updateEventDraft?: Maybe<EventDraft>;
  addScrapeNews: ScrapeNews;
  updateScrapeNews: ScrapeNews;
  addScrapeEvent: ScrapeEvent;
  updateScrapeEvent: ScrapeEvent;
  deleteScrapeNews?: Maybe<ScrapeNews>;
  deleteScrapeEvent?: Maybe<ScrapeEvent>;
  addScrapeTask: ScrapeTask;
  allEnvAddScrapeTask?: Maybe<ScrapeTask>;
  deleteScrapeTask?: Maybe<ScrapeTask>;
  allEnvDeleteScrapeTask?: Maybe<ScrapeTask>;
  runScrapeTaskByCode?: Maybe<Scalars['String']>;
  addIntegration: Integration;
  updateIntegration?: Maybe<Integration>;
  deleteIntegration?: Maybe<Integration>;
  addScrapeSelector: ScrapeSelector;
  deleteScrapeSelector?: Maybe<ScrapeSelector>;
  getTwitterRequestToken?: Maybe<TwitterRequestTokenResponse>;
  removeModerationFeedback?: Maybe<Moderation>;
  addAdminUser: AdminUser;
  updateAdminUser: AdminUser;
  deleteAdminUser?: Maybe<AdminUser>;
  /**   Post (announcement or event) has been seen in installation */
  addPostViewedBy?: Maybe<PostView>;
  /**   register new pet */
  addPet?: Maybe<Pet>;
  /**   remove Pet by ID - only owner can remove Pet */
  deletePet?: Maybe<Pet>;
  /**   update existing pet - only owner can update Pet */
  updatePet?: Maybe<Pet>;
  /**   sets approvalState to 'PUBLISH' */
  publishPet?: Maybe<Pet>;
  /**
   *   sets approvalState to 'UNPUBLISH'
   *  When unpublishing in batch, returns the 1st post
   */
  unpublishPet?: Maybe<Pet>;
  /**   change system components state */
  updateSystemStatus: Scalars['String'];
  /**   log activity on coupon. If activity data are not valid, null is returned */
  addCouponUserActivity?: Maybe<CouponUserActivity>;
  /**   add coupon for local business */
  addCoupon?: Maybe<Coupon>;
  /**   update coupon for local business */
  updateCoupon?: Maybe<Coupon>;
  /**   add coupon draft */
  addCouponDraft?: Maybe<Coupon>;
  /**   update coupon draft */
  updateCouponDraft?: Maybe<Coupon>;
  /**   delete coupon for local business */
  deleteCoupon?: Maybe<Coupon>;
  /**   publish coupon draft */
  publishCouponDraft?: Maybe<Coupon>;
  /**   publish coupon */
  publishCoupon?: Maybe<Coupon>;
  /**   unpublish coupon, when unpublishing in batch, returns the 1st post */
  unpublishCoupon?: Maybe<Coupon>;
  /**   cancels scheduled publishment of coupon */
  cancelScheduledCoupon?: Maybe<Coupon>;
  detectTextInImage: TextDetectionResult;
  addRaffleEntry?: Maybe<Installation>;
  deleteChatGptMessages: Scalars['Int'];
  chatGptInfo: ChatGptInfoResponse;
  chatGptSuggestions: ChatGptSuggestionsResponse;
  chatGptAnswer: ChatGptAnswerResponse;
  chatGptAnswerStream: ChatGptAnswerStreamResponse;
  deleteChatGptMessagesWidget: Scalars['Int'];
  chatGptSuggestionsWidget: ChatGptSuggestionsWidgetResponse;
  chatGptAnswerWidget: ChatGptAnswerWidgetResponse;
  chatGptAnswerStreamWidget: ChatGptAnswerStreamWidgetResponse;
  addMessagingProblem?: Maybe<Scalars['ID']>;
  updateMessagingProblem?: Maybe<Scalars['Int']>;
  deleteMessagingProblem: Scalars['Int'];
  addInformationToChatKnowledgeBase: ChatKnowledgeBaseInformation;
  deleteInformationFromChatKnowledgeBase: Scalars['String'];
  savePostForInstallation: Scalars['Boolean'];
  unsavePostForInstallation: Scalars['Boolean'];
  createComment: Scalars['Boolean'];
  deleteComment: Scalars['Boolean'];
  generateItinerary: Itinerary;
  createItinerary: Itinerary;
  generateItineraryEntry: Itinerary;
  updateItineraryEntry: ItineraryEntry;
  addTUIMarketItem: MarketItem;
  updateTUIMarketItem: MarketItem;
  addCustomMarketItem: MarketItem;
  updateCustomMarketItem: MarketItem;
  addViatorMarketItem: MarketItem;
  updateViatorMarketItem: MarketItem;
  deleteMarketItem: MarketItem;
  addInfluencer: Influencer;
  updateInfluencer: Influencer;
  deleteInfluencer: Influencer;
  addInfluencerSpot: InfluencerSpot;
  updateInfluencerSpot: InfluencerSpot;
  deleteInfluencerSpot: InfluencerSpot;
  addInfluencerItinerary: InfluencerItinerary;
  updateInfluencerItinerary: InfluencerItinerary;
  deleteInfluencerItinerary: InfluencerItinerary;
  createBadge: Badge;
  updateBadge: Badge;
  acquireBadge: Installation;
  updateBadgeInteractionCounter: BadgeInteractionCounter;
  createEventDedup: ScrapeEventDedupIndex;
  batchCreateEventsDedup?: Maybe<Array<ScrapeEventDedupIndex>>;
  createMainItinerary: MainItinerary;
  generateTripPlanItinerary: Scalars['String'];
  generateTripPlanItineraryV2: TripPlanItineraryV2;
  clearMagicButtonHistory: ClearHistoryResponse;
  regenerateItineraryEntry: ItineraryEntryItem;
  notifyUsersItitnerary: ItineraryNotificationResponse;
  updateItinerary: ItineraryResponse;
  deleteItineraryEntry: ItineraryResponse;
  createCelebrityItineraries: Scalars['String'];
  sendChatBotNotification: PushNotificationResponse;
  upsertTopTen: TopTen;
  deleteTopTen: TopTen;
  resolveTopTenItem: ExternalItem;
  likeTopTen: TopTenLikeResponse;
  unlikeTopTen: TopTenLikeResponse;
  blockUserProfile: BlockUserProfileResponse;
  unblockUserProfile: BlockUserProfileResponse;
  createUserPreferenceCard: Scalars['String'];
  updateUserPreferenceCard: Scalars['String'];
  createPlacePreferenceCards: Scalars['String'];
  storeUserCardPreferences: UserCardPreferencesResponse;
  magicButtonFeedback: MagicButtonFeedback;
  requestPersonalizedItinerary: ItineraryStatusResponse;
  requestWeekItinerary: ItineraryStatusResponse;
  regenerateWeekItinerary: ItineraryStatusResponse;
  regenerateDayItinerary: ItineraryStatusResponse;
  updateWeekItinerary: WeekItinerary;
  magicButtonRelatedQuestion: MagicButtonRelatedAnswer;
  bookmarkMagicButtonAnswer: MagicButtonBookmark;
  unbookmarkMagicButtonAnswer: MagicButtonBookmark;
  applyPayWallPartnerCode: Scalars['Boolean'];
  translate: Translations;
};

export type MutationCreateUserArgs = {
  user: UserInput;
};

export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  user: UserInput;
};

export type MutationAddPlaceArgs = {
  place: PlaceInput;
};

export type MutationUpdatePlaceArgs = {
  id: Scalars['ID'];
  place: PlaceInput;
};

export type MutationSetPlaceInfoArgs = {
  id: Scalars['ID'];
  info: PlaceInfoInput;
};

export type MutationSetPlaceBrandingArgs = {
  id: Scalars['ID'];
  branding: BrandingInputPlace;
};

export type MutationHidePlaceArgs = {
  id: Scalars['ID'];
};

export type MutationDeletePlaceArgs = {
  id: Scalars['ID'];
};

export type MutationCreatePaymentTransactionArgs = {
  transaction: CreatePaymentTransactionInput;
};

export type MutationCreateScrapePaymentTransactionArgs = {
  transaction: CreateScrapePaymentTransactionInput;
};

export type MutationUpdatePaymentTransactionProcessingStatusArgs = {
  id: Scalars['ID'];
  status: ProcessingStatus;
};

export type MutationSetStripePaymentIdArgs = {
  id: Scalars['ID'];
  stripePaymentId: Scalars['ID'];
};

export type MutationCancelStripePaymentIdArgs = {
  stripePaymentId: Scalars['ID'];
};

export type MutationCancelTuiPaymentTransactionArgs = {
  id: Scalars['ID'];
  orderUuid: Scalars['String'];
  orderItemUuid: Scalars['String'];
  cancelParams: CancelTuiPaymentInput;
};

export type MutationCompleteTuiPaymentTransactionArgs = {
  id: Scalars['ID'];
};

export type MutationCreateTuiPaymentArgs = {
  installationUuid: Scalars['ID'];
  marketPaymentValues: TuiMarketPaymentValues;
};

export type MutationAddInstallationArgs = {
  installation: InstallationInput;
};

export type MutationUpdateInstallationArgs = {
  id: Scalars['ID'];
  installation: InstallationInput;
};

export type MutationSetInstallationInterestsArgs = {
  id: Scalars['ID'];
  interests: Array<InstallationInterestInput>;
};

export type MutationPutInstallationInterestArgs = {
  id: Scalars['ID'];
  interest: Scalars['ID'];
  type?: InputMaybe<InterestEnum>;
};

export type MutationPutInstallationInterestsArgs = {
  id: Scalars['ID'];
  interests: Array<InstallationInterestInput>;
};

export type MutationRemoveInstallationInterestArgs = {
  id: Scalars['ID'];
  interest: Scalars['ID'];
  type?: InputMaybe<InterestEnum>;
};

export type MutationRemoveInstallationInterestsArgs = {
  id: Scalars['ID'];
  interests: Array<InstallationInterestInput>;
};

export type MutationPutInstallationReminderArgs = {
  id: Scalars['ID'];
  reminder: InstallationReminderInput;
};

export type MutationRemoveInstallationReminderArgs = {
  id: Scalars['ID'];
  reminder: InstallationReminderInput;
};

export type MutationPinInstallationCouponInstitutionArgs = {
  id: Scalars['ID'];
  institutionUuid: Scalars['ID'];
};

export type MutationUnpinInstallationCouponInstitutionArgs = {
  id: Scalars['ID'];
  institutionUuid: Scalars['ID'];
};

export type MutationSetInstallationMetadataArgs = {
  id: Scalars['ID'];
  metadata: InstallationMetadataInput;
};

export type MutationUpdateInstallationProfileArgs = {
  id: Scalars['ID'];
  profile: InstallationProfileInput;
};

export type MutationSyncInstallationProfileArgs = {
  id: Scalars['ID'];
  profile: InstallationProfileInput;
};

export type MutationDeleteInstallationProfileArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteInstallationArgs = {
  id: Scalars['ID'];
};

export type MutationAddInstallationGeoRangeArgs = {
  range: InstallationGeoRangeInput;
};

export type MutationAddInstallationGeoMetadataArgs = {
  installationUuid: Scalars['ID'];
  metadata: InstallationGeoMetadataInput;
};

export type MutationUpdateInstallationGeoMetadataArgs = {
  installationUuid: Scalars['ID'];
  metadata: InstallationGeoMetadataInput;
};

export type MutationDeleteInstallationGeoMetadataArgs = {
  installationUuid: Scalars['ID'];
};

export type MutationComputeInstallationHomeAddressArgs = {
  installationUuid: Scalars['ID'];
};

export type MutationFollowArgs = {
  installationUuid: Scalars['ID'];
  targetInstallationUuid: Scalars['ID'];
};

export type MutationUnfollowArgs = {
  installationUuid: Scalars['ID'];
  targetInstallationUuid: Scalars['ID'];
};

export type MutationAddNotificationArgs = {
  notification: NotificationInput;
};

export type MutationDeleteNotificationArgs = {
  id: Scalars['ID'];
};

export type MutationDeliverNotificationArgs = {
  id: Scalars['ID'];
};

export type MutationSendNowPostNotificationArgs = {
  postUuid: Scalars['ID'];
};

export type MutationSendNowMessagingProblemNotificationArgs = {
  messagingProblemUuid: Scalars['ID'];
};

export type MutationSendSuperAdminNotificationArgs = {
  message: SuperAdminMessageInput;
  filter: SuperAdminNotificationFilterInput;
};

export type MutationPushNotificationArgs = {
  installationUuid: Scalars['ID'];
  title: Scalars['String'];
  body: Scalars['String'];
};

export type MutationSetNotificationLimitsArgs = {
  limits: NotificationLimitsInput;
};

export type MutationSendEmailArgs = {
  email: EmailInput;
};

export type MutationAddReportArgs = {
  type?: InputMaybe<Type>;
  placeUuid?: InputMaybe<Scalars['String']>;
  installation?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<GeoPointInput>;
  address?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<Scalars['String']>>;
  report?: InputMaybe<ReportInput>;
};

export type MutationDeleteReportArgs = {
  id: Scalars['ID'];
  secret: Scalars['String'];
};

export type MutationLikeReportArgs = {
  report: Scalars['ID'];
  installation: Scalars['ID'];
};

export type MutationUnlikeReportArgs = {
  likeId: Scalars['ID'];
};

export type MutationGenerateUploadUrlsArgs = {
  imagesCount: Scalars['Int'];
  contentType?: InputMaybe<Scalars['String']>;
};

export type MutationGenerateNamedUploadUrlsArgs = {
  files: Array<FileUploadInput>;
  uploadMethod?: InputMaybe<UploadMethod>;
  taskCode?: InputMaybe<Scalars['String']>;
  reminderCategoryCode?: InputMaybe<Scalars['String']>;
  institutionUuid?: InputMaybe<Scalars['String']>;
  placeUuid?: InputMaybe<Scalars['String']>;
  installationUuid?: InputMaybe<Scalars['String']>;
};

export type MutationUploadNamedImagesArgs = {
  files: Array<FileUploadInput>;
  taskCode?: InputMaybe<Scalars['String']>;
  reminderCategoryCode?: InputMaybe<Scalars['String']>;
  institutionUuid?: InputMaybe<Scalars['String']>;
  placeUuid?: InputMaybe<Scalars['String']>;
  installationUuid?: InputMaybe<Scalars['String']>;
};

export type MutationUploadImagesArgs = {
  imageUrls: Array<Scalars['String']>;
  contentType?: InputMaybe<Scalars['String']>;
};

export type MutationCreateVideoMessageArgs = {
  installationUuid: Scalars['String'];
  institutionUuid: Scalars['String'];
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  videoLink: Scalars['String'];
};

export type MutationAddInstitutionArgs = {
  institution: InstitutionInput;
};

export type MutationUpdateInstitutionArgs = {
  id: Scalars['ID'];
  institution: InstitutionInput;
};

export type MutationSetInstitutionBrandingArgs = {
  id: Scalars['ID'];
  branding: BrandingInput;
};

export type MutationSetInstitutionInfoArgs = {
  id: Scalars['ID'];
  info: InstitutionInfoInput;
};

export type MutationPutInstitutionTaskCodeArgs = {
  id: Scalars['ID'];
  taskCode: Scalars['String'];
};

export type MutationRemoveInstitutionTaskCodeArgs = {
  id: Scalars['ID'];
  taskCode: Scalars['String'];
};

export type MutationPutInstitutionPlacesArgs = {
  id: Scalars['ID'];
  inPlaces: Array<InstitutionInPlaceInput>;
};

export type MutationRemoveInstitutionPlaceArgs = {
  id: Scalars['ID'];
  placeUuid: Scalars['String'];
};

export type MutationDeleteInstitutionArgs = {
  id: Scalars['ID'];
};

export type MutationHideInstitutionArgs = {
  id: Scalars['ID'];
};

export type MutationAddCategoryArgs = {
  category: CategoryInput;
};

export type MutationUpdateCategoryArgs = {
  id: Scalars['ID'];
  category: CategoryInput;
};

export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};

export type MutationAddAnnouncementArgs = {
  announcement: AnnouncementInput;
};

export type MutationUpdateAnnouncementArgs = {
  id: Scalars['ID'];
  announcement: AnnouncementInput;
};

export type MutationUpdateAnnouncementAiMetadataArgs = {
  id: Scalars['ID'];
  aiMetadata: AiMetadataInput;
};

export type MutationDeleteAnnouncementArgs = {
  id: Scalars['ID'];
};

export type MutationPublishAnnouncementArgs = {
  id: Scalars['ID'];
};

export type MutationUnpublishAnnouncementArgs = {
  id: Scalars['ID'];
  batch?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationAddUserMapPostArgs = {
  post: UserPostInput;
  gpsLocation: GeoPointInput;
};

export type MutationUpdateUserPostArgs = {
  id: Scalars['ID'];
  post: UserPostInput;
  gpsLocation?: InputMaybe<GeoPointInput>;
};

export type MutationDeleteUserPostArgs = {
  id: Scalars['ID'];
};

export type MutationPublishQuickQuestionArgs = {
  id: Scalars['ID'];
};

export type MutationUnpublishQuickQuestionArgs = {
  id: Scalars['ID'];
  batch?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationUpdateQuickQuestionAiMetadataArgs = {
  id: Scalars['ID'];
  aiMetadata: AiMetadataInput;
};

export type MutationUpdateAnnouncementCategoriesArgs = {
  id: Scalars['ID'];
  categoryUuids: Array<Scalars['String']>;
};

export type MutationPinAnnouncementArgs = {
  id: Scalars['ID'];
  placeUuid?: InputMaybe<Scalars['String']>;
};

export type MutationUnpinAnnouncementArgs = {
  id: Scalars['ID'];
  placeUuid?: InputMaybe<Scalars['String']>;
};

export type MutationLikeAnnouncementArgs = {
  id: Scalars['ID'];
  placeUuid?: InputMaybe<Scalars['String']>;
  installation: Scalars['ID'];
};

export type MutationUnlikeAnnouncementArgs = {
  likeId: Scalars['ID'];
};

export type MutationLikeQuickQuestionArgs = {
  id: Scalars['ID'];
  installation: Scalars['ID'];
};

export type MutationUnlikeQuickQuestionArgs = {
  likeId: Scalars['ID'];
};

export type MutationAddAnnouncementDraftArgs = {
  announcement: AnnouncementDraftInput;
};

export type MutationUpdateAnnouncementDraftArgs = {
  id: Scalars['ID'];
  announcement: AnnouncementDraftInput;
};

export type MutationCancelScheduledAnnouncementArgs = {
  id: Scalars['ID'];
  placeUuid?: InputMaybe<Scalars['String']>;
};

export type MutationCancelScheduledQuickQuestionArgs = {
  id: Scalars['ID'];
};

export type MutationAddQuickQuestionArgs = {
  quickQuestion: QuickQuestionInput;
};

export type MutationDeleteQuickQuestionArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateQuickQuestionArgs = {
  id: Scalars['ID'];
  quickQuestion: QuickQuestionInput;
};

export type MutationAddQuickQuestionDraftArgs = {
  quickQuestion: QuickQuestionDraftInput;
};

export type MutationUpdateQuickQuestionDraftArgs = {
  id: Scalars['ID'];
  quickQuestion: QuickQuestionDraftInput;
};

export type MutationAddEventArgs = {
  event: EventInput;
};

export type MutationUpdateEventArgs = {
  id: Scalars['ID'];
  event: EventInput;
};

export type MutationUpdateEventAiMetadataArgs = {
  id: Scalars['ID'];
  aiMetadata: AiMetadataInput;
};

export type MutationDeleteEventArgs = {
  id: Scalars['ID'];
};

export type MutationPublishEventArgs = {
  id: Scalars['ID'];
};

export type MutationUnpublishEventArgs = {
  id: Scalars['ID'];
  batch?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationLikeEventArgs = {
  event: Scalars['ID'];
  placeUuid?: InputMaybe<Scalars['String']>;
  installation: Scalars['ID'];
};

export type MutationUnlikeEventArgs = {
  likeId: Scalars['ID'];
};

export type MutationAddEventDraftArgs = {
  eventDraft: EventDraftInput;
};

export type MutationUpdateEventDraftArgs = {
  id: Scalars['ID'];
  eventDraft: EventDraftInput;
};

export type MutationAddScrapeNewsArgs = {
  news: ScrapeNewsInput;
};

export type MutationUpdateScrapeNewsArgs = {
  news: ScrapeNewsInput;
};

export type MutationAddScrapeEventArgs = {
  event: ScrapeEventInput;
};

export type MutationUpdateScrapeEventArgs = {
  event: ScrapeEventInput;
};

export type MutationDeleteScrapeNewsArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteScrapeEventArgs = {
  id: Scalars['ID'];
};

export type MutationAddScrapeTaskArgs = {
  task: ScrapeTaskInput;
};

export type MutationAllEnvAddScrapeTaskArgs = {
  task: ScrapeTaskInput;
};

export type MutationDeleteScrapeTaskArgs = {
  code: Scalars['String'];
};

export type MutationAllEnvDeleteScrapeTaskArgs = {
  code: Scalars['String'];
};

export type MutationRunScrapeTaskByCodeArgs = {
  code?: InputMaybe<Scalars['String']>;
};

export type MutationAddIntegrationArgs = {
  integration: IntegrationInput;
};

export type MutationUpdateIntegrationArgs = {
  id: Scalars['ID'];
  integration: IntegrationInput;
};

export type MutationDeleteIntegrationArgs = {
  id: Scalars['ID'];
};

export type MutationAddScrapeSelectorArgs = {
  selector: ScrapeSelectorInput;
};

export type MutationDeleteScrapeSelectorArgs = {
  code: Scalars['String'];
};

export type MutationGetTwitterRequestTokenArgs = {
  callbackUrl: Scalars['AWSURL'];
};

export type MutationRemoveModerationFeedbackArgs = {
  id: Scalars['ID'];
};

export type MutationAddAdminUserArgs = {
  user: AdminUserInput;
};

export type MutationUpdateAdminUserArgs = {
  user: AdminUserInput;
};

export type MutationDeleteAdminUserArgs = {
  username: Scalars['String'];
};

export type MutationAddPostViewedByArgs = {
  view: PostViewInput;
};

export type MutationAddPetArgs = {
  pet: PetInput;
  installationUuid: Scalars['ID'];
};

export type MutationDeletePetArgs = {
  id: Scalars['ID'];
  installationUuid?: InputMaybe<Scalars['ID']>;
};

export type MutationUpdatePetArgs = {
  id: Scalars['ID'];
  pet: PetInput;
  installationUuid: Scalars['ID'];
};

export type MutationPublishPetArgs = {
  id: Scalars['ID'];
};

export type MutationUnpublishPetArgs = {
  id: Scalars['ID'];
  batch?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationUpdateSystemStatusArgs = {
  componentsActions: Array<ComponentActionInput>;
};

export type MutationAddCouponUserActivityArgs = {
  activity: CouponUserActivityInput;
};

export type MutationAddCouponArgs = {
  coupon: CouponInput;
};

export type MutationUpdateCouponArgs = {
  id: Scalars['ID'];
  coupon: CouponInput;
};

export type MutationAddCouponDraftArgs = {
  coupon: CouponInput;
};

export type MutationUpdateCouponDraftArgs = {
  id: Scalars['ID'];
  coupon: CouponInput;
};

export type MutationDeleteCouponArgs = {
  id: Scalars['ID'];
};

export type MutationPublishCouponDraftArgs = {
  id: Scalars['ID'];
};

export type MutationPublishCouponArgs = {
  id: Scalars['ID'];
};

export type MutationUnpublishCouponArgs = {
  id: Scalars['ID'];
  batch?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationCancelScheduledCouponArgs = {
  id: Scalars['ID'];
};

export type MutationDetectTextInImageArgs = {
  type: TextDetectionImageType;
  s3ImageUrl: Scalars['String'];
  allowedPaymentReceivers?: InputMaybe<Array<PaymentReceiver>>;
};

export type MutationAddRaffleEntryArgs = {
  installationUuid: Scalars['String'];
  email: Scalars['String'];
  raffleUuid: Scalars['String'];
};

export type MutationDeleteChatGptMessagesArgs = {
  sessionStart?: InputMaybe<Scalars['AWSDateTime']>;
  sessionEnd?: InputMaybe<Scalars['AWSDateTime']>;
  installationUuid: Scalars['ID'];
};

export type MutationChatGptInfoArgs = {
  sessionStart: Scalars['AWSDateTime'];
  installationUuid: Scalars['ID'];
  topic?: InputMaybe<ChatGptTopic>;
  institutionUuid?: InputMaybe<Scalars['ID']>;
  landmarkUuid?: InputMaybe<Scalars['ID']>;
  chatIdentifier?: InputMaybe<ChatIdentifierInput>;
  infoType: ChatGptInfoType;
  info: Scalars['Boolean'];
};

export type MutationChatGptSuggestionsArgs = {
  sessionStart: Scalars['AWSDateTime'];
  installationUuid: Scalars['ID'];
  topic?: InputMaybe<ChatGptTopic>;
  institutionUuid?: InputMaybe<Scalars['ID']>;
  landmarkUuid?: InputMaybe<Scalars['ID']>;
  chatIdentifier?: InputMaybe<ChatIdentifierInput>;
  lang?: InputMaybe<Scalars['String']>;
  gpsLocation?: InputMaybe<GeoPointInput>;
};

export type MutationChatGptAnswerArgs = {
  sessionStart: Scalars['AWSDateTime'];
  installationUuid: Scalars['ID'];
  topic?: InputMaybe<ChatGptTopic>;
  institutionUuid?: InputMaybe<Scalars['ID']>;
  landmarkUuid?: InputMaybe<Scalars['ID']>;
  chatIdentifier?: InputMaybe<ChatIdentifierInput>;
  lang?: InputMaybe<Scalars['String']>;
  questionType?: InputMaybe<ChatGptQuestionType>;
  question: Scalars['String'];
  stream?: InputMaybe<Scalars['Boolean']>;
  vectorisation?: InputMaybe<Scalars['Boolean']>;
  gpsLocation?: InputMaybe<GeoPointInput>;
};

export type MutationChatGptAnswerStreamArgs = {
  installationUuid: Scalars['ID'];
  topic?: InputMaybe<ChatGptTopic>;
  institutionUuid?: InputMaybe<Scalars['ID']>;
  landmarkUuid?: InputMaybe<Scalars['ID']>;
  chatIdentifier?: InputMaybe<ChatIdentifierInput>;
  text: Scalars['String'];
  isCompleted: Scalars['Boolean'];
  order: Scalars['Int'];
};

export type MutationDeleteChatGptMessagesWidgetArgs = {
  sessionStart?: InputMaybe<Scalars['AWSDateTime']>;
  sessionEnd?: InputMaybe<Scalars['AWSDateTime']>;
  installationUuid: Scalars['ID'];
};

export type MutationChatGptSuggestionsWidgetArgs = {
  sessionStart: Scalars['AWSDateTime'];
  installationUuid: Scalars['ID'];
  placeName: Scalars['String'];
  lang?: InputMaybe<Scalars['String']>;
  gpsLocation?: InputMaybe<GeoPointInput>;
};

export type MutationChatGptAnswerWidgetArgs = {
  sessionStart: Scalars['AWSDateTime'];
  installationUuid: Scalars['ID'];
  placeName: Scalars['String'];
  question: Scalars['String'];
  stream?: InputMaybe<Scalars['Boolean']>;
  gpsLocation?: InputMaybe<GeoPointInput>;
  placeUuid?: InputMaybe<Scalars['ID']>;
  institutionUuid?: InputMaybe<Scalars['ID']>;
};

export type MutationChatGptAnswerStreamWidgetArgs = {
  installationUuid: Scalars['ID'];
  text: Scalars['String'];
  isCompleted: Scalars['Boolean'];
  order: Scalars['Int'];
};

export type MutationAddMessagingProblemArgs = {
  data: MessagingProblemInput;
};

export type MutationUpdateMessagingProblemArgs = {
  id: Scalars['ID'];
  data: MessagingProblemInput;
};

export type MutationDeleteMessagingProblemArgs = {
  id: Scalars['ID'];
};

export type MutationAddInformationToChatKnowledgeBaseArgs = {
  placeUuid?: InputMaybe<Scalars['ID']>;
  institutionUuid?: InputMaybe<Scalars['ID']>;
  landmarkUuid?: InputMaybe<Scalars['ID']>;
  text: Scalars['String'];
  segmentText?: InputMaybe<Scalars['Boolean']>;
  intent?: InputMaybe<ChatKnowledgeIntent>;
};

export type MutationDeleteInformationFromChatKnowledgeBaseArgs = {
  id: Scalars['ID'];
};

export type MutationSavePostForInstallationArgs = {
  installationUuid: Scalars['ID'];
  type: PostType;
  id: Scalars['ID'];
};

export type MutationUnsavePostForInstallationArgs = {
  installationUuid: Scalars['ID'];
  type: PostType;
  id: Scalars['ID'];
};

export type MutationCreateCommentArgs = {
  installationUuid: Scalars['ID'];
  installationEmail: Scalars['String'];
  type: CommentType;
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type MutationDeleteCommentArgs = {
  installationUuid: Scalars['ID'];
  installationEmail: Scalars['String'];
  id: Scalars['ID'];
};

export type MutationGenerateItineraryArgs = {
  installationUuid: Scalars['ID'];
  gpsLocation?: InputMaybe<GeoPointInput>;
};

export type MutationCreateItineraryArgs = {
  itinerary: ItineraryInput;
  itineraryEntries: Array<ItineraryEntryInput>;
};

export type MutationGenerateItineraryEntryArgs = {
  installationUuid: Scalars['ID'];
  entryUuid: Scalars['ID'];
  gpsLocation?: InputMaybe<GeoPointInput>;
};

export type MutationUpdateItineraryEntryArgs = {
  id: Scalars['ID'];
  itineraryEntry: ItineraryEntryInput;
};

export type MutationAddTuiMarketItemArgs = {
  marketItem: TuiMarketItemInput;
};

export type MutationUpdateTuiMarketItemArgs = {
  id: Scalars['ID'];
  marketItem: TuiMarketItemInput;
};

export type MutationAddCustomMarketItemArgs = {
  marketItem: CustomMarketItemInput;
};

export type MutationUpdateCustomMarketItemArgs = {
  id: Scalars['ID'];
  marketItem: CustomMarketItemInput;
};

export type MutationAddViatorMarketItemArgs = {
  marketItem: ViatorMarketItemInput;
};

export type MutationUpdateViatorMarketItemArgs = {
  id: Scalars['ID'];
  marketItem: ViatorMarketItemInput;
};

export type MutationDeleteMarketItemArgs = {
  id: Scalars['ID'];
};

export type MutationAddInfluencerArgs = {
  influencer: InfluencerInput;
};

export type MutationUpdateInfluencerArgs = {
  id: Scalars['ID'];
  influencer: InfluencerInput;
};

export type MutationDeleteInfluencerArgs = {
  id: Scalars['ID'];
};

export type MutationAddInfluencerSpotArgs = {
  influencerSpot: InfluencerSpotInput;
};

export type MutationUpdateInfluencerSpotArgs = {
  id: Scalars['ID'];
  influencerSpot: InfluencerSpotInput;
};

export type MutationDeleteInfluencerSpotArgs = {
  id: Scalars['ID'];
};

export type MutationAddInfluencerItineraryArgs = {
  influencerItinerary: InfluencerItineraryInput;
};

export type MutationUpdateInfluencerItineraryArgs = {
  id: Scalars['ID'];
  influencerItinerary: InfluencerItineraryInput;
};

export type MutationDeleteInfluencerItineraryArgs = {
  id: Scalars['ID'];
};

export type MutationCreateBadgeArgs = {
  badge: BadgeInput;
};

export type MutationUpdateBadgeArgs = {
  id: Scalars['ID'];
  badge: BadgeInput;
};

export type MutationAcquireBadgeArgs = {
  installationUuid: Scalars['ID'];
  badge: InstallationBadgeInput;
};

export type MutationUpdateBadgeInteractionCounterArgs = {
  installationUuid: Scalars['ID'];
  type: BadgeType;
};

export type MutationCreateEventDedupArgs = {
  id: Scalars['ID'];
  dedup: Scalars['String'];
  dateTimeFrom: Scalars['AWSDateTime'];
};

export type MutationBatchCreateEventsDedupArgs = {
  items: Array<ScrapeEventDedupIndexInput>;
};

export type MutationCreateMainItineraryArgs = {
  placeUuid: Scalars['ID'];
  day: MainItineraryDay;
  topic?: InputMaybe<MainItineraryTopic>;
  entries: Array<MainItineraryEntryInput>;
};

export type MutationGenerateTripPlanItineraryArgs = {
  installationUuid: Scalars['ID'];
  input: TripPlanInput;
  postUuids: Array<Scalars['ID']>;
};

export type MutationGenerateTripPlanItineraryV2Args = {
  installationUuid: Scalars['ID'];
  input: TripPlanInput;
  postUuids: Array<Scalars['ID']>;
};

export type MutationClearMagicButtonHistoryArgs = {
  installationUuid: Scalars['ID'];
  entryType?: InputMaybe<MainItineraryEntryType>;
};

export type MutationRegenerateItineraryEntryArgs = {
  installationUuid: Scalars['ID'];
  itineraryUuid: Scalars['ID'];
  entryUuid: Scalars['ID'];
  newEntryType?: InputMaybe<MainItineraryEntryType>;
  category?: InputMaybe<Scalars['String']>;
};

export type MutationNotifyUsersItitneraryArgs = {
  placeUuid: Scalars['ID'];
  notificationDetails: ItineraryNotificationDetails;
};

export type MutationUpdateItineraryArgs = {
  installationUuid: Scalars['ID'];
  itineraryUuid: Scalars['ID'];
  input: Array<UpdateItineraryInput>;
};

export type MutationDeleteItineraryEntryArgs = {
  installationUuid: Scalars['ID'];
  itineraryUuid: Scalars['ID'];
  entryUuid: Scalars['ID'];
};

export type MutationCreateCelebrityItinerariesArgs = {
  input: CreateCelebrityItinerariesInput;
};

export type MutationSendChatBotNotificationArgs = {
  target: NotificationTargetInput;
  notification: PushNotificationMessageInput;
};

export type MutationUpsertTopTenArgs = {
  topTenInput: TopTenInput;
};

export type MutationDeleteTopTenArgs = {
  installationUuid: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationResolveTopTenItemArgs = {
  googleId: Scalars['ID'];
  placeUuid: Scalars['ID'];
};

export type MutationLikeTopTenArgs = {
  installationUuid: Scalars['ID'];
  topTenUuid: Scalars['ID'];
};

export type MutationUnlikeTopTenArgs = {
  installationUuid: Scalars['ID'];
  topTenUuid: Scalars['ID'];
};

export type MutationBlockUserProfileArgs = {
  installationUuid: Scalars['ID'];
  targetInstallationUuid: Scalars['ID'];
};

export type MutationUnblockUserProfileArgs = {
  installationUuid: Scalars['ID'];
  targetInstallationUuid: Scalars['ID'];
};

export type MutationCreateUserPreferenceCardArgs = {
  card: UserPreferenceCardInput;
};

export type MutationUpdateUserPreferenceCardArgs = {
  cardUuid: Scalars['ID'];
  card: UserPreferenceCardInput;
};

export type MutationCreatePlacePreferenceCardsArgs = {
  placePreferenceCards: PlacePreferenceCardsInput;
};

export type MutationStoreUserCardPreferencesArgs = {
  installationUuid: Scalars['ID'];
  cardPreferences: UserCardPreferencesInput;
};

export type MutationMagicButtonFeedbackArgs = {
  userUuid: Scalars['ID'];
  googleId: Scalars['ID'];
  answerUuid: Scalars['ID'];
  feedback: MagicButtonFeedbackInput;
};

export type MutationRequestPersonalizedItineraryArgs = {
  installationUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  date: Scalars['AWSDate'];
};

export type MutationRequestWeekItineraryArgs = {
  installationUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
};

export type MutationRegenerateWeekItineraryArgs = {
  installationUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
};

export type MutationRegenerateDayItineraryArgs = {
  installationUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  day: Scalars['Int'];
};

export type MutationUpdateWeekItineraryArgs = {
  installationUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  input: Array<DayItineraryInput>;
};

export type MutationMagicButtonRelatedQuestionArgs = {
  userUuid: Scalars['ID'];
  answerUuid: Scalars['ID'];
  question: Scalars['String'];
  questionType?: InputMaybe<MagicButtonQuestionFollowUpType>;
  lang?: InputMaybe<SupportedLanguages>;
};

export type MutationBookmarkMagicButtonAnswerArgs = {
  userUuid: Scalars['ID'];
  answerUuid: Scalars['ID'];
};

export type MutationUnbookmarkMagicButtonAnswerArgs = {
  userUuid: Scalars['ID'];
  answerUuid: Scalars['ID'];
};

export type MutationApplyPayWallPartnerCodeArgs = {
  installationUuid: Scalars['ID'];
  code: Scalars['String'];
};

export type MutationTranslateArgs = {
  input: TranslateInput;
};

export type NerLocation = {
  __typename?: 'NERLocation';
  locationNameRaw: Scalars['String'];
  scoreFromNER: Scalars['Float'];
  locationNameFormatted?: Maybe<Scalars['String']>;
  locationCoords?: Maybe<GeoPoint>;
};

export type NerLocationInput = {
  locationNameRaw: Scalars['String'];
  scoreFromNER: Scalars['Float'];
  locationNameFormatted?: InputMaybe<Scalars['String']>;
  locationCoords?: InputMaybe<GeoPointInput>;
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  deliveredAt?: Maybe<Scalars['AWSDateTime']>;
  channel: Channel;
  source?: Maybe<Scalars['String']>;
  message: Message;
  filter: Filter;
  msgType?: Maybe<NotificationType>;
  place?: Maybe<Place>;
  isScraped?: Maybe<Scalars['Boolean']>;
};

export type NotificationByFilterInput = {
  placeUuid: Scalars['String'];
};

export type NotificationFilterInput = {
  placeUuid?: InputMaybe<Scalars['String']>;
  installationUuid?: InputMaybe<Scalars['String']>;
  institutionUuid?: InputMaybe<Scalars['String']>;
  interest?: InputMaybe<Scalars['String']>;
  interestType?: InputMaybe<InterestEnum>;
  problemUuid?: InputMaybe<Scalars['String']>;
  problemReporterUuid?: InputMaybe<Scalars['String']>;
  areaOfInterest?: InputMaybe<GeoShapeInput>;
};

export type NotificationInput = {
  version?: InputMaybe<Scalars['ID']>;
  channel: Channel;
  emergency?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  message: MessageInput;
  filter: NotificationFilterInput;
  msgType?: InputMaybe<NotificationType>;
  isScraped?: InputMaybe<Scalars['Boolean']>;
};

export type NotificationLimitsInput = {
  placeUuid: Scalars['String'];
  perDay: Scalars['Int'];
  perHour: Scalars['Int'];
  notificationWindows?: InputMaybe<Array<NotificationWindowInput>>;
  perSource?: InputMaybe<Scalars['Int']>;
};

export enum NotificationStatus {
  Sent = 'Sent',
  Delivered = 'Delivered',
  Failed = 'Failed',
  Opened = 'Opened',
}

export type NotificationStdByFilterInput = {
  installationUuid: Scalars['String'];
  postId?: InputMaybe<Scalars['String']>;
};

export type NotificationTargetInput = {
  installationUuid?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['AWSEmail']>;
  expoToken?: InputMaybe<Scalars['String']>;
};

export type NotificationTokens = {
  __typename?: 'NotificationTokens';
  expoToken?: Maybe<Scalars['String']>;
  apnsToken?: Maybe<Scalars['String']>;
  fcmToken?: Maybe<Scalars['String']>;
};

export type NotificationTokensInput = {
  expoToken?: InputMaybe<Scalars['String']>;
  apnsToken?: InputMaybe<Scalars['String']>;
  fcmToken?: InputMaybe<Scalars['String']>;
};

export enum NotificationType {
  Push = 'PUSH',
}

export type NotificationWindow = {
  __typename?: 'NotificationWindow';
  fromHour: Scalars['Int'];
  toHour: Scalars['Int'];
};

export type NotificationWindowInput = {
  fromHour: Scalars['Int'];
  toHour: Scalars['Int'];
};

export type NotificationsStdInstallation = {
  __typename?: 'NotificationsStdInstallation';
  installationUuid?: Maybe<Scalars['String']>;
  expoToken?: Maybe<Scalars['String']>;
};

export type NotificationsStdMessage = {
  __typename?: 'NotificationsStdMessage';
  body?: Maybe<Scalars['String']>;
  categoryUuid?: Maybe<Scalars['String']>;
  categoryUuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['String']>;
  institutionUuid?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type NotificationsStdNotification = {
  __typename?: 'NotificationsStdNotification';
  category?: Maybe<Scalars['String']>;
  command?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  installation?: Maybe<NotificationsStdInstallation>;
  installationUuid?: Maybe<Scalars['String']>;
  message?: Maybe<NotificationsStdMessage>;
  placeUuid?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type OpenSearchHit = {
  __typename?: 'OpenSearchHit';
  _index: Scalars['String'];
  _type: Scalars['String'];
  _id: Scalars['String'];
  _score: Scalars['Float'];
  _source: Scalars['AWSJSON'];
};

export type OpenSearchHits = {
  __typename?: 'OpenSearchHits';
  hits: Array<OpenSearchHit>;
};

export type OpenSearchResponse = {
  __typename?: 'OpenSearchResponse';
  body: OpenSearchResponseBody;
};

export type OpenSearchResponseBody = {
  __typename?: 'OpenSearchResponseBody';
  hits?: Maybe<OpenSearchHits>;
  aggregations?: Maybe<Scalars['AWSJSON']>;
  error?: Maybe<Scalars['AWSJSON']>;
};

export type OpeningHours = {
  __typename?: 'OpeningHours';
  day: Scalars['Int'];
  open?: Maybe<Scalars['AWSTime']>;
  close?: Maybe<Scalars['AWSTime']>;
};

export type OpensearchAnnouncements = {
  __typename?: 'OpensearchAnnouncements';
  count: Scalars['Int'];
  announcements: Array<IAnnouncement>;
};

export type OpensearchEvents = {
  __typename?: 'OpensearchEvents';
  count: Scalars['Int'];
  events: Array<Event>;
  nextToken?: Maybe<Scalars['String']>;
};

export type OpensearchEventsV2 = {
  __typename?: 'OpensearchEventsV2';
  count: Scalars['Int'];
  events: Array<Event>;
  pageOffset?: Maybe<Scalars['String']>;
};

export type OptionsInput = {
  sandbox?: InputMaybe<Scalars['Boolean']>;
};

export type PaginatedActivatedCoupons = {
  __typename?: 'PaginatedActivatedCoupons';
  nextToken?: Maybe<Scalars['String']>;
  coupons: Array<ActivatedCoupon>;
  count: Scalars['Int'];
};

export type PaginatedAdminUsers = {
  __typename?: 'PaginatedAdminUsers';
  users: Array<AdminUser>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedAnnouncement = {
  __typename?: 'PaginatedAnnouncement';
  placeUuid?: Maybe<Scalars['ID']>;
  place?: Maybe<Place>;
  count?: Maybe<Scalars['Int']>;
  announcements: Array<IAnnouncement>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedAnnouncementFeedbacks = {
  __typename?: 'PaginatedAnnouncementFeedbacks';
  positiveCount?: Maybe<Scalars['Int']>;
  negativeCount?: Maybe<Scalars['Int']>;
  feedbacks?: Maybe<Array<AnnouncementFeedback>>;
  nextToken?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  spamCount?: Maybe<Scalars['Int']>;
  scamCount?: Maybe<Scalars['Int']>;
  fakeNewsCount?: Maybe<Scalars['Int']>;
  hatefulCount?: Maybe<Scalars['Int']>;
  inappropriateCount?: Maybe<Scalars['Int']>;
  otherCount?: Maybe<Scalars['Int']>;
  likeCount?: Maybe<Scalars['Int']>;
  kudosCount?: Maybe<Scalars['Int']>;
};

export type PaginatedAnnouncementsForInstallation = {
  __typename?: 'PaginatedAnnouncementsForInstallation';
  installationUuid: Scalars['ID'];
  place?: Maybe<Place>;
  count?: Maybe<Scalars['Int']>;
  announcements: Array<IAnnouncement>;
  nextToken?: Maybe<Scalars['String']>;
  queryStartedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type PaginatedBusinessActiveUsersCounts = {
  __typename?: 'PaginatedBusinessActiveUsersCounts';
  places: Array<BusinessActiveUsersCounts>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedBusinessNotificationCounts = {
  __typename?: 'PaginatedBusinessNotificationCounts';
  places: Array<BusinessNotificationCounts>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedCategories = {
  __typename?: 'PaginatedCategories';
  placeUuid: Scalars['ID'];
  categories: Array<Category>;
  nextToken?: Maybe<Scalars['String']>;
};

/**   @deprecated(reason: "Replaced with ChatGptMessagesResponse") */
export type PaginatedChatGptMessages = {
  __typename?: 'PaginatedChatGPTMessages';
  messages: Array<ChatGptMessage>;
  totalCount: Scalars['Int'];
};

export type PaginatedChatGptWidgetMessages = {
  __typename?: 'PaginatedChatGPTWidgetMessages';
  messages: Array<ChatGptWidgetMessage>;
  totalCount: Scalars['Int'];
};

export type PaginatedChatKnowledgeBaseInformation = {
  __typename?: 'PaginatedChatKnowledgeBaseInformation';
  information: Array<ChatKnowledgeBaseInformation>;
};

/**   Status type of each component */
export type PaginatedComponentStates = {
  __typename?: 'PaginatedComponentStates';
  componentActions: Array<ComponentAction>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedCouponUserActivities = {
  __typename?: 'PaginatedCouponUserActivities';
  nextToken?: Maybe<Scalars['String']>;
  activities: Array<CouponUserActivity>;
  activationsCount?: Maybe<Scalars['Int']>;
};

export type PaginatedCoupons = {
  __typename?: 'PaginatedCoupons';
  coupons: Array<Coupon>;
  count: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
  mapFilter?: Maybe<MapFilter>;
};

export type PaginatedEntities = {
  __typename?: 'PaginatedEntities';
  place: Place;
  totalCount: Scalars['Int'];
  entities: Array<Entity>;
  /** @deprecated use pageOffset and pageSize */
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedEventFeedbacks = {
  __typename?: 'PaginatedEventFeedbacks';
  positiveCount?: Maybe<Scalars['Int']>;
  negativeCount?: Maybe<Scalars['Int']>;
  feedbacks?: Maybe<Array<EventFeedback>>;
  nextToken?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  spamCount?: Maybe<Scalars['Int']>;
  scamCount?: Maybe<Scalars['Int']>;
  fakeNewsCount?: Maybe<Scalars['Int']>;
  hatefulCount?: Maybe<Scalars['Int']>;
  inappropriateCount?: Maybe<Scalars['Int']>;
  otherCount?: Maybe<Scalars['Int']>;
  likeCount?: Maybe<Scalars['Int']>;
  kudosCount?: Maybe<Scalars['Int']>;
};

export type PaginatedEvents = {
  __typename?: 'PaginatedEvents';
  placeUuid?: Maybe<Scalars['ID']>;
  place?: Maybe<Place>;
  count?: Maybe<Scalars['Int']>;
  events: Array<Event>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedEventsForInstallation = {
  __typename?: 'PaginatedEventsForInstallation';
  installationUuid: Scalars['ID'];
  events: Array<Event>;
  count?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  queryStartedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type PaginatedExploreAllBySearch = {
  __typename?: 'PaginatedExploreAllBySearch';
  posts: Array<Maybe<Post>>;
  places: Array<Maybe<Place>>;
  institutions: Array<Maybe<Institution>>;
};

export type PaginatedExploreItems = {
  __typename?: 'PaginatedExploreItems';
  exploreItems: Array<ExploreItem>;
  pageOffset?: Maybe<Scalars['Int']>;
};

export type PaginatedFeedbacks = {
  __typename?: 'PaginatedFeedbacks';
  positiveCount: Scalars['Int'];
  negativeCount: Scalars['Int'];
  feedbacks: Array<Feedback>;
  nextToken?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
};

export type PaginatedFollowers = {
  __typename?: 'PaginatedFollowers';
  followers: Array<SocialProfile>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedFollowings = {
  __typename?: 'PaginatedFollowings';
  followings: Array<SocialProfile>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedFollowups = {
  __typename?: 'PaginatedFollowups';
  followups: Array<Followup>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedGeoRanges = {
  __typename?: 'PaginatedGeoRanges';
  ranges: Array<InstallationGeoRange>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedHarmfulReasonReports = {
  __typename?: 'PaginatedHarmfulReasonReports';
  spamCount: Scalars['Int'];
  scamCount: Scalars['Int'];
  fakeNewsCount: Scalars['Int'];
  hatefulCount: Scalars['Int'];
  inappropriateCount: Scalars['Int'];
  otherCount: Scalars['Int'];
  harmfulContentReports: Array<HarmfulReasonReport>;
  nextToken?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
};

export type PaginatedInbox = {
  __typename?: 'PaginatedInbox';
  messages: Array<InboxMessage>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedInfluencerItineraries = {
  __typename?: 'PaginatedInfluencerItineraries';
  influencerItineraries: Array<InfluencerItinerary>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedInfluencerSpots = {
  __typename?: 'PaginatedInfluencerSpots';
  influencerSpots: Array<InfluencerSpot>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedInfluencers = {
  __typename?: 'PaginatedInfluencers';
  influencers: Array<Influencer>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedInsights = {
  __typename?: 'PaginatedInsights';
  insights: Array<Insight>;
  nextToken?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
};

export type PaginatedInstallationReminders = {
  __typename?: 'PaginatedInstallationReminders';
  /**   default 100 */
  pageSize?: Maybe<Scalars['Int']>;
  /**   default 0 */
  pageOffset?: Maybe<Scalars['Int']>;
  installationReminders: Array<InstallationScheduledReminder>;
};

export type PaginatedInstallations = {
  __typename?: 'PaginatedInstallations';
  place?: Maybe<Place>;
  installations: Array<Installation>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedInstitutions = {
  __typename?: 'PaginatedInstitutions';
  place: Place;
  institutions: Array<Institution>;
  /** @deprecated use pageOffset and pageSize */
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedIntegrations = {
  __typename?: 'PaginatedIntegrations';
  integrations: Array<Integration>;
  nextToken?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
};

export type PaginatedItineraryEntries = {
  __typename?: 'PaginatedItineraryEntries';
  entries: Array<ItineraryEntryItem>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedLandmarks = {
  __typename?: 'PaginatedLandmarks';
  landmarks: Array<Landmark>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedMarketItems = {
  __typename?: 'PaginatedMarketItems';
  marketItems: Array<Announcement>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedMarketItemsV2 = {
  __typename?: 'PaginatedMarketItemsV2';
  marketItems: Array<MarketItem>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedMessagingCategories = {
  __typename?: 'PaginatedMessagingCategories';
  messagingCategories: Array<MessagingCategory>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedMessagingProblems = {
  __typename?: 'PaginatedMessagingProblems';
  messagingProblems: Array<MessagingProblem>;
  pageSize?: Maybe<Scalars['Int']>;
  pageOffset?: Maybe<Scalars['Int']>;
  mapFilter?: Maybe<MapFilter>;
};

export type PaginatedModerations = {
  __typename?: 'PaginatedModerations';
  feedbacks: Array<Feedback>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedNotifications = {
  __typename?: 'PaginatedNotifications';
  place?: Maybe<Place>;
  limits?: Maybe<Limits>;
  notifications: Array<Notification>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedNotificationsStd = {
  __typename?: 'PaginatedNotificationsStd';
  notifications: Array<NotificationsStdNotification>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedPaymentTransactions = {
  __typename?: 'PaginatedPaymentTransactions';
  paymentTransactions: Array<PaymentTransaction>;
  nextToken?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type PaginatedPets = {
  __typename?: 'PaginatedPets';
  pets: Array<Maybe<Pet>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedPlaces = {
  __typename?: 'PaginatedPlaces';
  places: Array<Place>;
  totalCount?: Maybe<Scalars['Int']>;
  /** @deprecated Next-token-pagination is not used anymore, use pageOffset and pageSize instead */
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedPost = {
  __typename?: 'PaginatedPost';
  placeUuid?: Maybe<Scalars['ID']>;
  place?: Maybe<Place>;
  count?: Maybe<Scalars['Int']>;
  announcements: Array<IAnnouncement>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedPosts = {
  __typename?: 'PaginatedPosts';
  nextToken?: Maybe<Scalars['String']>;
  posts: Array<Post>;
};

export type PaginatedPostsBySearch = {
  __typename?: 'PaginatedPostsBySearch';
  posts: Array<Maybe<Post>>;
  pageSize?: Maybe<Scalars['Int']>;
  pageOffset?: Maybe<Scalars['Int']>;
};

export type PaginatedPostsForInstallation = {
  __typename?: 'PaginatedPostsForInstallation';
  installationUuid: Scalars['ID'];
  posts: Array<Post>;
  /**   return only for first page */
  emergencies?: Maybe<Array<Announcement>>;
  /**   return only for first page */
  raffles?: Maybe<Array<Announcement>>;
  nextToken?: Maybe<Scalars['String']>;
  queryStartedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type PaginatedPostsForInstallationEmergenciesArgs = {
  placeUuid?: InputMaybe<Scalars['String']>;
};

export type PaginatedPostsForInstallationRafflesArgs = {
  placeUuid?: InputMaybe<Scalars['String']>;
};

export type PaginatedPostsStoriesForInstallation = {
  __typename?: 'PaginatedPostsStoriesForInstallation';
  stories: Array<Story>;
};

export type PaginatedQuickQuestions = {
  __typename?: 'PaginatedQuickQuestions';
  placeUuid?: Maybe<Scalars['ID']>;
  place?: Maybe<Place>;
  count?: Maybe<Scalars['Int']>;
  quickQuestions: Array<IQuickQuestion>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedReports = {
  __typename?: 'PaginatedReports';
  reports: Array<Report>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedRestaurants = {
  __typename?: 'PaginatedRestaurants';
  restaurants: Array<Restaurant>;
  nextToken?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type PaginatedScrapeEvents = {
  __typename?: 'PaginatedScrapeEvents';
  events: Array<ScrapeEvent>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedScrapeNews = {
  __typename?: 'PaginatedScrapeNews';
  news: Array<ScrapeNews>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedScrapeSelectors = {
  __typename?: 'PaginatedScrapeSelectors';
  selectors: Array<ScrapeSelector>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedScrapeTasks = {
  __typename?: 'PaginatedScrapeTasks';
  tasks: Array<ScrapeTask>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedSocialProfiles = {
  __typename?: 'PaginatedSocialProfiles';
  profiles: Array<SocialProfile>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedTopTens = {
  __typename?: 'PaginatedTopTens';
  topTens?: Maybe<Array<TopTen>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedTripPlanItineraries = {
  __typename?: 'PaginatedTripPlanItineraries';
  itineraries: Array<TripPlanItineraryV2>;
};

export type PaginatedVotersList = {
  __typename?: 'PaginatedVotersList';
  voters: Array<Scalars['String']>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum PaginationType {
  Pages = 'PAGES',
  LoadButton = 'LOAD_BUTTON',
  InfiniteScroll = 'INFINITE_SCROLL',
}

export type ParkingSpot = {
  __typename?: 'ParkingSpot';
  name?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  gpsLocation: GeoPoint;
};

export type ParkingTicket = {
  __typename?: 'ParkingTicket';
  installationUuid: Scalars['String'];
  licensePlate: LicensePlate;
  issueDate: Scalars['AWSDate'];
  duration: Scalars['String'];
  validFrom: Scalars['AWSDateTime'];
  validTo: Scalars['AWSDateTime'];
};

export type ParkingTicketDetectionResult = {
  __typename?: 'ParkingTicketDetectionResult';
  receiver: Scalars['String'];
  date: Scalars['String'];
  licenseNumber: Scalars['String'];
  citationNumber: Scalars['String'];
  amount: Scalars['String'];
  currency: Scalars['String'];
};

export type ParkingTicketsResponse = {
  __typename?: 'ParkingTicketsResponse';
  parkingTickets: Array<ParkingTicket>;
};

export type PayParkingSettings = {
  __typename?: 'PayParkingSettings';
  connectorTypes?: Maybe<Array<Scalars['String']>>;
};

export type PayParkingSettingsInput = {
  connectorTypes?: InputMaybe<Array<Scalars['String']>>;
};

export type PaymentDetails = {
  __typename?: 'PaymentDetails';
  description?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  citationNumber?: Maybe<Scalars['String']>;
  violation?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['AWSDate']>;
  dueDate?: Maybe<Scalars['AWSDate']>;
  duration?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['AWSDateTime']>;
  validTo?: Maybe<Scalars['AWSDateTime']>;
  postId?: Maybe<Scalars['String']>;
  paymentType?: Maybe<PaymentType>;
  paymentForm?: Maybe<PaymentForm>;
  smsMessageId?: Maybe<Scalars['String']>;
  /** @deprecated Replaced with marketPaymentDetails */
  merchantPrice?: Maybe<Scalars['String']>;
  /** @deprecated Replaced with marketPaymentDetails */
  totalPrice?: Maybe<Scalars['String']>;
  /** @deprecated Replaced with marketPaymentDetails */
  orderUuid?: Maybe<Scalars['String']>;
  /** @deprecated Replaced with marketPaymentDetails */
  cartUuid?: Maybe<Scalars['String']>;
  simplicityDiscountPercentage?: Maybe<Scalars['String']>;
};

export type PaymentDetailsInput = {
  description?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  citationNumber?: InputMaybe<Scalars['String']>;
  violation?: InputMaybe<Scalars['String']>;
  issueDate?: InputMaybe<Scalars['AWSDate']>;
  dueDate?: InputMaybe<Scalars['AWSDate']>;
  duration?: InputMaybe<Scalars['String']>;
  validFrom?: InputMaybe<Scalars['AWSDateTime']>;
  validTo?: InputMaybe<Scalars['AWSDateTime']>;
  postId?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<PaymentType>;
  paymentForm?: InputMaybe<PaymentForm>;
  smsMessageId?: InputMaybe<Scalars['String']>;
  merchantPrice?: InputMaybe<Scalars['String']>;
  totalPrice?: InputMaybe<Scalars['String']>;
  orderUuid?: InputMaybe<Scalars['String']>;
  cartUuid?: InputMaybe<Scalars['String']>;
  simplicityDiscountPercentage?: InputMaybe<Scalars['String']>;
};

export enum PaymentForm {
  Chat = 'CHAT',
  Sms = 'SMS',
}

export enum PaymentReceiver {
  CityOfSanJose = 'CITY_OF_SAN_JOSE',
  Sfmta = 'SFMTA',
  CityOfPaloAlto = 'CITY_OF_PALO_ALTO',
  Nyc = 'NYC',
  DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
}

export type PaymentSheetParams = {
  __typename?: 'PaymentSheetParams';
  clientSecret?: Maybe<Scalars['String']>;
  ephemeralKey?: Maybe<Scalars['String']>;
  publishableKey?: Maybe<Scalars['String']>;
  customerId: Scalars['String'];
  stripeIntent: Scalars['String'];
};

export enum PaymentStatus {
  New = 'NEW',
  Initialized = 'INITIALIZED',
  Paid = 'PAID',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Canceled = 'CANCELED',
  Free = 'FREE',
  RequestedRefund = 'REQUESTED_REFUND',
}

export type PaymentTuiRefundPolicy = {
  __typename?: 'PaymentTUIRefundPolicy';
  applicableUntil: Scalars['String'];
  currencyCode?: Maybe<Scalars['String']>;
  period: Scalars['String'];
  remainingTime: Scalars['String'];
  type: TuiRefundType;
  uuid: Scalars['String'];
  value: Scalars['Float'];
};

export type PaymentTransaction = {
  __typename?: 'PaymentTransaction';
  id: Scalars['ID'];
  stripePaymentId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['AWSDateTime'];
  installation?: Maybe<Installation>;
  receiver: Scalars['String'];
  amount: Scalars['String'];
  currency: Currency;
  title?: Maybe<Scalars['String']>;
  paymentStatus: PaymentStatus;
  paidAt?: Maybe<Scalars['AWSDateTime']>;
  processingStatus: ProcessingStatus;
  processedAt?: Maybe<Scalars['AWSDateTime']>;
  processedBy?: Maybe<Scalars['String']>;
  document?: Maybe<Image>;
  paymentType?: Maybe<PaymentType>;
  paymentForm?: Maybe<PaymentForm>;
  paymentDetails?: Maybe<PaymentDetails>;
  note?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['AWSJSON']>;
  marketItem?: Maybe<MarketItem>;
  marketPaymentDetails?: Maybe<MarketPaymentDetails>;
  simplicityPlus?: Maybe<Scalars['Boolean']>;
  refundDetails?: Maybe<Array<RefundDetail>>;
};

export type PaymentTransactionsByInstallationFilterInput = {
  paymentStatus?: InputMaybe<Array<PaymentStatus>>;
};

export type PaymentTransactionsByProcessingStatusFilterInput = {
  datetimeFrom?: InputMaybe<Scalars['AWSDateTime']>;
  datetimeTo?: InputMaybe<Scalars['AWSDateTime']>;
};

export type PaymentTransactionsByProcessingStatusOrderByInput = {
  by: PaymentTransactionsBySortBy;
  order: SortOrder;
};

export enum PaymentTransactionsBySortBy {
  CreatedAt = 'CREATED_AT',
}

export enum PaymentType {
  Ticket = 'Ticket',
  Donation = 'Donation',
  Parking = 'Parking',
  Tui = 'TUI',
}

export type Pet = {
  __typename?: 'Pet';
  /**   unique ID of pet (uuid) */
  id: Scalars['ID'];
  /**   registration date */
  createdAt: Scalars['AWSDateTime'];
  /**   last update time */
  updatedAt: Scalars['AWSDateTime'];
  /**   Pet images */
  imageObjects?: Maybe<Array<Image>>;
  /**   Pet name */
  name?: Maybe<Scalars['String']>;
  /**   state of Pet */
  state: PetState;
  /**   approval state of Pet */
  approvalState: ApprovalState;
  /**   free text about pet */
  description?: Maybe<Scalars['String']>;
  /**   owner phone number */
  phoneNumber?: Maybe<Scalars['String']>;
  /**   free text tags */
  tags?: Maybe<Array<Scalars['String']>>;
  /**   reward */
  reward?: Maybe<Scalars['String']>;
  /**   place */
  placeUuid: Scalars['String'];
  /**   lost date */
  lostAt?: Maybe<Scalars['AWSDateTime']>;
  /**   found date */
  foundAt?: Maybe<Scalars['AWSDateTime']>;
  email?: Maybe<Scalars['AWSEmail']>;
};

export type PetInput = {
  /**   Pet images */
  imageObjects?: InputMaybe<Array<ImageInput>>;
  /**   Pet name */
  name?: InputMaybe<Scalars['String']>;
  /**   state of Pet */
  state: PetState;
  /**   free text about pet */
  description?: InputMaybe<Scalars['String']>;
  /**   owner phone number */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /**   free text tags */
  tags?: InputMaybe<Array<Scalars['String']>>;
  /**   reward */
  reward?: InputMaybe<Scalars['String']>;
  /**   place */
  placeUuid: Scalars['String'];
  /**   lost date */
  lostAt?: InputMaybe<Scalars['AWSDateTime']>;
  /**   found date */
  foundAt?: InputMaybe<Scalars['AWSDateTime']>;
  email?: InputMaybe<Scalars['AWSEmail']>;
};

export enum PetState {
  Home = 'HOME',
  Lost = 'LOST',
  Found = 'FOUND',
}

export type PetsByFilter = {
  /**   return dogs in place */
  placeUuid: Scalars['String'];
  /**   return only pets owned by installation (ignore placeUuid and state) */
  installationUuid?: InputMaybe<Scalars['String']>;
  /**   return pets in state (can be combine with placeUuid) */
  state?: InputMaybe<PetState>;
};

export type PetsInPlaceByFilter = {
  state?: InputMaybe<PetState>;
};

export type PetsOrderBy = {
  by: PetsSortBy;
  order: SortOrder;
};

export enum PetsSortBy {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
  LostAt = 'LOST_AT',
  FoundAt = 'FOUND_AT',
}

export type Place = {
  __typename?: 'Place';
  id: Scalars['ID'];
  /**
   *   if not set, place is considered to be a City (root),
   *  otherwise place is considered to be a District of specified city
   */
  parentPlaceUuid?: Maybe<Scalars['ID']>;
  createdAt: Scalars['AWSDateTime'];
  name: Scalars['String'];
  nameForSearch?: Maybe<Scalars['String']>;
  timezoneCode: Scalars['String'];
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
  stateCode: Scalars['String'];
  stateName: Scalars['String'];
  gps: GeoPoint;
  weather?: Maybe<Weather>;
  branding?: Maybe<BrandingPlace>;
  dashboardImages?: Maybe<DashboardImages>;
  mapIntroImage?: Maybe<Image>;
  info?: Maybe<PlaceInfo>;
  features?: Maybe<PlaceFeatures>;
  featureFlags?: Maybe<Array<PlaceFeatureFlag>>;
  enabled?: Maybe<Scalars['String']>;
  /**  default language in place is english if not defined */
  defaultLanguage?: Maybe<Scalars['String']>;
  /**  list of required translation languages (IETF) for events/announcement in place */
  translateTo?: Maybe<Array<Scalars['String']>>;
  districts?: Maybe<Array<Place>>;
  hasDistricts?: Maybe<Scalars['Boolean']>;
  isDistrict?: Maybe<Scalars['Boolean']>;
  borough?: Maybe<Scalars['String']>;
  /**   if noNotificationsFromScrapers is true, do not send notif from scrapers for this city */
  noNotificationsFromScrapers?: Maybe<Scalars['Boolean']>;
  additionalGroupsOfInstitutions?: Maybe<Array<InstitutionPlaceRole>>;
  sharingUrl?: Maybe<Scalars['AWSURL']>;
  mapLimits?: Maybe<MapLimits>;
  visitorWelcomeVideoUrl?: Maybe<Scalars['AWSURL']>;
  isVisitorModeAvailable?: Maybe<Scalars['Boolean']>;
  isResidentModeAvailable?: Maybe<Scalars['Boolean']>;
  isOgCity?: Maybe<Scalars['Boolean']>;
  isItineraryModeAvailable?: Maybe<Scalars['Boolean']>;
  disabledContent?: Maybe<Array<PlaceContent>>;
  localCuisines?: Maybe<Array<RestaurantCuisine>>;
  sectionImages?: Maybe<SectionImages>;
  badgesImage?: Maybe<Image>;
  simCardLink?: Maybe<Scalars['AWSURL']>;
  headliner?: Maybe<Scalars['Boolean']>;
  welcomeScreenImage?: Maybe<Image>;
  popularDestination?: Maybe<Scalars['Boolean']>;
  trafficAvgSpeed?: Maybe<Scalars['Int']>;
  nameTranslations?: Maybe<FieldTranslations>;
  countryNameTranslations?: Maybe<FieldTranslations>;
};

export type PlaceFeatureFlagsArgs = {
  visitorMode?: InputMaybe<Scalars['Boolean']>;
  allFeatureFlags?: InputMaybe<Scalars['Boolean']>;
};

export type PlaceBadges = {
  __typename?: 'PlaceBadges';
  place: BadgePlace;
  badges: Array<Badge>;
};

export enum PlaceContent {
  Attractions = 'ATTRACTIONS',
  Events = 'EVENTS',
}

export type PlaceFeatureFlag = {
  __typename?: 'PlaceFeatureFlag';
  icon?: Maybe<Image>;
  title?: Maybe<Scalars['String']>;
  titleLocalizations?: Maybe<Array<TitleLocalization>>;
  description?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<FeatureFlagInternalType>;
  reminderSettings?: Maybe<ReminderSettings>;
  delightedSettings?: Maybe<DelightedSettings>;
  chatBotSettings?: Maybe<ChatBotSettings>;
  /** @deprecated Replaced with reportedProblemsSettings */
  messagingProblemCategoryUuid?: Maybe<Scalars['ID']>;
  /** @deprecated Replaced with reportedProblemsSettings */
  messagingProblemIsLaneAvailable?: Maybe<Scalars['Boolean']>;
  reportedProblemsSettings?: Maybe<ReportedProblemsSettings>;
  allowedPaymentReceivers?: Maybe<Array<PaymentReceiver>>;
  plateCheckerSettings?: Maybe<PlateCheckerSettings>;
  isFullnameRequired?: Maybe<Scalars['Boolean']>;
  disableProblemsOnMap?: Maybe<Scalars['Boolean']>;
  payParkingSettings?: Maybe<PayParkingSettings>;
  visitorMode?: Maybe<Scalars['Boolean']>;
  theInfatuationPlaceId?: Maybe<Scalars['String']>;
  tuiCityId?: Maybe<Scalars['Int']>;
  tuiCountryCode?: Maybe<CountryCode>;
  tuiIsSandbox?: Maybe<Scalars['Boolean']>;
  ticketmasterSettings?: Maybe<TicketmasterSettings>;
  chatContextEnabled?: Maybe<ChatContextFeatureFlag>;
  isSystem?: Maybe<Scalars['Boolean']>;
  viatorDestination?: Maybe<Scalars['String']>;
};

export type PlaceFeatureFlagInput = {
  icon?: InputMaybe<ImageInput>;
  title?: InputMaybe<Scalars['String']>;
  titleLocalizations?: InputMaybe<Array<TitleLocalizationInput>>;
  description?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<FeatureFlagInternalType>;
  reminderSettings?: InputMaybe<ReminderSettingsInput>;
  delightedSettings?: InputMaybe<DelightedSettingsInput>;
  chatBotSettings?: InputMaybe<ChatBotSettingsInput>;
  messagingProblemCategoryUuid?: InputMaybe<Scalars['ID']>;
  messagingProblemIsLaneAvailable?: InputMaybe<Scalars['Boolean']>;
  reportedProblemsSettings?: InputMaybe<ReportedProblemsSettingsInput>;
  allowedPaymentReceivers?: InputMaybe<Array<PaymentReceiver>>;
  plateCheckerSettings?: InputMaybe<PlateCheckerSettingsInput>;
  isFullnameRequired?: InputMaybe<Scalars['Boolean']>;
  disableProblemsOnMap?: InputMaybe<Scalars['Boolean']>;
  payParkingSettings?: InputMaybe<PayParkingSettingsInput>;
  visitorMode?: InputMaybe<Scalars['Boolean']>;
  theInfatuationPlaceId?: InputMaybe<Scalars['String']>;
  tuiCityId?: InputMaybe<Scalars['Int']>;
  tuiCountryCode?: InputMaybe<CountryCode>;
  tuiIsSandbox?: InputMaybe<Scalars['Boolean']>;
  ticketmasterSettings?: InputMaybe<TicketmasterSettingsInput>;
  chatContextEnabled?: InputMaybe<ChatContextFeatureFlagInput>;
  viatorDestination?: InputMaybe<Scalars['String']>;
};

export type PlaceFeatureFlags = {
  __typename?: 'PlaceFeatureFlags';
  featureFlags: Array<PlaceFeatureFlag>;
};

export type PlaceFeatures = {
  __typename?: 'PlaceFeatures';
  enablePetDirectory?: Maybe<Scalars['Boolean']>;
  enableCommunityFund?: Maybe<Scalars['Boolean']>;
  enableMessaging?: Maybe<Scalars['Boolean']>;
  enableRaffle?: Maybe<Scalars['Boolean']>;
  enableCoupon?: Maybe<Scalars['Boolean']>;
  /** @deprecated raffle functionality moved under announcements */
  raffleUrl?: Maybe<Scalars['AWSURL']>;
};

export type PlaceFeaturesInput = {
  enablePetDirectory?: InputMaybe<Scalars['Boolean']>;
  enableCommunityFund?: InputMaybe<Scalars['Boolean']>;
  enableMessaging?: InputMaybe<Scalars['Boolean']>;
  enableRaffle?: InputMaybe<Scalars['Boolean']>;
  raffleUrl?: InputMaybe<Scalars['AWSURL']>;
};

export type PlaceInfo = {
  __typename?: 'PlaceInfo';
  /**   Header for about place page */
  aboutHeader?: Maybe<Scalars['String']>;
  /**   Slate content for about place */
  about?: Maybe<Scalars['String']>;
  /**   Short description */
  description?: Maybe<Scalars['String']>;
  /**   The email that the places uses for report a problem */
  reportsEmail?: Maybe<Scalars['String']>;
};

export type PlaceInfoInput = {
  aboutHeader?: InputMaybe<Scalars['String']>;
  about?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  reportsEmail?: InputMaybe<Scalars['String']>;
};

export type PlaceInput = {
  id?: InputMaybe<Scalars['ID']>;
  /**
   *   if not set, place is considered to be a City (root),
   *  otherwise place is considered to be a District of specified city
   */
  parentPlaceUuid?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  nameForSearch?: InputMaybe<Scalars['String']>;
  hasDistricts?: InputMaybe<Scalars['Boolean']>;
  timezoneCode: Scalars['String'];
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
  stateCode: Scalars['String'];
  stateName: Scalars['String'];
  gps: GeoPointInput;
  branding?: InputMaybe<BrandingInputPlace>;
  dashboardImages?: InputMaybe<DashboardImagesInput>;
  mapIntroImage?: InputMaybe<ImageInput>;
  info?: InputMaybe<PlaceInfoInput>;
  features?: InputMaybe<PlaceFeaturesInput>;
  featureFlags?: InputMaybe<Array<PlaceFeatureFlagInput>>;
  /**  default language in place is english if not defined */
  defaultLanguage?: InputMaybe<Scalars['String']>;
  /**   if noNotificationsFromScrapers is true, do not send notif from scrapers for this city */
  noNotificationsFromScrapers?: InputMaybe<Scalars['Boolean']>;
  /**  list of required translation languages (IETF) for events/announcement in place */
  translateTo?: InputMaybe<Array<Scalars['String']>>;
  borough?: InputMaybe<Scalars['String']>;
  additionalGroupsOfInstitutions?: InputMaybe<Array<InstitutionPlaceRole>>;
  sharingUrl?: InputMaybe<Scalars['AWSURL']>;
  mapLimits?: InputMaybe<MapLimitsInput>;
  isVisitorModeAvailable?: InputMaybe<Scalars['Boolean']>;
  isItineraryModeAvailable?: InputMaybe<Scalars['Boolean']>;
  isResidentModeAvailable?: InputMaybe<Scalars['Boolean']>;
  isOgCity?: InputMaybe<Scalars['Boolean']>;
  visitorWelcomeVideoUrl?: InputMaybe<Scalars['String']>;
  disabledContent?: InputMaybe<Array<PlaceContent>>;
  localCuisines?: InputMaybe<Array<RestaurantCuisine>>;
  sectionImages?: InputMaybe<SectionImagesInput>;
  badgesImage?: InputMaybe<ImageInput>;
  simCardLink?: InputMaybe<Scalars['AWSURL']>;
  headliner?: InputMaybe<Scalars['Boolean']>;
  welcomeScreenImage?: InputMaybe<ImageInput>;
  popularDestination?: InputMaybe<Scalars['Boolean']>;
  trafficAvgSpeed?: InputMaybe<Scalars['Int']>;
};

export type PlacePreferenceCardsInput = {
  placeUuid: Scalars['ID'];
  cardUuids: Array<Scalars['ID']>;
};

export enum PlaceSortBy {
  Location = 'LOCATION',
}

export type PlacesBySearchFilterInput = {
  geo?: InputMaybe<GeoPointInput>;
  parentPlaceUuid?: InputMaybe<Scalars['ID']>;
};

/**   Used to filter places based on their ID and timezone offset */
export type PlacesFilterInput = {
  /**   non-empty array of placeUUIDs to filter by */
  ids?: InputMaybe<Array<Scalars['String']>>;
  /**   Uuid of parent city whose districts should be returned */
  parentPlaceUuid?: InputMaybe<Scalars['ID']>;
  /**   timezone to filter places by */
  timezoneCode?: InputMaybe<Scalars['String']>;
};

export type PlacesOrderByInput = {
  by: PlaceSortBy;
  order: SortOrder;
};

export enum PlacesSortBy {
  Name = 'NAME',
}

export type PlacessOrderByInput = {
  by: PlacesSortBy;
  order: SortOrder;
};

export enum PlateCheckerConnector {
  NycCityPay = 'NYC_CITY_PAY',
  CitationProcessing = 'CITATION_PROCESSING',
}

export type PlateCheckerSettings = {
  __typename?: 'PlateCheckerSettings';
  connector?: Maybe<PlateCheckerConnector>;
};

export type PlateCheckerSettingsInput = {
  connector?: InputMaybe<PlateCheckerConnector>;
};

export enum PlatformEnum {
  Ios = 'IOS',
  Android = 'ANDROID',
}

export enum PoiType {
  Landmark = 'LANDMARK',
}

export enum PointType {
  Point = 'Point',
}

export enum PolygonType {
  Polygon = 'Polygon',
}

export type Post =
  | Announcement
  | Event
  | QuickQuestion
  | UserPost
  | AnnouncementDraft
  | EventDraft
  | QuickQuestionDraft;

export type PostSaveResponse = {
  __typename?: 'PostSaveResponse';
  id: Scalars['ID'];
  placeUuid: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  updatedByAdmin?: Maybe<Scalars['Boolean']>;
  /** @deprecated Replaces with categories */
  category?: Maybe<Category>;
  categories: Array<Category>;
  institution: Institution;
  place: Place;
  places: Array<Place>;
  lang: Scalars['String'];
  title: Scalars['String'];
  content: Scalars['String'];
  likeCount: Scalars['Int'];
  /** @deprecated Replaced with feedbacks and then replaced with save */
  like?: Maybe<Like>;
  save?: Maybe<Save>;
  /**
   *   installationSave
   *  get feedback on announcement. If installationUuid is not specified all feedback are returned else only feedback made by installation
   */
  installationInteraction: PaginatedAnnouncementFeedbacks;
  publishedAt?: Maybe<Scalars['AWSDateTime']>;
  unpublishedAt?: Maybe<Scalars['AWSDateTime']>;
  subtitle?: Maybe<Scalars['String']>;
  pushContent?: Maybe<Scalars['String']>;
  perex?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['AWSURL']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageObjects?: Maybe<Array<Image>>;
  videoObjects?: Maybe<Array<Video>>;
  createdByAdmin?: Maybe<Scalars['Boolean']>;
  scheduledAt?: Maybe<Scalars['AWSDateTime']>;
  notifyNow?: Maybe<Scalars['Boolean']>;
  publishmentState: State;
  notifications?: Maybe<Array<Scalars['AWSDateTime']>>;
  channelIntegrations?: Maybe<Array<Scalars['String']>>;
  location?: Maybe<EventLocation>;
  raffleTitle?: Maybe<Scalars['String']>;
  raffleOptions?: Maybe<RaffleOptions>;
  createdUsingAI?: Maybe<Scalars['Boolean']>;
  AIPrompt?: Maybe<Scalars['String']>;
  aiMetadata?: Maybe<AiMetadata>;
  moderationCategory?: Maybe<ModerationCategories>;
};

export type PostSaveResponseInstallationInteractionArgs = {
  filter: InstallationFeedbacksFilter;
  nextToken?: InputMaybe<Scalars['String']>;
};

export enum PostSortBy {
  LikeCount = 'LIKE_COUNT',
  LikeCountAndCreatedAt = 'LIKE_COUNT_AND_CREATED_AT',
  CreatedAt = 'CREATED_AT',
  PublishedAt = 'PUBLISHED_AT',
  UpdatedAt = 'UPDATED_AT',
  UnpublishedAt = 'UNPUBLISHED_AT',
  ScheduledAt = 'SCHEDULED_AT',
}

/**   Type of post */
export enum PostType {
  Announcement = 'ANNOUNCEMENT',
  Donation = 'DONATION',
  Emergency = 'EMERGENCY',
  Event = 'EVENT',
  HiddenGem = 'HIDDEN_GEM',
  Landmark = 'LANDMARK',
  MarketItem = 'MARKET_ITEM',
  Poll = 'POLL',
  QuickQuestion = 'QUICK_QUESTION',
  Raffle = 'RAFFLE',
  Restaurant = 'RESTAURANT',
  TuiMarketItem = 'TUI_MARKET_ITEM',
  UserPost = 'USER_POST',
  CustomMarketItem = 'CUSTOM_MARKET_ITEM',
  ViatorMarketItem = 'VIATOR_MARKET_ITEM',
  GoogleItem = 'GOOGLE_ITEM',
  GooglePlaceDetail = 'GOOGLE_PLACE_DETAIL',
  Bar = 'BAR',
}

/**   Post typename */
export enum PostTypename {
  Announcement = 'Announcement',
  Donation = 'Donation',
  Emergency = 'Emergency',
  Event = 'Event',
  HiddenGem = 'HiddenGem',
  Landmark = 'Landmark',
  MarketItem = 'MarketItem',
  Poll = 'Poll',
  QuickQuestion = 'QuickQuestion',
  Raffle = 'Raffle',
  Restaurant = 'Restaurant',
  TuiMarketItem = 'TUIMarketItem',
  UserPost = 'UserPost',
  CustomMarketItem = 'CustomMarketItem',
  ViatorMarketItem = 'ViatorMarketItem',
}

export type PostUnsaveResponse = {
  __typename?: 'PostUnsaveResponse';
  id: Scalars['ID'];
  placeUuid: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  updatedByAdmin?: Maybe<Scalars['Boolean']>;
  /** @deprecated Replaces with categories */
  category?: Maybe<Category>;
  categories: Array<Category>;
  institution: Institution;
  place: Place;
  places: Array<Place>;
  lang: Scalars['String'];
  title: Scalars['String'];
  content: Scalars['String'];
  likeCount: Scalars['Int'];
  /**   get feedback on announcement. If installationUuid is not specified all feedback are returned else only feedback made by installation */
  installationInteraction: PaginatedAnnouncementFeedbacks;
  publishedAt?: Maybe<Scalars['AWSDateTime']>;
  unpublishedAt?: Maybe<Scalars['AWSDateTime']>;
  subtitle?: Maybe<Scalars['String']>;
  pushContent?: Maybe<Scalars['String']>;
  perex?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['AWSURL']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageObjects?: Maybe<Array<Image>>;
  videoObjects?: Maybe<Array<Video>>;
  createdByAdmin?: Maybe<Scalars['Boolean']>;
  scheduledAt?: Maybe<Scalars['AWSDateTime']>;
  notifyNow?: Maybe<Scalars['Boolean']>;
  publishmentState: State;
  notifications?: Maybe<Array<Scalars['AWSDateTime']>>;
  channelIntegrations?: Maybe<Array<Scalars['String']>>;
  location?: Maybe<EventLocation>;
  raffleTitle?: Maybe<Scalars['String']>;
  raffleOptions?: Maybe<RaffleOptions>;
  createdUsingAI?: Maybe<Scalars['Boolean']>;
  AIPrompt?: Maybe<Scalars['String']>;
  aiMetadata?: Maybe<AiMetadata>;
  moderationCategory?: Maybe<ModerationCategories>;
};

export type PostUnsaveResponseInstallationInteractionArgs = {
  filter: InstallationFeedbacksFilter;
  nextToken?: InputMaybe<Scalars['String']>;
};

/**   Post view data */
export type PostView = {
  __typename?: 'PostView';
  /**   time date when post has been shown in installation */
  createdAt: Scalars['AWSDateTime'];
  /**   ID of post (event, announcement,...) which has been seen */
  postId: Scalars['ID'];
  /**   ID of place in which has been post viewed */
  placeUuid: Scalars['ID'];
  /**   ID of installation */
  installationUuid: Scalars['ID'];
  /**   Type of post - event/announcement... */
  postType: PostType;
};

/**   input for new post view */
export type PostViewInput = {
  /**   ID of post (event, announcement,...) which has been seen */
  postId: Scalars['ID'];
  /**   ID of place in which has been post viewed */
  placeUuid: Scalars['ID'];
  /**   ID of installation */
  installationUuid: Scalars['ID'];
  /**   Type of post - event/announcement... */
  postType: PostType;
};

export type PostsByFilterInput = {
  placeUuid?: InputMaybe<Scalars['String']>;
  lang: Scalars['String'];
  categories?: InputMaybe<Array<Scalars['String']>>;
  institutions?: InputMaybe<Array<Scalars['String']>>;
  state?: InputMaybe<State>;
};

export type PostsBySearchFilterInput = {
  text?: InputMaybe<Scalars['String']>;
  placeUuid?: InputMaybe<Scalars['ID']>;
  parentPlaceUuid?: InputMaybe<Scalars['ID']>;
  postType?: InputMaybe<PostTypename>;
};

export type PostsForInstallationFilterInput = {
  institutions: Array<Scalars['ID']>;
  categories: Array<Scalars['ID']>;
  forYou?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
  marketItemsOnly?: InputMaybe<Scalars['Boolean']>;
};

export type PostsForInstallationForYouFilterInput = {
  institutions: Array<Scalars['ID']>;
  categories: Array<Scalars['ID']>;
};

export type PostsForInstallationOrderByInput = {
  by: PostsForInstallationSortBy;
  order: SortOrder;
};

export enum PostsForInstallationSortBy {
  PublishedAt = 'PUBLISHED_AT',
}

export type PostsForMap = {
  __typename?: 'PostsForMap';
  installationUuid: Scalars['ID'];
  mapFilter: MapFilter;
  posts: Array<MapPost>;
  pageSize: Scalars['Int'];
};

export type PostsForMapFilterInput = {
  categories?: InputMaybe<Array<Scalars['ID']>>;
  institutions?: InputMaybe<Array<Scalars['ID']>>;
  mapSelection?: InputMaybe<MapSelection>;
  postTypes?: InputMaybe<Array<PostType>>;
  datePeriod?: InputMaybe<EventDatePeriod>;
  map: MapFilterInput;
  text?: InputMaybe<Scalars['String']>;
  minItemDistance?: InputMaybe<Scalars['Int']>;
  restaurantCategories?: InputMaybe<Array<RestaurantCategory>>;
  landmarkFilter?: InputMaybe<MapLandmarkFilterInput>;
  allItemsInViewport?: InputMaybe<Scalars['Boolean']>;
  savedPosts?: InputMaybe<Scalars['Boolean']>;
};

export type PostsForSimpliMap = {
  __typename?: 'PostsForSimpliMap';
  installationUuid: Scalars['ID'];
  mapFilter?: Maybe<MapFilter>;
  posts: Array<SimpliMapPost>;
  pageSize: Scalars['Int'];
};

export type PostsOrderByInput = {
  by: PostSortBy;
  order: SortOrder;
};

export type PostsStoriesForInstallationOrderByInput = {
  by: PostsStoriesForInstallationSortBy;
  order: SortOrder;
};

export enum PostsStoriesForInstallationSortBy {
  PublishedAt = 'PUBLISHED_AT',
}

export type Price = {
  __typename?: 'Price';
  currency: Currency;
  amount: Scalars['Float'];
};

export type PriceInput = {
  currency: Currency;
  amount: Scalars['Float'];
};

export type Pricing = {
  __typename?: 'Pricing';
  currency: Currency;
  summary?: Maybe<PricingSummary>;
  info?: Maybe<PricingInfo>;
};

export type PricingAgeBand = {
  __typename?: 'PricingAgeBand';
  ageBand?: Maybe<Scalars['String']>;
  startAge?: Maybe<Scalars['String']>;
  endAge?: Maybe<Scalars['String']>;
  minTravelersPerBooking?: Maybe<Scalars['Int']>;
  maxTravelersPerBooking?: Maybe<Scalars['Int']>;
};

export type PricingAgeBandInput = {
  ageBand?: InputMaybe<Scalars['String']>;
  startAge?: InputMaybe<Scalars['String']>;
  endAge?: InputMaybe<Scalars['String']>;
  minTravelersPerBooking?: InputMaybe<Scalars['Int']>;
  maxTravelersPerBooking?: InputMaybe<Scalars['Int']>;
};

export type PricingInfo = {
  __typename?: 'PricingInfo';
  type?: Maybe<Scalars['String']>;
  ageBands?: Maybe<Array<PricingAgeBand>>;
  unitType?: Maybe<Scalars['String']>;
};

export type PricingInfoInput = {
  type?: InputMaybe<Scalars['String']>;
  ageBands?: InputMaybe<Array<PricingAgeBandInput>>;
  unitType?: InputMaybe<Scalars['String']>;
};

export type PricingInput = {
  currency: Currency;
  summary?: InputMaybe<PricingSummaryInput>;
  info?: InputMaybe<PricingInfoInput>;
};

export type PricingSummary = {
  __typename?: 'PricingSummary';
  fromPrice?: Maybe<Scalars['Float']>;
  fromPriceBeforeDiscount?: Maybe<Scalars['Float']>;
};

export type PricingSummaryInput = {
  fromPrice?: InputMaybe<Scalars['Float']>;
  fromPriceBeforeDiscount?: InputMaybe<Scalars['Float']>;
};

export enum ProcessingStatus {
  New = 'NEW',
  Done = 'DONE',
  Failure = 'FAILURE',
  Refunded = 'REFUNDED',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
}

export type Profile = {
  __typename?: 'Profile';
  username?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  socialLinks?: Maybe<Array<SocialLink>>;
  imageObjects?: Maybe<Array<Image>>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<Scalars['String']>;
  homeAddressCoordinates?: Maybe<GeoPoint>;
  homeAddressOrigin?: Maybe<Scalars['String']>;
  workAddress?: Maybe<Scalars['String']>;
  workAddressCoordinates?: Maybe<GeoPoint>;
  workAddressOrigin?: Maybe<Scalars['String']>;
  appleToken?: Maybe<AppleToken>;
  googleToken?: Maybe<GoogleToken>;
  fbToken?: Maybe<FbToken>;
  age?: Maybe<Scalars['Int']>;
  tripProps?: Maybe<TripProps>;
};

export enum PropertyType {
  Culinary = 'CULINARY',
  LocalSecrets = 'LOCAL_SECRETS',
  Photography = 'PHOTOGRAPHY',
  Art = 'ART',
  Nightlife = 'NIGHTLIFE',
  Food = 'FOOD',
  History = 'HISTORY',
  HiddenGems = 'HIDDEN_GEMS',
  UniquePlaces = 'UNIQUE_PLACES',
  LocalGastronomy = 'LOCAL_GASTRONOMY',
}

export type PushNotificationData = {
  __typename?: 'PushNotificationData';
  institutionUuid?: Maybe<Scalars['ID']>;
};

export type PushNotificationDataInput = {
  institutionUuid?: InputMaybe<Scalars['ID']>;
};

export type PushNotificationMessageInput = {
  type: PushNotificationType;
  title: Scalars['String'];
  subtitle?: InputMaybe<Scalars['String']>;
  body: Scalars['String'];
  deeplink?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<PushNotificationPriority>;
  data?: InputMaybe<PushNotificationDataInput>;
};

export enum PushNotificationPriority {
  High = 'high',
  Normal = 'normal',
  Default = 'default',
}

export type PushNotificationResponse = {
  __typename?: 'PushNotificationResponse';
  id: Scalars['ID'];
  type: PushNotificationType;
  status: PushNotificationStatus;
  createdAt: Scalars['AWSDateTime'];
  deliveredAt?: Maybe<Scalars['AWSDateTime']>;
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  deeplink?: Maybe<Scalars['String']>;
  data?: Maybe<PushNotificationData>;
};

export enum PushNotificationStatus {
  Ok = 'ok',
  Error = 'error',
}

export enum PushNotificationType {
  ChatBotMessage = 'CHAT_BOT_MESSAGE',
}

export type Query = {
  __typename?: 'Query';
  getUser: User;
  place?: Maybe<Place>;
  /**   Get place query */
  places: PaginatedPlaces;
  /** @deprecated Replaced by places */
  findPlaces: PaginatedPlaces;
  /** @deprecated Replaced by places */
  allPlaces: PaginatedPlaces;
  /** @deprecated Replaced by places */
  placesBy: PaginatedPlaces;
  placesUniqueTimezoneCodes: TimezoneCodes;
  featureFlagsByPlace: PlaceFeatureFlags;
  allInstallations: PaginatedInstallations;
  installation?: Maybe<Installation>;
  installationsBy: PaginatedInstallations;
  reminderInstallationsBy: PaginatedInstallations;
  installationUuidsByGeoShape: InstallationUuids;
  installationGeoMetadata: InstallationGeoMetadata;
  isDeviceOutOfHomeCity: DeviceOutOfHomeCity;
  paymentTransaction?: Maybe<PaymentTransaction>;
  paymentTransactionsByInstallation: PaginatedPaymentTransactions;
  paymentTransactionsByCreatedDate: PaginatedPaymentTransactions;
  paymentTransactionsByPaidDate: PaginatedPaymentTransactions;
  paymentTransactionsByProcessingStatus: PaginatedPaymentTransactions;
  allNotifications: PaginatedNotifications;
  notificationsLimits?: Maybe<Limits>;
  notification?: Maybe<Notification>;
  notificationsBy: PaginatedNotifications;
  notificationsStdBy: PaginatedNotificationsStd;
  followupsBy: PaginatedFollowups;
  /** @deprecated No longer supported */
  allReports: PaginatedReports;
  /** @deprecated No longer supported */
  reportsInRadius?: Maybe<Array<Maybe<Report>>>;
  /** @deprecated No longer supported */
  myReports: PaginatedReports;
  /** @deprecated No longer supported */
  report?: Maybe<Report>;
  /** @deprecated No longer supported */
  reportsInPlace: Scalars['Int'];
  moderationPostsBy: ModerationPosts;
  announcementsBy: OpensearchAnnouncements;
  /**   announcements by installationUuid (user's preferences) */
  announcementsForInstallation: PaginatedAnnouncementsForInstallation;
  /**   all posts (events+announcements) in user cluster */
  post: Post;
  /**   all posts (events+announcements) by installationUuid (user's preferences) */
  postsForInstallation: PaginatedPostsForInstallation;
  postsForInstallationForYou: PaginatedPostsForInstallation;
  tripPlanSuggestions: TripPlanSuggestions;
  tripPlanItinerary: TripPlanItinerary;
  tripPlanPlaces: Array<TripPlanPlace>;
  tripPlanItineraryById: TripPlanItineraryV2;
  tripPlanItineraries: PaginatedTripPlanItineraries;
  tripPlanItineraryPlaces: Array<TripPlanPlace>;
  /** @deprecated Replaced with postsForSimpliMap. Will be removed in the future. */
  postsForMap: PostsForMap;
  postsForSimpliMap: PostsForSimpliMap;
  mapQuery: OpenSearchResponse;
  postStoriesForInstallation: PaginatedPostsStoriesForInstallation;
  eventsDates: EventsDates;
  /**   get announcement like by id */
  announcementLike?: Maybe<Like>;
  quickQuestionsBy: PaginatedQuickQuestions;
  eventsBy: OpensearchEvents;
  eventsByV2: OpensearchEventsV2;
  similarEvents: OpensearchEvents;
  eventsForInstallation: PaginatedEventsForInstallation;
  search: SearchResponse;
  searchTopTen: ExternalItem;
  allCategories: PaginatedCategories;
  /**   Return filtered categories */
  categoriesBy: PaginatedCategories;
  category?: Maybe<Category>;
  institutionsBy: PaginatedInstitutions;
  /** @deprecated use institutionsPlacesBySearch with filter.entity */
  institutionsBySearch: PaginatedInstitutions;
  /**   use this query only when you need to search by NAME or GEO location */
  institutionsPlacesBySearch: PaginatedEntities;
  exploreAllBySearch: PaginatedExploreAllBySearch;
  postsBySearch: PaginatedPostsBySearch;
  /** @deprecated use institutionsPlacesBySearch with filter.entity */
  placesBySearch: PaginatedPlaces;
  allInstitutions: PaginatedInstitutions;
  institution?: Maybe<Institution>;
  scrapeNewsByCode: PaginatedScrapeNews;
  scrapeNews: PaginatedScrapeNews;
  allScrapeNews: PaginatedScrapeNews;
  scrapeEventsByCode: PaginatedScrapeEvents;
  scrapeFutureEventsByCode: PaginatedScrapeEvents;
  scrapeEvents: PaginatedScrapeEvents;
  allScrapeEvents: PaginatedScrapeEvents;
  inboxBy: PaginatedInbox;
  allScrapeTasks: PaginatedScrapeTasks;
  scrapeTaskByCode?: Maybe<ScrapeTask>;
  /**   deprecated, use adminDashboardAnalytics instead */
  adminDashboard?: Maybe<AdminDashboard>;
  adminDashboardAnalytics?: Maybe<AdminDashboardAnalytics>;
  adminDashboardTotalsAnalytics?: Maybe<DashboardAnalyticsTotalsDashboard>;
  adminDashboardTopReactions?: Maybe<TopPosts>;
  integration?: Maybe<Integration>;
  integrationsByInstitution: PaginatedIntegrations;
  scrapeSelector?: Maybe<ScrapeSelector>;
  allScrapeSelectors: PaginatedScrapeSelectors;
  insights: PaginatedInsights;
  feedbacks: PaginatedFeedbacks;
  /** @deprecated Use installationSaves */
  moderationVersions: PaginatedModerations;
  harmfulContentReports: PaginatedHarmfulReasonReports;
  adminUser?: Maybe<AdminUser>;
  adminUsers: PaginatedAdminUsers;
  service?: Maybe<Service>;
  verifyGeoLocation: Scalars['Boolean'];
  /**   Return Pets by filter - default sorting by update at */
  petsBy: PaginatedPets;
  /**   Return pet by ID if exists */
  pet?: Maybe<Pet>;
  /**   Return pets belonging to PlaceAdmin's place, optional state filter */
  petsInPlaceBy: PaginatedPets;
  /**   get system components status */
  systemStatus: PaginatedComponentStates;
  /**   get coupon by ID */
  coupon?: Maybe<Coupon>;
  /**   get coupons by filter */
  couponsBy: PaginatedCoupons;
  couponsForInstallation: CouponsForInstallation;
  googleSpreadsheetKeyValue?: Maybe<GoogleSpreadsheetKeyValue>;
  businessNotificationCounts: PaginatedBusinessNotificationCounts;
  businessActiveUsersCounts: PaginatedBusinessActiveUsersCounts;
  /** @deprecated Replaced with chatMessages */
  chatGptMessages: PaginatedChatGptMessages;
  chatMessages: ChatMessagesResponse;
  chatGptMessagesWidget: PaginatedChatGptWidgetMessages;
  transitSearchStops: TransitSearchStops;
  transitNearbyStops: TransitNearbyStops;
  transitPlan: TransitPlanResponse;
  /** @deprecated Will be used by admin only */
  messagingCategoriesBy?: Maybe<PaginatedMessagingCategories>;
  messagingProblem?: Maybe<MessagingProblem>;
  messagingProblemsBy: PaginatedMessagingProblems;
  messagingProblemsForInstallation: PaginatedMessagingProblems;
  listInformationFromChatKnowledgeBase: PaginatedChatKnowledgeBaseInformation;
  textSegmention: SegmentedText;
  savedPostsForInstallation: SavedPostsForInstallationResponse;
  commentCount?: Maybe<Scalars['Int']>;
  comments?: Maybe<CommentsResponse>;
  suggestedLicensePlates: SuggestedLicensePlatesResponse;
  activeParkingTickets: ParkingTicketsResponse;
  visitorInterests: VisitorInterestsResponse;
  itinerary: Itinerary;
  landmark: Landmark;
  landmarksBy: PaginatedLandmarks;
  /** @deprecated Replaced by landmarksBy */
  landmarksForInstallation: PaginatedLandmarks;
  restaurant: Restaurant;
  restaurants: RestaurantsResponse;
  similarRestaurants: RestaurantsResponse;
  restaurantsBy: PaginatedRestaurants;
  /** @deprecated Replaced by restaurantsBy */
  restaurantsForInstallation: PaginatedRestaurants;
  restaurantCategoriesCounts: RestaurantCategoriesCountsResponse;
  marketItem: MarketItem;
  marketItemsBy: PaginatedMarketItemsV2;
  marketItemsByV2: PaginatedMarketItemsV2;
  /** @deprecated Replaced by marketItemsBy */
  marketItemsForInstallation: PaginatedMarketItems;
  marketFiltersCount: Array<MarketFilterCount>;
  installationsByEmail: PaginatedInstallations;
  influencer: Influencer;
  influencersBy: PaginatedInfluencers;
  influencerSpot: InfluencerSpot;
  influencerSpotsBy: PaginatedInfluencerSpots;
  influencerItinerary: InfluencerItinerary;
  influencerItinerariesBy: PaginatedInfluencerItineraries;
  badge: Badge;
  badgesForInstallation: BadgesForInstallationResponse;
  batchGetEventsDedup?: Maybe<Array<ScrapeEventDedupIndex>>;
  getMainItinerary: MainItinerary;
  getItinerary: ItineraryResponse;
  getItineraryById: ItineraryResponse;
  getItineraryEntry: ItineraryEntryItem;
  getItineraryCategories: ItineraryCategoriesResponse;
  getWeekItinerary: WeekItinerary;
  getDayItineraryById: DayItinerary;
  getFollowingItineraryEntry: FollowingEntryItemResponse;
  itineraryCelebritiesForPlace: Array<ItineraryCelebrity>;
  getCelebrityItinerary: CelebrityItineraryResponse;
  findRoute: ItineraryRoute;
  getMagicItineraryEntry: ItineraryEntryItem;
  getMagicButtonEntry: ItineraryEntryItem;
  magicButton: MagicButtonResponse;
  magicButtonResult: MagicButtonResponse;
  magicButtonPersonalized: MagicButtonPersonalizedResponse;
  magicButtonBookmarks: MagicButtonBookmarksResponse;
  magicButtonRecentSearches: MagicButtonRecentSearchesResponse;
  magicButtonDynamicQuestions: MagicButtonDynamicQuestionsResponse;
  magicButtonRelatedQuestionHistory: MagicButtonRelatedQuestionsResponse;
  isBookmarked: Scalars['Boolean'];
  getExploreItems: PaginatedExploreItems;
  searchEntriesBy: PaginatedItineraryEntries;
  getUserPreferenceCards: UserPreferenceCardsResponse;
  getPreferenceCardsByIds: UserPreferenceCardsResponse;
  getUserCardPreferences: UserCardPreferencesResponse;
  getAllGenericCards: UserPreferenceCardsResponse;
  getSocialProfile: SocialProfile;
  likedTopTens: PaginatedTopTens;
  installationTopTens: PaginatedTopTens;
  followers: PaginatedFollowers;
  followings: PaginatedFollowings;
  searchUserProfile: PaginatedSocialProfiles;
  discoverTopTens: PaginatedTopTens;
  getTopTen?: Maybe<TopTen>;
  blockedUserProfiles: PaginatedSocialProfiles;
  googleItem: GoogleItem;
  randomPostImage: Image;
  currentWeather: CurrentWeather;
  dashboardForYou: DashboardForYouResponse;
  googlePlaceDetail: GooglePlaceDetail;
};

export type QueryGetUserArgs = {
  id: Scalars['ID'];
};

export type QueryPlaceArgs = {
  id: Scalars['ID'];
  lang?: InputMaybe<SupportedLanguages>;
};

export type QueryPlacesArgs = {
  prefix?: InputMaybe<Scalars['String']>;
  gpsLocation?: InputMaybe<GeoPointInput>;
  timezoneCode?: InputMaybe<Scalars['String']>;
  placeUuids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentPlaceUuid?: InputMaybe<Scalars['ID']>;
  institutionUuid?: InputMaybe<Scalars['ID']>;
  includeDistricts?: InputMaybe<Scalars['Boolean']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  visitorMode?: InputMaybe<Scalars['Boolean']>;
  itineraryMode?: InputMaybe<Scalars['Boolean']>;
  popularDestination?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<PlacessOrderByInput>;
  lang?: InputMaybe<SupportedLanguages>;
};

export type QueryFindPlacesArgs = {
  prefix: Scalars['String'];
};

export type QueryAllPlacesArgs = {
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  gpsLocation?: InputMaybe<GeoPointInput>;
  order?: InputMaybe<PlacesOrderByInput>;
  parentPlaceUuid?: InputMaybe<Scalars['ID']>;
  includeDistricts?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPlacesByArgs = {
  filter: PlacesFilterInput;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryFeatureFlagsByPlaceArgs = {
  placeUuid: Scalars['ID'];
  visitorModeEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAllInstallationsArgs = {
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryInstallationArgs = {
  id: Scalars['ID'];
  lang?: InputMaybe<SupportedLanguages>;
};

export type QueryInstallationsByArgs = {
  filter: InstallationsFilterInput;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryReminderInstallationsByArgs = {
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryInstallationUuidsByGeoShapeArgs = {
  geoShape: GeoShapeInput;
  pageSize?: InputMaybe<Scalars['Int']>;
  pageOffset?: InputMaybe<Scalars['Int']>;
};

export type QueryInstallationGeoMetadataArgs = {
  installationUuid: Scalars['ID'];
};

export type QueryIsDeviceOutOfHomeCityArgs = {
  placeUuid: Scalars['ID'];
  currentGpsCoords: GeoPointInput;
};

export type QueryPaymentTransactionArgs = {
  id: Scalars['ID'];
  app?: InputMaybe<App>;
};

export type QueryPaymentTransactionsByInstallationArgs = {
  installationUuid: Scalars['ID'];
  filter?: InputMaybe<PaymentTransactionsByInstallationFilterInput>;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryPaymentTransactionsByCreatedDateArgs = {
  createdDate: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryPaymentTransactionsByPaidDateArgs = {
  paidDate: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryPaymentTransactionsByProcessingStatusArgs = {
  processingStatus: ProcessingStatus;
  filter?: InputMaybe<PaymentTransactionsByProcessingStatusFilterInput>;
  orderBy?: InputMaybe<PaymentTransactionsByProcessingStatusOrderByInput>;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  app?: InputMaybe<App>;
};

export type QueryAllNotificationsArgs = {
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryNotificationsLimitsArgs = {
  placeUuid: Scalars['String'];
};

export type QueryNotificationArgs = {
  id: Scalars['ID'];
};

export type QueryNotificationsByArgs = {
  filter: NotificationByFilterInput;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryNotificationsStdByArgs = {
  filter: NotificationStdByFilterInput;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryFollowupsByArgs = {
  filter: FollowupByFilterInput;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryAllReportsArgs = {
  installation?: InputMaybe<Scalars['String']>;
  placeUuid: Scalars['String'];
  filter?: InputMaybe<ReportsFilters>;
  orderBy?: InputMaybe<ReportOrderByInput>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryReportsInRadiusArgs = {
  installation?: InputMaybe<Scalars['String']>;
  meters: Scalars['Int'];
  location: GeoPointInput;
};

export type QueryMyReportsArgs = {
  installation?: InputMaybe<Scalars['String']>;
  placeUuid: Scalars['String'];
  filter?: InputMaybe<ReportsFilters>;
  orderBy?: InputMaybe<ReportOrderByInput>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryReportArgs = {
  installation?: InputMaybe<Scalars['String']>;
  reportId: Scalars['ID'];
};

export type QueryReportsInPlaceArgs = {
  placeUuid: Scalars['String'];
};

export type QueryModerationPostsByArgs = {
  filter: ModerationPostsByFilterInput;
  pageOffset: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type QueryAnnouncementsByArgs = {
  filter: AnnouncementsByFilterInput;
  orderBy?: InputMaybe<AnnouncementsOrderByInput>;
  pageOffset: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type QueryAnnouncementsForInstallationArgs = {
  installationUuid: Scalars['ID'];
  lang?: InputMaybe<Scalars['String']>;
  coords?: InputMaybe<GeoPointInput>;
  orderBy?: InputMaybe<AnnouncementsForInstallationOrderByInput>;
  fromDatetime?: InputMaybe<Scalars['AWSDateTime']>;
  toDatetime?: InputMaybe<Scalars['AWSDateTime']>;
  nextToken?: InputMaybe<Scalars['String']>;
  queryStartedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type QueryPostArgs = {
  id: Scalars['ID'];
  lang?: InputMaybe<Scalars['String']>;
  installationUuid?: InputMaybe<Scalars['ID']>;
};

export type QueryPostsForInstallationArgs = {
  installationUuid: Scalars['ID'];
  postType?: InputMaybe<PostType>;
  lang?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PostsForInstallationFilterInput>;
  coords?: InputMaybe<GeoPointInput>;
  orderBy?: InputMaybe<PostsForInstallationOrderByInput>;
  fromDatetime?: InputMaybe<Scalars['AWSDateTime']>;
  toDatetime?: InputMaybe<Scalars['AWSDateTime']>;
  nextToken?: InputMaybe<Scalars['String']>;
  queryStartedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type QueryPostsForInstallationForYouArgs = {
  installationUuid: Scalars['ID'];
  postType?: InputMaybe<PostType>;
  lang?: InputMaybe<Scalars['String']>;
  filter: PostsForInstallationForYouFilterInput;
  fromDatetime?: InputMaybe<Scalars['AWSDateTime']>;
  toDatetime?: InputMaybe<Scalars['AWSDateTime']>;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryTripPlanSuggestionsArgs = {
  installationUuid: Scalars['ID'];
  input: TripPlanInput;
};

export type QueryTripPlanItineraryArgs = {
  installationUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
  pageOffset?: InputMaybe<Scalars['Int']>;
};

export type QueryTripPlanPlacesArgs = {
  installationUuid: Scalars['ID'];
};

export type QueryTripPlanItineraryByIdArgs = {
  id: Scalars['ID'];
};

export type QueryTripPlanItinerariesArgs = {
  installationUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
  pageOffset?: InputMaybe<Scalars['Int']>;
};

export type QueryTripPlanItineraryPlacesArgs = {
  installationUuid: Scalars['ID'];
};

export type QueryPostsForMapArgs = {
  installationUuid: Scalars['ID'];
  filter: PostsForMapFilterInput;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryPostsForSimpliMapArgs = {
  installationUuid: Scalars['ID'];
  filter: PostsForMapFilterInput;
  clusterPosts?: InputMaybe<Scalars['Boolean']>;
  zoomLevel?: InputMaybe<Scalars['Int']>;
};

export type QueryMapQueryArgs = {
  queryBody: Scalars['AWSJSON'];
  caching: MapQueryCachingInput;
  index?: InputMaybe<Scalars['String']>;
};

export type QueryPostStoriesForInstallationArgs = {
  installationUuid: Scalars['ID'];
  orderBy?: InputMaybe<PostsStoriesForInstallationOrderByInput>;
};

export type QueryEventsDatesArgs = {
  filter?: InputMaybe<EventsDatesFilter>;
  fromDatetime: Scalars['AWSDateTime'];
  toDatetime: Scalars['AWSDateTime'];
  timezoneCode?: InputMaybe<Scalars['String']>;
};

export type QueryAnnouncementLikeArgs = {
  id: Scalars['ID'];
};

export type QueryQuickQuestionsByArgs = {
  filter: QuickQuestionsByFilterInput;
  installation?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<QuickQuestionsOrderByInput>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryEventsByArgs = {
  installationUuid?: InputMaybe<Scalars['ID']>;
  filter: EventsByFilterInput;
  orderBy?: InputMaybe<EventsOrderByInput>;
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryEventsByV2Args = {
  filter: EventsByFilterInputV2;
  orderBy?: InputMaybe<EventsOrderByInput>;
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QuerySimilarEventsArgs = {
  id: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
  pageOffset?: InputMaybe<Scalars['Int']>;
};

export type QueryEventsForInstallationArgs = {
  installationUuid: Scalars['ID'];
  lang?: InputMaybe<Scalars['String']>;
  coords?: InputMaybe<GeoPointInput>;
  orderBy?: InputMaybe<EventsForInstallationOrderByInput>;
  fromDatetime?: InputMaybe<Scalars['AWSDateTime']>;
  toDatetime?: InputMaybe<Scalars['AWSDateTime']>;
  nextToken?: InputMaybe<Scalars['String']>;
  queryStartedAt?: InputMaybe<Scalars['AWSDateTime']>;
};

export type QuerySearchArgs = {
  text?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SearchFilterInput>;
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  timezoneCode?: InputMaybe<Scalars['String']>;
};

export type QuerySearchTopTenArgs = {
  text?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SearchFilterInput>;
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryAllCategoriesArgs = {
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryCategoriesByArgs = {
  filter: CategoriesByFilterInput;
  lang?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<CategoriesOrderByInput>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryInstitutionsByArgs = {
  filter: InstitutionsByFilterInput;
  orderBy?: InputMaybe<InstitutionsOrderByInput>;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryInstitutionsBySearchArgs = {
  filter: InstitutionsBySearchFilterInput;
  orderBy?: InputMaybe<InstitutionsOrderByInput>;
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryInstitutionsPlacesBySearchArgs = {
  filter: InstitutionsPlacesBySearchFilterInput;
  orderBy?: InputMaybe<InstitutionsOrderByInput>;
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  visitorMode?: InputMaybe<Scalars['Boolean']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryExploreAllBySearchArgs = {
  filter: ExploreAllBySearchFilterInput;
  pageSize?: InputMaybe<Scalars['Int']>;
  installationUuid?: InputMaybe<Scalars['ID']>;
  postsPageOffset?: InputMaybe<Scalars['Int']>;
  visitorMode?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPostsBySearchArgs = {
  filter: PostsBySearchFilterInput;
  pageSize?: InputMaybe<Scalars['Int']>;
  postsPageOffset?: InputMaybe<Scalars['Int']>;
};

export type QueryPlacesBySearchArgs = {
  filter: PlacesBySearchFilterInput;
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryAllInstitutionsArgs = {
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryInstitutionArgs = {
  id: Scalars['ID'];
};

export type QueryScrapeNewsByCodeArgs = {
  taskCode: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryScrapeNewsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryAllScrapeNewsArgs = {
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryScrapeEventsByCodeArgs = {
  taskCode: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryScrapeFutureEventsByCodeArgs = {
  taskCode: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryScrapeEventsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryAllScrapeEventsArgs = {
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryInboxByArgs = {
  filter: InboxByFilterInput;
  lang?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryAllScrapeTasksArgs = {
  prefix?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryScrapeTaskByCodeArgs = {
  code?: InputMaybe<Scalars['String']>;
};

export type QueryAdminDashboardArgs = {
  filter: DashboardFilterInput;
};

export type QueryAdminDashboardAnalyticsArgs = {
  filter: DashboardAnalyticsFilterInput;
};

export type QueryAdminDashboardTotalsAnalyticsArgs = {
  filter: DashboardAnalyticsFilterInput;
};

export type QueryAdminDashboardTopReactionsArgs = {
  filter: DashboardAnalyticsFilterInput;
};

export type QueryIntegrationArgs = {
  id: Scalars['ID'];
};

export type QueryIntegrationsByInstitutionArgs = {
  institutionId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryScrapeSelectorArgs = {
  code: Scalars['String'];
};

export type QueryAllScrapeSelectorsArgs = {
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryInsightsArgs = {
  filter: InsightsFilterInput;
  orderBy?: InputMaybe<InsightsOrderByInput>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryFeedbacksArgs = {
  filter?: InputMaybe<FeedbacksFilter>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryModerationVersionsArgs = {
  filter: ModerationVersionsFilter;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryHarmfulContentReportsArgs = {
  filter?: InputMaybe<HarmfulReasonReportsFilter>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryAdminUserArgs = {
  username: Scalars['String'];
};

export type QueryAdminUsersArgs = {
  filter: AdminUsersFilterInput;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryVerifyGeoLocationArgs = {
  location: GeoPointInput;
  city: Scalars['String'];
};

export type QueryPetsByArgs = {
  filter: PetsByFilter;
  orderBy?: InputMaybe<PetsOrderBy>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryPetArgs = {
  id: Scalars['ID'];
};

export type QueryPetsInPlaceByArgs = {
  filter?: InputMaybe<PetsInPlaceByFilter>;
  orderBy?: InputMaybe<PetsOrderBy>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QuerySystemStatusArgs = {
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryCouponArgs = {
  id: Scalars['ID'];
};

export type QueryCouponsByArgs = {
  filter: CouponsByFilterInput;
  orderBy?: InputMaybe<CouponsOrderByInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryCouponsForInstallationArgs = {
  installationUuid: Scalars['ID'];
};

export type QueryGoogleSpreadsheetKeyValueArgs = {
  spreadsheet?: InputMaybe<QueryGoogleSpreadsheetInput>;
};

export type QueryBusinessNotificationCountsArgs = {
  filter: BusinessNotificationCountsFilterInput;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryBusinessActiveUsersCountsArgs = {
  filter?: InputMaybe<BusinessActiveUsersCountsFilterInput>;
  nextToken?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryChatGptMessagesArgs = {
  sessionStart?: InputMaybe<Scalars['AWSDateTime']>;
  sessionEnd?: InputMaybe<Scalars['AWSDateTime']>;
  installationUuid: Scalars['ID'];
  topic?: InputMaybe<ChatGptTopic>;
  institutionUuid?: InputMaybe<Scalars['ID']>;
  landmarkUuid?: InputMaybe<Scalars['ID']>;
  chatIdentifier?: InputMaybe<ChatIdentifierInput>;
  lang?: InputMaybe<Scalars['String']>;
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryChatMessagesArgs = {
  sessionStart?: InputMaybe<Scalars['AWSDateTime']>;
  sessionEnd?: InputMaybe<Scalars['AWSDateTime']>;
  installationUuid: Scalars['ID'];
  topic?: InputMaybe<ChatGptTopic>;
  institutionUuid?: InputMaybe<Scalars['ID']>;
  landmarkUuid?: InputMaybe<Scalars['ID']>;
  chatIdentifier?: InputMaybe<ChatIdentifierInput>;
  lang?: InputMaybe<Scalars['String']>;
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryChatGptMessagesWidgetArgs = {
  sessionStart?: InputMaybe<Scalars['AWSDateTime']>;
  sessionEnd?: InputMaybe<Scalars['AWSDateTime']>;
  installationUuid: Scalars['ID'];
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryTransitSearchStopsArgs = {
  gpsLocation: GeoPointInput;
  query: Scalars['String'];
  maxNumResults?: InputMaybe<Scalars['Int']>;
};

export type QueryTransitNearbyStopsArgs = {
  gpsLocation: GeoPointInput;
  maxDistance?: InputMaybe<Scalars['Int']>;
};

export type QueryTransitPlanArgs = {
  from: TransitPlanLocationInput;
  to: TransitPlanLocationInput;
  arriveBy?: InputMaybe<Scalars['Boolean']>;
  datetime?: InputMaybe<Scalars['AWSDateTime']>;
  maxNumItineraries?: InputMaybe<Scalars['Int']>;
};

export type QueryMessagingCategoriesByArgs = {
  filter: MessagingCategoriesByFilterInput;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryMessagingProblemArgs = {
  id: Scalars['ID'];
};

export type QueryMessagingProblemsByArgs = {
  filter: MessagingProblemsFilterInput;
  orderBy?: InputMaybe<MessagingProblemsOrderByInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
  pageOffset?: InputMaybe<Scalars['Int']>;
};

export type QueryMessagingProblemsForInstallationArgs = {
  installationUuid: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
  pageOffset?: InputMaybe<Scalars['Int']>;
};

export type QueryListInformationFromChatKnowledgeBaseArgs = {
  filter: ListInformationFromChatKnowledgeBaseFilterInput;
  pageSize?: InputMaybe<Scalars['Int']>;
  pageOffset?: InputMaybe<Scalars['Int']>;
};

export type QueryTextSegmentionArgs = {
  text: Scalars['String'];
  language?: InputMaybe<SegmentationLanguages>;
};

export type QuerySavedPostsForInstallationArgs = {
  installationUuid: Scalars['ID'];
  filter?: InputMaybe<SavedPostsForInstallationFilter>;
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  responseType?: InputMaybe<SavedPostsForInstallationResponseType>;
};

export type QueryCommentCountArgs = {
  type: CommentType;
  id: Scalars['ID'];
};

export type QueryCommentsArgs = {
  type: CommentType;
  id: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
  pageOffset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CommentsOrderByInput>;
};

export type QuerySuggestedLicensePlatesArgs = {
  installationUuid: Scalars['ID'];
};

export type QueryActiveParkingTicketsArgs = {
  installationUuid: Scalars['ID'];
};

export type QueryVisitorInterestsArgs = {
  lang: Scalars['String'];
};

export type QueryItineraryArgs = {
  installationUuid: Scalars['ID'];
};

export type QueryLandmarkArgs = {
  id: Scalars['ID'];
  installationUuid?: InputMaybe<Scalars['ID']>;
};

export type QueryLandmarksByArgs = {
  installationUuid: Scalars['ID'];
  filter: LandmarksByFilterInput;
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryLandmarksForInstallationArgs = {
  installationUuid: Scalars['ID'];
  filter: LandmarksForInstallationFilterInput;
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryRestaurantArgs = {
  id: Scalars['ID'];
  installationUuid?: InputMaybe<Scalars['ID']>;
};

export type QueryRestaurantsArgs = {
  installationUuid?: InputMaybe<Scalars['ID']>;
  sort?: InputMaybe<RestaurantsSortInput>;
  filter?: InputMaybe<RestaurantsFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
  pageOffset?: InputMaybe<Scalars['Int']>;
};

export type QuerySimilarRestaurantsArgs = {
  id: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
  pageOffset?: InputMaybe<Scalars['Int']>;
};

export type QueryRestaurantsByArgs = {
  installationUuid: Scalars['ID'];
  filter: RestaurantsByFilterInput;
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryRestaurantsForInstallationArgs = {
  installationUuid: Scalars['ID'];
  filter: RestaurantsForInstallationFilterInput;
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryRestaurantCategoriesCountsArgs = {
  filter: RestaurantCategoriesCountsFilterInput;
};

export type QueryMarketItemArgs = {
  id: Scalars['ID'];
  lang?: InputMaybe<Scalars['String']>;
  installationUuid?: InputMaybe<Scalars['ID']>;
};

export type QueryMarketItemsByArgs = {
  installationUuid?: InputMaybe<Scalars['ID']>;
  filter: MarketItemsByFilterInput;
  lang?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<MarketItemsBySortByInput>;
};

export type QueryMarketItemsByV2Args = {
  filter: MarketItemsByFilterInput;
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryMarketItemsForInstallationArgs = {
  installationUuid: Scalars['ID'];
  filter?: InputMaybe<MarketItemsForInstallationFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryMarketFiltersCountArgs = {
  installationUuid: Scalars['ID'];
  filter: MarketItemsByFilterInput;
};

export type QueryInstallationsByEmailArgs = {
  email: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryInfluencerArgs = {
  id: Scalars['ID'];
};

export type QueryInfluencersByArgs = {
  filter: InfluencersByFilterInput;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryInfluencerSpotArgs = {
  id: Scalars['ID'];
};

export type QueryInfluencerSpotsByArgs = {
  filter: InfluencerSpotsByFilter;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryInfluencerItineraryArgs = {
  id: Scalars['ID'];
};

export type QueryInfluencerItinerariesByArgs = {
  filter: InfluencerItinerariesByFilter;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryBadgeArgs = {
  id: Scalars['ID'];
  installationUuid?: InputMaybe<Scalars['ID']>;
};

export type QueryBadgesForInstallationArgs = {
  installationUuid: Scalars['ID'];
  filter?: InputMaybe<BadgesForInstallationFilter>;
  pageSize?: InputMaybe<Scalars['Int']>;
  pageOffset?: InputMaybe<Scalars['Int']>;
};

export type QueryBatchGetEventsDedupArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryGetMainItineraryArgs = {
  installationUuid: Scalars['ID'];
  topic?: InputMaybe<MainItineraryTopic>;
  day?: InputMaybe<MainItineraryDay>;
};

export type QueryGetItineraryArgs = {
  installationUuid: Scalars['ID'];
  itineraryCompany?: InputMaybe<ItineraryCompany>;
  gpsLocation: GeoPointInput;
  date: Scalars['AWSDateTime'];
};

export type QueryGetItineraryByIdArgs = {
  itineraryUuid: Scalars['ID'];
};

export type QueryGetItineraryEntryArgs = {
  id: Scalars['ID'];
};

export type QueryGetItineraryCategoriesArgs = {
  filter: ItineraryCategoriesFilterInput;
};

export type QueryGetWeekItineraryArgs = {
  installationUuid: Scalars['ID'];
};

export type QueryGetDayItineraryByIdArgs = {
  itineraryUuid: Scalars['ID'];
};

export type QueryGetFollowingItineraryEntryArgs = {
  installationUuid: Scalars['ID'];
};

export type QueryItineraryCelebritiesForPlaceArgs = {
  placeUuid: Scalars['ID'];
};

export type QueryGetCelebrityItineraryArgs = {
  celebrityUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  dayNumber: Scalars['Int'];
};

export type QueryFindRouteArgs = {
  items: Array<RouteSolverInputItem>;
  startLocation: GeoPointInput;
  travelerTimeWindow: TimeWindowInput;
  maxRuntime: Scalars['Int'];
  breaks?: InputMaybe<Array<RouteSolverBreakInput>>;
  transportType?: InputMaybe<RouteTransportType>;
};

export type QueryGetMagicItineraryEntryArgs = {
  installationUuid: Scalars['ID'];
  gpsLocation: GeoPointInput;
  maxDistance?: InputMaybe<Scalars['Float']>;
  entryType?: InputMaybe<MainItineraryEntryType>;
};

export type QueryGetMagicButtonEntryArgs = {
  installationUuid: Scalars['ID'];
  filter: MagicButtonFilterInput;
};

export type QueryMagicButtonArgs = {
  installationUuid: Scalars['ID'];
  input: MagicButtonInput;
  data: MagicButtonDataInput;
  lang?: InputMaybe<SupportedLanguages>;
};

export type QueryMagicButtonResultArgs = {
  id: Scalars['ID'];
  installationUuid?: InputMaybe<Scalars['ID']>;
  lang?: InputMaybe<SupportedLanguages>;
};

export type QueryMagicButtonPersonalizedArgs = {
  installationUuid: Scalars['ID'];
  answerUuid: Scalars['ID'];
  data: MagicButtonDataInput;
  lang?: InputMaybe<SupportedLanguages>;
};

export type QueryMagicButtonBookmarksArgs = {
  userUuid: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryMagicButtonRecentSearchesArgs = {
  installationUuid: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
  pageOffset?: InputMaybe<Scalars['Int']>;
};

export type QueryMagicButtonDynamicQuestionsArgs = {
  installationUuid: Scalars['ID'];
  input: MagicButtonDataInput;
  lang?: InputMaybe<SupportedLanguages>;
};

export type QueryMagicButtonRelatedQuestionHistoryArgs = {
  userUuid: Scalars['ID'];
  answerUuid: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
  pageOffset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  lang?: InputMaybe<SupportedLanguages>;
};

export type QueryIsBookmarkedArgs = {
  userUuid: Scalars['ID'];
  answerUuid: Scalars['ID'];
};

export type QueryGetExploreItemsArgs = {
  placeUuid: Scalars['ID'];
  pageOffset?: InputMaybe<Scalars['Int']>;
};

export type QuerySearchEntriesByArgs = {
  filter: SearchEntriesByFilterInput;
  pageSize?: InputMaybe<Scalars['Int']>;
  pageOffset?: InputMaybe<Scalars['Int']>;
  timezoneCode: Scalars['String'];
};

export type QueryGetUserPreferenceCardsArgs = {
  placeUuid?: InputMaybe<Scalars['ID']>;
  lang?: InputMaybe<SupportedLanguages>;
};

export type QueryGetPreferenceCardsByIdsArgs = {
  cardUuids: Array<Scalars['ID']>;
};

export type QueryGetUserCardPreferencesArgs = {
  installationUuid: Scalars['ID'];
};

export type QueryGetSocialProfileArgs = {
  installationUuid: Scalars['ID'];
  targetInstallationUuid: Scalars['ID'];
};

export type QueryLikedTopTensArgs = {
  installationUuid: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryInstallationTopTensArgs = {
  installationUuid: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryFollowersArgs = {
  installationUuid: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryFollowingsArgs = {
  installationUuid: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QuerySearchUserProfileArgs = {
  installationUuid: Scalars['ID'];
  searchTerm: Scalars['String'];
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryDiscoverTopTensArgs = {
  installationUuid: Scalars['ID'];
  filter: DiscoverTopTenFilterInput;
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryGetTopTenArgs = {
  id: Scalars['ID'];
};

export type QueryBlockedUserProfilesArgs = {
  installationUuid: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type QueryGoogleItemArgs = {
  installationUuid: Scalars['ID'];
  id: Scalars['ID'];
};

export type QueryRandomPostImageArgs = {
  placeUuid: Scalars['ID'];
  type: ExploreType;
};

export type QueryCurrentWeatherArgs = {
  geopoint: GeoPointInput;
};

export type QueryDashboardForYouArgs = {
  installationUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  pageOffset?: InputMaybe<Scalars['Int']>;
  timezoneCode?: InputMaybe<Scalars['String']>;
};

export type QueryGooglePlaceDetailArgs = {
  id: Scalars['ID'];
};

export type QueryGoogleSpreadsheetInput = {
  spreadsheetURL: Scalars['String'];
};

export type QuickQuestion = IQuickQuestion & {
  __typename?: 'QuickQuestion';
  id: Scalars['ID'];
  question: Scalars['String'];
  questionOptions: Scalars['AWSJSON'];
  privateResults: Scalars['Boolean'];
  scheduledAt?: Maybe<Scalars['AWSDateTime']>;
  publishedAt?: Maybe<Scalars['AWSDateTime']>;
  quickQuestionEndDate: Scalars['AWSDateTime'];
  placeUuid?: Maybe<Scalars['ID']>;
  placeUuids?: Maybe<Array<Scalars['ID']>>;
  createdAt: Scalars['AWSDateTime'];
  likeCount: Scalars['Int'];
  like?: Maybe<Like>;
  save?: Maybe<Save>;
  quickQuestionFeedback?: Maybe<QuickQuestionFeedback>;
  institution?: Maybe<Institution>;
  categories: Array<Category>;
  lang: Scalars['String'];
  createdByAdmin?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedByAdmin?: Maybe<Scalars['Boolean']>;
  notifyNow?: Maybe<Scalars['Boolean']>;
  publishmentState: State;
  notifications?: Maybe<Array<Scalars['AWSDateTime']>>;
  aiMetadata?: Maybe<AiMetadata>;
  moderationCategory?: Maybe<ModerationCategories>;
  isChat?: Maybe<Scalars['Boolean']>;
  notifyVolunteersOnly?: Maybe<Scalars['Boolean']>;
  notifyAlsoNonCategoried?: Maybe<Scalars['Boolean']>;
  typename?: Maybe<PostType>;
  NERLocations?: Maybe<Array<NerLocation>>;
  gpsLocation?: Maybe<GeoPoint>;
};

export type QuickQuestionDraft = IQuickQuestion & {
  __typename?: 'QuickQuestionDraft';
  id: Scalars['ID'];
  question: Scalars['String'];
  questionOptions?: Maybe<Scalars['AWSJSON']>;
  privateResults?: Maybe<Scalars['Boolean']>;
  scheduledAt?: Maybe<Scalars['AWSDateTime']>;
  publishedAt?: Maybe<Scalars['AWSDateTime']>;
  quickQuestionEndDate?: Maybe<Scalars['AWSDateTime']>;
  placeUuid?: Maybe<Scalars['ID']>;
  placeUuids?: Maybe<Array<Scalars['ID']>>;
  createdAt: Scalars['AWSDateTime'];
  likeCount: Scalars['Int'];
  like?: Maybe<Like>;
  save?: Maybe<Save>;
  quickQuestionFeedback?: Maybe<QuickQuestionFeedback>;
  institution?: Maybe<Institution>;
  categories: Array<Category>;
  lang: Scalars['String'];
  createdByAdmin?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedByAdmin?: Maybe<Scalars['Boolean']>;
  notifyNow?: Maybe<Scalars['Boolean']>;
  publishmentState: State;
  notifications?: Maybe<Array<Scalars['AWSDateTime']>>;
  aiMetadata?: Maybe<AiMetadata>;
  moderationCategory?: Maybe<ModerationCategories>;
  isChat?: Maybe<Scalars['Boolean']>;
  notifyVolunteersOnly?: Maybe<Scalars['Boolean']>;
  notifyAlsoNonCategoried?: Maybe<Scalars['Boolean']>;
  typename?: Maybe<PostType>;
  NERLocations?: Maybe<Array<NerLocation>>;
  gpsLocation?: Maybe<GeoPoint>;
};

export type QuickQuestionDraftInput = {
  id?: InputMaybe<Scalars['ID']>;
  question: Scalars['String'];
  questionOptions?: InputMaybe<Scalars['AWSJSON']>;
  privateResults?: InputMaybe<Scalars['Boolean']>;
  institutionUuid: Scalars['String'];
  scheduledAt?: InputMaybe<Scalars['AWSDateTime']>;
  publishedAt?: InputMaybe<Scalars['AWSDateTime']>;
  notifyNow?: InputMaybe<Scalars['Boolean']>;
  quickQuestionEndDate?: InputMaybe<Scalars['AWSDateTime']>;
  notifications?: InputMaybe<Array<Scalars['AWSDateTime']>>;
  lang: Scalars['String'];
  categoryUuids: Array<Scalars['String']>;
  placeUuids?: InputMaybe<Array<Scalars['ID']>>;
  isChat?: InputMaybe<Scalars['Boolean']>;
  notifyVolunteersOnly?: InputMaybe<Scalars['Boolean']>;
  notifyAlsoNonCategoried?: InputMaybe<Scalars['Boolean']>;
  gpsLocation?: InputMaybe<GeoPointInput>;
};

export type QuickQuestionFeedback = {
  __typename?: 'QuickQuestionFeedback';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  quickQuestionOptionsUuids: Array<Scalars['String']>;
};

export type QuickQuestionInput = {
  id?: InputMaybe<Scalars['ID']>;
  question: Scalars['String'];
  questionOptions: Scalars['AWSJSON'];
  privateResults: Scalars['Boolean'];
  institutionUuid?: InputMaybe<Scalars['String']>;
  scheduledAt?: InputMaybe<Scalars['AWSDateTime']>;
  publishedAt?: InputMaybe<Scalars['AWSDateTime']>;
  notifyNow?: InputMaybe<Scalars['Boolean']>;
  quickQuestionEndDate: Scalars['AWSDateTime'];
  notifications?: InputMaybe<Array<Scalars['AWSDateTime']>>;
  lang: Scalars['String'];
  categoryUuids: Array<Scalars['String']>;
  placeUuids?: InputMaybe<Array<Scalars['ID']>>;
  isChat?: InputMaybe<Scalars['Boolean']>;
  notifyVolunteersOnly?: InputMaybe<Scalars['Boolean']>;
  notifyAlsoNonCategoried?: InputMaybe<Scalars['Boolean']>;
  gpsLocation?: InputMaybe<GeoPointInput>;
};

export enum QuickQuestionSortBy {
  PublishedAt = 'PUBLISHED_AT',
  LikeCount = 'LIKE_COUNT',
}

export type QuickQuestionsByFilterInput = {
  text?: InputMaybe<Scalars['String']>;
  placeUuid?: InputMaybe<Scalars['String']>;
  lang: Scalars['String'];
  categories?: InputMaybe<Array<Scalars['String']>>;
  institutions?: InputMaybe<Array<Scalars['String']>>;
  state?: InputMaybe<State>;
  isChat?: InputMaybe<Scalars['Boolean']>;
  uniqueInstitutions?: InputMaybe<Scalars['Boolean']>;
};

export type QuickQuestionsOrderByInput = {
  by: QuickQuestionSortBy;
  order: SortOrder;
};

export type RaffleEntry = {
  __typename?: 'RaffleEntry';
  email: Scalars['String'];
  raffleUuid: Scalars['String'];
  timestamp: Scalars['AWSDateTime'];
};

export type RaffleOptions = {
  __typename?: 'RaffleOptions';
  titleWidth?: Maybe<Scalars['Float']>;
  textWidth?: Maybe<Scalars['Float']>;
  textColor?: Maybe<Scalars['String']>;
  textPosition?: Maybe<Scalars['String']>;
  datetimeFrom?: Maybe<Scalars['AWSDateTime']>;
  datetimeTo?: Maybe<Scalars['AWSDateTime']>;
  showIncChanceFlag?: Maybe<Scalars['Boolean']>;
  termsAndConditionsUrl?: Maybe<Scalars['String']>;
  privacyPolicyUrl?: Maybe<Scalars['String']>;
  includeRegistration?: Maybe<Scalars['Boolean']>;
  linkTo?: Maybe<Scalars['String']>;
};

export type RaffleOptionsInput = {
  titleWidth?: InputMaybe<Scalars['Float']>;
  textWidth?: InputMaybe<Scalars['Float']>;
  textColor?: InputMaybe<Scalars['String']>;
  textPosition?: InputMaybe<Scalars['String']>;
  datetimeFrom?: InputMaybe<Scalars['AWSDateTime']>;
  datetimeTo?: InputMaybe<Scalars['AWSDateTime']>;
  showIncChanceFlag?: InputMaybe<Scalars['Boolean']>;
  termsAndConditionsUrl?: InputMaybe<Scalars['String']>;
  privacyPolicyUrl?: InputMaybe<Scalars['String']>;
  includeRegistration?: InputMaybe<Scalars['Boolean']>;
  linkTo?: InputMaybe<Scalars['String']>;
};

export enum Reaction {
  Yes = 'YES',
  No = 'NO',
  Spam = 'SPAM',
  Scam = 'SCAM',
  Fake = 'FAKE',
  Hateful = 'HATEFUL',
  Inappropriate = 'INAPPROPRIATE',
  Other = 'OTHER',
  Kudos = 'KUDOS',
  Like = 'LIKE',
  All = 'ALL',
}

export enum ReactionInFilter {
  Yes = 'YES',
  No = 'NO',
  All = 'ALL',
}

export type RecipientInput = {
  address: Scalars['String'];
};

export enum RecurringPeriod {
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
}

export type RecurringValidity = {
  __typename?: 'RecurringValidity';
  type: RecurringPeriod;
  day: Array<Scalars['Int']>;
  every: Scalars['Int'];
  fromDate: Scalars['AWSDate'];
  toDate?: Maybe<Scalars['AWSDate']>;
  timeFrom: Scalars['AWSTime'];
  timeTo: Scalars['AWSTime'];
  ocurrences?: Maybe<Scalars['Int']>;
};

export type RecurringValidityInput = {
  type: RecurringPeriod;
  day: Array<Scalars['Int']>;
  every: Scalars['Int'];
  fromDate: Scalars['AWSDate'];
  toDate?: InputMaybe<Scalars['AWSDate']>;
  timeFrom: Scalars['AWSTime'];
  timeTo: Scalars['AWSTime'];
  ocurrences?: InputMaybe<Scalars['Int']>;
};

export type RefundDetail = TuiRefundDetail;

export enum ReminderDeliveryOption {
  OneDayBefore = 'ONE_DAY_BEFORE',
  TwoDaysBefore = 'TWO_DAYS_BEFORE',
}

export type ReminderParserSettings = {
  __typename?: 'ReminderParserSettings';
  /**   e.g. nyc-json-waste */
  parser: Scalars['String'];
  apiUrl: Scalars['AWSURL'];
  config: Array<ConfigPath>;
};

export type ReminderParserSettingsInput = {
  parser: Scalars['String'];
  apiUrl: Scalars['AWSURL'];
  config: Array<ConfigPathInput>;
};

export type ReminderSettings = {
  __typename?: 'ReminderSettings';
  reminderType: ReminderType;
  reminderCategoryCodes?: Maybe<Array<Scalars['String']>>;
  visualization: ReminderVisualization;
  institutionUuid: Scalars['ID'];
  parserSettings: ReminderParserSettings;
};

export type ReminderSettingsInput = {
  reminderType: ReminderType;
  reminderCategoryCodes?: InputMaybe<Array<Scalars['String']>>;
  visualization: ReminderVisualization;
  institutionUuid: Scalars['ID'];
  parserSettings: ReminderParserSettingsInput;
};

export enum ReminderStatus {
  NotScheduled = 'NOT_SCHEDULED',
  Scheduled = 'SCHEDULED',
  Sent = 'SENT',
  Delivered = 'DELIVERED',
}

export enum ReminderType {
  WasteManagement = 'WASTE_MANAGEMENT',
  StreetSweeping = 'STREET_SWEEPING',
}

export enum ReminderVisualization {
  Standard = 'STANDARD',
  WeekDays = 'WEEK_DAYS',
}

export type RemindersByDate = {
  __typename?: 'RemindersByDate';
  date: Scalars['AWSDateTime'];
  reminders: Array<InstallationScheduledReminder>;
};

export type RemindersDates = {
  __typename?: 'RemindersDates';
  dates: Array<RemindersByDate>;
};

export type RemindersDatesFilter = {
  installationUuid: Scalars['ID'];
  reminderCategoryCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type Report = {
  __typename?: 'Report';
  /** @deprecated No longer supported */
  id?: Maybe<Scalars['ID']>;
  /** @deprecated No longer supported */
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  /** @deprecated No longer supported */
  likeCount?: Maybe<Scalars['Int']>;
  /** @deprecated No longer supported */
  status?: Maybe<Status>;
  email: Scalars['AWSEmail'];
  type?: Maybe<Type>;
  placeUuid: Scalars['String'];
  installation?: Maybe<Scalars['String']>;
  installationUuid: Scalars['String'];
  /** @deprecated No longer supported */
  title?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  location?: Maybe<GeoPoint>;
  address?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  images?: Maybe<Array<Image>>;
  imageObjects?: Maybe<Array<Image>>;
  videoObjects?: Maybe<Array<Video>>;
  /** @deprecated No longer supported */
  like?: Maybe<Like>;
};

export type ReportInput = {
  email: Scalars['AWSEmail'];
  type?: InputMaybe<Type>;
  msgCategoryUuid?: InputMaybe<Scalars['String']>;
  placeUuid: Scalars['String'];
  installationUuid: Scalars['String'];
  description: Scalars['String'];
  location?: InputMaybe<GeoPointInput>;
  address?: InputMaybe<Scalars['String']>;
  imageObjects?: InputMaybe<Array<ImageInput>>;
  videoObjects?: InputMaybe<Array<VideoInput>>;
};

export type ReportOrderByInput = {
  by: SortBy;
  order: SortOrder;
};

export type ReportedProblemVote = {
  __typename?: 'ReportedProblemVote';
  id: Scalars['ID'];
  installationUuid: Scalars['ID'];
  placeUuid?: Maybe<Scalars['String']>;
  interactionType: UserInteractionType;
  reportUuid: Scalars['String'];
  reportVote?: Maybe<Scalars['Int']>;
};

export type ReportedProblemsSettings = {
  __typename?: 'ReportedProblemsSettings';
  connectorTypes?: Maybe<Array<Maybe<MessagingProblemConnectorType>>>;
  isLaneAvailable?: Maybe<Scalars['Boolean']>;
  showCategories?: Maybe<Scalars['Boolean']>;
  isMediaRequired?: Maybe<Scalars['Boolean']>;
  potholeCategoryUuid?: Maybe<Scalars['ID']>;
};

export type ReportedProblemsSettingsInput = {
  connectorTypes?: InputMaybe<Array<InputMaybe<MessagingProblemConnectorType>>>;
  isLaneAvailable?: InputMaybe<Scalars['Boolean']>;
  showCategories?: InputMaybe<Scalars['Boolean']>;
  isMediaRequired?: InputMaybe<Scalars['Boolean']>;
  potholeCategoryUuid?: InputMaybe<Scalars['ID']>;
};

export type ReportsFilters = {
  type?: InputMaybe<Type>;
};

export type Response = {
  __typename?: 'Response';
  code: Code;
};

export type Restaurant = {
  __typename?: 'Restaurant';
  typename?: Maybe<PostType>;
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  placeUuid: Scalars['ID'];
  gpsLocation?: Maybe<GeoPoint>;
  imageUrl?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['AWSURL']>;
  photoUrls?: Maybe<Array<Scalars['AWSURL']>>;
  rating?: Maybe<Scalars['Float']>;
  userRatingCount?: Maybe<Scalars['Int']>;
  priceLevel?: Maybe<RestaurantPriceLevel>;
  cuisines?: Maybe<Array<RestaurantCuisine>>;
  bookTable?: Maybe<RestaurantBookTable>;
  /** @deprecated Not used anymore */
  isLocal?: Maybe<Scalars['Boolean']>;
  save?: Maybe<Save>;
  openingHours?: Maybe<Array<OpeningHours>>;
  /** @deprecated Replaced by imageObjects */
  image?: Maybe<Image>;
  imageObjects?: Maybe<Array<Image>>;
  locationAttributes?: Maybe<Array<LocationAttribute>>;
  reviews?: Maybe<Array<LocationReview>>;
  travelDistanceDuration?: Maybe<TravelDistanceDuration>;
  isNew?: Maybe<Scalars['Boolean']>;
  googleId?: Maybe<Scalars['String']>;
  translations?: Maybe<RestaurantTranslations>;
};

export type RestaurantTravelDistanceDurationArgs = {
  startLocation?: InputMaybe<GeoPointInput>;
};

export type RestaurantTranslationsArgs = {
  lang?: InputMaybe<Scalars['String']>;
};

export type RestaurantBookTable = {
  __typename?: 'RestaurantBookTable';
  type: Scalars['String'];
  url: Scalars['String'];
};

export type RestaurantBookTableInput = {
  type: Scalars['String'];
  url: Scalars['String'];
};

export type RestaurantCategoriesCountsFilterInput = {
  placeUuid?: InputMaybe<Scalars['ID']>;
  lang?: InputMaybe<Scalars['String']>;
};

export type RestaurantCategoriesCountsResponse = {
  __typename?: 'RestaurantCategoriesCountsResponse';
  categoriesCounts: Array<RestaurantCategoryCount>;
};

export enum RestaurantCategory {
  Asian = 'ASIAN',
  CoffeeShop = 'COFFEE_SHOP',
  Indian = 'INDIAN',
  Italian = 'ITALIAN',
  Mexican = 'MEXICAN',
  Seafood = 'SEAFOOD',
  Vegan = 'VEGAN',
  Luxury = 'LUXURY',
  Local = 'LOCAL',
  Bar = 'BAR',
}

export type RestaurantCategoryCount = {
  __typename?: 'RestaurantCategoryCount';
  category: RestaurantCategory;
  count: Scalars['Int'];
};

export enum RestaurantCuisine {
  AmericanRestaurant = 'AMERICAN_RESTAURANT',
  Bakery = 'BAKERY',
  Bar = 'BAR',
  BarbecueRestaurant = 'BARBECUE_RESTAURANT',
  BrazilianRestaurant = 'BRAZILIAN_RESTAURANT',
  BreakfastRestaurant = 'BREAKFAST_RESTAURANT',
  BrunchRestaurant = 'BRUNCH_RESTAURANT',
  Cafe = 'CAFE',
  ChineseRestaurant = 'CHINESE_RESTAURANT',
  CoffeeShop = 'COFFEE_SHOP',
  FastFoodRestaurant = 'FAST_FOOD_RESTAURANT',
  FrenchRestaurant = 'FRENCH_RESTAURANT',
  GreekRestaurant = 'GREEK_RESTAURANT',
  HamburgerRestaurant = 'HAMBURGER_RESTAURANT',
  IceCreamShop = 'ICE_CREAM_SHOP',
  IndianRestaurant = 'INDIAN_RESTAURANT',
  IndonesianRestaurant = 'INDONESIAN_RESTAURANT',
  ItalianRestaurant = 'ITALIAN_RESTAURANT',
  JapaneseRestaurant = 'JAPANESE_RESTAURANT',
  KoreanRestaurant = 'KOREAN_RESTAURANT',
  LebaneseRestaurant = 'LEBANESE_RESTAURANT',
  MediterraneanRestaurant = 'MEDITERRANEAN_RESTAURANT',
  MexicanRestaurant = 'MEXICAN_RESTAURANT',
  MiddleEasternRestaurant = 'MIDDLE_EASTERN_RESTAURANT',
  PizzaRestaurant = 'PIZZA_RESTAURANT',
  RamenRestaurant = 'RAMEN_RESTAURANT',
  Restaurant = 'RESTAURANT',
  SandwichShop = 'SANDWICH_SHOP',
  SeafoodRestaurant = 'SEAFOOD_RESTAURANT',
  SpanishRestaurant = 'SPANISH_RESTAURANT',
  SteakHouse = 'STEAK_HOUSE',
  SushiRestaurant = 'SUSHI_RESTAURANT',
  ThaiRestaurant = 'THAI_RESTAURANT',
  TurkishRestaurant = 'TURKISH_RESTAURANT',
  VeganRestaurant = 'VEGAN_RESTAURANT',
  VegetarianRestaurant = 'VEGETARIAN_RESTAURANT',
  VietnameseRestaurant = 'VIETNAMESE_RESTAURANT',
}

export type RestaurantEntry = {
  __typename?: 'RestaurantEntry';
  id: Scalars['ID'];
  type: MainItineraryEntryType;
  placeUuid: Scalars['ID'];
  itemUuid: Scalars['ID'];
  title: Scalars['String'];
  content: Scalars['String'];
  name: Scalars['String'];
  imageObjects?: Maybe<Array<Image>>;
  address?: Maybe<Scalars['String']>;
  gpsLocation: GeoPoint;
  rating?: Maybe<Scalars['Float']>;
  userRatingCount?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  priceLevel?: Maybe<ItineraryPriceLevel>;
  company?: Maybe<Array<ItineraryCompany>>;
  mood?: Maybe<Array<EntryMood>>;
  suitableWeather?: Maybe<Array<WeatherType>>;
  bookTable?: Maybe<RestaurantBookTable>;
  cuisines?: Maybe<Array<RestaurantCuisine>>;
  locationAttributes?: Maybe<Array<LocationAttribute>>;
  openingHours?: Maybe<Array<OpeningHours>>;
  phone?: Maybe<Scalars['String']>;
  isNew?: Maybe<Scalars['Boolean']>;
  additionalAttributes: Array<RestaurantEntryAttributes>;
};

export type RestaurantEntryAttributes = {
  __typename?: 'RestaurantEntryAttributes';
  attribute: Scalars['String'];
  reason: Scalars['String'];
};

export type RestaurantItineraryEntry = {
  __typename?: 'RestaurantItineraryEntry';
  id: Scalars['ID'];
  type: MainItineraryEntryType;
  placeUuid: Scalars['ID'];
  itemUuid: Scalars['ID'];
  title: Scalars['String'];
  content: Scalars['String'];
  name: Scalars['String'];
  daytime: Daytime;
  customDaytime?: Maybe<Daytime>;
  imageObjects?: Maybe<Array<Image>>;
  address: Scalars['String'];
  gpsLocation: GeoPoint;
  rating?: Maybe<Scalars['Float']>;
  userRatingCount?: Maybe<Scalars['Int']>;
  bookTable?: Maybe<RestaurantBookTable>;
  startTime: Scalars['AWSDateTime'];
  endTime: Scalars['AWSDateTime'];
  travelDistanceDuration?: Maybe<TravelDistanceDuration>;
  phone?: Maybe<Scalars['String']>;
  isNew?: Maybe<Scalars['Boolean']>;
  save?: Maybe<Save>;
};

export type RestaurantItineraryEntryTravelDistanceDurationArgs = {
  startLocation?: InputMaybe<GeoPointInput>;
};

export enum RestaurantPriceLevel {
  PriceLevelInexpensive = 'PRICE_LEVEL_INEXPENSIVE',
  PriceLevelModerate = 'PRICE_LEVEL_MODERATE',
  PriceLevelExpensive = 'PRICE_LEVEL_EXPENSIVE',
  PriceLevelVeryExpensive = 'PRICE_LEVEL_VERY_EXPENSIVE',
}

export type RestaurantTranslations = {
  __typename?: 'RestaurantTranslations';
  content: Scalars['String'];
  reviews?: Maybe<Array<LocationReview>>;
};

export type RestaurantsByFilterInput = {
  placeUuid?: InputMaybe<Scalars['ID']>;
  categories?: InputMaybe<Array<RestaurantCategory>>;
  map?: InputMaybe<MapFilterInput>;
  forYou?: InputMaybe<Scalars['Boolean']>;
  lang?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  gpsLocation?: InputMaybe<GeoPointInput>;
  isNew?: InputMaybe<Scalars['Boolean']>;
};

export type RestaurantsFilterInput = {
  placeUuid?: InputMaybe<Scalars['ID']>;
  text?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<RestaurantCategory>>;
  cuisines?: InputMaybe<Array<RestaurantCuisine>>;
  openNowTimeZone?: InputMaybe<Scalars['String']>;
  isNew?: InputMaybe<Scalars['Boolean']>;
  best?: InputMaybe<Scalars['Boolean']>;
};

export type RestaurantsForInstallationFilterInput = {
  lang?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<RestaurantCategory>>;
  map?: InputMaybe<MapFilterInput>;
  forYou?: InputMaybe<Scalars['Boolean']>;
};

export type RestaurantsResponse = {
  __typename?: 'RestaurantsResponse';
  restaurants: Array<Restaurant>;
  pageSize?: Maybe<Scalars['Int']>;
  pageOffset?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type RestaurantsSortInput = {
  gpsLocation?: InputMaybe<GeoPointInput>;
};

export enum ResultStatus {
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}

export type RouteSolverBreakInput = {
  timeWindow: TimeWindowInput;
  duration: Scalars['Int'];
};

export type RouteSolverInputItem = {
  id: Scalars['ID'];
  title: Scalars['String'];
  gpsLocation: GeoPointInput;
  score: Scalars['Float'];
  visitDuration: Scalars['Int'];
  timeWindow: TimeWindowInput;
  isRequired: Scalars['Boolean'];
};

export enum RouteTransportType {
  Walking = 'WALKING',
  PublicTransport = 'PUBLIC_TRANSPORT',
  Driving = 'DRIVING',
}

export type Save = {
  __typename?: 'Save';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  postUuid: Scalars['ID'];
};

export type SavedPost =
  | Announcement
  | Event
  | QuickQuestion
  | UserPost
  | Landmark
  | Restaurant
  | TuiMarketItem
  | ViatorMarketItem
  | CustomMarketItem;

export type SavedPostsForInstallationFilter = {
  placeUuid?: InputMaybe<Scalars['ID']>;
  postTypes?: InputMaybe<Array<PostType>>;
};

export type SavedPostsForInstallationResponse = {
  __typename?: 'SavedPostsForInstallationResponse';
  uuids: Array<Scalars['ID']>;
  posts: Array<SavedPost>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum SavedPostsForInstallationResponseType {
  Uuids = 'UUIDS',
  Posts = 'POSTS',
}

export type ScrapeCategory = {
  __typename?: 'ScrapeCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ScrapeCategoryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum ScrapeContentType {
  Html = 'HTML',
  Json = 'JSON',
}

export enum ScrapeDataType {
  News = 'NEWS',
  Events = 'EVENTS',
  Uploader = 'UPLOADER',
}

export type ScrapeEvent = {
  __typename?: 'ScrapeEvent';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  taskCode: Scalars['String'];
  url: Scalars['String'];
  title: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  imageObjects?: Maybe<Array<Image>>;
  perexText?: Maybe<Scalars['String']>;
  bodyHtml?: Maybe<Scalars['String']>;
  originalHtml?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  contentRaw?: Maybe<Scalars['String']>;
  dateTimeFrom?: Maybe<Scalars['AWSDateTime']>;
  dateTimeTo?: Maybe<Scalars['AWSDateTime']>;
  location?: Maybe<Scalars['String']>;
  initial?: Maybe<Scalars['Boolean']>;
  dedup?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  subTypename?: Maybe<SubTypename>;
  staticCategoryUuid?: Maybe<Scalars['ID']>;
  visitorScraper?: Maybe<Scalars['Boolean']>;
  marketUrl?: Maybe<Scalars['AWSURL']>;
  marketItemPricing?: Maybe<Pricing>;
  marketItemType?: Maybe<MarketItemType>;
  dataType?: Maybe<ScrapeDataType>;
  scraperType?: Maybe<ScrapeType>;
  streamingPlatform?: Maybe<StreamingPlatform>;
  timezone?: Maybe<Scalars['String']>;
};

export type ScrapeEventDedupIndex = {
  __typename?: 'ScrapeEventDedupIndex';
  id: Scalars['ID'];
  dedup: Scalars['String'];
  dateTimeFrom?: Maybe<Scalars['AWSDateTime']>;
};

export type ScrapeEventDedupIndexInput = {
  id?: InputMaybe<Scalars['ID']>;
  dedup: Scalars['String'];
  dateTimeFrom?: InputMaybe<Scalars['AWSDateTime']>;
};

export type ScrapeEventInput = {
  id?: InputMaybe<Scalars['ID']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  taskCode: Scalars['String'];
  url: Scalars['String'];
  title: Scalars['String'];
  image?: InputMaybe<Scalars['String']>;
  imageObjects?: InputMaybe<Array<ImageInput>>;
  perexText?: InputMaybe<Scalars['String']>;
  bodyHtml?: InputMaybe<Scalars['String']>;
  originalHtml?: InputMaybe<Scalars['String']>;
  bodyText?: InputMaybe<Scalars['String']>;
  contentRaw?: InputMaybe<Scalars['String']>;
  dateTimeFrom: Scalars['AWSDateTime'];
  dateTimeTo?: InputMaybe<Scalars['AWSDateTime']>;
  location?: InputMaybe<Scalars['String']>;
  initial?: InputMaybe<Scalars['Boolean']>;
  lang: Scalars['String'];
  dedup: Scalars['String'];
  subTypename?: InputMaybe<SubTypename>;
  staticCategoryUuid?: InputMaybe<Scalars['ID']>;
  visitorScraper?: InputMaybe<Scalars['Boolean']>;
  marketUrl?: InputMaybe<Scalars['AWSURL']>;
  marketItemPricing?: InputMaybe<PricingInput>;
  marketItemType?: InputMaybe<MarketItemType>;
  dataType?: InputMaybe<ScrapeDataType>;
  scraperType?: InputMaybe<ScrapeType>;
  streamingPlatform?: InputMaybe<StreamingPlatform>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type ScrapeItemSelector = {
  __typename?: 'ScrapeItemSelector';
  field: Scalars['String'];
  selector: Array<ScrapeSingleSelector>;
};

export type ScrapeItemSelectorInput = {
  field: Scalars['String'];
  selector: Array<ScrapeSingleSelectorInput>;
};

export type ScrapeNews = {
  __typename?: 'ScrapeNews';
  id: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  taskCode: Scalars['String'];
  url: Scalars['String'];
  title: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  imageObjects?: Maybe<Array<Image>>;
  perexText?: Maybe<Scalars['String']>;
  bodyHtml?: Maybe<Scalars['String']>;
  originalHtml?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  contentRaw?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['AWSDateTime']>;
  initial?: Maybe<Scalars['Boolean']>;
  dedup?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  subTypename?: Maybe<SubTypename>;
  scrapedLocations?: Maybe<Array<NerLocation>>;
  staticCategoryUuid?: Maybe<Scalars['ID']>;
  visitorScraper?: Maybe<Scalars['Boolean']>;
  dataType?: Maybe<ScrapeDataType>;
  scraperType?: Maybe<ScrapeType>;
};

export type ScrapeNewsInput = {
  id?: InputMaybe<Scalars['ID']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  taskCode: Scalars['String'];
  url: Scalars['String'];
  title: Scalars['String'];
  image?: InputMaybe<Scalars['String']>;
  imageObjects?: InputMaybe<Array<ImageInput>>;
  perexText?: InputMaybe<Scalars['String']>;
  bodyHtml?: InputMaybe<Scalars['String']>;
  originalHtml?: InputMaybe<Scalars['String']>;
  bodyText?: InputMaybe<Scalars['String']>;
  contentRaw?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['AWSDateTime']>;
  initial?: InputMaybe<Scalars['Boolean']>;
  lang: Scalars['String'];
  dedup: Scalars['String'];
  subTypename?: InputMaybe<SubTypename>;
  scrapedLocations?: InputMaybe<Array<NerLocationInput>>;
  staticCategoryUuid?: InputMaybe<Scalars['ID']>;
  visitorScraper?: InputMaybe<Scalars['Boolean']>;
  dataType?: InputMaybe<ScrapeDataType>;
  scraperType?: InputMaybe<ScrapeType>;
};

export type ScrapeSelector = {
  __typename?: 'ScrapeSelector';
  code: Scalars['String'];
  nodeSelector: Array<Scalars['String']>;
  subpageSelector: Array<ScrapeSingleSelector>;
  subpageUrlFormat?: Maybe<Scalars['String']>;
  imports: Array<Scalars['String']>;
  variables: Array<ScrapeVariable>;
  nodeSelectors: Array<ScrapeItemSelector>;
  subpageSelectors: Array<ScrapeItemSelector>;
  removePageTrash: Array<Scalars['String']>;
  nextPageSelector: Array<ScrapeSingleSelector>;
  maxPages: Scalars['Int'];
  originalSourceOverride?: Maybe<Scalars['String']>;
  paginationType?: Maybe<PaginationType>;
};

export type ScrapeSelectorInput = {
  code: Scalars['String'];
  nodeSelector: Array<Scalars['String']>;
  subpageSelector: Array<ScrapeSingleSelectorInput>;
  subpageUrlFormat?: InputMaybe<Scalars['String']>;
  imports: Array<Scalars['String']>;
  variables: Array<ScrapeVariableInput>;
  nodeSelectors: Array<ScrapeItemSelectorInput>;
  subpageSelectors: Array<ScrapeItemSelectorInput>;
  removePageTrash: Array<Scalars['String']>;
  nextPageSelector: Array<ScrapeSingleSelectorInput>;
  maxPages: Scalars['Int'];
  originalSourceOverride?: InputMaybe<Scalars['String']>;
  paginationType?: InputMaybe<PaginationType>;
};

export type ScrapeSingleSelector = {
  __typename?: 'ScrapeSingleSelector';
  collect: Array<Scalars['String']>;
  remove: Array<Scalars['String']>;
  regex?: Maybe<Scalars['String']>;
  replace: Array<Array<Scalars['String']>>;
};

export type ScrapeSingleSelectorInput = {
  collect: Array<Scalars['String']>;
  remove: Array<Scalars['String']>;
  regex?: InputMaybe<Scalars['String']>;
  replace: Array<Array<Scalars['String']>>;
};

export type ScrapeTask = {
  __typename?: 'ScrapeTask';
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  enabledStaging?: Maybe<Scalars['Boolean']>;
  enabledTesting?: Maybe<Scalars['Boolean']>;
  dataType: ScrapeDataType;
  scraperType: ScrapeType;
  websiteTemplate?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  JSRender: Scalars['Boolean'];
  contentType: ScrapeContentType;
  subpageContentType: ScrapeContentType;
  checkCodes: Array<Scalars['String']>;
  timeZone: Scalars['String'];
  proxy: Scalars['Boolean'];
  lang: Scalars['String'];
  runSchedule?: Maybe<Scalars['String']>;
  subTypename?: Maybe<SubTypename>;
  categories?: Maybe<Array<ScrapeCategory>>;
  visitorScraper?: Maybe<Scalars['Boolean']>;
  streamingPlatform?: Maybe<StreamingPlatform>;
  muteAlerts?: Maybe<Scalars['Boolean']>;
};

export type ScrapeTaskInput = {
  code: Scalars['String'];
  enabled: Scalars['Boolean'];
  enabledStaging?: InputMaybe<Scalars['Boolean']>;
  enabledTesting?: InputMaybe<Scalars['Boolean']>;
  dataType: ScrapeDataType;
  scraperType: ScrapeType;
  websiteTemplate?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
  JSRender: Scalars['Boolean'];
  contentType: ScrapeContentType;
  subpageContentType: ScrapeContentType;
  checkCodes: Array<Scalars['String']>;
  timeZone: Scalars['String'];
  proxy: Scalars['Boolean'];
  lang: Scalars['String'];
  runSchedule?: InputMaybe<Scalars['String']>;
  subTypename?: InputMaybe<SubTypename>;
  categories?: InputMaybe<Array<ScrapeCategoryInput>>;
  visitorScraper?: InputMaybe<Scalars['Boolean']>;
  streamingPlatform?: InputMaybe<StreamingPlatform>;
  muteAlerts?: InputMaybe<Scalars['Boolean']>;
};

export enum ScrapeType {
  Website = 'WEBSITE',
  Twitter = 'TWITTER',
  Google = 'GOOGLE',
  Yelp = 'YELP',
  NycPotholes = 'NYC_POTHOLES',
  TwitterOfficial = 'TWITTER_OFFICIAL',
  WebsiteMarket = 'WEBSITE_MARKET',
}

export type ScrapeVariable = {
  __typename?: 'ScrapeVariable';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ScrapeVariableInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SearchEntriesByFilterInput = {
  date?: InputMaybe<Scalars['AWSDate']>;
  placeUuid?: InputMaybe<Scalars['ID']>;
  entryType?: InputMaybe<MainItineraryEntryType>;
  text?: InputMaybe<Scalars['String']>;
  gpsLocation?: InputMaybe<GeoPointInput>;
};

export type SearchFilterInput = {
  types?: InputMaybe<Array<SearchType>>;
  placeUuid?: InputMaybe<Scalars['ID']>;
  gpsLocation?: InputMaybe<GeoPointInput>;
  date?: InputMaybe<Scalars['AWSDate']>;
};

export type SearchItem =
  | Landmark
  | Restaurant
  | TuiMarketItem
  | CustomMarketItem
  | ViatorMarketItem
  | Event;

export type SearchResponse = {
  __typename?: 'SearchResponse';
  items: Array<SearchItem>;
  pageSize?: Maybe<Scalars['Int']>;
  pageOffset?: Maybe<Scalars['Int']>;
};

export enum SearchType {
  Landmark = 'LANDMARK',
  Restaurant = 'RESTAURANT',
  Bar = 'BAR',
  Event = 'EVENT',
  MarketItem = 'MARKET_ITEM',
}

export type SectionImages = {
  __typename?: 'SectionImages';
  attractions?: Maybe<Image>;
  museums?: Maybe<Image>;
  theatre?: Maybe<Image>;
  concerts?: Maybe<Image>;
  sports?: Maybe<Image>;
  cell?: Maybe<Image>;
};

export type SectionImagesInput = {
  attractions?: InputMaybe<ImageInput>;
  museums?: InputMaybe<ImageInput>;
  theatre?: InputMaybe<ImageInput>;
  concerts?: InputMaybe<ImageInput>;
  sports?: InputMaybe<ImageInput>;
  cell?: InputMaybe<ImageInput>;
};

export enum SegmentationLanguages {
  Sk = 'sk',
  Hi = 'hi',
  Kk = 'kk',
  Am = 'am',
  De = 'de',
  El = 'el',
  It = 'it',
  Ru = 'ru',
  Nl = 'nl',
  Zh = 'zh',
  Bg = 'bg',
  Fr = 'fr',
  Es = 'es',
  My = 'my',
  Ar = 'ar',
  Da = 'da',
  Mr = 'mr',
  Ja = 'ja',
  Pl = 'pl',
  Hy = 'hy',
  En = 'en',
  Fa = 'fa',
  Ur = 'ur',
}

export type SegmentedText = {
  __typename?: 'SegmentedText';
  sentences: Array<Scalars['String']>;
  language: SegmentationLanguages;
};

export type Service = {
  __typename?: 'Service';
  version: Scalars['String'];
};

export type SimpliMapPost =
  | UserPost
  | Announcement
  | Event
  | QuickQuestion
  | Landmark
  | Restaurant
  | TuiMarketItem
  | MapPostCluster
  | CustomMarketItem
  | ViatorMarketItem;

export type SocialLink = {
  __typename?: 'SocialLink';
  type: SocialType;
  url: Scalars['String'];
  followersCount?: Maybe<Scalars['Int']>;
};

export type SocialLinkInput = {
  type: SocialType;
  url: Scalars['String'];
  followersCount?: InputMaybe<Scalars['Int']>;
};

export type SocialProfile = {
  __typename?: 'SocialProfile';
  installationUuid: Scalars['ID'];
  username: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  socialLinks?: Maybe<Array<SocialLink>>;
  imageObjects?: Maybe<Array<Image>>;
  isFollowed?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  followersCount?: Maybe<Scalars['Int']>;
  followingCount?: Maybe<Scalars['Int']>;
  topTenCount?: Maybe<Scalars['Int']>;
};

export enum SocialType {
  Instagram = 'INSTAGRAM',
  Facebook = 'FACEBOOK',
  Youtube = 'YOUTUBE',
  Twitter = 'TWITTER',
  Tiktok = 'TIKTOK',
  Pinterest = 'PINTEREST',
  Blog = 'BLOG',
  Website = 'WEBSITE',
}

export enum SortBy {
  LikeCount = 'LIKE_COUNT',
  CreatedAt = 'CREATED_AT',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum State {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
  Scheduled = 'SCHEDULED',
  Pinned = 'PINNED',
  Removed = 'REMOVED',
}

export enum Status {
  Open = 'OPEN',
  Solved = 'SOLVED',
  Resolved = 'RESOLVED',
}

export type Story = {
  __typename?: 'Story';
  institution: Institution;
  posts: Array<Post>;
};

export enum StreamingPlatform {
  DisneyPlus = 'DISNEY_PLUS',
  HboMax = 'HBO_MAX',
  Netflix = 'NETFLIX',
  AppleTv = 'APPLE_TV',
  AmazonPrime = 'AMAZON_PRIME',
  MovieRelease = 'MOVIE_RELEASE',
}

export enum SubTypename {
  NewBusiness = 'NEW_BUSINESS',
  ReportedProblem = 'REPORTED_PROBLEM',
}

export type Subscription = {
  __typename?: 'Subscription';
  subscribeToNewPushNotification?: Maybe<Scalars['String']>;
  subscribeToChatGptAnswerStream?: Maybe<ChatGptAnswerStreamResponse>;
  subscribeToChatGptAnswerStreamWidget?: Maybe<ChatGptAnswerStreamWidgetResponse>;
};

export type SubscriptionSubscribeToNewPushNotificationArgs = {
  installationUuid?: InputMaybe<Scalars['ID']>;
};

export type SubscriptionSubscribeToChatGptAnswerStreamArgs = {
  installationUuid: Scalars['ID'];
  topic?: InputMaybe<ChatGptTopic>;
  institutionUuid?: InputMaybe<Scalars['ID']>;
  landmarkUuid?: InputMaybe<Scalars['ID']>;
  chatIdentifier?: InputMaybe<ChatIdentifierInput>;
};

export type SubscriptionSubscribeToChatGptAnswerStreamWidgetArgs = {
  installationUuid: Scalars['ID'];
};

export enum SubscriptionFeature {
  Attractions = 'ATTRACTIONS',
  Chat = 'CHAT',
  Itinerary = 'ITINERARY',
  AiSearch = 'AI_SEARCH',
}

export type SuggestedLicensePlates = {
  __typename?: 'SuggestedLicensePlates';
  lastUsed: Array<LicensePlate>;
  saved: Array<LicensePlate>;
};

export type SuggestedLicensePlatesResponse = {
  __typename?: 'SuggestedLicensePlatesResponse';
  licensePlates: SuggestedLicensePlates;
};

export type SuperAdminMessageInput = {
  title: Scalars['String'];
  body: Scalars['String'];
  channel: Channel;
  deeplink?: InputMaybe<Scalars['String']>;
  postUuid?: InputMaybe<Scalars['String']>;
  notifyVolunteersOnly?: InputMaybe<Scalars['Boolean']>;
  notifyAlsoNonCategoried?: InputMaybe<Scalars['Boolean']>;
  categoryUuids?: InputMaybe<Array<Scalars['String']>>;
};

export type SuperAdminNotificationFilterInput = {
  institutionUuid?: InputMaybe<Scalars['String']>;
};

export enum SupportedLanguages {
  En = 'en',
  Uk = 'uk',
  Ru = 'ru',
  Sk = 'sk',
  Es = 'es',
  Fil = 'fil',
  Ar = 'ar',
  Fr = 'fr',
  Ur = 'ur',
  Bn = 'bn',
  Ko = 'ko',
  Pl = 'pl',
  Zh = 'zh',
  ZhTw = 'zh_TW',
  ZhCn = 'zh_CN',
  Ht = 'ht',
  Pt = 'pt',
  De = 'de',
  It = 'it',
  Hu = 'hu',
}

export type TuiBookingRequirements = {
  __typename?: 'TUIBookingRequirements';
  groupSize: Array<TuiGroupSize>;
  hasPassengerInfo?: Maybe<Scalars['Boolean']>;
  hasExtraCustomerData?: Maybe<Scalars['Boolean']>;
};

export type TuiBookingRequirementsInput = {
  groupSize: Array<TuiGroupSizeInput>;
  hasPassengerInfo?: InputMaybe<Scalars['Boolean']>;
  hasExtraCustomerData?: InputMaybe<Scalars['Boolean']>;
};

export enum TuiBookingType {
  CalendarNoTimeslots = 'CALENDAR_NO_TIMESLOTS',
  CalendarTimeslots = 'CALENDAR_TIMESLOTS',
  NoCalendarFixedEnd = 'NO_CALENDAR_FIXED_END',
  NoCalendarFixedValidity = 'NO_CALENDAR_FIXED_VALIDITY',
}

export type TuiGroupSize = {
  __typename?: 'TUIGroupSize';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type TuiGroupSizeInput = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type TuiMarketItem = IMarketItem & {
  __typename?: 'TUIMarketItem';
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  createdByAdmin?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  placeUuid: Scalars['ID'];
  simplicityDiscountPercentage: Scalars['Float'];
  categories: Array<Category>;
  lang: Scalars['String'];
  title: Scalars['String'];
  typename: MarketItemType;
  content?: Maybe<Scalars['String']>;
  imageObjects?: Maybe<Array<Image>>;
  duration?: Maybe<Scalars['Int']>;
  inclusions?: Maybe<Array<Scalars['String']>>;
  exclusions?: Maybe<Array<Scalars['String']>>;
  additionalInfo?: Maybe<Array<Scalars['String']>>;
  gpsLocation?: Maybe<GeoPoint>;
  textLocation?: Maybe<Scalars['String']>;
  meetingPoint?: Maybe<Scalars['String']>;
  price?: Maybe<MarketItemPrice>;
  marketUrl?: Maybe<Scalars['AWSURL']>;
  externalId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  reviewsCount?: Maybe<Scalars['Int']>;
  externalCategories: Array<Scalars['String']>;
  cancellationPolicy?: Maybe<MarketItemCancellationPolicy>;
  supplier?: Maybe<Scalars['String']>;
  reviewsAggregatedInfo?: Maybe<TuiReviewsAggregatedInfo>;
  bookingRequirements?: Maybe<TuiBookingRequirements>;
  hasMultipleOptions?: Maybe<Scalars['Boolean']>;
  voucherUsage?: Maybe<TuiVoucherUsage>;
  maxConfirmationTime?: Maybe<Scalars['String']>;
  bookingType?: Maybe<TuiBookingType>;
  refundPolicies?: Maybe<Array<TuiRefundPolicy>>;
  safetyInformation?: Maybe<Array<TuiSafetyInformation>>;
  imageUrls?: Maybe<Array<Scalars['String']>>;
  save?: Maybe<Save>;
  open?: Maybe<Scalars['Boolean']>;
  ignore?: Maybe<Scalars['Boolean']>;
  topPick?: Maybe<Scalars['Boolean']>;
  travelDistanceDuration?: Maybe<TravelDistanceDuration>;
  translations?: Maybe<AttractionTranslations>;
  internalLabels?: Maybe<Array<InternalLabel>>;
};

export type TuiMarketItemTravelDistanceDurationArgs = {
  startLocation?: InputMaybe<GeoPointInput>;
};

export type TuiMarketItemTranslationsArgs = {
  lang?: InputMaybe<Scalars['String']>;
};

export type TuiMarketItemInput = {
  createdByAdmin?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  placeUuid: Scalars['ID'];
  simplicityDiscountPercentage?: InputMaybe<Scalars['Float']>;
  categoryUuids: Array<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  typename: MarketItemType;
  content?: InputMaybe<Scalars['String']>;
  imageObjects?: InputMaybe<Array<ImageInput>>;
  duration?: InputMaybe<Scalars['Int']>;
  inclusions?: InputMaybe<Array<Scalars['String']>>;
  exclusions?: InputMaybe<Array<Scalars['String']>>;
  additionalInfo?: InputMaybe<Array<Scalars['String']>>;
  gpsLocation?: InputMaybe<GeoPointInput>;
  textLocation?: InputMaybe<Scalars['String']>;
  meetingPoint?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<MarketItemPriceInput>;
  marketUrl?: InputMaybe<Scalars['AWSURL']>;
  externalId?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Float']>;
  reviewsCount?: InputMaybe<Scalars['Int']>;
  externalCategories: Array<Scalars['String']>;
  cancellationPolicy?: InputMaybe<MarketItemCancellationPolicyInput>;
  supplier?: InputMaybe<Scalars['String']>;
  reviewsAggregatedInfo?: InputMaybe<TuiReviewsAggregatedInfoInput>;
  bookingRequirements?: InputMaybe<TuiBookingRequirementsInput>;
  hasMultipleOptions?: InputMaybe<Scalars['Boolean']>;
  voucherUsage?: InputMaybe<TuiVoucherUsage>;
  maxConfirmationTime?: InputMaybe<Scalars['String']>;
  bookingType?: InputMaybe<TuiBookingType>;
  refundPolicies?: InputMaybe<Array<TuiRefundPolicyInput>>;
  safetyInformation?: InputMaybe<Array<TuiSafetyInformationInput>>;
  imageUrls?: InputMaybe<Array<Scalars['String']>>;
  open?: InputMaybe<Scalars['Boolean']>;
  ignore?: InputMaybe<Scalars['Boolean']>;
  topPick?: InputMaybe<Scalars['Boolean']>;
  weightedAverageRating?: InputMaybe<Scalars['Float']>;
  internalLabels?: InputMaybe<Array<InternalLabel>>;
};

export type TuiMarketPaymentValues = {
  id: Scalars['String'];
  currency?: InputMaybe<Currency>;
  date: Scalars['String'];
  featureCode: Scalars['String'];
  pickupUuid: Scalars['String'];
  selectedProducts: Array<TuiProduct>;
  time?: InputMaybe<Scalars['String']>;
};

export type TuiProduct = {
  productId: Scalars['String'];
  amount: Scalars['Int'];
  holderCode?: InputMaybe<Scalars['String']>;
};

export type TuiRefundDetail = {
  __typename?: 'TUIRefundDetail';
  createdAt: Scalars['AWSDateTime'];
  amount: Scalars['String'];
  currency: Currency;
  refundPolicy: PaymentTuiRefundPolicy;
};

export type TuiRefundPolicy = {
  __typename?: 'TUIRefundPolicy';
  period: Scalars['String'];
  type: TuiRefundType;
  value: Scalars['Float'];
};

export type TuiRefundPolicyInput = {
  period: Scalars['String'];
  type: TuiRefundType;
  value: Scalars['Float'];
};

export enum TuiRefundType {
  Absolute = 'ABSOLUTE',
  Percentage = 'PERCENTAGE',
}

export type TuiReviewsAggregatedInfo = {
  __typename?: 'TUIReviewsAggregatedInfo';
  zero?: Maybe<Scalars['Int']>;
  one?: Maybe<Scalars['Int']>;
  two?: Maybe<Scalars['Int']>;
  three?: Maybe<Scalars['Int']>;
  four?: Maybe<Scalars['Int']>;
  five?: Maybe<Scalars['Int']>;
};

export type TuiReviewsAggregatedInfoInput = {
  zero?: InputMaybe<Scalars['Int']>;
  one?: InputMaybe<Scalars['Int']>;
  two?: InputMaybe<Scalars['Int']>;
  three?: InputMaybe<Scalars['Int']>;
  four?: InputMaybe<Scalars['Int']>;
  five?: InputMaybe<Scalars['Int']>;
};

export type TuiSafetyInformation = {
  __typename?: 'TUISafetyInformation';
  isActive: Scalars['Boolean'];
  slug: Scalars['String'];
};

export type TuiSafetyInformationInput = {
  isActive: Scalars['Boolean'];
  slug: Scalars['String'];
};

export enum TuiVoucherUsage {
  Mobile = 'MOBILE',
  Mixed = 'MIXED',
  Printed = 'PRINTED',
}

export enum TextDetectionImageType {
  ParkingTicket = 'PARKING_TICKET',
}

export type TextDetectionResult = ParkingTicketDetectionResult;

export enum TicketmasterCountryCode {
  Ae = 'AE',
  At = 'AT',
  Au = 'AU',
  Be = 'BE',
  Ca = 'CA',
  Ch = 'CH',
  Cz = 'CZ',
  De = 'DE',
  Dk = 'DK',
  Es = 'ES',
  Fi = 'FI',
  Fr = 'FR',
  Gb = 'GB',
  Ie = 'IE',
  It = 'IT',
  Mx = 'MX',
  Nl = 'NL',
  No = 'NO',
  Nz = 'NZ',
  Pl = 'PL',
  Se = 'SE',
  Tr = 'TR',
  Us = 'US',
  Za = 'ZA',
}

export type TicketmasterSettings = {
  __typename?: 'TicketmasterSettings';
  placeName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<TicketmasterCountryCode>;
  institutionUuid?: Maybe<Scalars['ID']>;
  stateCode?: Maybe<Scalars['String']>;
};

export type TicketmasterSettingsInput = {
  placeName?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<TicketmasterCountryCode>;
  institutionUuid?: InputMaybe<Scalars['ID']>;
};

export type TimeWindowInput = {
  start: Scalars['Int'];
  end: Scalars['Int'];
};

export type TimezoneCodes = {
  __typename?: 'TimezoneCodes';
  timezoneCodes: Array<Scalars['String']>;
};

export type TitleLocalization = {
  __typename?: 'TitleLocalization';
  title: Scalars['String'];
  language: Scalars['String'];
};

export type TitleLocalizationInput = {
  title: Scalars['String'];
  language: Scalars['String'];
};

export type TopPosts = {
  __typename?: 'TopPosts';
  placeUuid?: Maybe<Scalars['ID']>;
  institutionUuid?: Maybe<Scalars['ID']>;
  posts: Array<Post>;
};

export type TopTen = {
  __typename?: 'TopTen';
  id: Scalars['ID'];
  installationUuid: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  installationProfile: SocialProfile;
  imageObjects: Array<Image>;
  views?: Maybe<Scalars['Int']>;
  placeUuid: Scalars['ID'];
  place?: Maybe<Place>;
  topTenItems: Array<TopTenItem>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  translations?: Maybe<TopTenTranslations>;
};

export type TopTenTranslationsArgs = {
  lang?: InputMaybe<Scalars['String']>;
};

export type TopTenInput = {
  id?: InputMaybe<Scalars['ID']>;
  installationUuid: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  placeUuid: Scalars['ID'];
  imageObjects?: InputMaybe<Array<ImageInput>>;
  topTenItems: Array<TopTenItemInput>;
  deleted?: InputMaybe<Scalars['Boolean']>;
};

export type TopTenItem = {
  __typename?: 'TopTenItem';
  itemUuid: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  typename: TopTenType;
  item: ExternalItem;
};

export type TopTenItemInput = {
  itemUuid: Scalars['ID'];
  typename: TopTenType;
  description?: InputMaybe<Scalars['String']>;
};

export type TopTenItemTranslations = {
  __typename?: 'TopTenItemTranslations';
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  itemUuid: Scalars['ID'];
};

export type TopTenLikeResponse = {
  __typename?: 'TopTenLikeResponse';
  topTenUuid: Scalars['ID'];
  installationUuid: Scalars['ID'];
  success: Scalars['Boolean'];
};

export type TopTenTranslations = {
  __typename?: 'TopTenTranslations';
  description?: Maybe<Scalars['String']>;
  topTenItems: Array<TopTenItemTranslations>;
};

export enum TopTenType {
  Landmark = 'LANDMARK',
  Restaurant = 'RESTAURANT',
  Bar = 'BAR',
  GoogleItem = 'GOOGLE_ITEM',
}

export type TourEntry = {
  __typename?: 'TourEntry';
  id: Scalars['ID'];
  type: MainItineraryEntryType;
  placeUuid: Scalars['ID'];
  itemUuid: Scalars['ID'];
  title: Scalars['String'];
  content: Scalars['String'];
  name: Scalars['String'];
  imageObjects?: Maybe<Array<Image>>;
  address?: Maybe<Scalars['String']>;
  gpsLocation: GeoPoint;
  rating?: Maybe<Scalars['Float']>;
  userRatingCount?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  price?: Maybe<MarketItemPrice>;
  priceLevel?: Maybe<ItineraryPriceLevel>;
  simplicityDiscountPercentage: Scalars['Float'];
  company?: Maybe<Array<ItineraryCompany>>;
  mood?: Maybe<Array<EntryMood>>;
  suitableWeather?: Maybe<Array<WeatherType>>;
  additionalCategories?: Maybe<Array<Scalars['String']>>;
  externalCategories?: Maybe<Array<Scalars['String']>>;
  marketUrl?: Maybe<Scalars['AWSURL']>;
};

export type TourItineraryEntry = {
  __typename?: 'TourItineraryEntry';
  id: Scalars['ID'];
  type: MainItineraryEntryType;
  placeUuid: Scalars['ID'];
  itemUuid: Scalars['ID'];
  title: Scalars['String'];
  content: Scalars['String'];
  name: Scalars['String'];
  daytime: Daytime;
  customDaytime?: Maybe<Daytime>;
  imageObjects?: Maybe<Array<Image>>;
  address: Scalars['String'];
  gpsLocation: GeoPoint;
  rating?: Maybe<Scalars['Float']>;
  userRatingCount?: Maybe<Scalars['Int']>;
  price?: Maybe<MarketItemPrice>;
  priceLevel?: Maybe<ItineraryPriceLevel>;
  simplicityDiscountPercentage: Scalars['Float'];
  startTime: Scalars['AWSDateTime'];
  endTime: Scalars['AWSDateTime'];
  travelDistanceDuration?: Maybe<TravelDistanceDuration>;
  marketUrl?: Maybe<Scalars['AWSURL']>;
  save?: Maybe<Save>;
};

export type TourItineraryEntryTravelDistanceDurationArgs = {
  startLocation?: InputMaybe<GeoPointInput>;
};

export type TransitNearbyStop = {
  __typename?: 'TransitNearbyStop';
  locationType?: Maybe<Scalars['Int']>;
  globalStopId?: Maybe<Scalars['String']>;
  parentStationGlobalStopId?: Maybe<Scalars['String']>;
  stopName: Scalars['String'];
  stopPoint: GeoPoint;
  stopCode?: Maybe<Scalars['String']>;
  rtStopId?: Maybe<Scalars['String']>;
  routeType?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['Float']>;
  wheelchairBoarding?: Maybe<Scalars['Int']>;
};

export type TransitNearbyStops = {
  __typename?: 'TransitNearbyStops';
  stops: Array<TransitNearbyStop>;
};

export type TransitPlanAgency = {
  __typename?: 'TransitPlanAgency';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['AWSURL']>;
  timeZoneOffset?: Maybe<Scalars['AWSTimestamp']>;
};

export type TransitPlanItinerary = {
  __typename?: 'TransitPlanItinerary';
  startTimestamp?: Maybe<Scalars['AWSTimestamp']>;
  endTimestamp?: Maybe<Scalars['AWSTimestamp']>;
  duration?: Maybe<Scalars['Int']>;
  transfers?: Maybe<Scalars['Int']>;
  transitTime?: Maybe<Scalars['Int']>;
  walkTime?: Maybe<Scalars['Int']>;
  wheelchairNeed?: Maybe<TransitPlanItineraryAccessibility>;
  accessibility?: Maybe<TransitPlanItineraryAccessibility>;
  legs: Array<TransitPlanItineraryLeg>;
};

export enum TransitPlanItineraryAccessibility {
  None = 'None',
  WheelchairTripsWithUnknownStops = 'WheelchairTripsWithUnknownStops',
  WheelchairStrict = 'WheelchairStrict',
}

export type TransitPlanItineraryLeg = {
  __typename?: 'TransitPlanItineraryLeg';
  mode?: Maybe<TransitPlanMode>;
  agency?: Maybe<TransitPlanAgency>;
  route?: Maybe<TransitPlanRoute>;
  trip?: Maybe<TransitPlanTrip>;
  from?: Maybe<TransitPlanPlace>;
  to?: Maybe<TransitPlanPlace>;
  startTime: Scalars['AWSTimestamp'];
  endTime: Scalars['AWSTimestamp'];
  duration: Scalars['Int'];
  realTime?: Maybe<Scalars['Boolean']>;
  departureDelay?: Maybe<Scalars['Int']>;
  arrivalDelay?: Maybe<Scalars['Int']>;
  distance: Scalars['Float'];
  headsign?: Maybe<Scalars['String']>;
  interlineWithPreviousLeg?: Maybe<Scalars['Boolean']>;
  intermediateStops?: Maybe<Array<TransitPlanPlace>>;
  geometry?: Maybe<TransitPlanItineraryLegGeometry>;
};

export type TransitPlanItineraryLegGeometry = {
  __typename?: 'TransitPlanItineraryLegGeometry';
  length?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['String']>;
};

export type TransitPlanLocationInput = {
  address?: InputMaybe<Scalars['String']>;
  gpsLocation?: InputMaybe<GeoPointInput>;
};

export enum TransitPlanMode {
  Transit = 'TRANSIT',
  Walk = 'WALK',
  Bicycle = 'BICYCLE',
  BicycleRent = 'BICYCLE_RENT',
  Tram = 'TRAM',
  Bus = 'BUS',
  Rail = 'RAIL',
  Subway = 'SUBWAY',
  Ferry = 'FERRY',
  Gondola = 'GONDOLA',
  Wait = 'WAIT',
}

export type TransitPlanPlace = {
  __typename?: 'TransitPlanPlace';
  point: GeoPoint;
  name?: Maybe<Scalars['String']>;
  globalStopId?: Maybe<Scalars['String']>;
  stopId?: Maybe<Scalars['String']>;
  stopCode?: Maybe<Scalars['String']>;
  stopIndex?: Maybe<Scalars['Int']>;
  vertexType?: Maybe<TransitPlanPlaceVertexType>;
};

export enum TransitPlanPlaceVertexType {
  Transit = 'TRANSIT',
  Normal = 'NORMAL',
}

export type TransitPlanResponse = {
  __typename?: 'TransitPlanResponse';
  plan: TransitPlanResponsePlan;
};

export type TransitPlanResponsePlan = {
  __typename?: 'TransitPlanResponsePlan';
  date?: Maybe<Scalars['AWSTimestamp']>;
  from?: Maybe<TransitPlanPlace>;
  to?: Maybe<TransitPlanPlace>;
  itineraries: Array<TransitPlanItinerary>;
};

export type TransitPlanRoute = {
  __typename?: 'TransitPlanRoute';
  globalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  shortName?: Maybe<Scalars['String']>;
  longName?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type TransitPlanTrip = {
  __typename?: 'TransitPlanTrip';
  id?: Maybe<Scalars['String']>;
  blockId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  searchKey?: Maybe<Scalars['String']>;
};

export type TransitSearchStop = {
  __typename?: 'TransitSearchStop';
  locationType?: Maybe<Scalars['Int']>;
  globalStopId?: Maybe<Scalars['String']>;
  parentStationGlobalStopId?: Maybe<Scalars['String']>;
  stopName: Scalars['String'];
  stopPoint: GeoPoint;
  routeType?: Maybe<Scalars['Int']>;
  matchStrength?: Maybe<Scalars['Float']>;
};

export type TransitSearchStops = {
  __typename?: 'TransitSearchStops';
  results: Array<TransitSearchStop>;
};

export type TranslateInput = {
  id: Scalars['ID'];
  type: TranslateType;
  lang: SupportedLanguages;
};

export enum TranslateType {
  Event = 'EVENT',
  Landmark = 'LANDMARK',
  Restaurant = 'RESTAURANT',
  Attraction = 'ATTRACTION',
  GoogleItem = 'GOOGLE_ITEM',
  GooglePlaceDetail = 'GOOGLE_PLACE_DETAIL',
  TopTen = 'TOP_TEN',
}

/**   Text translated in specific language */
export type Translation = {
  __typename?: 'Translation';
  /**   use ISO standard, please! */
  lang: Scalars['String'];
  text: Scalars['String'];
};

/**   Define text translation in specific language */
export type TranslationInput = {
  /**   use ISO standard, please! */
  lang: Scalars['String'];
  text: Scalars['String'];
};

export type Translations =
  | EventTranslations
  | LandmarkTranslations
  | RestaurantTranslations
  | AttractionTranslations
  | GoogleItemTranslations
  | GooglePlaceDetailTranslations
  | TopTenTranslations;

export type TravelDistanceDuration = {
  __typename?: 'TravelDistanceDuration';
  distance: Scalars['Float'];
  /**   in KM */
  walkingDuration: Scalars['Int'];
  /**   in minutes */
  drivingDuration: Scalars['Int'];
};

export enum TravelerAgeBand {
  Adult = 'ADULT',
  Senior = 'SENIOR',
  Youth = 'YOUTH',
  Child = 'CHILD',
  Infant = 'INFANT',
  Traveler = 'TRAVELER',
}

export type TripPlanInput = {
  placeUuid: Scalars['ID'];
  date?: InputMaybe<TripPlanInputDates>;
  people: TripPlanInputPeople;
  budget: TripPlanInputBudget;
};

export enum TripPlanInputBudget {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
}

export type TripPlanInputDates = {
  from: Scalars['AWSDateTime'];
  to: Scalars['AWSDateTime'];
};

export type TripPlanInputPeople = {
  count: Scalars['Int'];
  type?: InputMaybe<TripPlanInputPeopleType>;
};

export enum TripPlanInputPeopleType {
  Couple = 'COUPLE',
  Friends = 'FRIENDS',
  Family = 'FAMILY',
}

export type TripPlanItinerary = {
  __typename?: 'TripPlanItinerary';
  itinerary: Array<TripPlanItineraryEntry>;
  pageSize?: Maybe<Scalars['Int']>;
  pageOffset?: Maybe<Scalars['Int']>;
};

export type TripPlanItineraryEntry = {
  __typename?: 'TripPlanItineraryEntry';
  date: Scalars['AWSDateTime'];
  dayNumber: Scalars['Int'];
  posts: Array<TripPlanPost>;
};

export type TripPlanItineraryV2 = {
  __typename?: 'TripPlanItineraryV2';
  id: Scalars['ID'];
  installationUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  startDate: Scalars['AWSDateTime'];
  endDate: Scalars['AWSDateTime'];
  entries: Array<TripPlanItineraryEntry>;
};

export type TripPlanPlace = {
  __typename?: 'TripPlanPlace';
  id: Scalars['ID'];
  name: Scalars['String'];
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
  branding?: Maybe<BrandingPlace>;
};

export type TripPlanPlaces = {
  __typename?: 'TripPlanPlaces';
  places: Array<TripPlanPlace>;
};

export type TripPlanPost = Event | Landmark | Restaurant | TuiMarketItem;

export type TripPlanSuggestions = {
  __typename?: 'TripPlanSuggestions';
  posts: Array<TripPlanPost>;
};

export type TripProps = {
  __typename?: 'TripProps';
  tripFrom: Scalars['AWSDateTime'];
  tripTo: Scalars['AWSDateTime'];
  companionType?: Maybe<CompanionType>;
  placeNameOrigin?: Maybe<Scalars['String']>;
};

export type TripPropsInput = {
  tripFrom: Scalars['AWSDateTime'];
  tripTo: Scalars['AWSDateTime'];
  companionType?: InputMaybe<CompanionType>;
  placeNameOrigin?: InputMaybe<Scalars['String']>;
};

export type TwitterRequestTokenResponse = {
  __typename?: 'TwitterRequestTokenResponse';
  token: Scalars['String'];
};

export enum Type {
  Idea = 'IDEA',
  Issue = 'ISSUE',
  Problem = 'PROBLEM',
}

export type UpdateItineraryInput = {
  daytime: Daytime;
  ids: Array<Scalars['String']>;
};

export type UploadField = {
  __typename?: 'UploadField';
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum UploadMethod {
  Post = 'POST',
  Put = 'PUT',
}

export enum UploadType {
  Wall = 'WALL',
  News = 'NEWS',
  Announcement = 'ANNOUNCEMENT',
  Event = 'EVENT',
  Coupon = 'COUPON',
  Place = 'PLACE',
  PlaceAvatar = 'PLACE_AVATAR',
  PlaceBackground = 'PLACE_BACKGROUND',
  PlaceLogo = 'PLACE_LOGO',
  PlaceTopTenBackground = 'PLACE_TOP_TEN_BACKGROUND',
  Institution = 'INSTITUTION',
  InstitutionAvatar = 'INSTITUTION_AVATAR',
  InstitutionLogo = 'INSTITUTION_LOGO',
  Report = 'REPORT',
  CommunityProject = 'COMMUNITY_PROJECT',
  Pet = 'PET',
  FeatureFlagIcons = 'FEATURE_FLAG_ICONS',
  Video = 'VIDEO',
  VideoMessaging = 'VIDEO_MESSAGING',
  PaymentDocument = 'PAYMENT_DOCUMENT',
  ReminderCategoryIcons = 'REMINDER_CATEGORY_ICONS',
  MarketItem = 'MARKET_ITEM',
  Influencer = 'INFLUENCER',
  Badge = 'BADGE',
  Restaurant = 'RESTAURANT',
  GoogleItem = 'GOOGLE_ITEM',
  TopTen = 'TOP_TEN',
  Landmark = 'LANDMARK',
  ChatMessage = 'CHAT_MESSAGE',
  Celebrity = 'CELEBRITY',
  PreferenceCard = 'PREFERENCE_CARD',
  InstallationProfile = 'INSTALLATION_PROFILE',
  PlaceDashboardImages = 'PLACE_DASHBOARD_IMAGES',
}

export type UploadUrl = {
  __typename?: 'UploadUrl';
  id: Scalars['String'];
  url: Scalars['AWSURL'];
  /**   presigned PUT url for 5 minutes */
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<UploadField>>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email: Scalars['AWSEmail'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  tokens?: Maybe<NotificationTokens>;
  notificationsDisabled?: Maybe<Scalars['Boolean']>;
};

export type UserCardPreferencesInput = {
  likes: Array<Scalars['ID']>;
  dislikes: Array<Scalars['ID']>;
};

export type UserCardPreferencesResponse = {
  __typename?: 'UserCardPreferencesResponse';
  installationUuid: Scalars['ID'];
  likes: Array<Scalars['ID']>;
  dislikes: Array<Scalars['ID']>;
  summary?: Maybe<Scalars['String']>;
  status: ResultStatus;
};

export type UserInput = {
  id?: InputMaybe<Scalars['ID']>;
  email: Scalars['AWSEmail'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  tokens?: InputMaybe<NotificationTokensInput>;
  notificationsDisabled?: InputMaybe<Scalars['Boolean']>;
};

export enum UserInteractionType {
  PostSave = 'POST_SAVE',
  ReportVote = 'REPORT_VOTE',
}

export type UserModel = {
  __typename?: 'UserModel';
  installationUuid: Scalars['ID'];
  postInterestVector: Array<Scalars['Float']>;
  eventInterestVector?: Maybe<Array<Scalars['Float']>>;
};

export type UserPost = {
  __typename?: 'UserPost';
  id: Scalars['ID'];
  typename?: Maybe<PostType>;
  installationUuid: Scalars['ID'];
  institutionUuid?: Maybe<Scalars['ID']>;
  institution?: Maybe<Institution>;
  places: Array<Place>;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  publishedAt?: Maybe<Scalars['AWSDateTime']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageObjects?: Maybe<Array<Image>>;
  videoObjects?: Maybe<Array<Video>>;
  title: Scalars['String'];
  content: Scalars['String'];
  lang: Scalars['String'];
  gpsLocation?: Maybe<GeoPoint>;
  coverVideo?: Maybe<Scalars['AWSURL']>;
  onlyForMap?: Maybe<Scalars['Boolean']>;
  publishmentState: State;
  save?: Maybe<Save>;
};

export type UserPostInput = {
  id?: InputMaybe<Scalars['ID']>;
  installationUuid: Scalars['ID'];
  placeUuids: Array<Scalars['ID']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageObjects?: InputMaybe<Array<ImageInput>>;
  videoObjects?: InputMaybe<Array<VideoInput>>;
  title: Scalars['String'];
  content: Scalars['String'];
  lang: Scalars['String'];
  coverVideo?: InputMaybe<Scalars['AWSURL']>;
};

export type UserPreferenceCard = {
  __typename?: 'UserPreferenceCard';
  id: Scalars['ID'];
  type: Scalars['String'];
  title: Scalars['String'];
  content: Scalars['String'];
  imageObjects: Array<Image>;
  relatedCards?: Maybe<Array<UserPreferenceCard>>;
  priceLevel?: Maybe<ItineraryPriceLevel>;
  tags?: Maybe<Array<Scalars['String']>>;
  events?: Maybe<Array<Scalars['String']>>;
  attractions?: Maybe<Array<Scalars['String']>>;
  landmarks?: Maybe<Array<Scalars['String']>>;
};

export type UserPreferenceCardInput = {
  id?: InputMaybe<Scalars['ID']>;
  placeUuid: Scalars['ID'];
  type: Scalars['String'];
  title: Scalars['String'];
  content: Scalars['String'];
  imageObjects: Array<ImageInput>;
  cardUuids?: InputMaybe<Array<Scalars['ID']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  priceLevel?: InputMaybe<ItineraryPriceLevel>;
  events?: InputMaybe<Array<Scalars['String']>>;
  attractions?: InputMaybe<Array<Scalars['String']>>;
  landmarks?: InputMaybe<Array<Scalars['String']>>;
  translations?: InputMaybe<Scalars['AWSJSON']>;
};

export type UserPreferenceCardsResponse = {
  __typename?: 'UserPreferenceCardsResponse';
  cards: Array<UserPreferenceCard>;
};

export type UserRatingLimits = {
  __typename?: 'UserRatingLimits';
  /**    last skip time was at least lastSkipMoreThan days ago */
  lastSkipMoreThan?: Maybe<Scalars['Int']>;
  /**   skipped it no more than maxSkippedUserRating */
  maxSkippedUserRating?: Maybe<Scalars['Int']>;
  /**   user opened App at least minTimesAppOpened times */
  minTimesAppOpened?: Maybe<Scalars['Int']>;
  /**   user spent in current session at least minDurationCurrentUserSession minutes */
  minDurationCurrentUserSession?: Maybe<Scalars['Int']>;
  /**   user viewed in current session at least minCurrentSessionViewedPages pages */
  minCurrentSessionViewedPages?: Maybe<Scalars['Int']>;
};

export type ViatorBookingRequirements = {
  __typename?: 'ViatorBookingRequirements';
  minTravelersPerBooking: Scalars['Int'];
  maxTravelersPerBooking: Scalars['Int'];
  requiresAdultForBooking: Scalars['Boolean'];
};

export type ViatorBookingRequirementsInput = {
  minTravelersPerBooking: Scalars['Int'];
  maxTravelersPerBooking: Scalars['Int'];
  requiresAdultForBooking: Scalars['Boolean'];
};

export type ViatorLanguageGuide = {
  __typename?: 'ViatorLanguageGuide';
  type: Scalars['String'];
  language: Scalars['String'];
  legacyGuide?: Maybe<Scalars['String']>;
};

export type ViatorLanguageGuideInput = {
  type: Scalars['String'];
  language: Scalars['String'];
  legacyGuide?: InputMaybe<Scalars['String']>;
};

export type ViatorLogistics = {
  __typename?: 'ViatorLogistics';
  startDescriptions?: Maybe<Array<Scalars['String']>>;
  endDescriptions?: Maybe<Array<Scalars['String']>>;
  travelerPickup?: Maybe<ViatorTravelerPickup>;
};

export type ViatorLogisticsInput = {
  startDescriptions?: InputMaybe<Array<Scalars['String']>>;
  endDescriptions?: InputMaybe<Array<Scalars['String']>>;
  travelerPickup?: InputMaybe<ViatorTravelerPickupInput>;
};

export type ViatorMarketItem = IMarketItem & {
  __typename?: 'ViatorMarketItem';
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  createdByAdmin?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  placeUuid: Scalars['ID'];
  simplicityDiscountPercentage: Scalars['Float'];
  categories: Array<Category>;
  lang: Scalars['String'];
  title: Scalars['String'];
  typename: MarketItemType;
  content?: Maybe<Scalars['String']>;
  imageObjects?: Maybe<Array<Image>>;
  duration?: Maybe<Scalars['Int']>;
  inclusions?: Maybe<Array<Scalars['String']>>;
  exclusions?: Maybe<Array<Scalars['String']>>;
  additionalInfo?: Maybe<Array<Scalars['String']>>;
  gpsLocation?: Maybe<GeoPoint>;
  textLocation?: Maybe<Scalars['String']>;
  meetingPoint?: Maybe<Scalars['String']>;
  price?: Maybe<MarketItemPrice>;
  marketUrl?: Maybe<Scalars['AWSURL']>;
  externalId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  reviewsCount?: Maybe<Scalars['Int']>;
  externalCategories: Array<Scalars['String']>;
  cancellationPolicy?: Maybe<MarketItemCancellationPolicy>;
  bookingRequirements?: Maybe<ViatorBookingRequirements>;
  bookingQuestions?: Maybe<Array<Scalars['String']>>;
  logistics?: Maybe<ViatorLogistics>;
  productOptions?: Maybe<Array<ViatorProductOption>>;
  save?: Maybe<Save>;
  /** @deprecated For app compatibility */
  bookingType?: Maybe<TuiBookingType>;
  ignore?: Maybe<Scalars['Boolean']>;
  topPick?: Maybe<Scalars['Boolean']>;
  travelDistanceDuration?: Maybe<TravelDistanceDuration>;
  translations?: Maybe<AttractionTranslations>;
  internalLabels?: Maybe<Array<InternalLabel>>;
};

export type ViatorMarketItemTravelDistanceDurationArgs = {
  startLocation?: InputMaybe<GeoPointInput>;
};

export type ViatorMarketItemTranslationsArgs = {
  lang?: InputMaybe<Scalars['String']>;
};

export type ViatorMarketItemInput = {
  createdByAdmin?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  placeUuid: Scalars['ID'];
  simplicityDiscountPercentage?: InputMaybe<Scalars['Float']>;
  categoryUuids: Array<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  typename: MarketItemType;
  content?: InputMaybe<Scalars['String']>;
  imageObjects?: InputMaybe<Array<ImageInput>>;
  duration?: InputMaybe<Scalars['Int']>;
  inclusions?: InputMaybe<Array<Scalars['String']>>;
  exclusions?: InputMaybe<Array<Scalars['String']>>;
  additionalInfo?: InputMaybe<Array<Scalars['String']>>;
  gpsLocation?: InputMaybe<GeoPointInput>;
  textLocation?: InputMaybe<Scalars['String']>;
  meetingPoint?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<MarketItemPriceInput>;
  marketUrl?: InputMaybe<Scalars['AWSURL']>;
  externalId?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Float']>;
  reviewsCount?: InputMaybe<Scalars['Int']>;
  externalCategories: Array<Scalars['String']>;
  cancellationPolicy?: InputMaybe<MarketItemCancellationPolicyInput>;
  bookingRequirements?: InputMaybe<ViatorBookingRequirementsInput>;
  bookingQuestions: Array<Scalars['String']>;
  logistics?: InputMaybe<ViatorLogisticsInput>;
  productOptions?: InputMaybe<Array<ViatorProductOptionInput>>;
  bookingType?: InputMaybe<TuiBookingType>;
  ignore?: InputMaybe<Scalars['Boolean']>;
  topPick?: InputMaybe<Scalars['Boolean']>;
  weightedAverageRating?: InputMaybe<Scalars['Float']>;
  internalLabels?: InputMaybe<Array<InternalLabel>>;
};

export type ViatorPaymentDetails = {
  __typename?: 'ViatorPaymentDetails';
  partnerCartRef: Scalars['String'];
  viatorItems: Array<ViatorPaymentItem>;
};

export type ViatorPaymentDetailsInput = {
  partnerCartRef: Scalars['String'];
  viatorItems: Array<ViatorPaymentItemInput>;
};

export type ViatorPaymentItem = {
  __typename?: 'ViatorPaymentItem';
  partnerBookingRef: Scalars['String'];
  productCode: Scalars['String'];
  productOptionCode?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['AWSTime']>;
  travelDate: Scalars['AWSDate'];
  travelers: Array<ViatorTravelers>;
};

export type ViatorPaymentItemInput = {
  partnerBookingRef: Scalars['String'];
  productCode: Scalars['String'];
  productOptionCode?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['AWSTime']>;
  travelDate: Scalars['AWSDate'];
  travelers: Array<ViatorTravelersInput>;
};

export type ViatorProductOption = {
  __typename?: 'ViatorProductOption';
  productOptionCode: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  languageGuides?: Maybe<Array<ViatorLanguageGuide>>;
};

export type ViatorProductOptionInput = {
  productOptionCode: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  languageGuides?: InputMaybe<Array<ViatorLanguageGuideInput>>;
};

export type ViatorTravelerPickup = {
  __typename?: 'ViatorTravelerPickup';
  pickupOptionType: Scalars['String'];
  allowCustomTravelerPickup: Scalars['Boolean'];
  minutesBeforeDepartureTimeForPickup?: Maybe<Scalars['Int']>;
  additionalInfo?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<ViatorTravelerPickupLocation>>;
};

export type ViatorTravelerPickupInput = {
  pickupOptionType: Scalars['String'];
  allowCustomTravelerPickup: Scalars['Boolean'];
  minutesBeforeDepartureTimeForPickup?: InputMaybe<Scalars['Int']>;
  additionalInfo?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Array<ViatorTravelerPickupLocationInput>>;
};

export type ViatorTravelerPickupLocation = {
  __typename?: 'ViatorTravelerPickupLocation';
  pickupType?: Maybe<Scalars['String']>;
};

export type ViatorTravelerPickupLocationInput = {
  pickupType?: InputMaybe<Scalars['String']>;
};

export type ViatorTravelers = {
  __typename?: 'ViatorTravelers';
  ageBand: TravelerAgeBand;
  numberOfTravelers: Scalars['Int'];
};

export type ViatorTravelersInput = {
  ageBand: TravelerAgeBand;
  numberOfTravelers: Scalars['Int'];
};

export type Video = {
  __typename?: 'Video';
  id: Scalars['ID'];
  links?: Maybe<Links>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
};

export type VideoInput = {
  id: Scalars['ID'];
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
};

export type VisitorInterests = {
  __typename?: 'VisitorInterests';
  attractions?: Maybe<Scalars['Boolean']>;
  events?: Maybe<Array<VisitorInterestsSubcategoriesEvents>>;
  kidsFriendly?: Maybe<Scalars['Boolean']>;
  museums?: Maybe<Scalars['Boolean']>;
  nightlife?: Maybe<Scalars['Boolean']>;
  relax?: Maybe<Scalars['Boolean']>;
  restaurants?: Maybe<Array<VisitorInterestsSubcategoriesRestaurants>>;
  shopping?: Maybe<Array<VisitorInterestsSubcategoriesShopping>>;
};

export enum VisitorInterestsCategories {
  Attractions = 'ATTRACTIONS',
  Events = 'EVENTS',
  KidsFriendly = 'KIDS_FRIENDLY',
  Museums = 'MUSEUMS',
  Nightlife = 'NIGHTLIFE',
  Relax = 'RELAX',
  Restaurants = 'RESTAURANTS',
  Shopping = 'SHOPPING',
}

export type VisitorInterestsInput = {
  attractions?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<Array<VisitorInterestsSubcategoriesEvents>>;
  kidsFriendly?: InputMaybe<Scalars['Boolean']>;
  museums?: InputMaybe<Scalars['Boolean']>;
  nightlife?: InputMaybe<Scalars['Boolean']>;
  relax?: InputMaybe<Scalars['Boolean']>;
  restaurants?: InputMaybe<Array<VisitorInterestsSubcategoriesRestaurants>>;
  shopping?: InputMaybe<Array<VisitorInterestsSubcategoriesShopping>>;
};

export type VisitorInterestsResponse = {
  __typename?: 'VisitorInterestsResponse';
  lang: Scalars['String'];
  visitorInterests: Scalars['AWSJSON'];
};

export enum VisitorInterestsSubcategoriesEvents {
  Comedy = 'COMEDY',
  Concerts = 'CONCERTS',
  ForKids = 'FOR_KIDS',
  Sports = 'SPORTS',
  Theatre = 'THEATRE',
}

export enum VisitorInterestsSubcategoriesRestaurants {
  Asian = 'ASIAN',
  FamilyFriendly = 'FAMILY_FRIENDLY',
  FineDining = 'FINE_DINING',
  Italian = 'ITALIAN',
  Local = 'LOCAL',
  Vegan = 'VEGAN',
}

export enum VisitorInterestsSubcategoriesShopping {
  Outlet = 'OUTLET',
  Premium = 'PREMIUM',
  Souvenirs = 'SOUVENIRS',
}

export type Weather = {
  __typename?: 'Weather';
  id: Scalars['ID'];
  label: Scalars['String'];
  description: Scalars['String'];
  iconName: Scalars['String'];
  tempValue: Scalars['Float'];
  tempUnit: Scalars['String'];
};

export type WeatherAlert = {
  __typename?: 'WeatherAlert';
  event: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export enum WeatherType {
  Sunny = 'SUNNY',
  Rainy = 'RAINY',
}

export type WeekItinerary = {
  __typename?: 'WeekItinerary';
  installationUuid: Scalars['ID'];
  placeUuid: Scalars['ID'];
  itineraries: Array<DayItinerary>;
};

export enum WeekItineraryDaytime {
  Morning = 'MORNING',
  Afternoon = 'AFTERNOON',
  Evening = 'EVENING',
  Night = 'NIGHT',
}

export type ImageVerification = {
  __typename?: 'imageVerification';
  status: ImageVerificationStatus;
  message?: Maybe<Scalars['String']>;
};

export enum ImageVerificationStatus {
  Ok = 'OK',
  Error = 'ERROR',
}

export type LocationRadius = {
  __typename?: 'locationRadius';
  prev?: Maybe<GeoPoint>;
  next?: Maybe<GeoPoint>;
};

export type MapQueryCachingInput = {
  tile: Scalars['String'];
  types: Scalars['String'];
};

export type PinInstallationCouponInstitutionResponse = {
  __typename?: 'pinInstallationCouponInstitutionResponse';
  installationUuid: Scalars['ID'];
  institutionUuid: Scalars['ID'];
};

type AnnouncementDetailFragment_Announcement_Fragment = {
  __typename: 'Announcement';
  content: string;
  createdAt: string;
  updatedAt: string;
  scheduledAt?: string | null | undefined;
  publishedAt?: string | null | undefined;
  id: string;
  lang: string;
  title: string;
  createdByAdmin?: boolean | null | undefined;
  publishmentState: State;
  notifyAlsoNonCategoried?: boolean | null | undefined;
  notifyVolunteersOnly?: boolean | null | undefined;
  notifications?: Array<string> | null | undefined;
  notifyNow?: boolean | null | undefined;
  category?:
    | {
        __typename?: 'Category';
        id: string;
        name: string;
        createdAt: string;
        categoryTypes: Array<CategoryType>;
        legacy?: boolean | null | undefined;
        isSystem?: boolean | null | undefined;
        images?:
          | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
  categories: Array<{
    __typename?: 'Category';
    id: string;
    name: string;
    createdAt: string;
    categoryTypes: Array<CategoryType>;
    legacy?: boolean | null | undefined;
    isSystem?: boolean | null | undefined;
    images?:
      | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
      | null
      | undefined;
  }>;
  imageObjects?:
    | Array<{
        __typename?: 'Image';
        fileName?: string | null | undefined;
        filePath?: string | null | undefined;
        id: string;
        links?: { __typename?: 'Links'; self: string } | null | undefined;
      }>
    | null
    | undefined;
  videoObjects?:
    | Array<{
        __typename?: 'Video';
        fileName?: string | null | undefined;
        filePath?: string | null | undefined;
        id: string;
        links?: { __typename?: 'Links'; self: string } | null | undefined;
      }>
    | null
    | undefined;
  location?:
    | {
        __typename?: 'EventLocation';
        name: string;
        gps?: { __typename?: 'GeoPoint'; lat: number; lon: number } | null | undefined;
      }
    | null
    | undefined;
  places: Array<{ __typename?: 'Place'; id: string; name: string }>;
  areaOfInterest?:
    | {
        __typename?: 'GeoShapePolygon';
        polygonType: PolygonType;
        polygonCoordinates: Array<Array<Array<number>>>;
      }
    | {
        __typename?: 'GeoShapeCircle';
        type: CircleType;
        coordinates: Array<number>;
        radius: string;
      }
    | { __typename?: 'GeoShapeGeometryCollection' }
    | null
    | undefined;
};

type AnnouncementDetailFragment_AnnouncementDraft_Fragment = {
  __typename: 'AnnouncementDraft';
  content?: string | null | undefined;
  createdAt: string;
  updatedAt: string;
  scheduledAt?: string | null | undefined;
  publishedAt?: string | null | undefined;
  id: string;
  lang: string;
  title: string;
  createdByAdmin?: boolean | null | undefined;
  publishmentState: State;
  notifyAlsoNonCategoried?: boolean | null | undefined;
  notifyVolunteersOnly?: boolean | null | undefined;
  notifications?: Array<string> | null | undefined;
  notifyNow?: boolean | null | undefined;
  category?:
    | {
        __typename?: 'Category';
        id: string;
        name: string;
        createdAt: string;
        categoryTypes: Array<CategoryType>;
        legacy?: boolean | null | undefined;
        isSystem?: boolean | null | undefined;
        images?:
          | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
  categories?:
    | Array<{
        __typename?: 'Category';
        id: string;
        name: string;
        createdAt: string;
        categoryTypes: Array<CategoryType>;
        legacy?: boolean | null | undefined;
        isSystem?: boolean | null | undefined;
        images?:
          | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
          | null
          | undefined;
      }>
    | null
    | undefined;
  imageObjects?:
    | Array<{
        __typename?: 'Image';
        fileName?: string | null | undefined;
        filePath?: string | null | undefined;
        id: string;
        links?: { __typename?: 'Links'; self: string } | null | undefined;
      }>
    | null
    | undefined;
  videoObjects?:
    | Array<{
        __typename?: 'Video';
        fileName?: string | null | undefined;
        filePath?: string | null | undefined;
        id: string;
        links?: { __typename?: 'Links'; self: string } | null | undefined;
      }>
    | null
    | undefined;
  location?:
    | {
        __typename?: 'EventLocation';
        name: string;
        gps?: { __typename?: 'GeoPoint'; lat: number; lon: number } | null | undefined;
      }
    | null
    | undefined;
  places?: Array<{ __typename?: 'Place'; id: string; name: string }> | null | undefined;
  areaOfInterest?:
    | {
        __typename?: 'GeoShapePolygon';
        polygonType: PolygonType;
        polygonCoordinates: Array<Array<Array<number>>>;
      }
    | {
        __typename?: 'GeoShapeCircle';
        type: CircleType;
        coordinates: Array<number>;
        radius: string;
      }
    | { __typename?: 'GeoShapeGeometryCollection' }
    | null
    | undefined;
};

export type AnnouncementDetailFragmentFragment =
  | AnnouncementDetailFragment_Announcement_Fragment
  | AnnouncementDetailFragment_AnnouncementDraft_Fragment;

export type AddAnnouncementDraftMutationVariables = Exact<{
  announcementDraft: AnnouncementDraftInput;
}>;

export type AddAnnouncementDraftMutation = {
  __typename?: 'Mutation';
  addAnnouncementDraft: { __typename?: 'AnnouncementDraft'; id: string };
};

export type UpdateAnouncementDraftMutationVariables = Exact<{
  id: Scalars['ID'];
  announcementDraft: AnnouncementDraftInput;
}>;

export type UpdateAnouncementDraftMutation = {
  __typename?: 'Mutation';
  updateAnnouncementDraft?: { __typename?: 'AnnouncementDraft'; id: string } | null | undefined;
};

export type AddAnnouncementMutationVariables = Exact<{
  announcement: AnnouncementInput;
}>;

export type AddAnnouncementMutation = {
  __typename?: 'Mutation';
  addAnnouncement: { __typename?: 'Announcement'; id: string };
};

export type UpdateAnouncementMutationVariables = Exact<{
  id: Scalars['ID'];
  announcement: AnnouncementInput;
}>;

export type UpdateAnouncementMutation = {
  __typename?: 'Mutation';
  updateAnnouncement?: { __typename?: 'Announcement'; id: string } | null | undefined;
};

export type DeleteAnnouncementMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteAnnouncementMutation = {
  __typename?: 'Mutation';
  deleteAnnouncement?:
    | { __typename?: 'Announcement'; id: string }
    | { __typename?: 'AnnouncementDraft'; id: string }
    | null
    | undefined;
};

export type PublishAnnouncementMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PublishAnnouncementMutation = {
  __typename?: 'Mutation';
  publishAnnouncement?: { __typename?: 'Announcement'; id: string } | null | undefined;
};

export type UnpublishAnnouncementMutationVariables = Exact<{
  id: Scalars['ID'];
  batch?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type UnpublishAnnouncementMutation = {
  __typename?: 'Mutation';
  unpublishAnnouncement?: { __typename?: 'Announcement'; id: string } | null | undefined;
};

export type CancelScheduledAnnouncementMutationVariables = Exact<{
  id: Scalars['ID'];
  placeUuid?: Maybe<Scalars['String']>;
}>;

export type CancelScheduledAnnouncementMutation = {
  __typename?: 'Mutation';
  cancelScheduledAnnouncement?: { __typename?: 'AnnouncementDraft'; id: string } | null | undefined;
};

export type UpdateAnnouncementCategoriesMutationVariables = Exact<{
  id: Scalars['ID'];
  categoryUuids: Array<Scalars['String']> | Scalars['String'];
}>;

export type UpdateAnnouncementCategoriesMutation = {
  __typename?: 'Mutation';
  updateAnnouncementCategories?: { __typename?: 'Announcement'; id: string } | null | undefined;
};

export type AnnouncementQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AnnouncementQuery = {
  __typename?: 'Query';
  announcement:
    | {
        __typename: 'Announcement';
        content: string;
        createdAt: string;
        updatedAt: string;
        scheduledAt?: string | null | undefined;
        publishedAt?: string | null | undefined;
        id: string;
        lang: string;
        title: string;
        createdByAdmin?: boolean | null | undefined;
        publishmentState: State;
        notifyAlsoNonCategoried?: boolean | null | undefined;
        notifyVolunteersOnly?: boolean | null | undefined;
        notifications?: Array<string> | null | undefined;
        notifyNow?: boolean | null | undefined;
        category?:
          | {
              __typename?: 'Category';
              id: string;
              name: string;
              createdAt: string;
              categoryTypes: Array<CategoryType>;
              legacy?: boolean | null | undefined;
              isSystem?: boolean | null | undefined;
              images?:
                | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
                | null
                | undefined;
            }
          | null
          | undefined;
        categories: Array<{
          __typename?: 'Category';
          id: string;
          name: string;
          createdAt: string;
          categoryTypes: Array<CategoryType>;
          legacy?: boolean | null | undefined;
          isSystem?: boolean | null | undefined;
          images?:
            | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
            | null
            | undefined;
        }>;
        imageObjects?:
          | Array<{
              __typename?: 'Image';
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              id: string;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }>
          | null
          | undefined;
        videoObjects?:
          | Array<{
              __typename?: 'Video';
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              id: string;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }>
          | null
          | undefined;
        location?:
          | {
              __typename?: 'EventLocation';
              name: string;
              gps?: { __typename?: 'GeoPoint'; lat: number; lon: number } | null | undefined;
            }
          | null
          | undefined;
        places: Array<{ __typename?: 'Place'; id: string; name: string }>;
        areaOfInterest?:
          | {
              __typename?: 'GeoShapePolygon';
              polygonType: PolygonType;
              polygonCoordinates: Array<Array<Array<number>>>;
            }
          | {
              __typename?: 'GeoShapeCircle';
              type: CircleType;
              coordinates: Array<number>;
              radius: string;
            }
          | { __typename?: 'GeoShapeGeometryCollection' }
          | null
          | undefined;
      }
    | { __typename?: 'Event' }
    | { __typename?: 'QuickQuestion' }
    | { __typename?: 'UserPost' }
    | {
        __typename: 'AnnouncementDraft';
        content?: string | null | undefined;
        createdAt: string;
        updatedAt: string;
        scheduledAt?: string | null | undefined;
        publishedAt?: string | null | undefined;
        id: string;
        lang: string;
        title: string;
        createdByAdmin?: boolean | null | undefined;
        publishmentState: State;
        notifyAlsoNonCategoried?: boolean | null | undefined;
        notifyVolunteersOnly?: boolean | null | undefined;
        notifications?: Array<string> | null | undefined;
        notifyNow?: boolean | null | undefined;
        category?:
          | {
              __typename?: 'Category';
              id: string;
              name: string;
              createdAt: string;
              categoryTypes: Array<CategoryType>;
              legacy?: boolean | null | undefined;
              isSystem?: boolean | null | undefined;
              images?:
                | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
                | null
                | undefined;
            }
          | null
          | undefined;
        categories?:
          | Array<{
              __typename?: 'Category';
              id: string;
              name: string;
              createdAt: string;
              categoryTypes: Array<CategoryType>;
              legacy?: boolean | null | undefined;
              isSystem?: boolean | null | undefined;
              images?:
                | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
                | null
                | undefined;
            }>
          | null
          | undefined;
        imageObjects?:
          | Array<{
              __typename?: 'Image';
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              id: string;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }>
          | null
          | undefined;
        videoObjects?:
          | Array<{
              __typename?: 'Video';
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              id: string;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }>
          | null
          | undefined;
        location?:
          | {
              __typename?: 'EventLocation';
              name: string;
              gps?: { __typename?: 'GeoPoint'; lat: number; lon: number } | null | undefined;
            }
          | null
          | undefined;
        places?: Array<{ __typename?: 'Place'; id: string; name: string }> | null | undefined;
        areaOfInterest?:
          | {
              __typename?: 'GeoShapePolygon';
              polygonType: PolygonType;
              polygonCoordinates: Array<Array<Array<number>>>;
            }
          | {
              __typename?: 'GeoShapeCircle';
              type: CircleType;
              coordinates: Array<number>;
              radius: string;
            }
          | { __typename?: 'GeoShapeGeometryCollection' }
          | null
          | undefined;
      }
    | { __typename?: 'EventDraft' }
    | { __typename?: 'QuickQuestionDraft' };
};

type AnnouncementsFields_Announcement_Fragment = {
  __typename: 'Announcement';
  createdAt: string;
  updatedAt: string;
  id: string;
  scheduledAt?: string | null | undefined;
  title: string;
  content: string;
  createdByAdmin?: boolean | null | undefined;
  publishmentState: State;
  notifyAlsoNonCategoried?: boolean | null | undefined;
  category?:
    | {
        __typename?: 'Category';
        id: string;
        name: string;
        createdAt: string;
        categoryTypes: Array<CategoryType>;
        legacy?: boolean | null | undefined;
        isSystem?: boolean | null | undefined;
        images?:
          | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
  categories: Array<{
    __typename?: 'Category';
    id: string;
    name: string;
    createdAt: string;
    categoryTypes: Array<CategoryType>;
    legacy?: boolean | null | undefined;
    isSystem?: boolean | null | undefined;
    images?:
      | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
      | null
      | undefined;
  }>;
  areaOfInterest?:
    | {
        __typename?: 'GeoShapePolygon';
        polygonType: PolygonType;
        polygonCoordinates: Array<Array<Array<number>>>;
      }
    | {
        __typename?: 'GeoShapeCircle';
        type: CircleType;
        coordinates: Array<number>;
        radius: string;
      }
    | { __typename?: 'GeoShapeGeometryCollection' }
    | null
    | undefined;
};

type AnnouncementsFields_AnnouncementDraft_Fragment = {
  __typename: 'AnnouncementDraft';
  createdAt: string;
  updatedAt: string;
  id: string;
  scheduledAt?: string | null | undefined;
  title: string;
  content?: string | null | undefined;
  createdByAdmin?: boolean | null | undefined;
  publishmentState: State;
  notifyAlsoNonCategoried?: boolean | null | undefined;
  category?:
    | {
        __typename?: 'Category';
        id: string;
        name: string;
        createdAt: string;
        categoryTypes: Array<CategoryType>;
        legacy?: boolean | null | undefined;
        isSystem?: boolean | null | undefined;
        images?:
          | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
  categories?:
    | Array<{
        __typename?: 'Category';
        id: string;
        name: string;
        createdAt: string;
        categoryTypes: Array<CategoryType>;
        legacy?: boolean | null | undefined;
        isSystem?: boolean | null | undefined;
        images?:
          | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
          | null
          | undefined;
      }>
    | null
    | undefined;
  areaOfInterest?:
    | {
        __typename?: 'GeoShapePolygon';
        polygonType: PolygonType;
        polygonCoordinates: Array<Array<Array<number>>>;
      }
    | {
        __typename?: 'GeoShapeCircle';
        type: CircleType;
        coordinates: Array<number>;
        radius: string;
      }
    | { __typename?: 'GeoShapeGeometryCollection' }
    | null
    | undefined;
};

export type AnnouncementsFieldsFragment =
  | AnnouncementsFields_Announcement_Fragment
  | AnnouncementsFields_AnnouncementDraft_Fragment;

export type AnnouncementsQueryVariables = Exact<{
  filter: AnnouncementsByFilterInput;
  pageOffset: Scalars['Int'];
  pageSize: Scalars['Int'];
  orderBy?: Maybe<AnnouncementsOrderByInput>;
}>;

export type AnnouncementsQuery = {
  __typename?: 'Query';
  announcementsBy: {
    __typename?: 'OpensearchAnnouncements';
    count: number;
    announcements: Array<
      | {
          __typename: 'Announcement';
          publishedAt?: string | null | undefined;
          createdAt: string;
          updatedAt: string;
          id: string;
          scheduledAt?: string | null | undefined;
          title: string;
          content: string;
          createdByAdmin?: boolean | null | undefined;
          publishmentState: State;
          notifyAlsoNonCategoried?: boolean | null | undefined;
          category?:
            | {
                __typename?: 'Category';
                id: string;
                name: string;
                createdAt: string;
                categoryTypes: Array<CategoryType>;
                legacy?: boolean | null | undefined;
                isSystem?: boolean | null | undefined;
                images?:
                  | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          categories: Array<{
            __typename?: 'Category';
            id: string;
            name: string;
            createdAt: string;
            categoryTypes: Array<CategoryType>;
            legacy?: boolean | null | undefined;
            isSystem?: boolean | null | undefined;
            images?:
              | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
              | null
              | undefined;
          }>;
          areaOfInterest?:
            | {
                __typename?: 'GeoShapePolygon';
                polygonType: PolygonType;
                polygonCoordinates: Array<Array<Array<number>>>;
              }
            | {
                __typename?: 'GeoShapeCircle';
                type: CircleType;
                coordinates: Array<number>;
                radius: string;
              }
            | { __typename?: 'GeoShapeGeometryCollection' }
            | null
            | undefined;
        }
      | { __typename?: 'AnnouncementDraft' }
    >;
  };
};

export type AnnouncementDraftsQueryVariables = Exact<{
  filter: AnnouncementsByFilterInput;
  pageOffset: Scalars['Int'];
  pageSize: Scalars['Int'];
  orderBy?: Maybe<AnnouncementsOrderByInput>;
}>;

export type AnnouncementDraftsQuery = {
  __typename?: 'Query';
  announcementsBy: {
    __typename?: 'OpensearchAnnouncements';
    count: number;
    announcements: Array<
      | { __typename?: 'Announcement' }
      | {
          __typename: 'AnnouncementDraft';
          createdAt: string;
          updatedAt: string;
          id: string;
          scheduledAt?: string | null | undefined;
          title: string;
          content?: string | null | undefined;
          createdByAdmin?: boolean | null | undefined;
          publishmentState: State;
          notifyAlsoNonCategoried?: boolean | null | undefined;
          category?:
            | {
                __typename?: 'Category';
                id: string;
                name: string;
                createdAt: string;
                categoryTypes: Array<CategoryType>;
                legacy?: boolean | null | undefined;
                isSystem?: boolean | null | undefined;
                images?:
                  | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          categories?:
            | Array<{
                __typename?: 'Category';
                id: string;
                name: string;
                createdAt: string;
                categoryTypes: Array<CategoryType>;
                legacy?: boolean | null | undefined;
                isSystem?: boolean | null | undefined;
                images?:
                  | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
          areaOfInterest?:
            | {
                __typename?: 'GeoShapePolygon';
                polygonType: PolygonType;
                polygonCoordinates: Array<Array<Array<number>>>;
              }
            | {
                __typename?: 'GeoShapeCircle';
                type: CircleType;
                coordinates: Array<number>;
                radius: string;
              }
            | { __typename?: 'GeoShapeGeometryCollection' }
            | null
            | undefined;
        }
    >;
  };
};

type GeoShapeFragment_Announcement_Fragment = {
  __typename?: 'Announcement';
  areaOfInterest?:
    | {
        __typename?: 'GeoShapePolygon';
        polygonType: PolygonType;
        polygonCoordinates: Array<Array<Array<number>>>;
      }
    | {
        __typename?: 'GeoShapeCircle';
        type: CircleType;
        coordinates: Array<number>;
        radius: string;
      }
    | { __typename?: 'GeoShapeGeometryCollection' }
    | null
    | undefined;
};

type GeoShapeFragment_AnnouncementDraft_Fragment = {
  __typename?: 'AnnouncementDraft';
  areaOfInterest?:
    | {
        __typename?: 'GeoShapePolygon';
        polygonType: PolygonType;
        polygonCoordinates: Array<Array<Array<number>>>;
      }
    | {
        __typename?: 'GeoShapeCircle';
        type: CircleType;
        coordinates: Array<number>;
        radius: string;
      }
    | { __typename?: 'GeoShapeGeometryCollection' }
    | null
    | undefined;
};

export type GeoShapeFragmentFragment =
  | GeoShapeFragment_Announcement_Fragment
  | GeoShapeFragment_AnnouncementDraft_Fragment;

export type CategoriesQueryVariables = Exact<{
  filter: CategoriesByFilterInput;
}>;

export type CategoriesQuery = {
  __typename?: 'Query';
  categoriesBy: {
    __typename?: 'PaginatedCategories';
    categories: Array<{
      __typename?: 'Category';
      id: string;
      name: string;
      createdAt: string;
      categoryTypes: Array<CategoryType>;
      legacy?: boolean | null | undefined;
      isSystem?: boolean | null | undefined;
      images?:
        | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
        | null
        | undefined;
    }>;
  };
};

export type CategoryFieldsFragment = {
  __typename?: 'Category';
  id: string;
  name: string;
  createdAt: string;
  categoryTypes: Array<CategoryType>;
  legacy?: boolean | null | undefined;
  isSystem?: boolean | null | undefined;
  images?:
    | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
    | null
    | undefined;
};

export type GenerateNamedUploadUrlsMutationVariables = Exact<{
  files: Array<FileUploadInput> | FileUploadInput;
  uploadMethod?: Maybe<UploadMethod>;
  institutionUuid?: Maybe<Scalars['String']>;
  placeUuid?: Maybe<Scalars['String']>;
}>;

export type GenerateNamedUploadUrlsMutation = {
  __typename?: 'Mutation';
  generateNamedUploadUrls: Array<{
    __typename?: 'UploadUrl';
    fileName?: string | null | undefined;
    filePath?: string | null | undefined;
    id: string;
    url: string;
    fields?: Array<{ __typename?: 'UploadField'; key: string; value: string }> | null | undefined;
  }>;
};

export type SendPostNotificationMutationVariables = Exact<{
  postUuid: Scalars['ID'];
}>;

export type SendPostNotificationMutation = {
  __typename?: 'Mutation';
  sendNowPostNotification?: string | null | undefined;
};

export type DeleteCouponMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteCouponMutation = {
  __typename?: 'Mutation';
  deleteCoupon?: { __typename?: 'Coupon'; id: string } | null | undefined;
};

export type UnpublishCouponMutationVariables = Exact<{
  id: Scalars['ID'];
  batch?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type UnpublishCouponMutation = {
  __typename?: 'Mutation';
  unpublishCoupon?: { __typename?: 'Coupon'; id: string } | null | undefined;
};

export type PublishCouponMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PublishCouponMutation = {
  __typename?: 'Mutation';
  publishCoupon?: { __typename?: 'Coupon'; id: string } | null | undefined;
};

export type CancelScheduledCouponMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CancelScheduledCouponMutation = {
  __typename?: 'Mutation';
  cancelScheduledCoupon?: { __typename?: 'Coupon'; id: string } | null | undefined;
};

export type AddCouponMutationVariables = Exact<{
  coupon: CouponInput;
}>;

export type AddCouponMutation = {
  __typename?: 'Mutation';
  addCoupon?: { __typename?: 'Coupon'; id: string } | null | undefined;
};

export type UpdateCouponMutationVariables = Exact<{
  id: Scalars['ID'];
  coupon: CouponInput;
}>;

export type UpdateCouponMutation = {
  __typename?: 'Mutation';
  updateCoupon?: { __typename?: 'Coupon'; id: string } | null | undefined;
};

export type AddCouponDraftMutationVariables = Exact<{
  coupon: CouponInput;
}>;

export type AddCouponDraftMutation = {
  __typename?: 'Mutation';
  addCouponDraft?: { __typename?: 'Coupon'; id: string } | null | undefined;
};

export type UpdateCouponDraftMutationVariables = Exact<{
  id: Scalars['ID'];
  coupon: CouponInput;
}>;

export type UpdateCouponDraftMutation = {
  __typename?: 'Mutation';
  updateCouponDraft?: { __typename?: 'Coupon'; id: string } | null | undefined;
};

export type CouponsQueryVariables = Exact<{
  filter: CouponsByFilterInput;
  nextToken?: Maybe<Scalars['String']>;
  orderBy?: Maybe<CouponsOrderByInput>;
}>;

export type CouponsQuery = {
  __typename?: 'Query';
  couponsBy: {
    __typename?: 'PaginatedCoupons';
    count: number;
    nextToken?: string | null | undefined;
    coupons: Array<{
      __typename?: 'Coupon';
      id: string;
      title: string;
      subtitle?: string | null | undefined;
      createdAt: string;
      publishedAt?: string | null | undefined;
      updatedAt: string;
      institutionUuid?: string | null | undefined;
      publishmentState: State;
      imageObjects?:
        | Array<{
            __typename?: 'Image';
            links?:
              | { __typename?: 'Links'; self: string; thumbnail?: string | null | undefined }
              | null
              | undefined;
          }>
        | null
        | undefined;
      validity: {
        __typename?: 'CouponValidity';
        isInfinite?: boolean | null | undefined;
        interval?:
          | {
              __typename?: 'IntervalValidity';
              toDate?: string | null | undefined;
              toTime?: string | null | undefined;
            }
          | null
          | undefined;
      };
      userActivities: {
        __typename?: 'PaginatedCouponUserActivities';
        activationsCount?: number | null | undefined;
      };
    }>;
  };
};

export type CouponQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CouponQuery = {
  __typename?: 'Query';
  coupon?:
    | {
        __typename?: 'Coupon';
        id: string;
        title: string;
        subtitle?: string | null | undefined;
        description?: string | null | undefined;
        notifyNow?: boolean | null | undefined;
        createdAt: string;
        publishedAt?: string | null | undefined;
        updatedAt: string;
        notifications?: Array<string> | null | undefined;
        institutionUuid?: string | null | undefined;
        publishmentState: State;
        imageObjects?:
          | Array<{
              __typename?: 'Image';
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              id: string;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }>
          | null
          | undefined;
        validity: {
          __typename?: 'CouponValidity';
          isInfinite?: boolean | null | undefined;
          interval?:
            | {
                __typename?: 'IntervalValidity';
                fromDate: string;
                toDate?: string | null | undefined;
                fromTime?: string | null | undefined;
                toTime?: string | null | undefined;
              }
            | null
            | undefined;
          recurring?:
            | {
                __typename?: 'RecurringValidity';
                type: RecurringPeriod;
                day: Array<number>;
                every: number;
                fromDate: string;
                toDate?: string | null | undefined;
                timeFrom: string;
                timeTo: string;
                ocurrences?: number | null | undefined;
              }
            | null
            | undefined;
        };
        userActivities: {
          __typename?: 'PaginatedCouponUserActivities';
          activationsCount?: number | null | undefined;
        };
      }
    | null
    | undefined;
};

export type AnnouncementFragment = {
  __typename?: 'Announcement';
  id: string;
  createdAt: string;
  title: string;
  announcementContent: string;
  institution?:
    | {
        __typename?: 'Institution';
        name: string;
        branding?:
          | {
              __typename?: 'Branding';
              avatar: {
                __typename?: 'Image';
                id: string;
                fileName?: string | null | undefined;
                filePath?: string | null | undefined;
                links?: { __typename?: 'Links'; self: string } | null | undefined;
              };
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  imageObjects?:
    | Array<{
        __typename?: 'Image';
        id: string;
        links?:
          | { __typename?: 'Links'; self: string; thumbnail?: string | null | undefined }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type EventFragment = {
  __typename?: 'Event';
  id: string;
  createdAt: string;
  title: string;
  eventContent?: string | null | undefined;
  institution?:
    | {
        __typename?: 'Institution';
        name: string;
        branding?:
          | {
              __typename?: 'Branding';
              avatar: {
                __typename?: 'Image';
                id: string;
                fileName?: string | null | undefined;
                filePath?: string | null | undefined;
                links?: { __typename?: 'Links'; self: string } | null | undefined;
              };
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  imageObjects?:
    | Array<{
        __typename?: 'Image';
        id: string;
        links?:
          | { __typename?: 'Links'; self: string; thumbnail?: string | null | undefined }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type AdminDashboardTopReactionsQueryVariables = Exact<{
  filter: DashboardAnalyticsFilterInput;
}>;

export type AdminDashboardTopReactionsQuery = {
  __typename?: 'Query';
  adminDashboardTopReactions?:
    | {
        __typename?: 'TopPosts';
        posts: Array<
          | {
              __typename: 'Announcement';
              id: string;
              createdAt: string;
              title: string;
              announcementContent: string;
              institution?:
                | {
                    __typename?: 'Institution';
                    name: string;
                    branding?:
                      | {
                          __typename?: 'Branding';
                          avatar: {
                            __typename?: 'Image';
                            id: string;
                            fileName?: string | null | undefined;
                            filePath?: string | null | undefined;
                            links?: { __typename?: 'Links'; self: string } | null | undefined;
                          };
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              imageObjects?:
                | Array<{
                    __typename?: 'Image';
                    id: string;
                    links?:
                      | {
                          __typename?: 'Links';
                          self: string;
                          thumbnail?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }
          | {
              __typename: 'Event';
              id: string;
              createdAt: string;
              title: string;
              eventContent?: string | null | undefined;
              institution?:
                | {
                    __typename?: 'Institution';
                    name: string;
                    branding?:
                      | {
                          __typename?: 'Branding';
                          avatar: {
                            __typename?: 'Image';
                            id: string;
                            fileName?: string | null | undefined;
                            filePath?: string | null | undefined;
                            links?: { __typename?: 'Links'; self: string } | null | undefined;
                          };
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              imageObjects?:
                | Array<{
                    __typename?: 'Image';
                    id: string;
                    links?:
                      | {
                          __typename?: 'Links';
                          self: string;
                          thumbnail?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }
          | { __typename: 'QuickQuestion'; id: string; question: string }
          | { __typename: 'UserPost'; id: string; createdAt: string }
          | { __typename: 'AnnouncementDraft'; id: string; createdAt: string }
          | { __typename: 'EventDraft'; id: string; createdAt: string }
          | { __typename: 'QuickQuestionDraft'; id: string; createdAt: string }
        >;
      }
    | null
    | undefined;
};

export type AdminDashboardTotalsAnalyticsQueryVariables = Exact<{
  filter: DashboardAnalyticsFilterInput;
}>;

export type AdminDashboardTotalsAnalyticsQuery = {
  __typename?: 'Query';
  adminDashboardTotalsAnalytics?:
    | {
        __typename?: 'DashboardAnalyticsTotalsDashboard';
        placeUuid: string;
        institutionUuid: string;
        Reactions?:
          | {
              __typename?: 'DashboardDiff';
              actual?: number | null | undefined;
              diff?: number | null | undefined;
            }
          | null
          | undefined;
        Subscribers?:
          | {
              __typename?: 'DashboardDiff';
              actual?: number | null | undefined;
              diff?: number | null | undefined;
            }
          | null
          | undefined;
        Followers?:
          | {
              __typename?: 'DashboardDiff';
              actual?: number | null | undefined;
              diff?: number | null | undefined;
            }
          | null
          | undefined;
        Reach?:
          | {
              __typename?: 'DashboardDiff';
              actual?: number | null | undefined;
              diff?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AdminDashboardQueryVariables = Exact<{
  filter: DashboardFilterInput;
}>;

export type AdminDashboardQuery = {
  __typename?: 'Query';
  adminDashboard?:
    | {
        __typename?: 'AdminDashboard';
        MAU?:
          | {
              __typename?: 'DashboardDiff';
              actual?: number | null | undefined;
              diff?: number | null | undefined;
            }
          | null
          | undefined;
        totalOpenedNotifications?:
          | {
              __typename?: 'DashboardDiff';
              actual?: number | null | undefined;
              diff?: number | null | undefined;
            }
          | null
          | undefined;
        totalSubscribers?:
          | {
              __typename?: 'DashboardDiff';
              actual?: number | null | undefined;
              diff?: number | null | undefined;
            }
          | null
          | undefined;
        totalSentNotifications?:
          | {
              __typename?: 'DashboardDiff';
              actual?: number | null | undefined;
              diff?: number | null | undefined;
            }
          | null
          | undefined;
        totalNewsViews?:
          | {
              __typename?: 'DashboardDiff';
              actual?: number | null | undefined;
              diff?: number | null | undefined;
            }
          | null
          | undefined;
        totalEventViews?:
          | {
              __typename?: 'DashboardDiff';
              actual?: number | null | undefined;
              diff?: number | null | undefined;
            }
          | null
          | undefined;
        totalShares?:
          | {
              __typename?: 'DashboardDiff';
              actual?: number | null | undefined;
              diff?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AdminDashboardAnalyticsQueryVariables = Exact<{
  filter: DashboardAnalyticsFilterInput;
}>;

export type AdminDashboardAnalyticsQuery = {
  __typename?: 'Query';
  adminDashboardAnalytics?:
    | {
        __typename?: 'AdminDashboardAnalytics';
        subscribers?: string | null | undefined;
        followers?: string | null | undefined;
        reach?: string | null | undefined;
        reactions?: string | null | undefined;
      }
    | null
    | undefined;
};

export type DonationsQueryVariables = Exact<{
  filter: AnnouncementsByFilterInput;
  pageOffset: Scalars['Int'];
  pageSize: Scalars['Int'];
  orderBy?: Maybe<AnnouncementsOrderByInput>;
}>;

export type DonationsQuery = {
  __typename?: 'Query';
  announcementsBy: {
    __typename?: 'OpensearchAnnouncements';
    count: number;
    announcements: Array<
      | {
          __typename: 'Announcement';
          publishedAt?: string | null | undefined;
          donationRaisedAmount?: number | null | undefined;
          donationTargetAmount?: number | null | undefined;
          donationCurrency?: string | null | undefined;
          createdAt: string;
          updatedAt: string;
          id: string;
          scheduledAt?: string | null | undefined;
          title: string;
          content: string;
          createdByAdmin?: boolean | null | undefined;
          publishmentState: State;
          notifyAlsoNonCategoried?: boolean | null | undefined;
          category?:
            | {
                __typename?: 'Category';
                id: string;
                name: string;
                createdAt: string;
                categoryTypes: Array<CategoryType>;
                legacy?: boolean | null | undefined;
                isSystem?: boolean | null | undefined;
                images?:
                  | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          categories: Array<{
            __typename?: 'Category';
            id: string;
            name: string;
            createdAt: string;
            categoryTypes: Array<CategoryType>;
            legacy?: boolean | null | undefined;
            isSystem?: boolean | null | undefined;
            images?:
              | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
              | null
              | undefined;
          }>;
          areaOfInterest?:
            | {
                __typename?: 'GeoShapePolygon';
                polygonType: PolygonType;
                polygonCoordinates: Array<Array<Array<number>>>;
              }
            | {
                __typename?: 'GeoShapeCircle';
                type: CircleType;
                coordinates: Array<number>;
                radius: string;
              }
            | { __typename?: 'GeoShapeGeometryCollection' }
            | null
            | undefined;
        }
      | { __typename?: 'AnnouncementDraft' }
    >;
  };
};

export type DonationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DonationQuery = {
  __typename?: 'Query';
  announcement:
    | {
        __typename: 'Announcement';
        donationRaisedAmount?: number | null | undefined;
        donationTargetAmount?: number | null | undefined;
        donationCurrency?: string | null | undefined;
        content: string;
        createdAt: string;
        updatedAt: string;
        scheduledAt?: string | null | undefined;
        publishedAt?: string | null | undefined;
        id: string;
        lang: string;
        title: string;
        createdByAdmin?: boolean | null | undefined;
        publishmentState: State;
        notifyAlsoNonCategoried?: boolean | null | undefined;
        notifyVolunteersOnly?: boolean | null | undefined;
        notifications?: Array<string> | null | undefined;
        notifyNow?: boolean | null | undefined;
        category?:
          | {
              __typename?: 'Category';
              id: string;
              name: string;
              createdAt: string;
              categoryTypes: Array<CategoryType>;
              legacy?: boolean | null | undefined;
              isSystem?: boolean | null | undefined;
              images?:
                | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
                | null
                | undefined;
            }
          | null
          | undefined;
        categories: Array<{
          __typename?: 'Category';
          id: string;
          name: string;
          createdAt: string;
          categoryTypes: Array<CategoryType>;
          legacy?: boolean | null | undefined;
          isSystem?: boolean | null | undefined;
          images?:
            | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
            | null
            | undefined;
        }>;
        imageObjects?:
          | Array<{
              __typename?: 'Image';
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              id: string;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }>
          | null
          | undefined;
        videoObjects?:
          | Array<{
              __typename?: 'Video';
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              id: string;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }>
          | null
          | undefined;
        location?:
          | {
              __typename?: 'EventLocation';
              name: string;
              gps?: { __typename?: 'GeoPoint'; lat: number; lon: number } | null | undefined;
            }
          | null
          | undefined;
        places: Array<{ __typename?: 'Place'; id: string; name: string }>;
        areaOfInterest?:
          | {
              __typename?: 'GeoShapePolygon';
              polygonType: PolygonType;
              polygonCoordinates: Array<Array<Array<number>>>;
            }
          | {
              __typename?: 'GeoShapeCircle';
              type: CircleType;
              coordinates: Array<number>;
              radius: string;
            }
          | { __typename?: 'GeoShapeGeometryCollection' }
          | null
          | undefined;
      }
    | { __typename?: 'Event' }
    | { __typename?: 'QuickQuestion' }
    | { __typename?: 'UserPost' }
    | { __typename?: 'AnnouncementDraft' }
    | { __typename?: 'EventDraft' }
    | { __typename?: 'QuickQuestionDraft' };
};

export type PinAnnouncementMutationVariables = Exact<{
  id: Scalars['ID'];
  placeUuid: Scalars['String'];
}>;

export type PinAnnouncementMutation = {
  __typename?: 'Mutation';
  pinAnnouncement?: { __typename?: 'Announcement'; id: string } | null | undefined;
};

export type UnpinAnnouncementMutationVariables = Exact<{
  id: Scalars['ID'];
  placeUuid: Scalars['String'];
}>;

export type UnpinAnnouncementMutation = {
  __typename?: 'Mutation';
  unpinAnnouncement?: { __typename?: 'Announcement'; id: string } | null | undefined;
};

export type DeleteEventMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteEventMutation = {
  __typename?: 'Mutation';
  deleteEvent?: { __typename?: 'Event'; id: string } | null | undefined;
};

export type UnpublishEventMutationVariables = Exact<{
  id: Scalars['ID'];
  batch?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type UnpublishEventMutation = {
  __typename?: 'Mutation';
  unpublishEvent?: { __typename?: 'Event'; id: string } | null | undefined;
};

export type PublishEventMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PublishEventMutation = {
  __typename?: 'Mutation';
  publishEvent?: { __typename?: 'Event'; id: string } | null | undefined;
};

export type AddEventMutationVariables = Exact<{
  event: EventInput;
}>;

export type AddEventMutation = {
  __typename?: 'Mutation';
  addEvent: { __typename?: 'Event'; id: string };
};

export type UpdateEventMutationVariables = Exact<{
  id: Scalars['ID'];
  event: EventInput;
}>;

export type UpdateEventMutation = {
  __typename?: 'Mutation';
  updateEvent?: { __typename?: 'Event'; id: string } | null | undefined;
};

export type EventsByQueryVariables = Exact<{
  filter: EventsByFilterInput;
  pageOffset: Scalars['Int'];
  pageSize: Scalars['Int'];
  orderBy?: Maybe<EventsOrderByInput>;
}>;

export type EventsByQuery = {
  __typename?: 'Query';
  eventsBy: {
    __typename?: 'OpensearchEvents';
    count: number;
    events: Array<{
      __typename?: 'Event';
      id: string;
      title: string;
      lang: string;
      perex?: string | null | undefined;
      createdAt: string;
      datetimeFrom?: string | null | undefined;
      datetimeTo?: string | null | undefined;
      publishedAt?: string | null | undefined;
      createdByAdmin?: boolean | null | undefined;
      category?: { __typename?: 'Category'; id: string; name: string } | null | undefined;
      institution?: { __typename?: 'Institution'; id: string; name: string } | null | undefined;
      location?:
        | {
            __typename?: 'EventLocation';
            name: string;
            online?: string | null | undefined;
            gps?: { __typename?: 'GeoPoint'; lat: number; lon: number } | null | undefined;
          }
        | null
        | undefined;
      places: Array<{ __typename?: 'Place'; id: string; name: string }>;
    }>;
  };
};

export type EventQueryVariables = Exact<{
  eventId: Scalars['ID'];
}>;

export type EventQuery = {
  __typename?: 'Query';
  event:
    | { __typename?: 'Announcement' }
    | {
        __typename: 'Event';
        id: string;
        title: string;
        lang: string;
        datetimeTo?: string | null | undefined;
        content?: string | null | undefined;
        datetimeFrom?: string | null | undefined;
        publishedAt?: string | null | undefined;
        publishmentState: State;
        source?: string | null | undefined;
        createdByAdmin?: boolean | null | undefined;
        marketUrl?: string | null | undefined;
        imageObjects?:
          | Array<{
              __typename?: 'Image';
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              id: string;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }>
          | null
          | undefined;
        videoObjects?:
          | Array<{
              __typename?: 'Video';
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              id: string;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }>
          | null
          | undefined;
        category?: { __typename?: 'Category'; name: string } | null | undefined;
        categories: Array<{
          __typename?: 'Category';
          id: string;
          name: string;
          createdAt: string;
          categoryTypes: Array<CategoryType>;
          legacy?: boolean | null | undefined;
          isSystem?: boolean | null | undefined;
          images?:
            | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
            | null
            | undefined;
        }>;
        institution?: { __typename?: 'Institution'; id: string; name: string } | null | undefined;
        places: Array<{ __typename?: 'Place'; id: string; name: string }>;
        location?:
          | {
              __typename?: 'EventLocation';
              name: string;
              online?: string | null | undefined;
              gps?: { __typename?: 'GeoPoint'; lat: number; lon: number } | null | undefined;
            }
          | null
          | undefined;
        marketItemPricing?:
          | {
              __typename?: 'Pricing';
              currency: Currency;
              summary?:
                | {
                    __typename?: 'PricingSummary';
                    fromPrice?: number | null | undefined;
                    fromPriceBeforeDiscount?: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | { __typename?: 'QuickQuestion' }
    | { __typename?: 'UserPost' }
    | { __typename?: 'AnnouncementDraft' }
    | { __typename?: 'EventDraft' }
    | { __typename?: 'QuickQuestionDraft' };
};

export type EventMarketFieldsFragment = {
  __typename?: 'Event';
  marketUrl?: string | null | undefined;
  marketItemPricing?:
    | {
        __typename?: 'Pricing';
        currency: Currency;
        summary?:
          | {
              __typename?: 'PricingSummary';
              fromPrice?: number | null | undefined;
              fromPriceBeforeDiscount?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateInstitutionInfoMutationVariables = Exact<{
  id: Scalars['ID'];
  info: InstitutionInfoInput;
}>;

export type UpdateInstitutionInfoMutation = {
  __typename?: 'Mutation';
  setInstitutionInfo?: { __typename?: 'Institution'; id: string } | null | undefined;
};

export type InstitutionFeatureFlagFragmentFragment = {
  __typename?: 'InstitutionFeatureFlag';
  title: string;
  type: InstitutionFeatureFlagEnum;
  value: string;
  feIcon?: InstitutionFeIconEnum | null | undefined;
  icon?:
    | {
        __typename?: 'Image';
        id: string;
        blurHash?: string | null | undefined;
        fileName?: string | null | undefined;
        filePath?: string | null | undefined;
        links?:
          | { __typename?: 'Links'; self: string; thumbnail?: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
  titleLocalizations?:
    | Array<{ __typename?: 'TitleLocalization'; title: string; language: string }>
    | null
    | undefined;
};

export type InstitutionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InstitutionQuery = {
  __typename?: 'Query';
  institution?:
    | {
        __typename?: 'Institution';
        id: string;
        name: string;
        description?: string | null | undefined;
        webSite?: string | null | undefined;
        logo?: string | null | undefined;
        createdAt: string;
        taskCodes?: Array<string> | null | undefined;
        isChatEnabled?: boolean | null | undefined;
        isRecommended?: boolean | null | undefined;
        lang?: string | null | undefined;
        category: {
          __typename?: 'Category';
          id: string;
          name: string;
          createdAt: string;
          categoryTypes: Array<CategoryType>;
          legacy?: boolean | null | undefined;
          isSystem?: boolean | null | undefined;
          images?:
            | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
            | null
            | undefined;
        };
        places?: Array<{ __typename?: 'Place'; id: string; name: string }> | null | undefined;
        branding?:
          | {
              __typename?: 'Branding';
              avatar: {
                __typename?: 'Image';
                id: string;
                links?: { __typename?: 'Links'; self: string } | null | undefined;
              };
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type InstitutionPlacesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InstitutionPlacesQuery = {
  __typename?: 'Query';
  institution?:
    | {
        __typename?: 'Institution';
        places?:
          | Array<{
              __typename?: 'Place';
              id: string;
              name: string;
              stateCode: string;
              countryCode: string;
              timezoneCode: string;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type InstitutionAnnouncementCountsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InstitutionAnnouncementCountsQuery = {
  __typename?: 'Query';
  institution?:
    | {
        __typename?: 'Institution';
        publishedNewsCount?: number | null | undefined;
        unpublishedNewsCount?: number | null | undefined;
        scheduledNewsCount?: number | null | undefined;
        draftsNewsCount?: number | null | undefined;
      }
    | null
    | undefined;
};

export type InstitutionInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InstitutionInfoQuery = {
  __typename?: 'Query';
  institution?:
    | {
        __typename?: 'Institution';
        name: string;
        lang?: string | null | undefined;
        info?:
          | {
              __typename?: 'InstitutionInfo';
              description?: string | null | undefined;
              website?: string | null | undefined;
              contactEmail?: string | null | undefined;
              contactPhone?: string | null | undefined;
              location?:
                | {
                    __typename?: 'Location';
                    address: string;
                    geo: { __typename?: 'GeoPoint'; lat: number; lon: number };
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CityInstitutionsIdsQueryVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>;
  filter: InstitutionsByFilterInput;
}>;

export type CityInstitutionsIdsQuery = {
  __typename?: 'Query';
  institutionsBy: {
    __typename?: 'PaginatedInstitutions';
    institutions: Array<{ __typename?: 'Institution'; id: string; name: string }>;
  };
};

export type InstiutionRoleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InstiutionRoleQuery = {
  __typename?: 'Query';
  institution?:
    | {
        __typename?: 'Institution';
        isChatEnabled?: boolean | null | undefined;
        visitorMode?: boolean | null | undefined;
        inPlaces?:
          | Array<{
              __typename?: 'InstitutionInPlace';
              role?: InstitutionPlaceRole | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type InstitutionBrandingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InstitutionBrandingQuery = {
  __typename?: 'Query';
  institution?:
    | {
        __typename?: 'Institution';
        branding?:
          | {
              __typename?: 'Branding';
              avatar: {
                __typename?: 'Image';
                id: string;
                links?: { __typename?: 'Links'; self: string } | null | undefined;
              };
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type InstitutionNameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InstitutionNameQuery = {
  __typename?: 'Query';
  institution?: { __typename?: 'Institution'; name: string } | null | undefined;
};

export type AllInstitutionsQueryVariables = Exact<{
  nextToken?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;

export type AllInstitutionsQuery = {
  __typename?: 'Query';
  allInstitutions: {
    __typename?: 'PaginatedInstitutions';
    nextToken?: string | null | undefined;
    institutions: Array<{
      __typename?: 'Institution';
      id: string;
      name: string;
      visitorMode?: boolean | null | undefined;
      inPlaces?:
        | Array<{
            __typename?: 'InstitutionInPlace';
            role?: InstitutionPlaceRole | null | undefined;
          }>
        | null
        | undefined;
    }>;
  };
};

export type UserInstitutionsQueryVariables = Exact<{
  username: Scalars['String'];
}>;

export type UserInstitutionsQuery = {
  __typename?: 'Query';
  adminUser?:
    | {
        __typename?: 'AdminUser';
        institutions?:
          | Array<{
              __typename?: 'Institution';
              id: string;
              name: string;
              visitorMode?: boolean | null | undefined;
              inPlaces?:
                | Array<{
                    __typename?: 'InstitutionInPlace';
                    role?: InstitutionPlaceRole | null | undefined;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type MarketItemsQueryVariables = Exact<{
  filter: AnnouncementsByFilterInput;
  pageOffset: Scalars['Int'];
  pageSize: Scalars['Int'];
  orderBy?: Maybe<AnnouncementsOrderByInput>;
}>;

export type MarketItemsQuery = {
  __typename?: 'Query';
  announcementsBy: {
    __typename?: 'OpensearchAnnouncements';
    count: number;
    announcements: Array<
      | {
          __typename: 'Announcement';
          publishedAt?: string | null | undefined;
          subtitle?: string | null | undefined;
          marketUrl?: string | null | undefined;
          marketItemType?: MarketItemType | null | undefined;
          marketItemRating?: number | null | undefined;
          marketItemDuration?: number | null | undefined;
          marketItemInclusions?: Array<string> | null | undefined;
          marketItemExclusions?: Array<string> | null | undefined;
          marketItemAdditionalInfo?: Array<string> | null | undefined;
          marketItemOrder?: number | null | undefined;
          marketItemViatorProductCode?: string | null | undefined;
          createdAt: string;
          updatedAt: string;
          id: string;
          scheduledAt?: string | null | undefined;
          title: string;
          content: string;
          createdByAdmin?: boolean | null | undefined;
          publishmentState: State;
          notifyAlsoNonCategoried?: boolean | null | undefined;
          marketItemPricing?:
            | {
                __typename?: 'Pricing';
                currency: Currency;
                summary?:
                  | {
                      __typename?: 'PricingSummary';
                      fromPrice?: number | null | undefined;
                      fromPriceBeforeDiscount?: number | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          marketItemCancellationPolicy?:
            | {
                __typename?: 'MarketItemCancellationPolicy';
                description?: string | null | undefined;
              }
            | null
            | undefined;
          marketItemLogistics?:
            | {
                __typename?: 'MarketItemLogistics';
                startDescriptions?: Array<string> | null | undefined;
                endDescriptions?: Array<string> | null | undefined;
              }
            | null
            | undefined;
          category?:
            | {
                __typename?: 'Category';
                id: string;
                name: string;
                createdAt: string;
                categoryTypes: Array<CategoryType>;
                legacy?: boolean | null | undefined;
                isSystem?: boolean | null | undefined;
                images?:
                  | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          categories: Array<{
            __typename?: 'Category';
            id: string;
            name: string;
            createdAt: string;
            categoryTypes: Array<CategoryType>;
            legacy?: boolean | null | undefined;
            isSystem?: boolean | null | undefined;
            images?:
              | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
              | null
              | undefined;
          }>;
          areaOfInterest?:
            | {
                __typename?: 'GeoShapePolygon';
                polygonType: PolygonType;
                polygonCoordinates: Array<Array<Array<number>>>;
              }
            | {
                __typename?: 'GeoShapeCircle';
                type: CircleType;
                coordinates: Array<number>;
                radius: string;
              }
            | { __typename?: 'GeoShapeGeometryCollection' }
            | null
            | undefined;
        }
      | { __typename?: 'AnnouncementDraft' }
    >;
  };
};

export type MarketItemDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MarketItemDetailQuery = {
  __typename?: 'Query';
  announcement:
    | {
        __typename: 'Announcement';
        typename?: PostType | null | undefined;
        publishedAt?: string | null | undefined;
        subtitle?: string | null | undefined;
        marketUrl?: string | null | undefined;
        marketItemType?: MarketItemType | null | undefined;
        marketItemRating?: number | null | undefined;
        marketItemDuration?: number | null | undefined;
        marketItemInclusions?: Array<string> | null | undefined;
        marketItemExclusions?: Array<string> | null | undefined;
        marketItemAdditionalInfo?: Array<string> | null | undefined;
        marketItemOrder?: number | null | undefined;
        marketItemViatorProductCode?: string | null | undefined;
        content: string;
        createdAt: string;
        updatedAt: string;
        scheduledAt?: string | null | undefined;
        id: string;
        lang: string;
        title: string;
        createdByAdmin?: boolean | null | undefined;
        publishmentState: State;
        notifyAlsoNonCategoried?: boolean | null | undefined;
        notifyVolunteersOnly?: boolean | null | undefined;
        notifications?: Array<string> | null | undefined;
        notifyNow?: boolean | null | undefined;
        marketItemPricing?:
          | {
              __typename?: 'Pricing';
              currency: Currency;
              summary?:
                | {
                    __typename?: 'PricingSummary';
                    fromPrice?: number | null | undefined;
                    fromPriceBeforeDiscount?: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        marketItemCancellationPolicy?:
          | { __typename?: 'MarketItemCancellationPolicy'; description?: string | null | undefined }
          | null
          | undefined;
        marketItemLogistics?:
          | {
              __typename?: 'MarketItemLogistics';
              startDescriptions?: Array<string> | null | undefined;
              endDescriptions?: Array<string> | null | undefined;
            }
          | null
          | undefined;
        category?:
          | {
              __typename?: 'Category';
              id: string;
              name: string;
              createdAt: string;
              categoryTypes: Array<CategoryType>;
              legacy?: boolean | null | undefined;
              isSystem?: boolean | null | undefined;
              images?:
                | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
                | null
                | undefined;
            }
          | null
          | undefined;
        categories: Array<{
          __typename?: 'Category';
          id: string;
          name: string;
          createdAt: string;
          categoryTypes: Array<CategoryType>;
          legacy?: boolean | null | undefined;
          isSystem?: boolean | null | undefined;
          images?:
            | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
            | null
            | undefined;
        }>;
        imageObjects?:
          | Array<{
              __typename?: 'Image';
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              id: string;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }>
          | null
          | undefined;
        videoObjects?:
          | Array<{
              __typename?: 'Video';
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              id: string;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }>
          | null
          | undefined;
        location?:
          | {
              __typename?: 'EventLocation';
              name: string;
              gps?: { __typename?: 'GeoPoint'; lat: number; lon: number } | null | undefined;
            }
          | null
          | undefined;
        places: Array<{ __typename?: 'Place'; id: string; name: string }>;
        areaOfInterest?:
          | {
              __typename?: 'GeoShapePolygon';
              polygonType: PolygonType;
              polygonCoordinates: Array<Array<Array<number>>>;
            }
          | {
              __typename?: 'GeoShapeCircle';
              type: CircleType;
              coordinates: Array<number>;
              radius: string;
            }
          | { __typename?: 'GeoShapeGeometryCollection' }
          | null
          | undefined;
      }
    | { __typename?: 'Event' }
    | { __typename?: 'QuickQuestion' }
    | { __typename?: 'UserPost' }
    | { __typename?: 'AnnouncementDraft' }
    | { __typename?: 'EventDraft' }
    | { __typename?: 'QuickQuestionDraft' };
};

export type ModeratorPostByQueryVariables = Exact<{
  filter: ModerationPostsByFilterInput;
  pageOffset: Scalars['Int'];
  pageSize: Scalars['Int'];
}>;

export type ModeratorPostByQuery = {
  __typename?: 'Query';
  moderationPostsBy: {
    __typename?: 'ModerationPosts';
    totalCount: number;
    posts: Array<
      | {
          __typename: 'Announcement';
          id: string;
          title: string;
          publishedAt?: string | null | undefined;
          moderationCategory?: ModerationCategories | null | undefined;
          installationInteraction: {
            __typename?: 'PaginatedAnnouncementFeedbacks';
            fakeNewsCount?: number | null | undefined;
            hatefulCount?: number | null | undefined;
            inappropriateCount?: number | null | undefined;
            scamCount?: number | null | undefined;
            spamCount?: number | null | undefined;
            otherCount?: number | null | undefined;
          };
          categories: Array<{ __typename?: 'Category'; categoryTypes: Array<CategoryType> }>;
          aiMetadata?:
            | {
                __typename?: 'AiMetadata';
                hatespeech?:
                  | {
                      __typename?: 'Hatespeech';
                      probability?: number | null | undefined;
                      status?: InferenceStatus | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }
      | {
          __typename: 'Event';
          id: string;
          title: string;
          publishedAt?: string | null | undefined;
          moderationCategory?: ModerationCategories | null | undefined;
          installationInteraction: {
            __typename?: 'PaginatedEventFeedbacks';
            fakeNewsCount?: number | null | undefined;
            hatefulCount?: number | null | undefined;
            inappropriateCount?: number | null | undefined;
            scamCount?: number | null | undefined;
            spamCount?: number | null | undefined;
            otherCount?: number | null | undefined;
          };
          categories: Array<{ __typename?: 'Category'; categoryTypes: Array<CategoryType> }>;
          aiMetadata?:
            | {
                __typename?: 'AiMetadata';
                hatespeech?:
                  | {
                      __typename?: 'Hatespeech';
                      probability?: number | null | undefined;
                      status?: InferenceStatus | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }
      | { __typename: 'QuickQuestion'; id: string; question: string }
      | { __typename: 'UserPost' }
      | { __typename: 'AnnouncementDraft' }
      | { __typename: 'EventDraft' }
      | { __typename: 'QuickQuestionDraft' }
    >;
  };
};

export type UpdatePetMutationVariables = Exact<{
  id: Scalars['ID'];
  installationUuid: Scalars['ID'];
  pet: PetInput;
}>;

export type UpdatePetMutation = {
  __typename?: 'Mutation';
  updatePet?: { __typename?: 'Pet'; id: string } | null | undefined;
};

export type DeletePetMutationVariables = Exact<{
  id: Scalars['ID'];
  installationUuid?: Maybe<Scalars['ID']>;
}>;

export type DeletePetMutation = {
  __typename?: 'Mutation';
  deletePet?: { __typename?: 'Pet'; id: string } | null | undefined;
};

export type PublishPetMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PublishPetMutation = {
  __typename?: 'Mutation';
  publishPet?: { __typename?: 'Pet'; id: string } | null | undefined;
};

export type UnpublishPetMutationVariables = Exact<{
  id: Scalars['ID'];
  batch?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type UnpublishPetMutation = {
  __typename?: 'Mutation';
  unpublishPet?: { __typename?: 'Pet'; id: string } | null | undefined;
};

export type PetsByQueryVariables = Exact<{
  filter: PetsByFilter;
  orderBy?: Maybe<PetsOrderBy>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type PetsByQuery = {
  __typename?: 'Query';
  petsBy: {
    __typename?: 'PaginatedPets';
    nextToken?: string | null | undefined;
    pets: Array<
      | {
          __typename?: 'Pet';
          id: string;
          name?: string | null | undefined;
          phoneNumber?: string | null | undefined;
          email?: string | null | undefined;
          reward?: string | null | undefined;
          state: PetState;
          description?: string | null | undefined;
          tags?: Array<string> | null | undefined;
          placeUuid: string;
          createdAt: string;
          updatedAt: string;
          lostAt?: string | null | undefined;
          foundAt?: string | null | undefined;
          approvalState: ApprovalState;
          imageObjects?:
            | Array<{
                __typename?: 'Image';
                id: string;
                blurHash?: string | null | undefined;
                fileName?: string | null | undefined;
                filePath?: string | null | undefined;
                links?:
                  | { __typename?: 'Links'; self: string; thumbnail?: string | null | undefined }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }
      | null
      | undefined
    >;
  };
};

export type PetsInPlaceByQueryVariables = Exact<{
  filter?: Maybe<PetsInPlaceByFilter>;
  orderBy?: Maybe<PetsOrderBy>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type PetsInPlaceByQuery = {
  __typename?: 'Query';
  petsInPlaceBy: {
    __typename?: 'PaginatedPets';
    nextToken?: string | null | undefined;
    pets: Array<
      | {
          __typename?: 'Pet';
          id: string;
          name?: string | null | undefined;
          phoneNumber?: string | null | undefined;
          email?: string | null | undefined;
          reward?: string | null | undefined;
          state: PetState;
          description?: string | null | undefined;
          tags?: Array<string> | null | undefined;
          placeUuid: string;
          createdAt: string;
          updatedAt: string;
          lostAt?: string | null | undefined;
          foundAt?: string | null | undefined;
          approvalState: ApprovalState;
          imageObjects?:
            | Array<{
                __typename?: 'Image';
                id: string;
                blurHash?: string | null | undefined;
                fileName?: string | null | undefined;
                filePath?: string | null | undefined;
                links?:
                  | { __typename?: 'Links'; self: string; thumbnail?: string | null | undefined }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }
      | null
      | undefined
    >;
  };
};

type PollsFields_Announcement_Fragment = {
  __typename: 'Announcement';
  createdAt: string;
  updatedAt: string;
  id: string;
  scheduledAt?: string | null | undefined;
  title: string;
  createdByAdmin?: boolean | null | undefined;
  publishmentState: State;
  content: string;
  category?:
    | {
        __typename?: 'Category';
        id: string;
        name: string;
        createdAt: string;
        categoryTypes: Array<CategoryType>;
        legacy?: boolean | null | undefined;
        isSystem?: boolean | null | undefined;
        images?:
          | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
  categories: Array<{
    __typename?: 'Category';
    id: string;
    name: string;
    createdAt: string;
    categoryTypes: Array<CategoryType>;
    legacy?: boolean | null | undefined;
    isSystem?: boolean | null | undefined;
    images?:
      | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
      | null
      | undefined;
  }>;
};

type PollsFields_AnnouncementDraft_Fragment = {
  __typename: 'AnnouncementDraft';
  createdAt: string;
  updatedAt: string;
  id: string;
  scheduledAt?: string | null | undefined;
  title: string;
  createdByAdmin?: boolean | null | undefined;
  publishmentState: State;
  content?: string | null | undefined;
  category?:
    | {
        __typename?: 'Category';
        id: string;
        name: string;
        createdAt: string;
        categoryTypes: Array<CategoryType>;
        legacy?: boolean | null | undefined;
        isSystem?: boolean | null | undefined;
        images?:
          | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
  categories?:
    | Array<{
        __typename?: 'Category';
        id: string;
        name: string;
        createdAt: string;
        categoryTypes: Array<CategoryType>;
        legacy?: boolean | null | undefined;
        isSystem?: boolean | null | undefined;
        images?:
          | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type PollsFieldsFragment =
  | PollsFields_Announcement_Fragment
  | PollsFields_AnnouncementDraft_Fragment;

export type PollsQueryVariables = Exact<{
  filter: AnnouncementsByFilterInput;
  pageOffset: Scalars['Int'];
  pageSize: Scalars['Int'];
  orderBy?: Maybe<AnnouncementsOrderByInput>;
}>;

export type PollsQuery = {
  __typename?: 'Query';
  announcementsBy: {
    __typename?: 'OpensearchAnnouncements';
    count: number;
    announcements: Array<
      | {
          __typename: 'Announcement';
          publishedAt?: string | null | undefined;
          createdAt: string;
          updatedAt: string;
          id: string;
          scheduledAt?: string | null | undefined;
          title: string;
          createdByAdmin?: boolean | null | undefined;
          publishmentState: State;
          content: string;
          category?:
            | {
                __typename?: 'Category';
                id: string;
                name: string;
                createdAt: string;
                categoryTypes: Array<CategoryType>;
                legacy?: boolean | null | undefined;
                isSystem?: boolean | null | undefined;
                images?:
                  | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          categories: Array<{
            __typename?: 'Category';
            id: string;
            name: string;
            createdAt: string;
            categoryTypes: Array<CategoryType>;
            legacy?: boolean | null | undefined;
            isSystem?: boolean | null | undefined;
            images?:
              | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
              | null
              | undefined;
          }>;
        }
      | { __typename?: 'AnnouncementDraft' }
    >;
  };
};

export type PollDraftsQueryVariables = Exact<{
  filter: AnnouncementsByFilterInput;
  pageOffset: Scalars['Int'];
  pageSize: Scalars['Int'];
  orderBy?: Maybe<AnnouncementsOrderByInput>;
}>;

export type PollDraftsQuery = {
  __typename?: 'Query';
  announcementsBy: {
    __typename?: 'OpensearchAnnouncements';
    count: number;
    announcements: Array<
      | { __typename?: 'Announcement' }
      | {
          __typename: 'AnnouncementDraft';
          createdAt: string;
          updatedAt: string;
          id: string;
          scheduledAt?: string | null | undefined;
          title: string;
          createdByAdmin?: boolean | null | undefined;
          publishmentState: State;
          content?: string | null | undefined;
          category?:
            | {
                __typename?: 'Category';
                id: string;
                name: string;
                createdAt: string;
                categoryTypes: Array<CategoryType>;
                legacy?: boolean | null | undefined;
                isSystem?: boolean | null | undefined;
                images?:
                  | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          categories?:
            | Array<{
                __typename?: 'Category';
                id: string;
                name: string;
                createdAt: string;
                categoryTypes: Array<CategoryType>;
                legacy?: boolean | null | undefined;
                isSystem?: boolean | null | undefined;
                images?:
                  | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }
    >;
  };
};

export type InstitutionPollsCountsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type InstitutionPollsCountsQuery = {
  __typename?: 'Query';
  institution?:
    | {
        __typename?: 'Institution';
        publishedPollNewsCount?: number | null | undefined;
        unpublishedPollNewsCount?: number | null | undefined;
        draftsPollNewsCount?: number | null | undefined;
        scheduledPollNewsCount?: number | null | undefined;
      }
    | null
    | undefined;
};

export type AddQuickQuestionMutationVariables = Exact<{
  quickQuestion: QuickQuestionInput;
}>;

export type AddQuickQuestionMutation = {
  __typename?: 'Mutation';
  addQuickQuestion: { __typename?: 'QuickQuestion'; id: string };
};

export type UpdateQuickQuestionMutationVariables = Exact<{
  id: Scalars['ID'];
  quickQuestion: QuickQuestionInput;
}>;

export type UpdateQuickQuestionMutation = {
  __typename?: 'Mutation';
  updateQuickQuestion: { __typename?: 'QuickQuestion'; id: string };
};

export type DeleteQuickQuestionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteQuickQuestionMutation = {
  __typename?: 'Mutation';
  deleteQuickQuestion:
    | { __typename?: 'QuickQuestion'; id: string }
    | { __typename?: 'QuickQuestionDraft'; id: string };
};

export type PublishQuickQuestionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PublishQuickQuestionMutation = {
  __typename?: 'Mutation';
  publishQuickQuestion?: { __typename?: 'QuickQuestion'; id: string } | null | undefined;
};

export type UnpublishQuickQuestionMutationVariables = Exact<{
  id: Scalars['ID'];
  batch?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type UnpublishQuickQuestionMutation = {
  __typename?: 'Mutation';
  unpublishQuickQuestion?: { __typename?: 'QuickQuestion'; id: string } | null | undefined;
};

export type CancelScheduledQuickQuestionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CancelScheduledQuickQuestionMutation = {
  __typename?: 'Mutation';
  cancelScheduledQuickQuestion?:
    | { __typename?: 'QuickQuestionDraft'; id: string }
    | null
    | undefined;
};

export type QuickQuestionQueryVariables = Exact<{
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;

export type QuickQuestionQuery = {
  __typename?: 'Query';
  post:
    | { __typename?: 'Announcement' }
    | { __typename?: 'Event' }
    | {
        __typename: 'QuickQuestion';
        id: string;
        question: string;
        questionOptions: string;
        quickQuestionEndDate: string;
        publishedAt?: string | null | undefined;
        publishmentState: State;
        notifications?: Array<string> | null | undefined;
        notifyNow?: boolean | null | undefined;
        lang: string;
        scheduledAt?: string | null | undefined;
        privateResults: boolean;
        institution?: { __typename?: 'Institution'; id: string; name: string } | null | undefined;
      }
    | { __typename?: 'UserPost' }
    | { __typename?: 'AnnouncementDraft' }
    | { __typename?: 'EventDraft' }
    | { __typename?: 'QuickQuestionDraft' };
};

export type QuickQuestionsByQueryVariables = Exact<{
  filter: QuickQuestionsByFilterInput;
  orderBy?: Maybe<QuickQuestionsOrderByInput>;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type QuickQuestionsByQuery = {
  __typename?: 'Query';
  quickQuestionsBy: {
    __typename?: 'PaginatedQuickQuestions';
    count?: number | null | undefined;
    nextToken?: string | null | undefined;
    quickQuestions: Array<
      | {
          __typename: 'QuickQuestion';
          id: string;
          question: string;
          questionOptions: string;
          quickQuestionEndDate: string;
          publishedAt?: string | null | undefined;
          publishmentState: State;
          notifications?: Array<string> | null | undefined;
          notifyNow?: boolean | null | undefined;
          lang: string;
          scheduledAt?: string | null | undefined;
          privateResults: boolean;
          institution?:
            | {
                __typename?: 'Institution';
                id: string;
                name: string;
                branding?:
                  | {
                      __typename?: 'Branding';
                      avatar: {
                        __typename?: 'Image';
                        links?: { __typename?: 'Links'; self: string } | null | undefined;
                      };
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }
      | {
          __typename: 'QuickQuestionDraft';
          id: string;
          question: string;
          questionOptions?: string | null | undefined;
          quickQuestionEndDate?: string | null | undefined;
          publishedAt?: string | null | undefined;
          publishmentState: State;
          notifications?: Array<string> | null | undefined;
          notifyNow?: boolean | null | undefined;
          lang: string;
          scheduledAt?: string | null | undefined;
          privateResults?: boolean | null | undefined;
          institution?:
            | {
                __typename?: 'Institution';
                id: string;
                name: string;
                branding?:
                  | {
                      __typename?: 'Branding';
                      avatar: {
                        __typename?: 'Image';
                        links?: { __typename?: 'Links'; self: string } | null | undefined;
                      };
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }
    >;
  };
};

export type RaffleDetailFragmentFragment = {
  __typename: 'Announcement';
  content: string;
  createdAt: string;
  updatedAt: string;
  scheduledAt?: string | null | undefined;
  id: string;
  lang: string;
  title: string;
  subtitle?: string | null | undefined;
  createdByAdmin?: boolean | null | undefined;
  publishmentState: State;
  notifications?: Array<string> | null | undefined;
  notifyNow?: boolean | null | undefined;
  category?:
    | {
        __typename?: 'Category';
        id: string;
        name: string;
        createdAt: string;
        categoryTypes: Array<CategoryType>;
        legacy?: boolean | null | undefined;
        isSystem?: boolean | null | undefined;
        images?:
          | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
  categories: Array<{
    __typename?: 'Category';
    id: string;
    name: string;
    createdAt: string;
    categoryTypes: Array<CategoryType>;
    legacy?: boolean | null | undefined;
    isSystem?: boolean | null | undefined;
    images?:
      | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
      | null
      | undefined;
  }>;
  imageObjects?:
    | Array<{
        __typename?: 'Image';
        id: string;
        fileName?: string | null | undefined;
        filePath?: string | null | undefined;
        links?: { __typename?: 'Links'; self: string } | null | undefined;
      }>
    | null
    | undefined;
  raffleOptions?:
    | {
        __typename?: 'RaffleOptions';
        titleWidth?: number | null | undefined;
        textWidth?: number | null | undefined;
        textColor?: string | null | undefined;
        textPosition?: string | null | undefined;
        datetimeFrom?: string | null | undefined;
        datetimeTo?: string | null | undefined;
        showIncChanceFlag?: boolean | null | undefined;
        termsAndConditionsUrl?: string | null | undefined;
        privacyPolicyUrl?: string | null | undefined;
      }
    | null
    | undefined;
  location?:
    | {
        __typename?: 'EventLocation';
        name: string;
        gps?: { __typename?: 'GeoPoint'; lat: number; lon: number } | null | undefined;
      }
    | null
    | undefined;
  places: Array<{ __typename?: 'Place'; id: string; name: string }>;
};

export type RaffleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RaffleQuery = {
  __typename?: 'Query';
  announcement:
    | {
        __typename: 'Announcement';
        content: string;
        createdAt: string;
        updatedAt: string;
        scheduledAt?: string | null | undefined;
        id: string;
        lang: string;
        title: string;
        subtitle?: string | null | undefined;
        createdByAdmin?: boolean | null | undefined;
        publishmentState: State;
        notifications?: Array<string> | null | undefined;
        notifyNow?: boolean | null | undefined;
        category?:
          | {
              __typename?: 'Category';
              id: string;
              name: string;
              createdAt: string;
              categoryTypes: Array<CategoryType>;
              legacy?: boolean | null | undefined;
              isSystem?: boolean | null | undefined;
              images?:
                | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
                | null
                | undefined;
            }
          | null
          | undefined;
        categories: Array<{
          __typename?: 'Category';
          id: string;
          name: string;
          createdAt: string;
          categoryTypes: Array<CategoryType>;
          legacy?: boolean | null | undefined;
          isSystem?: boolean | null | undefined;
          images?:
            | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
            | null
            | undefined;
        }>;
        imageObjects?:
          | Array<{
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }>
          | null
          | undefined;
        raffleOptions?:
          | {
              __typename?: 'RaffleOptions';
              titleWidth?: number | null | undefined;
              textWidth?: number | null | undefined;
              textColor?: string | null | undefined;
              textPosition?: string | null | undefined;
              datetimeFrom?: string | null | undefined;
              datetimeTo?: string | null | undefined;
              showIncChanceFlag?: boolean | null | undefined;
              termsAndConditionsUrl?: string | null | undefined;
              privacyPolicyUrl?: string | null | undefined;
            }
          | null
          | undefined;
        location?:
          | {
              __typename?: 'EventLocation';
              name: string;
              gps?: { __typename?: 'GeoPoint'; lat: number; lon: number } | null | undefined;
            }
          | null
          | undefined;
        places: Array<{ __typename?: 'Place'; id: string; name: string }>;
      }
    | { __typename?: 'Event' }
    | { __typename?: 'QuickQuestion' }
    | { __typename?: 'UserPost' }
    | { __typename?: 'AnnouncementDraft' }
    | { __typename?: 'EventDraft' }
    | { __typename?: 'QuickQuestionDraft' };
};

type RafflesFields_Announcement_Fragment = {
  __typename: 'Announcement';
  createdAt: string;
  updatedAt: string;
  id: string;
  scheduledAt?: string | null | undefined;
  title: string;
  createdByAdmin?: boolean | null | undefined;
  publishmentState: State;
  content: string;
  category?:
    | {
        __typename?: 'Category';
        id: string;
        name: string;
        createdAt: string;
        categoryTypes: Array<CategoryType>;
        legacy?: boolean | null | undefined;
        isSystem?: boolean | null | undefined;
        images?:
          | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
  categories: Array<{
    __typename?: 'Category';
    id: string;
    name: string;
    createdAt: string;
    categoryTypes: Array<CategoryType>;
    legacy?: boolean | null | undefined;
    isSystem?: boolean | null | undefined;
    images?:
      | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
      | null
      | undefined;
  }>;
  raffleOptions?:
    | {
        __typename?: 'RaffleOptions';
        titleWidth?: number | null | undefined;
        textWidth?: number | null | undefined;
        textColor?: string | null | undefined;
        textPosition?: string | null | undefined;
        datetimeFrom?: string | null | undefined;
        datetimeTo?: string | null | undefined;
        showIncChanceFlag?: boolean | null | undefined;
        termsAndConditionsUrl?: string | null | undefined;
        privacyPolicyUrl?: string | null | undefined;
      }
    | null
    | undefined;
};

type RafflesFields_AnnouncementDraft_Fragment = {
  __typename: 'AnnouncementDraft';
  createdAt: string;
  updatedAt: string;
  id: string;
  scheduledAt?: string | null | undefined;
  title: string;
  createdByAdmin?: boolean | null | undefined;
  publishmentState: State;
  content?: string | null | undefined;
  category?:
    | {
        __typename?: 'Category';
        id: string;
        name: string;
        createdAt: string;
        categoryTypes: Array<CategoryType>;
        legacy?: boolean | null | undefined;
        isSystem?: boolean | null | undefined;
        images?:
          | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
  categories?:
    | Array<{
        __typename?: 'Category';
        id: string;
        name: string;
        createdAt: string;
        categoryTypes: Array<CategoryType>;
        legacy?: boolean | null | undefined;
        isSystem?: boolean | null | undefined;
        images?:
          | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
          | null
          | undefined;
      }>
    | null
    | undefined;
  raffleOptions?:
    | {
        __typename?: 'RaffleOptions';
        titleWidth?: number | null | undefined;
        textWidth?: number | null | undefined;
        textColor?: string | null | undefined;
        textPosition?: string | null | undefined;
        datetimeFrom?: string | null | undefined;
        datetimeTo?: string | null | undefined;
        showIncChanceFlag?: boolean | null | undefined;
        termsAndConditionsUrl?: string | null | undefined;
        privacyPolicyUrl?: string | null | undefined;
      }
    | null
    | undefined;
};

export type RafflesFieldsFragment =
  | RafflesFields_Announcement_Fragment
  | RafflesFields_AnnouncementDraft_Fragment;

export type RafflesQueryVariables = Exact<{
  filter: AnnouncementsByFilterInput;
  pageOffset: Scalars['Int'];
  pageSize: Scalars['Int'];
  orderBy?: Maybe<AnnouncementsOrderByInput>;
}>;

export type RafflesQuery = {
  __typename?: 'Query';
  announcementsBy: {
    __typename?: 'OpensearchAnnouncements';
    count: number;
    announcements: Array<
      | {
          __typename: 'Announcement';
          publishedAt?: string | null | undefined;
          createdAt: string;
          updatedAt: string;
          id: string;
          scheduledAt?: string | null | undefined;
          title: string;
          createdByAdmin?: boolean | null | undefined;
          publishmentState: State;
          content: string;
          category?:
            | {
                __typename?: 'Category';
                id: string;
                name: string;
                createdAt: string;
                categoryTypes: Array<CategoryType>;
                legacy?: boolean | null | undefined;
                isSystem?: boolean | null | undefined;
                images?:
                  | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          categories: Array<{
            __typename?: 'Category';
            id: string;
            name: string;
            createdAt: string;
            categoryTypes: Array<CategoryType>;
            legacy?: boolean | null | undefined;
            isSystem?: boolean | null | undefined;
            images?:
              | { __typename?: 'CategoryImages'; svgIcon?: string | null | undefined }
              | null
              | undefined;
          }>;
          raffleOptions?:
            | {
                __typename?: 'RaffleOptions';
                titleWidth?: number | null | undefined;
                textWidth?: number | null | undefined;
                textColor?: string | null | undefined;
                textPosition?: string | null | undefined;
                datetimeFrom?: string | null | undefined;
                datetimeTo?: string | null | undefined;
                showIncChanceFlag?: boolean | null | undefined;
                termsAndConditionsUrl?: string | null | undefined;
                privacyPolicyUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | { __typename?: 'AnnouncementDraft' }
    >;
  };
};

export type GetServiceInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetServiceInfoQuery = {
  __typename?: 'Query';
  service?: { __typename?: 'Service'; version: string } | null | undefined;
};

export type SaBusinessNotificationsQueryVariables = Exact<{
  filter: BusinessNotificationCountsFilterInput;
  nextToken?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;

export type SaBusinessNotificationsQuery = {
  __typename?: 'Query';
  businessNotificationCounts: {
    __typename?: 'PaginatedBusinessNotificationCounts';
    nextToken?: string | null | undefined;
    places: Array<{
      __typename?: 'BusinessNotificationCounts';
      placeUuid: string;
      name: string;
      counts: Array<{
        __typename?: 'BusinessMetricsNotificationCounts';
        count: number;
        date: string;
      }>;
    }>;
  };
};

export type BusinessActiveUsersCountsQueryVariables = Exact<{
  filter?: Maybe<BusinessActiveUsersCountsFilterInput>;
  nextToken?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;

export type BusinessActiveUsersCountsQuery = {
  __typename?: 'Query';
  businessActiveUsersCounts: {
    __typename?: 'PaginatedBusinessActiveUsersCounts';
    nextToken?: string | null | undefined;
    places: Array<{
      __typename?: 'BusinessActiveUsersCounts';
      placeUuid: string;
      name: string;
      today: number;
      yesterday: number;
      thirtyDays: number;
    }>;
  };
};

export type SaAddInfluencerMutationVariables = Exact<{
  influencer: InfluencerInput;
}>;

export type SaAddInfluencerMutation = {
  __typename?: 'Mutation';
  addInfluencer: { __typename?: 'Influencer'; id: string };
};

export type SaUpdateInfluencerMutationVariables = Exact<{
  influencer: InfluencerInput;
  id: Scalars['ID'];
}>;

export type SaUpdateInfluencerMutation = {
  __typename?: 'Mutation';
  updateInfluencer: { __typename?: 'Influencer'; id: string };
};

export type SaDeleteInfluencerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SaDeleteInfluencerMutation = {
  __typename?: 'Mutation';
  deleteInfluencer: { __typename?: 'Influencer'; id: string };
};

export type SaAddInfluencerSpotMutationVariables = Exact<{
  influencerSpot: InfluencerSpotInput;
}>;

export type SaAddInfluencerSpotMutation = {
  __typename?: 'Mutation';
  addInfluencerSpot: { __typename?: 'InfluencerSpot'; id: string };
};

export type SaUpdateInfluencerSpotMutationVariables = Exact<{
  influencerSpot: InfluencerSpotInput;
  id: Scalars['ID'];
}>;

export type SaUpdateInfluencerSpotMutation = {
  __typename?: 'Mutation';
  updateInfluencerSpot: { __typename?: 'InfluencerSpot'; id: string };
};

export type SaDeleteInfluencerSpotMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SaDeleteInfluencerSpotMutation = {
  __typename?: 'Mutation';
  deleteInfluencerSpot: { __typename?: 'InfluencerSpot'; id: string };
};

export type SaInfluencersQueryVariables = Exact<{
  filter: InfluencersByFilterInput;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type SaInfluencersQuery = {
  __typename?: 'Query';
  influencersBy: {
    __typename?: 'PaginatedInfluencers';
    nextToken?: string | null | undefined;
    influencers: Array<{
      __typename?: 'Influencer';
      id: string;
      name: string;
      description?: string | null | undefined;
      avatar?:
        | {
            __typename?: 'Image';
            fileName?: string | null | undefined;
            filePath?: string | null | undefined;
            id: string;
            links?: { __typename?: 'Links'; self: string } | null | undefined;
          }
        | null
        | undefined;
      background?:
        | {
            __typename?: 'Image';
            fileName?: string | null | undefined;
            filePath?: string | null | undefined;
            id: string;
            links?: { __typename?: 'Links'; self: string } | null | undefined;
          }
        | null
        | undefined;
      socials: Array<{ __typename?: 'InfluencerSocials'; type: SocialType; url: string }>;
      properties: Array<{ __typename?: 'InfluencerProperty'; name: PropertyType; value: string }>;
      places: Array<{ __typename?: 'Place'; id: string; name: string }>;
    }>;
  };
};

export type SaInfluencerSpotsQueryVariables = Exact<{
  filter: InfluencerSpotsByFilter;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type SaInfluencerSpotsQuery = {
  __typename?: 'Query';
  influencerSpotsBy: {
    __typename?: 'PaginatedInfluencerSpots';
    nextToken?: string | null | undefined;
    influencerSpots: Array<{
      __typename?: 'InfluencerSpot';
      id: string;
      placeUuid: string;
      influencerUuid: string;
      title: string;
      content?: string | null | undefined;
      startTime?: string | null | undefined;
      endTime?: string | null | undefined;
      duration?: number | null | undefined;
      favourite: boolean;
      image?:
        | {
            __typename?: 'Image';
            fileName?: string | null | undefined;
            filePath?: string | null | undefined;
            id: string;
            links?: { __typename?: 'Links'; self: string } | null | undefined;
          }
        | null
        | undefined;
      location?:
        | {
            __typename?: 'Location';
            address: string;
            geo: { __typename?: 'GeoPoint'; lat: number; lon: number };
          }
        | null
        | undefined;
      marketItem?:
        | { __typename?: 'TUIMarketItem'; id: string; title: string }
        | { __typename?: 'CustomMarketItem'; id: string; title: string }
        | { __typename?: 'ViatorMarketItem'; id: string; title: string }
        | null
        | undefined;
      price?: { __typename?: 'Price'; currency: Currency; amount: number } | null | undefined;
      category?: { __typename?: 'Category'; id: string; name: string } | null | undefined;
    }>;
  };
};

export type ImageFragmentFragment = {
  __typename?: 'Image';
  fileName?: string | null | undefined;
  filePath?: string | null | undefined;
  id: string;
  links?: { __typename?: 'Links'; self: string } | null | undefined;
};

export type SaInfluencerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SaInfluencerQuery = {
  __typename?: 'Query';
  influencer: {
    __typename?: 'Influencer';
    id: string;
    name: string;
    description?: string | null | undefined;
    avatar?:
      | {
          __typename?: 'Image';
          fileName?: string | null | undefined;
          filePath?: string | null | undefined;
          id: string;
          links?: { __typename?: 'Links'; self: string } | null | undefined;
        }
      | null
      | undefined;
    background?:
      | {
          __typename?: 'Image';
          fileName?: string | null | undefined;
          filePath?: string | null | undefined;
          id: string;
          links?: { __typename?: 'Links'; self: string } | null | undefined;
        }
      | null
      | undefined;
    socials: Array<{ __typename?: 'InfluencerSocials'; type: SocialType; url: string }>;
    properties: Array<{ __typename?: 'InfluencerProperty'; name: PropertyType; value: string }>;
    places: Array<{ __typename?: 'Place'; id: string; name: string }>;
  };
};

export type SaInfluencerSpotQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SaInfluencerSpotQuery = {
  __typename?: 'Query';
  influencerSpot: {
    __typename?: 'InfluencerSpot';
    id: string;
    placeUuid: string;
    influencerUuid: string;
    title: string;
    content?: string | null | undefined;
    startTime?: string | null | undefined;
    endTime?: string | null | undefined;
    duration?: number | null | undefined;
    favourite: boolean;
    image?:
      | {
          __typename?: 'Image';
          fileName?: string | null | undefined;
          filePath?: string | null | undefined;
          id: string;
          links?: { __typename?: 'Links'; self: string } | null | undefined;
        }
      | null
      | undefined;
    location?:
      | {
          __typename?: 'Location';
          address: string;
          geo: { __typename?: 'GeoPoint'; lat: number; lon: number };
        }
      | null
      | undefined;
    marketItem?:
      | { __typename?: 'TUIMarketItem'; id: string; title: string }
      | { __typename?: 'CustomMarketItem'; id: string; title: string }
      | { __typename?: 'ViatorMarketItem'; id: string; title: string }
      | null
      | undefined;
    price?: { __typename?: 'Price'; currency: Currency; amount: number } | null | undefined;
    category?: { __typename?: 'Category'; id: string; name: string } | null | undefined;
  };
};

export type SaInfluencerSpotPlaceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SaInfluencerSpotPlaceQuery = {
  __typename?: 'Query';
  place?: { __typename?: 'Place'; id: string; name: string } | null | undefined;
};

export type SaMarketItemsByQueryVariables = Exact<{
  filter: MarketItemsByFilterInput;
  nextToken?: Maybe<Scalars['String']>;
}>;

export type SaMarketItemsByQuery = {
  __typename?: 'Query';
  marketItemsBy: {
    __typename?: 'PaginatedMarketItemsV2';
    nextToken?: string | null | undefined;
    marketItems: Array<
      | {
          __typename?: 'TUIMarketItem';
          id: string;
          title: string;
          content?: string | null | undefined;
          placeUuid: string;
          duration?: number | null | undefined;
          imageObjects?:
            | Array<{
                __typename?: 'Image';
                fileName?: string | null | undefined;
                filePath?: string | null | undefined;
                id: string;
                links?: { __typename?: 'Links'; self: string } | null | undefined;
              }>
            | null
            | undefined;
          gpsLocation?: { __typename?: 'GeoPoint'; lat: number; lon: number } | null | undefined;
          price?:
            | {
                __typename?: 'MarketItemPrice';
                retailPrice?: number | null | undefined;
                currency: Currency;
              }
            | null
            | undefined;
        }
      | {
          __typename?: 'CustomMarketItem';
          id: string;
          title: string;
          content?: string | null | undefined;
          placeUuid: string;
          duration?: number | null | undefined;
          imageObjects?:
            | Array<{
                __typename?: 'Image';
                fileName?: string | null | undefined;
                filePath?: string | null | undefined;
                id: string;
                links?: { __typename?: 'Links'; self: string } | null | undefined;
              }>
            | null
            | undefined;
          gpsLocation?: { __typename?: 'GeoPoint'; lat: number; lon: number } | null | undefined;
          price?:
            | {
                __typename?: 'MarketItemPrice';
                retailPrice?: number | null | undefined;
                currency: Currency;
              }
            | null
            | undefined;
        }
      | {
          __typename?: 'ViatorMarketItem';
          id: string;
          title: string;
          content?: string | null | undefined;
          placeUuid: string;
          duration?: number | null | undefined;
          imageObjects?:
            | Array<{
                __typename?: 'Image';
                fileName?: string | null | undefined;
                filePath?: string | null | undefined;
                id: string;
                links?: { __typename?: 'Links'; self: string } | null | undefined;
              }>
            | null
            | undefined;
          gpsLocation?: { __typename?: 'GeoPoint'; lat: number; lon: number } | null | undefined;
          price?:
            | {
                __typename?: 'MarketItemPrice';
                retailPrice?: number | null | undefined;
                currency: Currency;
              }
            | null
            | undefined;
        }
    >;
  };
};

export type SaAddInstitutionMutationVariables = Exact<{
  institution: InstitutionInput;
}>;

export type SaAddInstitutionMutation = {
  __typename?: 'Mutation';
  addInstitution: { __typename?: 'Institution'; id: string };
};

export type SaDeleteInstitutionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SaDeleteInstitutionMutation = {
  __typename?: 'Mutation';
  deleteInstitution?: { __typename?: 'Institution'; id: string } | null | undefined;
};

export type SaHideInstitutionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SaHideInstitutionMutation = {
  __typename?: 'Mutation';
  hideInstitution?: { __typename?: 'Institution'; id: string } | null | undefined;
};

export type SaUpdateInstitutionMutationVariables = Exact<{
  id: Scalars['ID'];
  institution: InstitutionInput;
}>;

export type SaUpdateInstitutionMutation = {
  __typename?: 'Mutation';
  updateInstitution: { __typename?: 'Institution'; id: string };
};

export type SaAddInstitutionRoleMutationVariables = Exact<{
  id: Scalars['ID'];
  inPlaces: Array<InstitutionInPlaceInput> | InstitutionInPlaceInput;
}>;

export type SaAddInstitutionRoleMutation = {
  __typename?: 'Mutation';
  putInstitutionPlaces?: { __typename?: 'Institution'; id: string } | null | undefined;
};

export type SaAllInstitutionsQueryVariables = Exact<{
  nextToken?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;

export type SaAllInstitutionsQuery = {
  __typename?: 'Query';
  allInstitutions: {
    __typename?: 'PaginatedInstitutions';
    nextToken?: string | null | undefined;
    institutions: Array<{
      __typename?: 'Institution';
      id: string;
      enabled?: string | null | undefined;
      createdAt: string;
      name: string;
      places?: Array<{ __typename?: 'Place'; id: string; name: string }> | null | undefined;
      category: { __typename?: 'Category'; id: string; name: string };
      info?:
        | {
            __typename?: 'InstitutionInfo';
            contactEmail?: string | null | undefined;
            contactPhone?: string | null | undefined;
            description?: string | null | undefined;
            website?: string | null | undefined;
            location?:
              | {
                  __typename?: 'Location';
                  address: string;
                  geo: { __typename?: 'GeoPoint'; lat: number; lon: number };
                }
              | null
              | undefined;
          }
        | null
        | undefined;
      branding?:
        | {
            __typename?: 'Branding';
            logo: {
              __typename?: 'Image';
              id: string;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            };
            avatar: {
              __typename?: 'Image';
              id: string;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            };
          }
        | null
        | undefined;
      inPlaces?:
        | Array<{
            __typename?: 'InstitutionInPlace';
            role?: InstitutionPlaceRole | null | undefined;
          }>
        | null
        | undefined;
    }>;
  };
};

export type SaInstitutionsByQueryVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>;
  filter: InstitutionsByFilterInput;
}>;

export type SaInstitutionsByQuery = {
  __typename?: 'Query';
  institutionsBy: {
    __typename?: 'PaginatedInstitutions';
    nextToken?: string | null | undefined;
    institutions: Array<{
      __typename?: 'Institution';
      id: string;
      enabled?: string | null | undefined;
      createdAt: string;
      name: string;
      taskCodes?: Array<string> | null | undefined;
      places?: Array<{ __typename?: 'Place'; id: string; name: string }> | null | undefined;
      category: { __typename?: 'Category'; id: string; name: string };
      info?:
        | {
            __typename?: 'InstitutionInfo';
            contactEmail?: string | null | undefined;
            contactPhone?: string | null | undefined;
            description?: string | null | undefined;
            website?: string | null | undefined;
            location?:
              | {
                  __typename?: 'Location';
                  address: string;
                  geo: { __typename?: 'GeoPoint'; lat: number; lon: number };
                }
              | null
              | undefined;
          }
        | null
        | undefined;
      branding?:
        | {
            __typename?: 'Branding';
            logo: {
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            };
            avatar: {
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            };
          }
        | null
        | undefined;
      inPlaces?:
        | Array<{
            __typename?: 'InstitutionInPlace';
            role?: InstitutionPlaceRole | null | undefined;
          }>
        | null
        | undefined;
    }>;
  };
};

export type SaInstitutionsBySearchQueryVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>;
  filter: InstitutionsBySearchFilterInput;
  pageOffset?: Maybe<Scalars['Int']>;
}>;

export type SaInstitutionsBySearchQuery = {
  __typename?: 'Query';
  institutionsBySearch: {
    __typename?: 'PaginatedInstitutions';
    nextToken?: string | null | undefined;
    institutions: Array<{
      __typename?: 'Institution';
      id: string;
      enabled?: string | null | undefined;
      createdAt: string;
      name: string;
      taskCodes?: Array<string> | null | undefined;
      places?: Array<{ __typename?: 'Place'; id: string; name: string }> | null | undefined;
      category: { __typename?: 'Category'; id: string; name: string };
      info?:
        | {
            __typename?: 'InstitutionInfo';
            contactEmail?: string | null | undefined;
            contactPhone?: string | null | undefined;
            description?: string | null | undefined;
            website?: string | null | undefined;
            location?:
              | {
                  __typename?: 'Location';
                  address: string;
                  geo: { __typename?: 'GeoPoint'; lat: number; lon: number };
                }
              | null
              | undefined;
          }
        | null
        | undefined;
      branding?:
        | {
            __typename?: 'Branding';
            logo: {
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            };
            avatar: {
              __typename?: 'Image';
              id: string;
              fileName?: string | null | undefined;
              filePath?: string | null | undefined;
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            };
          }
        | null
        | undefined;
      inPlaces?:
        | Array<{
            __typename?: 'InstitutionInPlace';
            role?: InstitutionPlaceRole | null | undefined;
          }>
        | null
        | undefined;
    }>;
  };
};

export type SaInstitutionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SaInstitutionQuery = {
  __typename?: 'Query';
  institution?:
    | {
        __typename?: 'Institution';
        id: string;
        createdAt: string;
        enabled?: string | null | undefined;
        name: string;
        taskCodes?: Array<string> | null | undefined;
        isChatEnabled?: boolean | null | undefined;
        isRecommended?: boolean | null | undefined;
        lang?: string | null | undefined;
        visitorMode?: boolean | null | undefined;
        preferences?: Array<string> | null | undefined;
        countryCode?: string | null | undefined;
        places?: Array<{ __typename?: 'Place'; id: string; name: string }> | null | undefined;
        inPlaces?:
          | Array<{
              __typename?: 'InstitutionInPlace';
              role?: InstitutionPlaceRole | null | undefined;
            }>
          | null
          | undefined;
        category: { __typename?: 'Category'; id: string; name: string };
        info?:
          | {
              __typename?: 'InstitutionInfo';
              contactEmail?: string | null | undefined;
              contactPhone?: string | null | undefined;
              description?: string | null | undefined;
              website?: string | null | undefined;
              location?:
                | {
                    __typename?: 'Location';
                    address: string;
                    geo: { __typename?: 'GeoPoint'; lat: number; lon: number };
                  }
                | null
                | undefined;
              featureFlags?:
                | Array<{
                    __typename?: 'InstitutionFeatureFlag';
                    title: string;
                    type: InstitutionFeatureFlagEnum;
                    value: string;
                    feIcon?: InstitutionFeIconEnum | null | undefined;
                    icon?:
                      | {
                          __typename?: 'Image';
                          id: string;
                          blurHash?: string | null | undefined;
                          fileName?: string | null | undefined;
                          filePath?: string | null | undefined;
                          links?:
                            | {
                                __typename?: 'Links';
                                self: string;
                                thumbnail?: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                    titleLocalizations?:
                      | Array<{ __typename?: 'TitleLocalization'; title: string; language: string }>
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
        branding?:
          | {
              __typename?: 'Branding';
              logo: {
                __typename?: 'Image';
                id: string;
                fileName?: string | null | undefined;
                filePath?: string | null | undefined;
                links?: { __typename?: 'Links'; self: string } | null | undefined;
              };
              avatar: {
                __typename?: 'Image';
                id: string;
                fileName?: string | null | undefined;
                filePath?: string | null | undefined;
                links?: { __typename?: 'Links'; self: string } | null | undefined;
              };
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SaInstitutionRoleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SaInstitutionRoleQuery = {
  __typename?: 'Query';
  institution?:
    | {
        __typename?: 'Institution';
        inPlaces?:
          | Array<{
              __typename?: 'InstitutionInPlace';
              role?: InstitutionPlaceRole | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SaVisitorInterestsQueryVariables = Exact<{
  lang: Scalars['String'];
}>;

export type SaVisitorInterestsQuery = {
  __typename?: 'Query';
  visitorInterests: {
    __typename?: 'VisitorInterestsResponse';
    lang: string;
    visitorInterests: string;
  };
};

export type SendSaSendNotificationMutationVariables = Exact<{
  message: SuperAdminMessageInput;
  filter: SuperAdminNotificationFilterInput;
}>;

export type SendSaSendNotificationMutation = {
  __typename?: 'Mutation';
  sendSuperAdminNotification?: string | null | undefined;
};

export type SAnotifyUsersItitneraryMutationVariables = Exact<{
  placeUuid: Scalars['ID'];
  notificationDetails: ItineraryNotificationDetails;
}>;

export type SAnotifyUsersItitneraryMutation = {
  __typename?: 'Mutation';
  notifyUsersItitnerary: {
    __typename?: 'ItineraryNotificationResponse';
    success: boolean;
    count?: number | null | undefined;
  };
};

export type SAsendChatBotNotificationMutationVariables = Exact<{
  target: NotificationTargetInput;
  notification: PushNotificationMessageInput;
}>;

export type SAsendChatBotNotificationMutation = {
  __typename?: 'Mutation';
  sendChatBotNotification: {
    __typename?: 'PushNotificationResponse';
    id: string;
    status: PushNotificationStatus;
  };
};

export type SaChangePaymentProcessingStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  processingStatus: ProcessingStatus;
}>;

export type SaChangePaymentProcessingStatusMutation = {
  __typename?: 'Mutation';
  updatePaymentTransactionProcessingStatus: { __typename?: 'PaymentTransaction'; id: string };
};

export type SaPaymentTransactionsQueryVariables = Exact<{
  filter?: Maybe<PaymentTransactionsByProcessingStatusFilterInput>;
  nextToken?: Maybe<Scalars['String']>;
  processingStatus: ProcessingStatus;
  orderBy?: Maybe<PaymentTransactionsByProcessingStatusOrderByInput>;
  pageSize?: Maybe<Scalars['Int']>;
  app?: Maybe<App>;
}>;

export type SaPaymentTransactionsQuery = {
  __typename?: 'Query';
  paymentTransactionsByProcessingStatus: {
    __typename?: 'PaginatedPaymentTransactions';
    nextToken?: string | null | undefined;
    paymentTransactions: Array<{
      __typename?: 'PaymentTransaction';
      id: string;
      stripePaymentId?: string | null | undefined;
      createdAt: string;
      currency: Currency;
      paymentStatus: PaymentStatus;
      paidAt?: string | null | undefined;
      simplicityPlus?: boolean | null | undefined;
      processingStatus: ProcessingStatus;
      amount: string;
      paymentType?: PaymentType | null | undefined;
      receiver: string;
      metadata?: string | null | undefined;
      installation?: { __typename?: 'Installation'; id: string } | null | undefined;
      document?:
        | {
            __typename?: 'Image';
            links?: { __typename?: 'Links'; self: string } | null | undefined;
          }
        | null
        | undefined;
      paymentDetails?:
        | {
            __typename?: 'PaymentDetails';
            citationNumber?: string | null | undefined;
            licenseNumber?: string | null | undefined;
            issueDate?: string | null | undefined;
            postId?: string | null | undefined;
            stateCode?: string | null | undefined;
          }
        | null
        | undefined;
      marketPaymentDetails?:
        | {
            __typename?: 'MarketPaymentDetails';
            activityUuid: string;
            merchantPrice?: string | null | undefined;
            totalPrice?: string | null | undefined;
            cartUuid: string;
            order?:
              | {
                  __typename?: 'MarketPaymentOrder';
                  uuid?: string | null | undefined;
                  identifier: string;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
      marketItem?:
        | { __typename?: 'TUIMarketItem'; id: string; title: string }
        | { __typename?: 'CustomMarketItem'; id: string; title: string }
        | { __typename?: 'ViatorMarketItem'; id: string; title: string }
        | null
        | undefined;
    }>;
  };
};

export type SaPaymentTransactionQueryVariables = Exact<{
  id: Scalars['ID'];
  app?: Maybe<App>;
}>;

export type SaPaymentTransactionQuery = {
  __typename?: 'Query';
  paymentTransaction?:
    | {
        __typename?: 'PaymentTransaction';
        id: string;
        stripePaymentId?: string | null | undefined;
        processingStatus: ProcessingStatus;
        paidAt?: string | null | undefined;
        paymentStatus: PaymentStatus;
        amount: string;
        currency: Currency;
        createdAt: string;
        paymentType?: PaymentType | null | undefined;
        receiver: string;
        simplicityPlus?: boolean | null | undefined;
        metadata?: string | null | undefined;
        installation?: { __typename?: 'Installation'; id: string } | null | undefined;
        paymentDetails?:
          | {
              __typename?: 'PaymentDetails';
              citationNumber?: string | null | undefined;
              licenseNumber?: string | null | undefined;
              issueDate?: string | null | undefined;
              postId?: string | null | undefined;
              stateCode?: string | null | undefined;
              orderUuid?: string | null | undefined;
            }
          | null
          | undefined;
        document?:
          | {
              __typename?: 'Image';
              links?: { __typename?: 'Links'; self: string } | null | undefined;
            }
          | null
          | undefined;
        marketItem?:
          | { __typename?: 'TUIMarketItem'; id: string; title: string }
          | { __typename?: 'CustomMarketItem'; id: string; title: string }
          | { __typename?: 'ViatorMarketItem'; id: string; title: string }
          | null
          | undefined;
        marketPaymentDetails?:
          | {
              __typename?: 'MarketPaymentDetails';
              activityUuid: string;
              merchantPrice?: string | null | undefined;
              totalPrice?: string | null | undefined;
              cartUuid: string;
              isCustomerInfoProvided?: boolean | null | undefined;
              order?:
                | {
                    __typename?: 'MarketPaymentOrder';
                    uuid?: string | null | undefined;
                    date?: string | null | undefined;
                    identifier: string;
                    totalPrice?:
                      | {
                          __typename?: 'MarketPaymentPrice';
                          value: number;
                          currency: Currency;
                          formattedValue?: string | null | undefined;
                        }
                      | null
                      | undefined;
                    items: Array<{
                      __typename?: 'MarketPaymentOrderItem';
                      uuid?: string | null | undefined;
                    }>;
                  }
                | null
                | undefined;
              products: Array<{
                __typename?: 'MarketPaymentProduct';
                id?: string | null | undefined;
                title?: string | null | undefined;
                quantity?: number | null | undefined;
                ageRange?: string | null | undefined;
                cartItemUuid?: string | null | undefined;
                retailPrice?:
                  | {
                      __typename?: 'MarketPaymentPrice';
                      value: number;
                      currency: Currency;
                      formattedValue?: string | null | undefined;
                    }
                  | null
                  | undefined;
                merchantPrice?:
                  | {
                      __typename?: 'MarketPaymentPrice';
                      value: number;
                      currency: Currency;
                      formattedValue?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }>;
            }
          | null
          | undefined;
        refundDetails?:
          | Array<{
              __typename?: 'TUIRefundDetail';
              createdAt: string;
              amount: string;
              currency: Currency;
              refundPolicy: {
                __typename?: 'PaymentTUIRefundPolicy';
                uuid: string;
                value: number;
                type: TuiRefundType;
              };
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

type BaseMarketItemFragment_TuiMarketItem_Fragment = {
  __typename?: 'TUIMarketItem';
  id: string;
  title: string;
};

type BaseMarketItemFragment_CustomMarketItem_Fragment = {
  __typename?: 'CustomMarketItem';
  id: string;
  title: string;
};

type BaseMarketItemFragment_ViatorMarketItem_Fragment = {
  __typename?: 'ViatorMarketItem';
  id: string;
  title: string;
};

export type BaseMarketItemFragmentFragment =
  | BaseMarketItemFragment_TuiMarketItem_Fragment
  | BaseMarketItemFragment_CustomMarketItem_Fragment
  | BaseMarketItemFragment_ViatorMarketItem_Fragment;

export type SaUpdateUserPreferenceCardMutationVariables = Exact<{
  id: Scalars['ID'];
  card: UserPreferenceCardInput;
}>;

export type SaUpdateUserPreferenceCardMutation = {
  __typename?: 'Mutation';
  updateUserPreferenceCard: string;
};

export type SaAllPreferenceCardsQueryVariables = Exact<{ [key: string]: never }>;

export type SaAllPreferenceCardsQuery = {
  __typename?: 'Query';
  getAllGenericCards: {
    __typename?: 'UserPreferenceCardsResponse';
    cards: Array<{ __typename?: 'UserPreferenceCard'; id: string }>;
  };
};

export type SaRunScrapeTaskByCodeMutationVariables = Exact<{
  code?: Maybe<Scalars['String']>;
}>;

export type SaRunScrapeTaskByCodeMutation = {
  __typename?: 'Mutation';
  runScrapeTaskByCode?: string | null | undefined;
};

export type SaAddAdminUserMutationVariables = Exact<{
  user: AdminUserInput;
}>;

export type SaAddAdminUserMutation = {
  __typename?: 'Mutation';
  addAdminUser: {
    __typename?: 'AdminUser';
    username: string;
    email: string;
    tmpPassword?: string | null | undefined;
  };
};

export type SaDeleteAdminUserMutationVariables = Exact<{
  username: Scalars['String'];
}>;

export type SaDeleteAdminUserMutation = {
  __typename?: 'Mutation';
  deleteAdminUser?: { __typename?: 'AdminUser'; username: string } | null | undefined;
};

export type SaUpdateAdminUserMutationVariables = Exact<{
  user: AdminUserInput;
}>;

export type SaUpdateAdminUserMutation = {
  __typename?: 'Mutation';
  updateAdminUser: { __typename?: 'AdminUser'; username: string };
};

export type SaAllUsersQueryVariables = Exact<{
  nextToken?: Maybe<Scalars['String']>;
  filter: AdminUsersFilterInput;
}>;

export type SaAllUsersQuery = {
  __typename?: 'Query';
  adminUsers: {
    __typename?: 'PaginatedAdminUsers';
    nextToken?: string | null | undefined;
    users: Array<{
      __typename?: 'AdminUser';
      username: string;
      email: string;
      institutionUuids?: string | null | undefined;
      institution?: { __typename?: 'Institution'; name: string; id: string } | null | undefined;
      place?: { __typename?: 'Place'; name: string; id: string } | null | undefined;
    }>;
  };
};

export type SaAdminUserQueryVariables = Exact<{
  username: Scalars['String'];
}>;

export type SaAdminUserQuery = {
  __typename?: 'Query';
  adminUser?:
    | {
        __typename?: 'AdminUser';
        username: string;
        email: string;
        institutionUuids?: string | null | undefined;
        institutions?:
          | Array<{ __typename?: 'Institution'; id: string; name: string }>
          | null
          | undefined;
        place?: { __typename?: 'Place'; id: string; name: string } | null | undefined;
      }
    | null
    | undefined;
};

export const CategoryFieldsFragmentDoc = `
    fragment CategoryFields on Category {
  id
  name
  createdAt
  categoryTypes
  legacy
  isSystem
  images {
    svgIcon
  }
}
    `;
export const GeoShapeFragmentFragmentDoc = `
    fragment GeoShapeFragment on IAnnouncement {
  areaOfInterest {
    ... on GeoShapeCircle {
      type
      coordinates
      radius
    }
    ... on GeoShapePolygon {
      polygonType: type
      polygonCoordinates: coordinates
    }
  }
}
    `;
export const AnnouncementDetailFragmentFragmentDoc = `
    fragment announcementDetailFragment on IAnnouncement {
  __typename
  category {
    ...CategoryFields
  }
  categories {
    ...CategoryFields
  }
  content
  createdAt
  updatedAt
  scheduledAt
  publishedAt
  id
  imageObjects {
    fileName
    filePath
    links {
      self
    }
    id
  }
  videoObjects {
    fileName
    filePath
    links {
      self
    }
    id
  }
  lang
  title
  createdByAdmin
  publishmentState
  notifyAlsoNonCategoried
  notifyVolunteersOnly
  ...GeoShapeFragment
  notifications
  notifyNow
  location {
    name
    gps {
      lat
      lon
    }
  }
  places {
    id
    name
  }
}
    ${CategoryFieldsFragmentDoc}
${GeoShapeFragmentFragmentDoc}`;
export const AnnouncementsFieldsFragmentDoc = `
    fragment AnnouncementsFields on IAnnouncement {
  __typename
  category {
    ...CategoryFields
  }
  categories {
    ...CategoryFields
  }
  createdAt
  updatedAt
  id
  scheduledAt
  title
  content
  createdByAdmin
  publishmentState
  notifyAlsoNonCategoried
  ...GeoShapeFragment
}
    ${CategoryFieldsFragmentDoc}
${GeoShapeFragmentFragmentDoc}`;
export const AnnouncementFragmentDoc = `
    fragment Announcement on Announcement {
  id
  createdAt
  institution {
    name
    branding {
      avatar {
        id
        fileName
        filePath
        links {
          self
        }
      }
    }
  }
  title
  announcementContent: content
  imageObjects {
    id
    links {
      self
      thumbnail
    }
  }
}
    `;
export const EventFragmentDoc = `
    fragment Event on Event {
  id
  createdAt
  institution {
    name
    branding {
      avatar {
        id
        fileName
        filePath
        links {
          self
        }
      }
    }
  }
  title
  eventContent: content
  imageObjects {
    id
    links {
      self
      thumbnail
    }
  }
}
    `;
export const EventMarketFieldsFragmentDoc = `
    fragment EventMarketFields on Event {
  marketUrl
  marketItemPricing {
    currency
    summary {
      fromPrice
      fromPriceBeforeDiscount
    }
  }
}
    `;
export const InstitutionFeatureFlagFragmentFragmentDoc = `
    fragment InstitutionFeatureFlagFragment on InstitutionFeatureFlag {
  icon {
    id
    links {
      self
      thumbnail
    }
    blurHash
    fileName
    filePath
  }
  title
  titleLocalizations {
    title
    language
  }
  type
  value
  feIcon
}
    `;
export const PollsFieldsFragmentDoc = `
    fragment PollsFields on IAnnouncement {
  __typename
  category {
    ...CategoryFields
  }
  categories {
    ...CategoryFields
  }
  createdAt
  updatedAt
  id
  scheduledAt
  title
  createdByAdmin
  publishmentState
  content
}
    ${CategoryFieldsFragmentDoc}`;
export const RaffleDetailFragmentFragmentDoc = `
    fragment raffleDetailFragment on Announcement {
  __typename
  category {
    ...CategoryFields
  }
  categories {
    ...CategoryFields
  }
  content
  createdAt
  updatedAt
  scheduledAt
  id
  imageObjects {
    id
    fileName
    filePath
    links {
      self
    }
  }
  lang
  title
  subtitle
  createdByAdmin
  raffleOptions {
    titleWidth
    textWidth
    textColor
    textPosition
    datetimeFrom
    datetimeTo
    showIncChanceFlag
    termsAndConditionsUrl
    privacyPolicyUrl
  }
  publishmentState
  notifications
  notifyNow
  location {
    name
    gps {
      lat
      lon
    }
  }
  places {
    id
    name
  }
}
    ${CategoryFieldsFragmentDoc}`;
export const RafflesFieldsFragmentDoc = `
    fragment RafflesFields on IAnnouncement {
  __typename
  category {
    ...CategoryFields
  }
  categories {
    ...CategoryFields
  }
  createdAt
  updatedAt
  id
  scheduledAt
  title
  createdByAdmin
  publishmentState
  content
  raffleOptions {
    titleWidth
    textWidth
    textColor
    textPosition
    datetimeFrom
    datetimeTo
    showIncChanceFlag
    termsAndConditionsUrl
    privacyPolicyUrl
  }
}
    ${CategoryFieldsFragmentDoc}`;
export const ImageFragmentFragmentDoc = `
    fragment ImageFragment on Image {
  fileName
  filePath
  links {
    self
  }
  id
}
    `;
export const BaseMarketItemFragmentFragmentDoc = `
    fragment BaseMarketItemFragment on MarketItem {
  ... on TUIMarketItem {
    id
    title
  }
  ... on CustomMarketItem {
    id
    title
  }
  ... on ViatorMarketItem {
    id
    title
  }
}
    `;
export const AddAnnouncementDraftDocument = `
    mutation addAnnouncementDraft($announcementDraft: AnnouncementDraftInput!) {
  addAnnouncementDraft(announcement: $announcementDraft) {
    id
  }
}
    `;
export const useAddAnnouncementDraftMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AddAnnouncementDraftMutation,
    TError,
    AddAnnouncementDraftMutationVariables,
    TContext
  >,
) =>
  useMutation<
    AddAnnouncementDraftMutation,
    TError,
    AddAnnouncementDraftMutationVariables,
    TContext
  >(
    (variables?: AddAnnouncementDraftMutationVariables) =>
      fetcher<AddAnnouncementDraftMutation, AddAnnouncementDraftMutationVariables>(
        AddAnnouncementDraftDocument,
        variables,
      )(),
    options,
  );
export const UpdateAnouncementDraftDocument = `
    mutation updateAnouncementDraft($id: ID!, $announcementDraft: AnnouncementDraftInput!) {
  updateAnnouncementDraft(id: $id, announcement: $announcementDraft) {
    id
  }
}
    `;
export const useUpdateAnouncementDraftMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateAnouncementDraftMutation,
    TError,
    UpdateAnouncementDraftMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateAnouncementDraftMutation,
    TError,
    UpdateAnouncementDraftMutationVariables,
    TContext
  >(
    (variables?: UpdateAnouncementDraftMutationVariables) =>
      fetcher<UpdateAnouncementDraftMutation, UpdateAnouncementDraftMutationVariables>(
        UpdateAnouncementDraftDocument,
        variables,
      )(),
    options,
  );
export const AddAnnouncementDocument = `
    mutation addAnnouncement($announcement: AnnouncementInput!) {
  addAnnouncement(announcement: $announcement) {
    id
  }
}
    `;
export const useAddAnnouncementMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AddAnnouncementMutation,
    TError,
    AddAnnouncementMutationVariables,
    TContext
  >,
) =>
  useMutation<AddAnnouncementMutation, TError, AddAnnouncementMutationVariables, TContext>(
    (variables?: AddAnnouncementMutationVariables) =>
      fetcher<AddAnnouncementMutation, AddAnnouncementMutationVariables>(
        AddAnnouncementDocument,
        variables,
      )(),
    options,
  );
export const UpdateAnouncementDocument = `
    mutation updateAnouncement($id: ID!, $announcement: AnnouncementInput!) {
  updateAnnouncement(id: $id, announcement: $announcement) {
    id
  }
}
    `;
export const useUpdateAnouncementMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateAnouncementMutation,
    TError,
    UpdateAnouncementMutationVariables,
    TContext
  >,
) =>
  useMutation<UpdateAnouncementMutation, TError, UpdateAnouncementMutationVariables, TContext>(
    (variables?: UpdateAnouncementMutationVariables) =>
      fetcher<UpdateAnouncementMutation, UpdateAnouncementMutationVariables>(
        UpdateAnouncementDocument,
        variables,
      )(),
    options,
  );
export const DeleteAnnouncementDocument = `
    mutation deleteAnnouncement($id: ID!) {
  deleteAnnouncement(id: $id) {
    id
  }
}
    `;
export const useDeleteAnnouncementMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteAnnouncementMutation,
    TError,
    DeleteAnnouncementMutationVariables,
    TContext
  >,
) =>
  useMutation<DeleteAnnouncementMutation, TError, DeleteAnnouncementMutationVariables, TContext>(
    (variables?: DeleteAnnouncementMutationVariables) =>
      fetcher<DeleteAnnouncementMutation, DeleteAnnouncementMutationVariables>(
        DeleteAnnouncementDocument,
        variables,
      )(),
    options,
  );
export const PublishAnnouncementDocument = `
    mutation publishAnnouncement($id: ID!) {
  publishAnnouncement(id: $id) {
    id
  }
}
    `;
export const usePublishAnnouncementMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PublishAnnouncementMutation,
    TError,
    PublishAnnouncementMutationVariables,
    TContext
  >,
) =>
  useMutation<PublishAnnouncementMutation, TError, PublishAnnouncementMutationVariables, TContext>(
    (variables?: PublishAnnouncementMutationVariables) =>
      fetcher<PublishAnnouncementMutation, PublishAnnouncementMutationVariables>(
        PublishAnnouncementDocument,
        variables,
      )(),
    options,
  );
export const UnpublishAnnouncementDocument = `
    mutation unpublishAnnouncement($id: ID!, $batch: [ID!]) {
  unpublishAnnouncement(id: $id, batch: $batch) {
    id
  }
}
    `;
export const useUnpublishAnnouncementMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UnpublishAnnouncementMutation,
    TError,
    UnpublishAnnouncementMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UnpublishAnnouncementMutation,
    TError,
    UnpublishAnnouncementMutationVariables,
    TContext
  >(
    (variables?: UnpublishAnnouncementMutationVariables) =>
      fetcher<UnpublishAnnouncementMutation, UnpublishAnnouncementMutationVariables>(
        UnpublishAnnouncementDocument,
        variables,
      )(),
    options,
  );
export const CancelScheduledAnnouncementDocument = `
    mutation cancelScheduledAnnouncement($id: ID!, $placeUuid: String) {
  cancelScheduledAnnouncement(id: $id, placeUuid: $placeUuid) {
    id
  }
}
    `;
export const useCancelScheduledAnnouncementMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CancelScheduledAnnouncementMutation,
    TError,
    CancelScheduledAnnouncementMutationVariables,
    TContext
  >,
) =>
  useMutation<
    CancelScheduledAnnouncementMutation,
    TError,
    CancelScheduledAnnouncementMutationVariables,
    TContext
  >(
    (variables?: CancelScheduledAnnouncementMutationVariables) =>
      fetcher<CancelScheduledAnnouncementMutation, CancelScheduledAnnouncementMutationVariables>(
        CancelScheduledAnnouncementDocument,
        variables,
      )(),
    options,
  );
export const UpdateAnnouncementCategoriesDocument = `
    mutation updateAnnouncementCategories($id: ID!, $categoryUuids: [String!]!) {
  updateAnnouncementCategories(id: $id, categoryUuids: $categoryUuids) {
    id
  }
}
    `;
export const useUpdateAnnouncementCategoriesMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateAnnouncementCategoriesMutation,
    TError,
    UpdateAnnouncementCategoriesMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateAnnouncementCategoriesMutation,
    TError,
    UpdateAnnouncementCategoriesMutationVariables,
    TContext
  >(
    (variables?: UpdateAnnouncementCategoriesMutationVariables) =>
      fetcher<UpdateAnnouncementCategoriesMutation, UpdateAnnouncementCategoriesMutationVariables>(
        UpdateAnnouncementCategoriesDocument,
        variables,
      )(),
    options,
  );
export const AnnouncementDocument = `
    query Announcement($id: ID!) {
  announcement: post(id: $id) {
    ... on Announcement {
      ...announcementDetailFragment
    }
    ... on AnnouncementDraft {
      ...announcementDetailFragment
    }
  }
}
    ${AnnouncementDetailFragmentFragmentDoc}`;
export const useAnnouncementQuery = <TData = AnnouncementQuery, TError = unknown>(
  variables: AnnouncementQueryVariables,
  options?: UseQueryOptions<AnnouncementQuery, TError, TData>,
) =>
  useQuery<AnnouncementQuery, TError, TData>(
    ['Announcement', variables],
    fetcher<AnnouncementQuery, AnnouncementQueryVariables>(AnnouncementDocument, variables),
    options,
  );
useAnnouncementQuery.document = AnnouncementDocument;

useAnnouncementQuery.getKey = (variables: AnnouncementQueryVariables) => [
  'Announcement',
  variables,
];

export const AnnouncementsDocument = `
    query Announcements($filter: AnnouncementsByFilterInput!, $pageOffset: Int!, $pageSize: Int!, $orderBy: AnnouncementsOrderByInput) {
  announcementsBy(
    filter: $filter
    pageOffset: $pageOffset
    pageSize: $pageSize
    orderBy: $orderBy
  ) {
    announcements {
      ... on Announcement {
        ...AnnouncementsFields
        publishedAt
      }
    }
    count
  }
}
    ${AnnouncementsFieldsFragmentDoc}`;
export const useAnnouncementsQuery = <TData = AnnouncementsQuery, TError = unknown>(
  variables: AnnouncementsQueryVariables,
  options?: UseQueryOptions<AnnouncementsQuery, TError, TData>,
) =>
  useQuery<AnnouncementsQuery, TError, TData>(
    ['Announcements', variables],
    fetcher<AnnouncementsQuery, AnnouncementsQueryVariables>(AnnouncementsDocument, variables),
    options,
  );
useAnnouncementsQuery.document = AnnouncementsDocument;

useAnnouncementsQuery.getKey = (variables: AnnouncementsQueryVariables) => [
  'Announcements',
  variables,
];

export const AnnouncementDraftsDocument = `
    query AnnouncementDrafts($filter: AnnouncementsByFilterInput!, $pageOffset: Int!, $pageSize: Int!, $orderBy: AnnouncementsOrderByInput) {
  announcementsBy(
    filter: $filter
    pageOffset: $pageOffset
    pageSize: $pageSize
    orderBy: $orderBy
  ) {
    announcements {
      ... on AnnouncementDraft {
        ...AnnouncementsFields
      }
    }
    count
  }
}
    ${AnnouncementsFieldsFragmentDoc}`;
export const useAnnouncementDraftsQuery = <TData = AnnouncementDraftsQuery, TError = unknown>(
  variables: AnnouncementDraftsQueryVariables,
  options?: UseQueryOptions<AnnouncementDraftsQuery, TError, TData>,
) =>
  useQuery<AnnouncementDraftsQuery, TError, TData>(
    ['AnnouncementDrafts', variables],
    fetcher<AnnouncementDraftsQuery, AnnouncementDraftsQueryVariables>(
      AnnouncementDraftsDocument,
      variables,
    ),
    options,
  );
useAnnouncementDraftsQuery.document = AnnouncementDraftsDocument;

useAnnouncementDraftsQuery.getKey = (variables: AnnouncementDraftsQueryVariables) => [
  'AnnouncementDrafts',
  variables,
];

export const CategoriesDocument = `
    query Categories($filter: CategoriesByFilterInput!) {
  categoriesBy(filter: $filter) {
    categories {
      ...CategoryFields
    }
  }
}
    ${CategoryFieldsFragmentDoc}`;
export const useCategoriesQuery = <TData = CategoriesQuery, TError = unknown>(
  variables: CategoriesQueryVariables,
  options?: UseQueryOptions<CategoriesQuery, TError, TData>,
) =>
  useQuery<CategoriesQuery, TError, TData>(
    ['Categories', variables],
    fetcher<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, variables),
    options,
  );
useCategoriesQuery.document = CategoriesDocument;

useCategoriesQuery.getKey = (variables: CategoriesQueryVariables) => ['Categories', variables];

export const GenerateNamedUploadUrlsDocument = `
    mutation GenerateNamedUploadUrls($files: [FileUploadInput!]!, $uploadMethod: UploadMethod, $institutionUuid: String, $placeUuid: String) {
  generateNamedUploadUrls(
    files: $files
    uploadMethod: $uploadMethod
    institutionUuid: $institutionUuid
    placeUuid: $placeUuid
  ) {
    fileName
    filePath
    id
    url
    fields {
      key
      value
    }
  }
}
    `;
export const useGenerateNamedUploadUrlsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    GenerateNamedUploadUrlsMutation,
    TError,
    GenerateNamedUploadUrlsMutationVariables,
    TContext
  >,
) =>
  useMutation<
    GenerateNamedUploadUrlsMutation,
    TError,
    GenerateNamedUploadUrlsMutationVariables,
    TContext
  >(
    (variables?: GenerateNamedUploadUrlsMutationVariables) =>
      fetcher<GenerateNamedUploadUrlsMutation, GenerateNamedUploadUrlsMutationVariables>(
        GenerateNamedUploadUrlsDocument,
        variables,
      )(),
    options,
  );
export const SendPostNotificationDocument = `
    mutation sendPostNotification($postUuid: ID!) {
  sendNowPostNotification(postUuid: $postUuid)
}
    `;
export const useSendPostNotificationMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SendPostNotificationMutation,
    TError,
    SendPostNotificationMutationVariables,
    TContext
  >,
) =>
  useMutation<
    SendPostNotificationMutation,
    TError,
    SendPostNotificationMutationVariables,
    TContext
  >(
    (variables?: SendPostNotificationMutationVariables) =>
      fetcher<SendPostNotificationMutation, SendPostNotificationMutationVariables>(
        SendPostNotificationDocument,
        variables,
      )(),
    options,
  );
export const DeleteCouponDocument = `
    mutation DeleteCoupon($id: ID!) {
  deleteCoupon(id: $id) {
    id
  }
}
    `;
export const useDeleteCouponMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteCouponMutation,
    TError,
    DeleteCouponMutationVariables,
    TContext
  >,
) =>
  useMutation<DeleteCouponMutation, TError, DeleteCouponMutationVariables, TContext>(
    (variables?: DeleteCouponMutationVariables) =>
      fetcher<DeleteCouponMutation, DeleteCouponMutationVariables>(
        DeleteCouponDocument,
        variables,
      )(),
    options,
  );
export const UnpublishCouponDocument = `
    mutation UnpublishCoupon($id: ID!, $batch: [ID!]) {
  unpublishCoupon(id: $id, batch: $batch) {
    id
  }
}
    `;
export const useUnpublishCouponMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UnpublishCouponMutation,
    TError,
    UnpublishCouponMutationVariables,
    TContext
  >,
) =>
  useMutation<UnpublishCouponMutation, TError, UnpublishCouponMutationVariables, TContext>(
    (variables?: UnpublishCouponMutationVariables) =>
      fetcher<UnpublishCouponMutation, UnpublishCouponMutationVariables>(
        UnpublishCouponDocument,
        variables,
      )(),
    options,
  );
export const PublishCouponDocument = `
    mutation PublishCoupon($id: ID!) {
  publishCoupon(id: $id) {
    id
  }
}
    `;
export const usePublishCouponMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PublishCouponMutation,
    TError,
    PublishCouponMutationVariables,
    TContext
  >,
) =>
  useMutation<PublishCouponMutation, TError, PublishCouponMutationVariables, TContext>(
    (variables?: PublishCouponMutationVariables) =>
      fetcher<PublishCouponMutation, PublishCouponMutationVariables>(
        PublishCouponDocument,
        variables,
      )(),
    options,
  );
export const CancelScheduledCouponDocument = `
    mutation CancelScheduledCoupon($id: ID!) {
  cancelScheduledCoupon(id: $id) {
    id
  }
}
    `;
export const useCancelScheduledCouponMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CancelScheduledCouponMutation,
    TError,
    CancelScheduledCouponMutationVariables,
    TContext
  >,
) =>
  useMutation<
    CancelScheduledCouponMutation,
    TError,
    CancelScheduledCouponMutationVariables,
    TContext
  >(
    (variables?: CancelScheduledCouponMutationVariables) =>
      fetcher<CancelScheduledCouponMutation, CancelScheduledCouponMutationVariables>(
        CancelScheduledCouponDocument,
        variables,
      )(),
    options,
  );
export const AddCouponDocument = `
    mutation addCoupon($coupon: CouponInput!) {
  addCoupon(coupon: $coupon) {
    id
  }
}
    `;
export const useAddCouponMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<AddCouponMutation, TError, AddCouponMutationVariables, TContext>,
) =>
  useMutation<AddCouponMutation, TError, AddCouponMutationVariables, TContext>(
    (variables?: AddCouponMutationVariables) =>
      fetcher<AddCouponMutation, AddCouponMutationVariables>(AddCouponDocument, variables)(),
    options,
  );
export const UpdateCouponDocument = `
    mutation updateCoupon($id: ID!, $coupon: CouponInput!) {
  updateCoupon(coupon: $coupon, id: $id) {
    id
  }
}
    `;
export const useUpdateCouponMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateCouponMutation,
    TError,
    UpdateCouponMutationVariables,
    TContext
  >,
) =>
  useMutation<UpdateCouponMutation, TError, UpdateCouponMutationVariables, TContext>(
    (variables?: UpdateCouponMutationVariables) =>
      fetcher<UpdateCouponMutation, UpdateCouponMutationVariables>(
        UpdateCouponDocument,
        variables,
      )(),
    options,
  );
export const AddCouponDraftDocument = `
    mutation addCouponDraft($coupon: CouponInput!) {
  addCouponDraft(coupon: $coupon) {
    id
  }
}
    `;
export const useAddCouponDraftMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AddCouponDraftMutation,
    TError,
    AddCouponDraftMutationVariables,
    TContext
  >,
) =>
  useMutation<AddCouponDraftMutation, TError, AddCouponDraftMutationVariables, TContext>(
    (variables?: AddCouponDraftMutationVariables) =>
      fetcher<AddCouponDraftMutation, AddCouponDraftMutationVariables>(
        AddCouponDraftDocument,
        variables,
      )(),
    options,
  );
export const UpdateCouponDraftDocument = `
    mutation updateCouponDraft($id: ID!, $coupon: CouponInput!) {
  updateCouponDraft(coupon: $coupon, id: $id) {
    id
  }
}
    `;
export const useUpdateCouponDraftMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateCouponDraftMutation,
    TError,
    UpdateCouponDraftMutationVariables,
    TContext
  >,
) =>
  useMutation<UpdateCouponDraftMutation, TError, UpdateCouponDraftMutationVariables, TContext>(
    (variables?: UpdateCouponDraftMutationVariables) =>
      fetcher<UpdateCouponDraftMutation, UpdateCouponDraftMutationVariables>(
        UpdateCouponDraftDocument,
        variables,
      )(),
    options,
  );
export const CouponsDocument = `
    query Coupons($filter: CouponsByFilterInput!, $nextToken: String, $orderBy: CouponsOrderByInput) {
  couponsBy(filter: $filter, nextToken: $nextToken, orderBy: $orderBy) {
    coupons {
      id
      title
      subtitle
      createdAt
      publishedAt
      updatedAt
      institutionUuid
      imageObjects {
        links {
          self
          thumbnail
        }
      }
      publishmentState
      validity {
        isInfinite
        interval {
          toDate
          toTime
        }
      }
      userActivities {
        activationsCount
      }
    }
    count
    nextToken
  }
}
    `;
export const useCouponsQuery = <TData = CouponsQuery, TError = unknown>(
  variables: CouponsQueryVariables,
  options?: UseQueryOptions<CouponsQuery, TError, TData>,
) =>
  useQuery<CouponsQuery, TError, TData>(
    ['Coupons', variables],
    fetcher<CouponsQuery, CouponsQueryVariables>(CouponsDocument, variables),
    options,
  );
useCouponsQuery.document = CouponsDocument;

useCouponsQuery.getKey = (variables: CouponsQueryVariables) => ['Coupons', variables];

export const CouponDocument = `
    query Coupon($id: ID!) {
  coupon(id: $id) {
    id
    title
    subtitle
    description
    notifyNow
    createdAt
    publishedAt
    updatedAt
    notifications
    institutionUuid
    imageObjects {
      fileName
      filePath
      links {
        self
      }
      id
    }
    publishmentState
    validity {
      interval {
        fromDate
        toDate
        fromTime
        toTime
      }
      isInfinite
      recurring {
        type
        day
        every
        fromDate
        toDate
        timeFrom
        timeTo
        ocurrences
      }
    }
    userActivities {
      activationsCount
    }
  }
}
    `;
export const useCouponQuery = <TData = CouponQuery, TError = unknown>(
  variables: CouponQueryVariables,
  options?: UseQueryOptions<CouponQuery, TError, TData>,
) =>
  useQuery<CouponQuery, TError, TData>(
    ['Coupon', variables],
    fetcher<CouponQuery, CouponQueryVariables>(CouponDocument, variables),
    options,
  );
useCouponQuery.document = CouponDocument;

useCouponQuery.getKey = (variables: CouponQueryVariables) => ['Coupon', variables];

export const AdminDashboardTopReactionsDocument = `
    query adminDashboardTopReactions($filter: DashboardAnalyticsFilterInput!) {
  adminDashboardTopReactions(filter: $filter) {
    posts {
      __typename
      ... on Announcement {
        ...Announcement
      }
      ... on Event {
        ...Event
      }
      ... on QuickQuestion {
        id
        question
      }
      ... on AnnouncementDraft {
        id
        createdAt
      }
      ... on EventDraft {
        id
        createdAt
      }
      ... on QuickQuestionDraft {
        id
        createdAt
      }
      ... on UserPost {
        id
        createdAt
      }
    }
  }
}
    ${AnnouncementFragmentDoc}
${EventFragmentDoc}`;
export const useAdminDashboardTopReactionsQuery = <
  TData = AdminDashboardTopReactionsQuery,
  TError = unknown,
>(
  variables: AdminDashboardTopReactionsQueryVariables,
  options?: UseQueryOptions<AdminDashboardTopReactionsQuery, TError, TData>,
) =>
  useQuery<AdminDashboardTopReactionsQuery, TError, TData>(
    ['adminDashboardTopReactions', variables],
    fetcher<AdminDashboardTopReactionsQuery, AdminDashboardTopReactionsQueryVariables>(
      AdminDashboardTopReactionsDocument,
      variables,
    ),
    options,
  );
useAdminDashboardTopReactionsQuery.document = AdminDashboardTopReactionsDocument;

useAdminDashboardTopReactionsQuery.getKey = (
  variables: AdminDashboardTopReactionsQueryVariables,
) => ['adminDashboardTopReactions', variables];

export const AdminDashboardTotalsAnalyticsDocument = `
    query adminDashboardTotalsAnalytics($filter: DashboardAnalyticsFilterInput!) {
  adminDashboardTotalsAnalytics(filter: $filter) {
    placeUuid
    institutionUuid
    Reactions {
      actual
      diff
    }
    Subscribers {
      actual
      diff
    }
    Followers {
      actual
      diff
    }
    Reach {
      actual
      diff
    }
  }
}
    `;
export const useAdminDashboardTotalsAnalyticsQuery = <
  TData = AdminDashboardTotalsAnalyticsQuery,
  TError = unknown,
>(
  variables: AdminDashboardTotalsAnalyticsQueryVariables,
  options?: UseQueryOptions<AdminDashboardTotalsAnalyticsQuery, TError, TData>,
) =>
  useQuery<AdminDashboardTotalsAnalyticsQuery, TError, TData>(
    ['adminDashboardTotalsAnalytics', variables],
    fetcher<AdminDashboardTotalsAnalyticsQuery, AdminDashboardTotalsAnalyticsQueryVariables>(
      AdminDashboardTotalsAnalyticsDocument,
      variables,
    ),
    options,
  );
useAdminDashboardTotalsAnalyticsQuery.document = AdminDashboardTotalsAnalyticsDocument;

useAdminDashboardTotalsAnalyticsQuery.getKey = (
  variables: AdminDashboardTotalsAnalyticsQueryVariables,
) => ['adminDashboardTotalsAnalytics', variables];

export const AdminDashboardDocument = `
    query adminDashboard($filter: DashboardFilterInput!) {
  adminDashboard(filter: $filter) {
    MAU {
      actual
      diff
    }
    totalOpenedNotifications {
      actual
      diff
    }
    totalSubscribers {
      actual
      diff
    }
    totalSentNotifications {
      actual
      diff
    }
    totalNewsViews {
      actual
      diff
    }
    totalEventViews {
      actual
      diff
    }
    totalShares {
      actual
      diff
    }
  }
}
    `;
export const useAdminDashboardQuery = <TData = AdminDashboardQuery, TError = unknown>(
  variables: AdminDashboardQueryVariables,
  options?: UseQueryOptions<AdminDashboardQuery, TError, TData>,
) =>
  useQuery<AdminDashboardQuery, TError, TData>(
    ['adminDashboard', variables],
    fetcher<AdminDashboardQuery, AdminDashboardQueryVariables>(AdminDashboardDocument, variables),
    options,
  );
useAdminDashboardQuery.document = AdminDashboardDocument;

useAdminDashboardQuery.getKey = (variables: AdminDashboardQueryVariables) => [
  'adminDashboard',
  variables,
];

export const AdminDashboardAnalyticsDocument = `
    query AdminDashboardAnalytics($filter: DashboardAnalyticsFilterInput!) {
  adminDashboardAnalytics(filter: $filter) {
    subscribers
    followers
    reach
    reactions
  }
}
    `;
export const useAdminDashboardAnalyticsQuery = <
  TData = AdminDashboardAnalyticsQuery,
  TError = unknown,
>(
  variables: AdminDashboardAnalyticsQueryVariables,
  options?: UseQueryOptions<AdminDashboardAnalyticsQuery, TError, TData>,
) =>
  useQuery<AdminDashboardAnalyticsQuery, TError, TData>(
    ['AdminDashboardAnalytics', variables],
    fetcher<AdminDashboardAnalyticsQuery, AdminDashboardAnalyticsQueryVariables>(
      AdminDashboardAnalyticsDocument,
      variables,
    ),
    options,
  );
useAdminDashboardAnalyticsQuery.document = AdminDashboardAnalyticsDocument;

useAdminDashboardAnalyticsQuery.getKey = (variables: AdminDashboardAnalyticsQueryVariables) => [
  'AdminDashboardAnalytics',
  variables,
];

export const DonationsDocument = `
    query Donations($filter: AnnouncementsByFilterInput!, $pageOffset: Int!, $pageSize: Int!, $orderBy: AnnouncementsOrderByInput) {
  announcementsBy(
    filter: $filter
    pageOffset: $pageOffset
    pageSize: $pageSize
    orderBy: $orderBy
  ) {
    announcements {
      ... on Announcement {
        ...AnnouncementsFields
        publishedAt
        donationRaisedAmount
        donationTargetAmount
        donationCurrency
      }
    }
    count
  }
}
    ${AnnouncementsFieldsFragmentDoc}`;
export const useDonationsQuery = <TData = DonationsQuery, TError = unknown>(
  variables: DonationsQueryVariables,
  options?: UseQueryOptions<DonationsQuery, TError, TData>,
) =>
  useQuery<DonationsQuery, TError, TData>(
    ['Donations', variables],
    fetcher<DonationsQuery, DonationsQueryVariables>(DonationsDocument, variables),
    options,
  );
useDonationsQuery.document = DonationsDocument;

useDonationsQuery.getKey = (variables: DonationsQueryVariables) => ['Donations', variables];

export const DonationDocument = `
    query Donation($id: ID!) {
  announcement: post(id: $id) {
    ... on Announcement {
      ...announcementDetailFragment
      donationRaisedAmount
      donationTargetAmount
      donationCurrency
    }
  }
}
    ${AnnouncementDetailFragmentFragmentDoc}`;
export const useDonationQuery = <TData = DonationQuery, TError = unknown>(
  variables: DonationQueryVariables,
  options?: UseQueryOptions<DonationQuery, TError, TData>,
) =>
  useQuery<DonationQuery, TError, TData>(
    ['Donation', variables],
    fetcher<DonationQuery, DonationQueryVariables>(DonationDocument, variables),
    options,
  );
useDonationQuery.document = DonationDocument;

useDonationQuery.getKey = (variables: DonationQueryVariables) => ['Donation', variables];

export const PinAnnouncementDocument = `
    mutation pinAnnouncement($id: ID!, $placeUuid: String!) {
  pinAnnouncement(id: $id, placeUuid: $placeUuid) {
    id
  }
}
    `;
export const usePinAnnouncementMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PinAnnouncementMutation,
    TError,
    PinAnnouncementMutationVariables,
    TContext
  >,
) =>
  useMutation<PinAnnouncementMutation, TError, PinAnnouncementMutationVariables, TContext>(
    (variables?: PinAnnouncementMutationVariables) =>
      fetcher<PinAnnouncementMutation, PinAnnouncementMutationVariables>(
        PinAnnouncementDocument,
        variables,
      )(),
    options,
  );
export const UnpinAnnouncementDocument = `
    mutation unpinAnnouncement($id: ID!, $placeUuid: String!) {
  unpinAnnouncement(id: $id, placeUuid: $placeUuid) {
    id
  }
}
    `;
export const useUnpinAnnouncementMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UnpinAnnouncementMutation,
    TError,
    UnpinAnnouncementMutationVariables,
    TContext
  >,
) =>
  useMutation<UnpinAnnouncementMutation, TError, UnpinAnnouncementMutationVariables, TContext>(
    (variables?: UnpinAnnouncementMutationVariables) =>
      fetcher<UnpinAnnouncementMutation, UnpinAnnouncementMutationVariables>(
        UnpinAnnouncementDocument,
        variables,
      )(),
    options,
  );
export const DeleteEventDocument = `
    mutation DeleteEvent($id: ID!) {
  deleteEvent(id: $id) {
    id
  }
}
    `;
export const useDeleteEventMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<DeleteEventMutation, TError, DeleteEventMutationVariables, TContext>,
) =>
  useMutation<DeleteEventMutation, TError, DeleteEventMutationVariables, TContext>(
    (variables?: DeleteEventMutationVariables) =>
      fetcher<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, variables)(),
    options,
  );
export const UnpublishEventDocument = `
    mutation UnpublishEvent($id: ID!, $batch: [ID!]) {
  unpublishEvent(id: $id, batch: $batch) {
    id
  }
}
    `;
export const useUnpublishEventMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UnpublishEventMutation,
    TError,
    UnpublishEventMutationVariables,
    TContext
  >,
) =>
  useMutation<UnpublishEventMutation, TError, UnpublishEventMutationVariables, TContext>(
    (variables?: UnpublishEventMutationVariables) =>
      fetcher<UnpublishEventMutation, UnpublishEventMutationVariables>(
        UnpublishEventDocument,
        variables,
      )(),
    options,
  );
export const PublishEventDocument = `
    mutation PublishEvent($id: ID!) {
  publishEvent(id: $id) {
    id
  }
}
    `;
export const usePublishEventMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PublishEventMutation,
    TError,
    PublishEventMutationVariables,
    TContext
  >,
) =>
  useMutation<PublishEventMutation, TError, PublishEventMutationVariables, TContext>(
    (variables?: PublishEventMutationVariables) =>
      fetcher<PublishEventMutation, PublishEventMutationVariables>(
        PublishEventDocument,
        variables,
      )(),
    options,
  );
export const AddEventDocument = `
    mutation addEvent($event: EventInput!) {
  addEvent(event: $event) {
    id
  }
}
    `;
export const useAddEventMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<AddEventMutation, TError, AddEventMutationVariables, TContext>,
) =>
  useMutation<AddEventMutation, TError, AddEventMutationVariables, TContext>(
    (variables?: AddEventMutationVariables) =>
      fetcher<AddEventMutation, AddEventMutationVariables>(AddEventDocument, variables)(),
    options,
  );
export const UpdateEventDocument = `
    mutation updateEvent($id: ID!, $event: EventInput!) {
  updateEvent(id: $id, event: $event) {
    id
  }
}
    `;
export const useUpdateEventMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateEventMutation, TError, UpdateEventMutationVariables, TContext>,
) =>
  useMutation<UpdateEventMutation, TError, UpdateEventMutationVariables, TContext>(
    (variables?: UpdateEventMutationVariables) =>
      fetcher<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, variables)(),
    options,
  );
export const EventsByDocument = `
    query eventsBy($filter: EventsByFilterInput!, $pageOffset: Int!, $pageSize: Int!, $orderBy: EventsOrderByInput) {
  eventsBy(
    filter: $filter
    pageOffset: $pageOffset
    pageSize: $pageSize
    orderBy: $orderBy
  ) {
    events {
      id
      title
      lang
      perex
      createdAt
      datetimeFrom
      datetimeTo
      publishedAt
      createdByAdmin
      category {
        id
        name
      }
      institution {
        id
        name
      }
      location {
        name
        online
        gps {
          lat
          lon
        }
      }
      places {
        id
        name
      }
      datetimeFrom
    }
    count
  }
}
    `;
export const useEventsByQuery = <TData = EventsByQuery, TError = unknown>(
  variables: EventsByQueryVariables,
  options?: UseQueryOptions<EventsByQuery, TError, TData>,
) =>
  useQuery<EventsByQuery, TError, TData>(
    ['eventsBy', variables],
    fetcher<EventsByQuery, EventsByQueryVariables>(EventsByDocument, variables),
    options,
  );
useEventsByQuery.document = EventsByDocument;

useEventsByQuery.getKey = (variables: EventsByQueryVariables) => ['eventsBy', variables];

export const EventDocument = `
    query event($eventId: ID!) {
  event: post(id: $eventId) {
    ... on Event {
      __typename
      id
      title
      lang
      datetimeTo
      imageObjects {
        fileName
        filePath
        id
        links {
          self
        }
      }
      videoObjects {
        fileName
        filePath
        id
        links {
          self
        }
      }
      content
      datetimeFrom
      datetimeTo
      publishedAt
      publishmentState
      source
      createdByAdmin
      category {
        name
      }
      categories {
        ...CategoryFields
      }
      institution {
        id
        name
      }
      places {
        id
        name
      }
      location {
        name
        online
        gps {
          lat
          lon
        }
      }
      ...EventMarketFields
    }
  }
}
    ${CategoryFieldsFragmentDoc}
${EventMarketFieldsFragmentDoc}`;
export const useEventQuery = <TData = EventQuery, TError = unknown>(
  variables: EventQueryVariables,
  options?: UseQueryOptions<EventQuery, TError, TData>,
) =>
  useQuery<EventQuery, TError, TData>(
    ['event', variables],
    fetcher<EventQuery, EventQueryVariables>(EventDocument, variables),
    options,
  );
useEventQuery.document = EventDocument;

useEventQuery.getKey = (variables: EventQueryVariables) => ['event', variables];

export const UpdateInstitutionInfoDocument = `
    mutation updateInstitutionInfo($id: ID!, $info: InstitutionInfoInput!) {
  setInstitutionInfo(id: $id, info: $info) {
    id
  }
}
    `;
export const useUpdateInstitutionInfoMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateInstitutionInfoMutation,
    TError,
    UpdateInstitutionInfoMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateInstitutionInfoMutation,
    TError,
    UpdateInstitutionInfoMutationVariables,
    TContext
  >(
    (variables?: UpdateInstitutionInfoMutationVariables) =>
      fetcher<UpdateInstitutionInfoMutation, UpdateInstitutionInfoMutationVariables>(
        UpdateInstitutionInfoDocument,
        variables,
      )(),
    options,
  );
export const InstitutionDocument = `
    query Institution($id: ID!) {
  institution(id: $id) {
    id
    name
    description
    webSite
    category {
      ...CategoryFields
    }
    logo
    createdAt
    places {
      id
      name
    }
    taskCodes
    webSite
    branding {
      avatar {
        id
        links {
          self
        }
      }
    }
    isChatEnabled
    isRecommended
    lang
  }
}
    ${CategoryFieldsFragmentDoc}`;
export const useInstitutionQuery = <TData = InstitutionQuery, TError = unknown>(
  variables: InstitutionQueryVariables,
  options?: UseQueryOptions<InstitutionQuery, TError, TData>,
) =>
  useQuery<InstitutionQuery, TError, TData>(
    ['Institution', variables],
    fetcher<InstitutionQuery, InstitutionQueryVariables>(InstitutionDocument, variables),
    options,
  );
useInstitutionQuery.document = InstitutionDocument;

useInstitutionQuery.getKey = (variables: InstitutionQueryVariables) => ['Institution', variables];

export const InstitutionPlacesDocument = `
    query institutionPlaces($id: ID!) {
  institution(id: $id) {
    places {
      id
      name
      stateCode
      countryCode
      timezoneCode
    }
  }
}
    `;
export const useInstitutionPlacesQuery = <TData = InstitutionPlacesQuery, TError = unknown>(
  variables: InstitutionPlacesQueryVariables,
  options?: UseQueryOptions<InstitutionPlacesQuery, TError, TData>,
) =>
  useQuery<InstitutionPlacesQuery, TError, TData>(
    ['institutionPlaces', variables],
    fetcher<InstitutionPlacesQuery, InstitutionPlacesQueryVariables>(
      InstitutionPlacesDocument,
      variables,
    ),
    options,
  );
useInstitutionPlacesQuery.document = InstitutionPlacesDocument;

useInstitutionPlacesQuery.getKey = (variables: InstitutionPlacesQueryVariables) => [
  'institutionPlaces',
  variables,
];

export const InstitutionAnnouncementCountsDocument = `
    query institutionAnnouncementCounts($id: ID!) {
  institution(id: $id) {
    publishedNewsCount
    unpublishedNewsCount
    scheduledNewsCount
    draftsNewsCount
  }
}
    `;
export const useInstitutionAnnouncementCountsQuery = <
  TData = InstitutionAnnouncementCountsQuery,
  TError = unknown,
>(
  variables: InstitutionAnnouncementCountsQueryVariables,
  options?: UseQueryOptions<InstitutionAnnouncementCountsQuery, TError, TData>,
) =>
  useQuery<InstitutionAnnouncementCountsQuery, TError, TData>(
    ['institutionAnnouncementCounts', variables],
    fetcher<InstitutionAnnouncementCountsQuery, InstitutionAnnouncementCountsQueryVariables>(
      InstitutionAnnouncementCountsDocument,
      variables,
    ),
    options,
  );
useInstitutionAnnouncementCountsQuery.document = InstitutionAnnouncementCountsDocument;

useInstitutionAnnouncementCountsQuery.getKey = (
  variables: InstitutionAnnouncementCountsQueryVariables,
) => ['institutionAnnouncementCounts', variables];

export const InstitutionInfoDocument = `
    query institutionInfo($id: ID!) {
  institution(id: $id) {
    name
    lang
    info {
      description
      website
      contactEmail
      contactPhone
      location {
        address
        geo {
          lat
          lon
        }
      }
    }
  }
}
    `;
export const useInstitutionInfoQuery = <TData = InstitutionInfoQuery, TError = unknown>(
  variables: InstitutionInfoQueryVariables,
  options?: UseQueryOptions<InstitutionInfoQuery, TError, TData>,
) =>
  useQuery<InstitutionInfoQuery, TError, TData>(
    ['institutionInfo', variables],
    fetcher<InstitutionInfoQuery, InstitutionInfoQueryVariables>(
      InstitutionInfoDocument,
      variables,
    ),
    options,
  );
useInstitutionInfoQuery.document = InstitutionInfoDocument;

useInstitutionInfoQuery.getKey = (variables: InstitutionInfoQueryVariables) => [
  'institutionInfo',
  variables,
];

export const CityInstitutionsIdsDocument = `
    query cityInstitutionsIds($pageSize: Int, $filter: InstitutionsByFilterInput!) {
  institutionsBy(filter: $filter, pageSize: $pageSize) {
    institutions {
      id
      name
    }
  }
}
    `;
export const useCityInstitutionsIdsQuery = <TData = CityInstitutionsIdsQuery, TError = unknown>(
  variables: CityInstitutionsIdsQueryVariables,
  options?: UseQueryOptions<CityInstitutionsIdsQuery, TError, TData>,
) =>
  useQuery<CityInstitutionsIdsQuery, TError, TData>(
    ['cityInstitutionsIds', variables],
    fetcher<CityInstitutionsIdsQuery, CityInstitutionsIdsQueryVariables>(
      CityInstitutionsIdsDocument,
      variables,
    ),
    options,
  );
useCityInstitutionsIdsQuery.document = CityInstitutionsIdsDocument;

useCityInstitutionsIdsQuery.getKey = (variables: CityInstitutionsIdsQueryVariables) => [
  'cityInstitutionsIds',
  variables,
];

export const InstiutionRoleDocument = `
    query instiutionRole($id: ID!) {
  institution(id: $id) {
    inPlaces {
      role
    }
    isChatEnabled
    visitorMode
  }
}
    `;
export const useInstiutionRoleQuery = <TData = InstiutionRoleQuery, TError = unknown>(
  variables: InstiutionRoleQueryVariables,
  options?: UseQueryOptions<InstiutionRoleQuery, TError, TData>,
) =>
  useQuery<InstiutionRoleQuery, TError, TData>(
    ['instiutionRole', variables],
    fetcher<InstiutionRoleQuery, InstiutionRoleQueryVariables>(InstiutionRoleDocument, variables),
    options,
  );
useInstiutionRoleQuery.document = InstiutionRoleDocument;

useInstiutionRoleQuery.getKey = (variables: InstiutionRoleQueryVariables) => [
  'instiutionRole',
  variables,
];

export const InstitutionBrandingDocument = `
    query institutionBranding($id: ID!) {
  institution(id: $id) {
    branding {
      avatar {
        id
        links {
          self
        }
      }
    }
  }
}
    `;
export const useInstitutionBrandingQuery = <TData = InstitutionBrandingQuery, TError = unknown>(
  variables: InstitutionBrandingQueryVariables,
  options?: UseQueryOptions<InstitutionBrandingQuery, TError, TData>,
) =>
  useQuery<InstitutionBrandingQuery, TError, TData>(
    ['institutionBranding', variables],
    fetcher<InstitutionBrandingQuery, InstitutionBrandingQueryVariables>(
      InstitutionBrandingDocument,
      variables,
    ),
    options,
  );
useInstitutionBrandingQuery.document = InstitutionBrandingDocument;

useInstitutionBrandingQuery.getKey = (variables: InstitutionBrandingQueryVariables) => [
  'institutionBranding',
  variables,
];

export const InstitutionNameDocument = `
    query institutionName($id: ID!) {
  institution(id: $id) {
    name
  }
}
    `;
export const useInstitutionNameQuery = <TData = InstitutionNameQuery, TError = unknown>(
  variables: InstitutionNameQueryVariables,
  options?: UseQueryOptions<InstitutionNameQuery, TError, TData>,
) =>
  useQuery<InstitutionNameQuery, TError, TData>(
    ['institutionName', variables],
    fetcher<InstitutionNameQuery, InstitutionNameQueryVariables>(
      InstitutionNameDocument,
      variables,
    ),
    options,
  );
useInstitutionNameQuery.document = InstitutionNameDocument;

useInstitutionNameQuery.getKey = (variables: InstitutionNameQueryVariables) => [
  'institutionName',
  variables,
];

export const AllInstitutionsDocument = `
    query AllInstitutions($nextToken: String, $pageSize: Int) {
  allInstitutions(nextToken: $nextToken, pageSize: $pageSize) {
    institutions {
      id
      name
      inPlaces {
        role
      }
      visitorMode
    }
    nextToken
  }
}
    `;
export const useAllInstitutionsQuery = <TData = AllInstitutionsQuery, TError = unknown>(
  variables?: AllInstitutionsQueryVariables,
  options?: UseQueryOptions<AllInstitutionsQuery, TError, TData>,
) =>
  useQuery<AllInstitutionsQuery, TError, TData>(
    ['AllInstitutions', variables],
    fetcher<AllInstitutionsQuery, AllInstitutionsQueryVariables>(
      AllInstitutionsDocument,
      variables,
    ),
    options,
  );
useAllInstitutionsQuery.document = AllInstitutionsDocument;

useAllInstitutionsQuery.getKey = (variables?: AllInstitutionsQueryVariables) => [
  'AllInstitutions',
  variables,
];

export const UserInstitutionsDocument = `
    query UserInstitutions($username: String!) {
  adminUser(username: $username) {
    institutions {
      id
      name
      inPlaces {
        role
      }
      visitorMode
    }
  }
}
    `;
export const useUserInstitutionsQuery = <TData = UserInstitutionsQuery, TError = unknown>(
  variables: UserInstitutionsQueryVariables,
  options?: UseQueryOptions<UserInstitutionsQuery, TError, TData>,
) =>
  useQuery<UserInstitutionsQuery, TError, TData>(
    ['UserInstitutions', variables],
    fetcher<UserInstitutionsQuery, UserInstitutionsQueryVariables>(
      UserInstitutionsDocument,
      variables,
    ),
    options,
  );
useUserInstitutionsQuery.document = UserInstitutionsDocument;

useUserInstitutionsQuery.getKey = (variables: UserInstitutionsQueryVariables) => [
  'UserInstitutions',
  variables,
];

export const MarketItemsDocument = `
    query MarketItems($filter: AnnouncementsByFilterInput!, $pageOffset: Int!, $pageSize: Int!, $orderBy: AnnouncementsOrderByInput) {
  announcementsBy(
    filter: $filter
    pageOffset: $pageOffset
    pageSize: $pageSize
    orderBy: $orderBy
  ) {
    announcements {
      ... on Announcement {
        ...AnnouncementsFields
        publishedAt
        subtitle
        marketUrl
        marketItemType
        marketItemRating
        marketItemDuration
        marketItemPricing {
          currency
          summary {
            fromPrice
            fromPriceBeforeDiscount
          }
        }
        marketItemInclusions
        marketItemExclusions
        marketItemAdditionalInfo
        marketItemCancellationPolicy {
          description
        }
        marketItemLogistics {
          startDescriptions
          endDescriptions
        }
        marketItemOrder
        marketItemViatorProductCode
      }
    }
    count
  }
}
    ${AnnouncementsFieldsFragmentDoc}`;
export const useMarketItemsQuery = <TData = MarketItemsQuery, TError = unknown>(
  variables: MarketItemsQueryVariables,
  options?: UseQueryOptions<MarketItemsQuery, TError, TData>,
) =>
  useQuery<MarketItemsQuery, TError, TData>(
    ['MarketItems', variables],
    fetcher<MarketItemsQuery, MarketItemsQueryVariables>(MarketItemsDocument, variables),
    options,
  );
useMarketItemsQuery.document = MarketItemsDocument;

useMarketItemsQuery.getKey = (variables: MarketItemsQueryVariables) => ['MarketItems', variables];

export const MarketItemDetailDocument = `
    query MarketItemDetail($id: ID!) {
  announcement: post(id: $id) {
    ... on Announcement {
      ...announcementDetailFragment
      typename
      publishedAt
      subtitle
      marketUrl
      marketItemType
      marketItemRating
      marketItemDuration
      marketItemPricing {
        currency
        summary {
          fromPrice
          fromPriceBeforeDiscount
        }
      }
      marketItemInclusions
      marketItemExclusions
      marketItemAdditionalInfo
      marketItemCancellationPolicy {
        description
      }
      marketItemLogistics {
        startDescriptions
        endDescriptions
      }
      marketItemOrder
      marketItemViatorProductCode
    }
  }
}
    ${AnnouncementDetailFragmentFragmentDoc}`;
export const useMarketItemDetailQuery = <TData = MarketItemDetailQuery, TError = unknown>(
  variables: MarketItemDetailQueryVariables,
  options?: UseQueryOptions<MarketItemDetailQuery, TError, TData>,
) =>
  useQuery<MarketItemDetailQuery, TError, TData>(
    ['MarketItemDetail', variables],
    fetcher<MarketItemDetailQuery, MarketItemDetailQueryVariables>(
      MarketItemDetailDocument,
      variables,
    ),
    options,
  );
useMarketItemDetailQuery.document = MarketItemDetailDocument;

useMarketItemDetailQuery.getKey = (variables: MarketItemDetailQueryVariables) => [
  'MarketItemDetail',
  variables,
];

export const ModeratorPostByDocument = `
    query ModeratorPostBy($filter: ModerationPostsByFilterInput!, $pageOffset: Int!, $pageSize: Int!) {
  moderationPostsBy(filter: $filter, pageOffset: $pageOffset, pageSize: $pageSize) {
    posts {
      __typename
      ... on Event {
        id
        title
        publishedAt
        installationInteraction(
          filter: {category: HARMFUL_CONTENT_REPORT, reaction: ALL}
        ) {
          fakeNewsCount
          hatefulCount
          inappropriateCount
          scamCount
          spamCount
          otherCount
        }
        moderationCategory
        categories {
          categoryTypes
        }
        aiMetadata {
          hatespeech {
            probability
            status
          }
        }
      }
      ... on Announcement {
        id
        title
        publishedAt
        installationInteraction(
          filter: {category: HARMFUL_CONTENT_REPORT, reaction: ALL}
        ) {
          fakeNewsCount
          hatefulCount
          inappropriateCount
          scamCount
          spamCount
          otherCount
        }
        moderationCategory
        categories {
          categoryTypes
        }
        aiMetadata {
          hatespeech {
            probability
            status
          }
        }
      }
      ... on QuickQuestion {
        id
        question
      }
    }
    totalCount
  }
}
    `;
export const useModeratorPostByQuery = <TData = ModeratorPostByQuery, TError = unknown>(
  variables: ModeratorPostByQueryVariables,
  options?: UseQueryOptions<ModeratorPostByQuery, TError, TData>,
) =>
  useQuery<ModeratorPostByQuery, TError, TData>(
    ['ModeratorPostBy', variables],
    fetcher<ModeratorPostByQuery, ModeratorPostByQueryVariables>(
      ModeratorPostByDocument,
      variables,
    ),
    options,
  );
useModeratorPostByQuery.document = ModeratorPostByDocument;

useModeratorPostByQuery.getKey = (variables: ModeratorPostByQueryVariables) => [
  'ModeratorPostBy',
  variables,
];

export const UpdatePetDocument = `
    mutation UpdatePet($id: ID!, $installationUuid: ID!, $pet: PetInput!) {
  updatePet(id: $id, installationUuid: $installationUuid, pet: $pet) {
    id
  }
}
    `;
export const useUpdatePetMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdatePetMutation, TError, UpdatePetMutationVariables, TContext>,
) =>
  useMutation<UpdatePetMutation, TError, UpdatePetMutationVariables, TContext>(
    (variables?: UpdatePetMutationVariables) =>
      fetcher<UpdatePetMutation, UpdatePetMutationVariables>(UpdatePetDocument, variables)(),
    options,
  );
export const DeletePetDocument = `
    mutation DeletePet($id: ID!, $installationUuid: ID) {
  deletePet(id: $id, installationUuid: $installationUuid) {
    id
  }
}
    `;
export const useDeletePetMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<DeletePetMutation, TError, DeletePetMutationVariables, TContext>,
) =>
  useMutation<DeletePetMutation, TError, DeletePetMutationVariables, TContext>(
    (variables?: DeletePetMutationVariables) =>
      fetcher<DeletePetMutation, DeletePetMutationVariables>(DeletePetDocument, variables)(),
    options,
  );
export const PublishPetDocument = `
    mutation PublishPet($id: ID!) {
  publishPet(id: $id) {
    id
  }
}
    `;
export const usePublishPetMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<PublishPetMutation, TError, PublishPetMutationVariables, TContext>,
) =>
  useMutation<PublishPetMutation, TError, PublishPetMutationVariables, TContext>(
    (variables?: PublishPetMutationVariables) =>
      fetcher<PublishPetMutation, PublishPetMutationVariables>(PublishPetDocument, variables)(),
    options,
  );
export const UnpublishPetDocument = `
    mutation UnpublishPet($id: ID!, $batch: [ID!]) {
  unpublishPet(id: $id, batch: $batch) {
    id
  }
}
    `;
export const useUnpublishPetMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UnpublishPetMutation,
    TError,
    UnpublishPetMutationVariables,
    TContext
  >,
) =>
  useMutation<UnpublishPetMutation, TError, UnpublishPetMutationVariables, TContext>(
    (variables?: UnpublishPetMutationVariables) =>
      fetcher<UnpublishPetMutation, UnpublishPetMutationVariables>(
        UnpublishPetDocument,
        variables,
      )(),
    options,
  );
export const PetsByDocument = `
    query PetsBy($filter: PetsByFilter!, $orderBy: PetsOrderBy, $nextToken: String) {
  petsBy(filter: $filter, orderBy: $orderBy, nextToken: $nextToken) {
    pets {
      id
      name
      phoneNumber
      email
      reward
      state
      description
      tags
      placeUuid
      createdAt
      updatedAt
      lostAt
      foundAt
      approvalState
      imageObjects {
        id
        links {
          self
          thumbnail
        }
        blurHash
        fileName
        filePath
      }
    }
    nextToken
  }
}
    `;
export const usePetsByQuery = <TData = PetsByQuery, TError = unknown>(
  variables: PetsByQueryVariables,
  options?: UseQueryOptions<PetsByQuery, TError, TData>,
) =>
  useQuery<PetsByQuery, TError, TData>(
    ['PetsBy', variables],
    fetcher<PetsByQuery, PetsByQueryVariables>(PetsByDocument, variables),
    options,
  );
usePetsByQuery.document = PetsByDocument;

usePetsByQuery.getKey = (variables: PetsByQueryVariables) => ['PetsBy', variables];

export const PetsInPlaceByDocument = `
    query petsInPlaceBy($filter: PetsInPlaceByFilter, $orderBy: PetsOrderBy, $nextToken: String) {
  petsInPlaceBy(filter: $filter, orderBy: $orderBy, nextToken: $nextToken) {
    pets {
      id
      name
      phoneNumber
      email
      reward
      state
      description
      tags
      placeUuid
      createdAt
      updatedAt
      lostAt
      foundAt
      approvalState
      imageObjects {
        id
        links {
          self
          thumbnail
        }
        blurHash
        fileName
        filePath
      }
    }
    nextToken
  }
}
    `;
export const usePetsInPlaceByQuery = <TData = PetsInPlaceByQuery, TError = unknown>(
  variables?: PetsInPlaceByQueryVariables,
  options?: UseQueryOptions<PetsInPlaceByQuery, TError, TData>,
) =>
  useQuery<PetsInPlaceByQuery, TError, TData>(
    ['petsInPlaceBy', variables],
    fetcher<PetsInPlaceByQuery, PetsInPlaceByQueryVariables>(PetsInPlaceByDocument, variables),
    options,
  );
usePetsInPlaceByQuery.document = PetsInPlaceByDocument;

usePetsInPlaceByQuery.getKey = (variables?: PetsInPlaceByQueryVariables) => [
  'petsInPlaceBy',
  variables,
];

export const PollsDocument = `
    query Polls($filter: AnnouncementsByFilterInput!, $pageOffset: Int!, $pageSize: Int!, $orderBy: AnnouncementsOrderByInput) {
  announcementsBy(
    filter: $filter
    pageOffset: $pageOffset
    pageSize: $pageSize
    orderBy: $orderBy
  ) {
    announcements {
      ... on Announcement {
        ...PollsFields
        publishedAt
      }
    }
    count
  }
}
    ${PollsFieldsFragmentDoc}`;
export const usePollsQuery = <TData = PollsQuery, TError = unknown>(
  variables: PollsQueryVariables,
  options?: UseQueryOptions<PollsQuery, TError, TData>,
) =>
  useQuery<PollsQuery, TError, TData>(
    ['Polls', variables],
    fetcher<PollsQuery, PollsQueryVariables>(PollsDocument, variables),
    options,
  );
usePollsQuery.document = PollsDocument;

usePollsQuery.getKey = (variables: PollsQueryVariables) => ['Polls', variables];

export const PollDraftsDocument = `
    query PollDrafts($filter: AnnouncementsByFilterInput!, $pageOffset: Int!, $pageSize: Int!, $orderBy: AnnouncementsOrderByInput) {
  announcementsBy(
    filter: $filter
    pageOffset: $pageOffset
    pageSize: $pageSize
    orderBy: $orderBy
  ) {
    announcements {
      ... on AnnouncementDraft {
        ...PollsFields
      }
    }
    count
  }
}
    ${PollsFieldsFragmentDoc}`;
export const usePollDraftsQuery = <TData = PollDraftsQuery, TError = unknown>(
  variables: PollDraftsQueryVariables,
  options?: UseQueryOptions<PollDraftsQuery, TError, TData>,
) =>
  useQuery<PollDraftsQuery, TError, TData>(
    ['PollDrafts', variables],
    fetcher<PollDraftsQuery, PollDraftsQueryVariables>(PollDraftsDocument, variables),
    options,
  );
usePollDraftsQuery.document = PollDraftsDocument;

usePollDraftsQuery.getKey = (variables: PollDraftsQueryVariables) => ['PollDrafts', variables];

export const InstitutionPollsCountsDocument = `
    query institutionPollsCounts($id: ID!) {
  institution(id: $id) {
    publishedPollNewsCount
    unpublishedPollNewsCount
    draftsPollNewsCount
    scheduledPollNewsCount
  }
}
    `;
export const useInstitutionPollsCountsQuery = <
  TData = InstitutionPollsCountsQuery,
  TError = unknown,
>(
  variables: InstitutionPollsCountsQueryVariables,
  options?: UseQueryOptions<InstitutionPollsCountsQuery, TError, TData>,
) =>
  useQuery<InstitutionPollsCountsQuery, TError, TData>(
    ['institutionPollsCounts', variables],
    fetcher<InstitutionPollsCountsQuery, InstitutionPollsCountsQueryVariables>(
      InstitutionPollsCountsDocument,
      variables,
    ),
    options,
  );
useInstitutionPollsCountsQuery.document = InstitutionPollsCountsDocument;

useInstitutionPollsCountsQuery.getKey = (variables: InstitutionPollsCountsQueryVariables) => [
  'institutionPollsCounts',
  variables,
];

export const AddQuickQuestionDocument = `
    mutation addQuickQuestion($quickQuestion: QuickQuestionInput!) {
  addQuickQuestion(quickQuestion: $quickQuestion) {
    id
  }
}
    `;
export const useAddQuickQuestionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AddQuickQuestionMutation,
    TError,
    AddQuickQuestionMutationVariables,
    TContext
  >,
) =>
  useMutation<AddQuickQuestionMutation, TError, AddQuickQuestionMutationVariables, TContext>(
    (variables?: AddQuickQuestionMutationVariables) =>
      fetcher<AddQuickQuestionMutation, AddQuickQuestionMutationVariables>(
        AddQuickQuestionDocument,
        variables,
      )(),
    options,
  );
export const UpdateQuickQuestionDocument = `
    mutation updateQuickQuestion($id: ID!, $quickQuestion: QuickQuestionInput!) {
  updateQuickQuestion(id: $id, quickQuestion: $quickQuestion) {
    id
  }
}
    `;
export const useUpdateQuickQuestionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateQuickQuestionMutation,
    TError,
    UpdateQuickQuestionMutationVariables,
    TContext
  >,
) =>
  useMutation<UpdateQuickQuestionMutation, TError, UpdateQuickQuestionMutationVariables, TContext>(
    (variables?: UpdateQuickQuestionMutationVariables) =>
      fetcher<UpdateQuickQuestionMutation, UpdateQuickQuestionMutationVariables>(
        UpdateQuickQuestionDocument,
        variables,
      )(),
    options,
  );
export const DeleteQuickQuestionDocument = `
    mutation deleteQuickQuestion($id: ID!) {
  deleteQuickQuestion(id: $id) {
    id
  }
}
    `;
export const useDeleteQuickQuestionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteQuickQuestionMutation,
    TError,
    DeleteQuickQuestionMutationVariables,
    TContext
  >,
) =>
  useMutation<DeleteQuickQuestionMutation, TError, DeleteQuickQuestionMutationVariables, TContext>(
    (variables?: DeleteQuickQuestionMutationVariables) =>
      fetcher<DeleteQuickQuestionMutation, DeleteQuickQuestionMutationVariables>(
        DeleteQuickQuestionDocument,
        variables,
      )(),
    options,
  );
export const PublishQuickQuestionDocument = `
    mutation publishQuickQuestion($id: ID!) {
  publishQuickQuestion(id: $id) {
    id
  }
}
    `;
export const usePublishQuickQuestionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PublishQuickQuestionMutation,
    TError,
    PublishQuickQuestionMutationVariables,
    TContext
  >,
) =>
  useMutation<
    PublishQuickQuestionMutation,
    TError,
    PublishQuickQuestionMutationVariables,
    TContext
  >(
    (variables?: PublishQuickQuestionMutationVariables) =>
      fetcher<PublishQuickQuestionMutation, PublishQuickQuestionMutationVariables>(
        PublishQuickQuestionDocument,
        variables,
      )(),
    options,
  );
export const UnpublishQuickQuestionDocument = `
    mutation unpublishQuickQuestion($id: ID!, $batch: [ID!]) {
  unpublishQuickQuestion(id: $id, batch: $batch) {
    id
  }
}
    `;
export const useUnpublishQuickQuestionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UnpublishQuickQuestionMutation,
    TError,
    UnpublishQuickQuestionMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UnpublishQuickQuestionMutation,
    TError,
    UnpublishQuickQuestionMutationVariables,
    TContext
  >(
    (variables?: UnpublishQuickQuestionMutationVariables) =>
      fetcher<UnpublishQuickQuestionMutation, UnpublishQuickQuestionMutationVariables>(
        UnpublishQuickQuestionDocument,
        variables,
      )(),
    options,
  );
export const CancelScheduledQuickQuestionDocument = `
    mutation cancelScheduledQuickQuestion($id: ID!) {
  cancelScheduledQuickQuestion(id: $id) {
    id
  }
}
    `;
export const useCancelScheduledQuickQuestionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CancelScheduledQuickQuestionMutation,
    TError,
    CancelScheduledQuickQuestionMutationVariables,
    TContext
  >,
) =>
  useMutation<
    CancelScheduledQuickQuestionMutation,
    TError,
    CancelScheduledQuickQuestionMutationVariables,
    TContext
  >(
    (variables?: CancelScheduledQuickQuestionMutationVariables) =>
      fetcher<CancelScheduledQuickQuestionMutation, CancelScheduledQuickQuestionMutationVariables>(
        CancelScheduledQuickQuestionDocument,
        variables,
      )(),
    options,
  );
export const QuickQuestionDocument = `
    query QuickQuestion($id: ID!, $lang: String) {
  post(id: $id, lang: $lang) {
    ... on QuickQuestion {
      __typename
      id
      question
      questionOptions
      quickQuestionEndDate
      publishedAt
      publishmentState
      institution {
        id
        name
      }
      notifications
      notifyNow
      lang
      scheduledAt
      privateResults
    }
  }
}
    `;
export const useQuickQuestionQuery = <TData = QuickQuestionQuery, TError = unknown>(
  variables: QuickQuestionQueryVariables,
  options?: UseQueryOptions<QuickQuestionQuery, TError, TData>,
) =>
  useQuery<QuickQuestionQuery, TError, TData>(
    ['QuickQuestion', variables],
    fetcher<QuickQuestionQuery, QuickQuestionQueryVariables>(QuickQuestionDocument, variables),
    options,
  );
useQuickQuestionQuery.document = QuickQuestionDocument;

useQuickQuestionQuery.getKey = (variables: QuickQuestionQueryVariables) => [
  'QuickQuestion',
  variables,
];

export const QuickQuestionsByDocument = `
    query QuickQuestionsBy($filter: QuickQuestionsByFilterInput!, $orderBy: QuickQuestionsOrderByInput, $nextToken: String) {
  quickQuestionsBy(filter: $filter, orderBy: $orderBy, nextToken: $nextToken) {
    count
    quickQuestions {
      __typename
      id
      question
      questionOptions
      quickQuestionEndDate
      publishedAt
      publishmentState
      institution {
        id
        name
        branding {
          avatar {
            links {
              self
            }
          }
        }
      }
      notifications
      notifyNow
      lang
      scheduledAt
      privateResults
    }
    nextToken
  }
}
    `;
export const useQuickQuestionsByQuery = <TData = QuickQuestionsByQuery, TError = unknown>(
  variables: QuickQuestionsByQueryVariables,
  options?: UseQueryOptions<QuickQuestionsByQuery, TError, TData>,
) =>
  useQuery<QuickQuestionsByQuery, TError, TData>(
    ['QuickQuestionsBy', variables],
    fetcher<QuickQuestionsByQuery, QuickQuestionsByQueryVariables>(
      QuickQuestionsByDocument,
      variables,
    ),
    options,
  );
useQuickQuestionsByQuery.document = QuickQuestionsByDocument;

useQuickQuestionsByQuery.getKey = (variables: QuickQuestionsByQueryVariables) => [
  'QuickQuestionsBy',
  variables,
];

export const RaffleDocument = `
    query Raffle($id: ID!) {
  announcement: post(id: $id) {
    ... on Announcement {
      ...raffleDetailFragment
    }
  }
}
    ${RaffleDetailFragmentFragmentDoc}`;
export const useRaffleQuery = <TData = RaffleQuery, TError = unknown>(
  variables: RaffleQueryVariables,
  options?: UseQueryOptions<RaffleQuery, TError, TData>,
) =>
  useQuery<RaffleQuery, TError, TData>(
    ['Raffle', variables],
    fetcher<RaffleQuery, RaffleQueryVariables>(RaffleDocument, variables),
    options,
  );
useRaffleQuery.document = RaffleDocument;

useRaffleQuery.getKey = (variables: RaffleQueryVariables) => ['Raffle', variables];

export const RafflesDocument = `
    query Raffles($filter: AnnouncementsByFilterInput!, $pageOffset: Int!, $pageSize: Int!, $orderBy: AnnouncementsOrderByInput) {
  announcementsBy(
    filter: $filter
    pageOffset: $pageOffset
    pageSize: $pageSize
    orderBy: $orderBy
  ) {
    announcements {
      ... on Announcement {
        ...RafflesFields
        publishedAt
      }
    }
    count
  }
}
    ${RafflesFieldsFragmentDoc}`;
export const useRafflesQuery = <TData = RafflesQuery, TError = unknown>(
  variables: RafflesQueryVariables,
  options?: UseQueryOptions<RafflesQuery, TError, TData>,
) =>
  useQuery<RafflesQuery, TError, TData>(
    ['Raffles', variables],
    fetcher<RafflesQuery, RafflesQueryVariables>(RafflesDocument, variables),
    options,
  );
useRafflesQuery.document = RafflesDocument;

useRafflesQuery.getKey = (variables: RafflesQueryVariables) => ['Raffles', variables];

export const GetServiceInfoDocument = `
    query getServiceInfo {
  service {
    version
  }
}
    `;
export const useGetServiceInfoQuery = <TData = GetServiceInfoQuery, TError = unknown>(
  variables?: GetServiceInfoQueryVariables,
  options?: UseQueryOptions<GetServiceInfoQuery, TError, TData>,
) =>
  useQuery<GetServiceInfoQuery, TError, TData>(
    ['getServiceInfo', variables],
    fetcher<GetServiceInfoQuery, GetServiceInfoQueryVariables>(GetServiceInfoDocument, variables),
    options,
  );
useGetServiceInfoQuery.document = GetServiceInfoDocument;

useGetServiceInfoQuery.getKey = (variables?: GetServiceInfoQueryVariables) => [
  'getServiceInfo',
  variables,
];

export const SaBusinessNotificationsDocument = `
    query SABusinessNotifications($filter: BusinessNotificationCountsFilterInput!, $nextToken: String, $pageSize: Int) {
  businessNotificationCounts(
    filter: $filter
    nextToken: $nextToken
    pageSize: $pageSize
  ) {
    places {
      placeUuid
      name
      counts {
        count
        date
      }
    }
    nextToken
  }
}
    `;
export const useSaBusinessNotificationsQuery = <
  TData = SaBusinessNotificationsQuery,
  TError = unknown,
>(
  variables: SaBusinessNotificationsQueryVariables,
  options?: UseQueryOptions<SaBusinessNotificationsQuery, TError, TData>,
) =>
  useQuery<SaBusinessNotificationsQuery, TError, TData>(
    ['SABusinessNotifications', variables],
    fetcher<SaBusinessNotificationsQuery, SaBusinessNotificationsQueryVariables>(
      SaBusinessNotificationsDocument,
      variables,
    ),
    options,
  );
useSaBusinessNotificationsQuery.document = SaBusinessNotificationsDocument;

useSaBusinessNotificationsQuery.getKey = (variables: SaBusinessNotificationsQueryVariables) => [
  'SABusinessNotifications',
  variables,
];

export const BusinessActiveUsersCountsDocument = `
    query businessActiveUsersCounts($filter: BusinessActiveUsersCountsFilterInput, $nextToken: String, $pageSize: Int) {
  businessActiveUsersCounts(
    filter: $filter
    nextToken: $nextToken
    pageSize: $pageSize
  ) {
    places {
      placeUuid
      name
      today
      yesterday
      thirtyDays
    }
    nextToken
  }
}
    `;
export const useBusinessActiveUsersCountsQuery = <
  TData = BusinessActiveUsersCountsQuery,
  TError = unknown,
>(
  variables?: BusinessActiveUsersCountsQueryVariables,
  options?: UseQueryOptions<BusinessActiveUsersCountsQuery, TError, TData>,
) =>
  useQuery<BusinessActiveUsersCountsQuery, TError, TData>(
    ['businessActiveUsersCounts', variables],
    fetcher<BusinessActiveUsersCountsQuery, BusinessActiveUsersCountsQueryVariables>(
      BusinessActiveUsersCountsDocument,
      variables,
    ),
    options,
  );
useBusinessActiveUsersCountsQuery.document = BusinessActiveUsersCountsDocument;

useBusinessActiveUsersCountsQuery.getKey = (
  variables?: BusinessActiveUsersCountsQueryVariables,
) => ['businessActiveUsersCounts', variables];

export const SaAddInfluencerDocument = `
    mutation SAAddInfluencer($influencer: InfluencerInput!) {
  addInfluencer(influencer: $influencer) {
    id
  }
}
    `;
export const useSaAddInfluencerMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaAddInfluencerMutation,
    TError,
    SaAddInfluencerMutationVariables,
    TContext
  >,
) =>
  useMutation<SaAddInfluencerMutation, TError, SaAddInfluencerMutationVariables, TContext>(
    (variables?: SaAddInfluencerMutationVariables) =>
      fetcher<SaAddInfluencerMutation, SaAddInfluencerMutationVariables>(
        SaAddInfluencerDocument,
        variables,
      )(),
    options,
  );
export const SaUpdateInfluencerDocument = `
    mutation SAUpdateInfluencer($influencer: InfluencerInput!, $id: ID!) {
  updateInfluencer(influencer: $influencer, id: $id) {
    id
  }
}
    `;
export const useSaUpdateInfluencerMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaUpdateInfluencerMutation,
    TError,
    SaUpdateInfluencerMutationVariables,
    TContext
  >,
) =>
  useMutation<SaUpdateInfluencerMutation, TError, SaUpdateInfluencerMutationVariables, TContext>(
    (variables?: SaUpdateInfluencerMutationVariables) =>
      fetcher<SaUpdateInfluencerMutation, SaUpdateInfluencerMutationVariables>(
        SaUpdateInfluencerDocument,
        variables,
      )(),
    options,
  );
export const SaDeleteInfluencerDocument = `
    mutation SADeleteInfluencer($id: ID!) {
  deleteInfluencer(id: $id) {
    id
  }
}
    `;
export const useSaDeleteInfluencerMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaDeleteInfluencerMutation,
    TError,
    SaDeleteInfluencerMutationVariables,
    TContext
  >,
) =>
  useMutation<SaDeleteInfluencerMutation, TError, SaDeleteInfluencerMutationVariables, TContext>(
    (variables?: SaDeleteInfluencerMutationVariables) =>
      fetcher<SaDeleteInfluencerMutation, SaDeleteInfluencerMutationVariables>(
        SaDeleteInfluencerDocument,
        variables,
      )(),
    options,
  );
export const SaAddInfluencerSpotDocument = `
    mutation SAAddInfluencerSpot($influencerSpot: InfluencerSpotInput!) {
  addInfluencerSpot(influencerSpot: $influencerSpot) {
    id
  }
}
    `;
export const useSaAddInfluencerSpotMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaAddInfluencerSpotMutation,
    TError,
    SaAddInfluencerSpotMutationVariables,
    TContext
  >,
) =>
  useMutation<SaAddInfluencerSpotMutation, TError, SaAddInfluencerSpotMutationVariables, TContext>(
    (variables?: SaAddInfluencerSpotMutationVariables) =>
      fetcher<SaAddInfluencerSpotMutation, SaAddInfluencerSpotMutationVariables>(
        SaAddInfluencerSpotDocument,
        variables,
      )(),
    options,
  );
export const SaUpdateInfluencerSpotDocument = `
    mutation SAUpdateInfluencerSpot($influencerSpot: InfluencerSpotInput!, $id: ID!) {
  updateInfluencerSpot(influencerSpot: $influencerSpot, id: $id) {
    id
  }
}
    `;
export const useSaUpdateInfluencerSpotMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaUpdateInfluencerSpotMutation,
    TError,
    SaUpdateInfluencerSpotMutationVariables,
    TContext
  >,
) =>
  useMutation<
    SaUpdateInfluencerSpotMutation,
    TError,
    SaUpdateInfluencerSpotMutationVariables,
    TContext
  >(
    (variables?: SaUpdateInfluencerSpotMutationVariables) =>
      fetcher<SaUpdateInfluencerSpotMutation, SaUpdateInfluencerSpotMutationVariables>(
        SaUpdateInfluencerSpotDocument,
        variables,
      )(),
    options,
  );
export const SaDeleteInfluencerSpotDocument = `
    mutation SADeleteInfluencerSpot($id: ID!) {
  deleteInfluencerSpot(id: $id) {
    id
  }
}
    `;
export const useSaDeleteInfluencerSpotMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaDeleteInfluencerSpotMutation,
    TError,
    SaDeleteInfluencerSpotMutationVariables,
    TContext
  >,
) =>
  useMutation<
    SaDeleteInfluencerSpotMutation,
    TError,
    SaDeleteInfluencerSpotMutationVariables,
    TContext
  >(
    (variables?: SaDeleteInfluencerSpotMutationVariables) =>
      fetcher<SaDeleteInfluencerSpotMutation, SaDeleteInfluencerSpotMutationVariables>(
        SaDeleteInfluencerSpotDocument,
        variables,
      )(),
    options,
  );
export const SaInfluencersDocument = `
    query SAInfluencers($filter: InfluencersByFilterInput!, $nextToken: String) {
  influencersBy(filter: $filter, nextToken: $nextToken) {
    influencers {
      id
      avatar {
        ...ImageFragment
      }
      background {
        ...ImageFragment
      }
      name
      description
      socials {
        type
        url
      }
      properties {
        name
        value
      }
      places {
        id
        name
      }
    }
    nextToken
  }
}
    ${ImageFragmentFragmentDoc}`;
export const useSaInfluencersQuery = <TData = SaInfluencersQuery, TError = unknown>(
  variables: SaInfluencersQueryVariables,
  options?: UseQueryOptions<SaInfluencersQuery, TError, TData>,
) =>
  useQuery<SaInfluencersQuery, TError, TData>(
    ['SAInfluencers', variables],
    fetcher<SaInfluencersQuery, SaInfluencersQueryVariables>(SaInfluencersDocument, variables),
    options,
  );
useSaInfluencersQuery.document = SaInfluencersDocument;

useSaInfluencersQuery.getKey = (variables: SaInfluencersQueryVariables) => [
  'SAInfluencers',
  variables,
];

export const SaInfluencerSpotsDocument = `
    query SAInfluencerSpots($filter: InfluencerSpotsByFilter!, $nextToken: String) {
  influencerSpotsBy(filter: $filter, nextToken: $nextToken) {
    influencerSpots {
      id
      placeUuid
      influencerUuid
      title
      content
      image {
        ...ImageFragment
      }
      location {
        address
        geo {
          lat
          lon
        }
      }
      startTime
      endTime
      duration
      marketItem {
        ...BaseMarketItemFragment
      }
      price {
        currency
        amount
      }
      category {
        id
        name
      }
      favourite
    }
    nextToken
  }
}
    ${ImageFragmentFragmentDoc}
${BaseMarketItemFragmentFragmentDoc}`;
export const useSaInfluencerSpotsQuery = <TData = SaInfluencerSpotsQuery, TError = unknown>(
  variables: SaInfluencerSpotsQueryVariables,
  options?: UseQueryOptions<SaInfluencerSpotsQuery, TError, TData>,
) =>
  useQuery<SaInfluencerSpotsQuery, TError, TData>(
    ['SAInfluencerSpots', variables],
    fetcher<SaInfluencerSpotsQuery, SaInfluencerSpotsQueryVariables>(
      SaInfluencerSpotsDocument,
      variables,
    ),
    options,
  );
useSaInfluencerSpotsQuery.document = SaInfluencerSpotsDocument;

useSaInfluencerSpotsQuery.getKey = (variables: SaInfluencerSpotsQueryVariables) => [
  'SAInfluencerSpots',
  variables,
];

export const SaInfluencerDocument = `
    query SAInfluencer($id: ID!) {
  influencer(id: $id) {
    id
    avatar {
      ...ImageFragment
    }
    background {
      ...ImageFragment
    }
    name
    description
    socials {
      type
      url
    }
    properties {
      name
      value
    }
    places {
      id
      name
    }
  }
}
    ${ImageFragmentFragmentDoc}`;
export const useSaInfluencerQuery = <TData = SaInfluencerQuery, TError = unknown>(
  variables: SaInfluencerQueryVariables,
  options?: UseQueryOptions<SaInfluencerQuery, TError, TData>,
) =>
  useQuery<SaInfluencerQuery, TError, TData>(
    ['SAInfluencer', variables],
    fetcher<SaInfluencerQuery, SaInfluencerQueryVariables>(SaInfluencerDocument, variables),
    options,
  );
useSaInfluencerQuery.document = SaInfluencerDocument;

useSaInfluencerQuery.getKey = (variables: SaInfluencerQueryVariables) => [
  'SAInfluencer',
  variables,
];

export const SaInfluencerSpotDocument = `
    query SAInfluencerSpot($id: ID!) {
  influencerSpot(id: $id) {
    id
    placeUuid
    influencerUuid
    title
    content
    image {
      ...ImageFragment
    }
    location {
      address
      geo {
        lat
        lon
      }
    }
    startTime
    endTime
    duration
    marketItem {
      ...BaseMarketItemFragment
    }
    price {
      currency
      amount
    }
    category {
      id
      name
    }
    favourite
  }
}
    ${ImageFragmentFragmentDoc}
${BaseMarketItemFragmentFragmentDoc}`;
export const useSaInfluencerSpotQuery = <TData = SaInfluencerSpotQuery, TError = unknown>(
  variables: SaInfluencerSpotQueryVariables,
  options?: UseQueryOptions<SaInfluencerSpotQuery, TError, TData>,
) =>
  useQuery<SaInfluencerSpotQuery, TError, TData>(
    ['SAInfluencerSpot', variables],
    fetcher<SaInfluencerSpotQuery, SaInfluencerSpotQueryVariables>(
      SaInfluencerSpotDocument,
      variables,
    ),
    options,
  );
useSaInfluencerSpotQuery.document = SaInfluencerSpotDocument;

useSaInfluencerSpotQuery.getKey = (variables: SaInfluencerSpotQueryVariables) => [
  'SAInfluencerSpot',
  variables,
];

export const SaInfluencerSpotPlaceDocument = `
    query SAInfluencerSpotPlace($id: ID!) {
  place(id: $id) {
    id
    name
  }
}
    `;
export const useSaInfluencerSpotPlaceQuery = <TData = SaInfluencerSpotPlaceQuery, TError = unknown>(
  variables: SaInfluencerSpotPlaceQueryVariables,
  options?: UseQueryOptions<SaInfluencerSpotPlaceQuery, TError, TData>,
) =>
  useQuery<SaInfluencerSpotPlaceQuery, TError, TData>(
    ['SAInfluencerSpotPlace', variables],
    fetcher<SaInfluencerSpotPlaceQuery, SaInfluencerSpotPlaceQueryVariables>(
      SaInfluencerSpotPlaceDocument,
      variables,
    ),
    options,
  );
useSaInfluencerSpotPlaceQuery.document = SaInfluencerSpotPlaceDocument;

useSaInfluencerSpotPlaceQuery.getKey = (variables: SaInfluencerSpotPlaceQueryVariables) => [
  'SAInfluencerSpotPlace',
  variables,
];

export const SaMarketItemsByDocument = `
    query SAMarketItemsBy($filter: MarketItemsByFilterInput!, $nextToken: String) {
  marketItemsBy(filter: $filter, nextToken: $nextToken) {
    marketItems {
      ... on TUIMarketItem {
        id
        title
        imageObjects {
          ...ImageFragment
        }
        content
        placeUuid
        gpsLocation {
          lat
          lon
        }
        duration
        price {
          retailPrice
          currency
        }
      }
      ... on CustomMarketItem {
        id
        title
        imageObjects {
          ...ImageFragment
        }
        content
        placeUuid
        gpsLocation {
          lat
          lon
        }
        duration
        price {
          retailPrice
          currency
        }
      }
      ... on ViatorMarketItem {
        id
        title
        imageObjects {
          ...ImageFragment
        }
        content
        placeUuid
        gpsLocation {
          lat
          lon
        }
        duration
        price {
          retailPrice
          currency
        }
      }
    }
    nextToken
  }
}
    ${ImageFragmentFragmentDoc}`;
export const useSaMarketItemsByQuery = <TData = SaMarketItemsByQuery, TError = unknown>(
  variables: SaMarketItemsByQueryVariables,
  options?: UseQueryOptions<SaMarketItemsByQuery, TError, TData>,
) =>
  useQuery<SaMarketItemsByQuery, TError, TData>(
    ['SAMarketItemsBy', variables],
    fetcher<SaMarketItemsByQuery, SaMarketItemsByQueryVariables>(
      SaMarketItemsByDocument,
      variables,
    ),
    options,
  );
useSaMarketItemsByQuery.document = SaMarketItemsByDocument;

useSaMarketItemsByQuery.getKey = (variables: SaMarketItemsByQueryVariables) => [
  'SAMarketItemsBy',
  variables,
];

export const SaAddInstitutionDocument = `
    mutation SAAddInstitution($institution: InstitutionInput!) {
  addInstitution(institution: $institution) {
    id
  }
}
    `;
export const useSaAddInstitutionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaAddInstitutionMutation,
    TError,
    SaAddInstitutionMutationVariables,
    TContext
  >,
) =>
  useMutation<SaAddInstitutionMutation, TError, SaAddInstitutionMutationVariables, TContext>(
    (variables?: SaAddInstitutionMutationVariables) =>
      fetcher<SaAddInstitutionMutation, SaAddInstitutionMutationVariables>(
        SaAddInstitutionDocument,
        variables,
      )(),
    options,
  );
export const SaDeleteInstitutionDocument = `
    mutation SADeleteInstitution($id: ID!) {
  deleteInstitution(id: $id) {
    id
  }
}
    `;
export const useSaDeleteInstitutionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaDeleteInstitutionMutation,
    TError,
    SaDeleteInstitutionMutationVariables,
    TContext
  >,
) =>
  useMutation<SaDeleteInstitutionMutation, TError, SaDeleteInstitutionMutationVariables, TContext>(
    (variables?: SaDeleteInstitutionMutationVariables) =>
      fetcher<SaDeleteInstitutionMutation, SaDeleteInstitutionMutationVariables>(
        SaDeleteInstitutionDocument,
        variables,
      )(),
    options,
  );
export const SaHideInstitutionDocument = `
    mutation SAHideInstitution($id: ID!) {
  hideInstitution(id: $id) {
    id
  }
}
    `;
export const useSaHideInstitutionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaHideInstitutionMutation,
    TError,
    SaHideInstitutionMutationVariables,
    TContext
  >,
) =>
  useMutation<SaHideInstitutionMutation, TError, SaHideInstitutionMutationVariables, TContext>(
    (variables?: SaHideInstitutionMutationVariables) =>
      fetcher<SaHideInstitutionMutation, SaHideInstitutionMutationVariables>(
        SaHideInstitutionDocument,
        variables,
      )(),
    options,
  );
export const SaUpdateInstitutionDocument = `
    mutation SAUpdateInstitution($id: ID!, $institution: InstitutionInput!) {
  updateInstitution(id: $id, institution: $institution) {
    id
  }
}
    `;
export const useSaUpdateInstitutionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaUpdateInstitutionMutation,
    TError,
    SaUpdateInstitutionMutationVariables,
    TContext
  >,
) =>
  useMutation<SaUpdateInstitutionMutation, TError, SaUpdateInstitutionMutationVariables, TContext>(
    (variables?: SaUpdateInstitutionMutationVariables) =>
      fetcher<SaUpdateInstitutionMutation, SaUpdateInstitutionMutationVariables>(
        SaUpdateInstitutionDocument,
        variables,
      )(),
    options,
  );
export const SaAddInstitutionRoleDocument = `
    mutation SAAddInstitutionRole($id: ID!, $inPlaces: [InstitutionInPlaceInput!]!) {
  putInstitutionPlaces(id: $id, inPlaces: $inPlaces) {
    id
  }
}
    `;
export const useSaAddInstitutionRoleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaAddInstitutionRoleMutation,
    TError,
    SaAddInstitutionRoleMutationVariables,
    TContext
  >,
) =>
  useMutation<
    SaAddInstitutionRoleMutation,
    TError,
    SaAddInstitutionRoleMutationVariables,
    TContext
  >(
    (variables?: SaAddInstitutionRoleMutationVariables) =>
      fetcher<SaAddInstitutionRoleMutation, SaAddInstitutionRoleMutationVariables>(
        SaAddInstitutionRoleDocument,
        variables,
      )(),
    options,
  );
export const SaAllInstitutionsDocument = `
    query SAAllInstitutions($nextToken: String, $pageSize: Int) {
  allInstitutions(nextToken: $nextToken, pageSize: $pageSize) {
    institutions {
      id
      enabled
      createdAt
      places {
        id
        name
      }
      name
      category {
        id
        name
      }
      info {
        contactEmail
        contactPhone
        location {
          address
          geo {
            lat
            lon
          }
        }
        description
        website
      }
      branding {
        logo {
          id
          links {
            self
          }
        }
        avatar {
          id
          links {
            self
          }
        }
      }
      inPlaces {
        role
      }
    }
    nextToken
  }
}
    `;
export const useSaAllInstitutionsQuery = <TData = SaAllInstitutionsQuery, TError = unknown>(
  variables?: SaAllInstitutionsQueryVariables,
  options?: UseQueryOptions<SaAllInstitutionsQuery, TError, TData>,
) =>
  useQuery<SaAllInstitutionsQuery, TError, TData>(
    ['SAAllInstitutions', variables],
    fetcher<SaAllInstitutionsQuery, SaAllInstitutionsQueryVariables>(
      SaAllInstitutionsDocument,
      variables,
    ),
    options,
  );
useSaAllInstitutionsQuery.document = SaAllInstitutionsDocument;

useSaAllInstitutionsQuery.getKey = (variables?: SaAllInstitutionsQueryVariables) => [
  'SAAllInstitutions',
  variables,
];

export const SaInstitutionsByDocument = `
    query SAInstitutionsBy($pageSize: Int, $filter: InstitutionsByFilterInput!) {
  institutionsBy(filter: $filter, pageSize: $pageSize) {
    nextToken
    institutions {
      id
      enabled
      createdAt
      places {
        id
        name
      }
      name
      category {
        id
        name
      }
      taskCodes
      info {
        contactEmail
        contactPhone
        location {
          address
          geo {
            lat
            lon
          }
        }
        description
        website
      }
      branding {
        logo {
          id
          fileName
          filePath
          links {
            self
          }
        }
        avatar {
          id
          fileName
          filePath
          links {
            self
          }
        }
      }
      inPlaces {
        role
      }
    }
  }
}
    `;
export const useSaInstitutionsByQuery = <TData = SaInstitutionsByQuery, TError = unknown>(
  variables: SaInstitutionsByQueryVariables,
  options?: UseQueryOptions<SaInstitutionsByQuery, TError, TData>,
) =>
  useQuery<SaInstitutionsByQuery, TError, TData>(
    ['SAInstitutionsBy', variables],
    fetcher<SaInstitutionsByQuery, SaInstitutionsByQueryVariables>(
      SaInstitutionsByDocument,
      variables,
    ),
    options,
  );
useSaInstitutionsByQuery.document = SaInstitutionsByDocument;

useSaInstitutionsByQuery.getKey = (variables: SaInstitutionsByQueryVariables) => [
  'SAInstitutionsBy',
  variables,
];

export const SaInstitutionsBySearchDocument = `
    query SAInstitutionsBySearch($pageSize: Int, $filter: InstitutionsBySearchFilterInput!, $pageOffset: Int) {
  institutionsBySearch(
    filter: $filter
    pageSize: $pageSize
    pageOffset: $pageOffset
  ) {
    nextToken
    institutions {
      id
      enabled
      createdAt
      places {
        id
        name
      }
      name
      category {
        id
        name
      }
      taskCodes
      info {
        contactEmail
        contactPhone
        location {
          address
          geo {
            lat
            lon
          }
        }
        description
        website
      }
      branding {
        logo {
          id
          fileName
          filePath
          links {
            self
          }
        }
        avatar {
          id
          fileName
          filePath
          links {
            self
          }
        }
      }
      inPlaces {
        role
      }
    }
  }
}
    `;
export const useSaInstitutionsBySearchQuery = <
  TData = SaInstitutionsBySearchQuery,
  TError = unknown,
>(
  variables: SaInstitutionsBySearchQueryVariables,
  options?: UseQueryOptions<SaInstitutionsBySearchQuery, TError, TData>,
) =>
  useQuery<SaInstitutionsBySearchQuery, TError, TData>(
    ['SAInstitutionsBySearch', variables],
    fetcher<SaInstitutionsBySearchQuery, SaInstitutionsBySearchQueryVariables>(
      SaInstitutionsBySearchDocument,
      variables,
    ),
    options,
  );
useSaInstitutionsBySearchQuery.document = SaInstitutionsBySearchDocument;

useSaInstitutionsBySearchQuery.getKey = (variables: SaInstitutionsBySearchQueryVariables) => [
  'SAInstitutionsBySearch',
  variables,
];

export const SaInstitutionDocument = `
    query SAInstitution($id: ID!) {
  institution(id: $id) {
    id
    createdAt
    enabled
    places {
      id
      name
    }
    inPlaces {
      role
    }
    name
    category {
      id
      name
    }
    taskCodes
    info {
      contactEmail
      contactPhone
      location {
        address
        geo {
          lat
          lon
        }
      }
      description
      website
      featureFlags {
        ...InstitutionFeatureFlagFragment
      }
    }
    branding {
      logo {
        id
        fileName
        filePath
        links {
          self
        }
      }
      avatar {
        id
        fileName
        filePath
        links {
          self
        }
      }
    }
    isChatEnabled
    isRecommended
    lang
    visitorMode
    preferences
    countryCode
  }
}
    ${InstitutionFeatureFlagFragmentFragmentDoc}`;
export const useSaInstitutionQuery = <TData = SaInstitutionQuery, TError = unknown>(
  variables: SaInstitutionQueryVariables,
  options?: UseQueryOptions<SaInstitutionQuery, TError, TData>,
) =>
  useQuery<SaInstitutionQuery, TError, TData>(
    ['SAInstitution', variables],
    fetcher<SaInstitutionQuery, SaInstitutionQueryVariables>(SaInstitutionDocument, variables),
    options,
  );
useSaInstitutionQuery.document = SaInstitutionDocument;

useSaInstitutionQuery.getKey = (variables: SaInstitutionQueryVariables) => [
  'SAInstitution',
  variables,
];

export const SaInstitutionRoleDocument = `
    query SAInstitutionRole($id: ID!) {
  institution(id: $id) {
    inPlaces {
      role
    }
  }
}
    `;
export const useSaInstitutionRoleQuery = <TData = SaInstitutionRoleQuery, TError = unknown>(
  variables: SaInstitutionRoleQueryVariables,
  options?: UseQueryOptions<SaInstitutionRoleQuery, TError, TData>,
) =>
  useQuery<SaInstitutionRoleQuery, TError, TData>(
    ['SAInstitutionRole', variables],
    fetcher<SaInstitutionRoleQuery, SaInstitutionRoleQueryVariables>(
      SaInstitutionRoleDocument,
      variables,
    ),
    options,
  );
useSaInstitutionRoleQuery.document = SaInstitutionRoleDocument;

useSaInstitutionRoleQuery.getKey = (variables: SaInstitutionRoleQueryVariables) => [
  'SAInstitutionRole',
  variables,
];

export const SaVisitorInterestsDocument = `
    query SAVisitorInterests($lang: String!) {
  visitorInterests(lang: $lang) {
    lang
    visitorInterests
  }
}
    `;
export const useSaVisitorInterestsQuery = <TData = SaVisitorInterestsQuery, TError = unknown>(
  variables: SaVisitorInterestsQueryVariables,
  options?: UseQueryOptions<SaVisitorInterestsQuery, TError, TData>,
) =>
  useQuery<SaVisitorInterestsQuery, TError, TData>(
    ['SAVisitorInterests', variables],
    fetcher<SaVisitorInterestsQuery, SaVisitorInterestsQueryVariables>(
      SaVisitorInterestsDocument,
      variables,
    ),
    options,
  );
useSaVisitorInterestsQuery.document = SaVisitorInterestsDocument;

useSaVisitorInterestsQuery.getKey = (variables: SaVisitorInterestsQueryVariables) => [
  'SAVisitorInterests',
  variables,
];

export const SendSaSendNotificationDocument = `
    mutation sendSASendNotification($message: SuperAdminMessageInput!, $filter: SuperAdminNotificationFilterInput!) {
  sendSuperAdminNotification(message: $message, filter: $filter)
}
    `;
export const useSendSaSendNotificationMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SendSaSendNotificationMutation,
    TError,
    SendSaSendNotificationMutationVariables,
    TContext
  >,
) =>
  useMutation<
    SendSaSendNotificationMutation,
    TError,
    SendSaSendNotificationMutationVariables,
    TContext
  >(
    (variables?: SendSaSendNotificationMutationVariables) =>
      fetcher<SendSaSendNotificationMutation, SendSaSendNotificationMutationVariables>(
        SendSaSendNotificationDocument,
        variables,
      )(),
    options,
  );
export const SAnotifyUsersItitneraryDocument = `
    mutation SAnotifyUsersItitnerary($placeUuid: ID!, $notificationDetails: ItineraryNotificationDetails!) {
  notifyUsersItitnerary(
    placeUuid: $placeUuid
    notificationDetails: $notificationDetails
  ) {
    success
    count
  }
}
    `;
export const useSAnotifyUsersItitneraryMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SAnotifyUsersItitneraryMutation,
    TError,
    SAnotifyUsersItitneraryMutationVariables,
    TContext
  >,
) =>
  useMutation<
    SAnotifyUsersItitneraryMutation,
    TError,
    SAnotifyUsersItitneraryMutationVariables,
    TContext
  >(
    (variables?: SAnotifyUsersItitneraryMutationVariables) =>
      fetcher<SAnotifyUsersItitneraryMutation, SAnotifyUsersItitneraryMutationVariables>(
        SAnotifyUsersItitneraryDocument,
        variables,
      )(),
    options,
  );
export const SAsendChatBotNotificationDocument = `
    mutation SAsendChatBotNotification($target: NotificationTargetInput!, $notification: PushNotificationMessageInput!) {
  sendChatBotNotification(target: $target, notification: $notification) {
    id
    status
  }
}
    `;
export const useSAsendChatBotNotificationMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SAsendChatBotNotificationMutation,
    TError,
    SAsendChatBotNotificationMutationVariables,
    TContext
  >,
) =>
  useMutation<
    SAsendChatBotNotificationMutation,
    TError,
    SAsendChatBotNotificationMutationVariables,
    TContext
  >(
    (variables?: SAsendChatBotNotificationMutationVariables) =>
      fetcher<SAsendChatBotNotificationMutation, SAsendChatBotNotificationMutationVariables>(
        SAsendChatBotNotificationDocument,
        variables,
      )(),
    options,
  );
export const SaChangePaymentProcessingStatusDocument = `
    mutation SAChangePaymentProcessingStatus($id: ID!, $processingStatus: ProcessingStatus!) {
  updatePaymentTransactionProcessingStatus(id: $id, status: $processingStatus) {
    id
  }
}
    `;
export const useSaChangePaymentProcessingStatusMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaChangePaymentProcessingStatusMutation,
    TError,
    SaChangePaymentProcessingStatusMutationVariables,
    TContext
  >,
) =>
  useMutation<
    SaChangePaymentProcessingStatusMutation,
    TError,
    SaChangePaymentProcessingStatusMutationVariables,
    TContext
  >(
    (variables?: SaChangePaymentProcessingStatusMutationVariables) =>
      fetcher<
        SaChangePaymentProcessingStatusMutation,
        SaChangePaymentProcessingStatusMutationVariables
      >(SaChangePaymentProcessingStatusDocument, variables)(),
    options,
  );
export const SaPaymentTransactionsDocument = `
    query SAPaymentTransactions($filter: PaymentTransactionsByProcessingStatusFilterInput, $nextToken: String, $processingStatus: ProcessingStatus!, $orderBy: PaymentTransactionsByProcessingStatusOrderByInput, $pageSize: Int, $app: App) {
  paymentTransactionsByProcessingStatus(
    filter: $filter
    nextToken: $nextToken
    processingStatus: $processingStatus
    orderBy: $orderBy
    pageSize: $pageSize
    app: $app
  ) {
    paymentTransactions {
      id
      stripePaymentId
      installation {
        id
      }
      createdAt
      currency
      paymentStatus
      paidAt
      document {
        links {
          self
        }
      }
      paymentDetails {
        citationNumber
        licenseNumber
        issueDate
        postId
        stateCode
      }
      simplicityPlus
      marketPaymentDetails {
        activityUuid
        merchantPrice
        totalPrice
        cartUuid
        order {
          uuid
          identifier
        }
      }
      processingStatus
      amount
      paymentType
      receiver
      paymentType
      marketItem {
        ...BaseMarketItemFragment
      }
      metadata
    }
    nextToken
  }
}
    ${BaseMarketItemFragmentFragmentDoc}`;
export const useSaPaymentTransactionsQuery = <TData = SaPaymentTransactionsQuery, TError = unknown>(
  variables: SaPaymentTransactionsQueryVariables,
  options?: UseQueryOptions<SaPaymentTransactionsQuery, TError, TData>,
) =>
  useQuery<SaPaymentTransactionsQuery, TError, TData>(
    ['SAPaymentTransactions', variables],
    fetcher<SaPaymentTransactionsQuery, SaPaymentTransactionsQueryVariables>(
      SaPaymentTransactionsDocument,
      variables,
    ),
    options,
  );
useSaPaymentTransactionsQuery.document = SaPaymentTransactionsDocument;

useSaPaymentTransactionsQuery.getKey = (variables: SaPaymentTransactionsQueryVariables) => [
  'SAPaymentTransactions',
  variables,
];

export const SaPaymentTransactionDocument = `
    query SaPaymentTransaction($id: ID!, $app: App) {
  paymentTransaction(id: $id, app: $app) {
    id
    stripePaymentId
    installation {
      id
    }
    processingStatus
    paidAt
    paymentStatus
    paymentDetails {
      citationNumber
      licenseNumber
      issueDate
      postId
      stateCode
      orderUuid
    }
    amount
    currency
    createdAt
    paymentType
    receiver
    paymentType
    document {
      links {
        self
      }
    }
    marketItem {
      ...BaseMarketItemFragment
    }
    simplicityPlus
    marketPaymentDetails {
      activityUuid
      merchantPrice
      totalPrice
      cartUuid
      order {
        uuid
        date
        identifier
        totalPrice {
          value
          currency
          formattedValue
        }
        items {
          uuid
        }
      }
      products {
        id
        title
        quantity
        ageRange
        retailPrice {
          value
          currency
          formattedValue
        }
        merchantPrice {
          value
          currency
          formattedValue
        }
        cartItemUuid
      }
      isCustomerInfoProvided
    }
    refundDetails {
      ... on TUIRefundDetail {
        createdAt
        amount
        currency
        refundPolicy {
          uuid
          value
          type
        }
      }
    }
    metadata
  }
}
    ${BaseMarketItemFragmentFragmentDoc}`;
export const useSaPaymentTransactionQuery = <TData = SaPaymentTransactionQuery, TError = unknown>(
  variables: SaPaymentTransactionQueryVariables,
  options?: UseQueryOptions<SaPaymentTransactionQuery, TError, TData>,
) =>
  useQuery<SaPaymentTransactionQuery, TError, TData>(
    ['SaPaymentTransaction', variables],
    fetcher<SaPaymentTransactionQuery, SaPaymentTransactionQueryVariables>(
      SaPaymentTransactionDocument,
      variables,
    ),
    options,
  );
useSaPaymentTransactionQuery.document = SaPaymentTransactionDocument;

useSaPaymentTransactionQuery.getKey = (variables: SaPaymentTransactionQueryVariables) => [
  'SaPaymentTransaction',
  variables,
];

export const SaUpdateUserPreferenceCardDocument = `
    mutation SAUpdateUserPreferenceCard($id: ID!, $card: UserPreferenceCardInput!) {
  updateUserPreferenceCard(cardUuid: $id, card: $card)
}
    `;
export const useSaUpdateUserPreferenceCardMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaUpdateUserPreferenceCardMutation,
    TError,
    SaUpdateUserPreferenceCardMutationVariables,
    TContext
  >,
) =>
  useMutation<
    SaUpdateUserPreferenceCardMutation,
    TError,
    SaUpdateUserPreferenceCardMutationVariables,
    TContext
  >(
    (variables?: SaUpdateUserPreferenceCardMutationVariables) =>
      fetcher<SaUpdateUserPreferenceCardMutation, SaUpdateUserPreferenceCardMutationVariables>(
        SaUpdateUserPreferenceCardDocument,
        variables,
      )(),
    options,
  );
export const SaAllPreferenceCardsDocument = `
    query SAAllPreferenceCards {
  getAllGenericCards {
    cards {
      id
    }
  }
}
    `;
export const useSaAllPreferenceCardsQuery = <TData = SaAllPreferenceCardsQuery, TError = unknown>(
  variables?: SaAllPreferenceCardsQueryVariables,
  options?: UseQueryOptions<SaAllPreferenceCardsQuery, TError, TData>,
) =>
  useQuery<SaAllPreferenceCardsQuery, TError, TData>(
    ['SAAllPreferenceCards', variables],
    fetcher<SaAllPreferenceCardsQuery, SaAllPreferenceCardsQueryVariables>(
      SaAllPreferenceCardsDocument,
      variables,
    ),
    options,
  );
useSaAllPreferenceCardsQuery.document = SaAllPreferenceCardsDocument;

useSaAllPreferenceCardsQuery.getKey = (variables?: SaAllPreferenceCardsQueryVariables) => [
  'SAAllPreferenceCards',
  variables,
];

export const SaRunScrapeTaskByCodeDocument = `
    mutation SARunScrapeTaskByCode($code: String) {
  runScrapeTaskByCode(code: $code)
}
    `;
export const useSaRunScrapeTaskByCodeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaRunScrapeTaskByCodeMutation,
    TError,
    SaRunScrapeTaskByCodeMutationVariables,
    TContext
  >,
) =>
  useMutation<
    SaRunScrapeTaskByCodeMutation,
    TError,
    SaRunScrapeTaskByCodeMutationVariables,
    TContext
  >(
    (variables?: SaRunScrapeTaskByCodeMutationVariables) =>
      fetcher<SaRunScrapeTaskByCodeMutation, SaRunScrapeTaskByCodeMutationVariables>(
        SaRunScrapeTaskByCodeDocument,
        variables,
      )(),
    options,
  );
export const SaAddAdminUserDocument = `
    mutation SAAddAdminUser($user: AdminUserInput!) {
  addAdminUser(user: $user) {
    username
    email
    tmpPassword
  }
}
    `;
export const useSaAddAdminUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaAddAdminUserMutation,
    TError,
    SaAddAdminUserMutationVariables,
    TContext
  >,
) =>
  useMutation<SaAddAdminUserMutation, TError, SaAddAdminUserMutationVariables, TContext>(
    (variables?: SaAddAdminUserMutationVariables) =>
      fetcher<SaAddAdminUserMutation, SaAddAdminUserMutationVariables>(
        SaAddAdminUserDocument,
        variables,
      )(),
    options,
  );
export const SaDeleteAdminUserDocument = `
    mutation SADeleteAdminUser($username: String!) {
  deleteAdminUser(username: $username) {
    username
  }
}
    `;
export const useSaDeleteAdminUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaDeleteAdminUserMutation,
    TError,
    SaDeleteAdminUserMutationVariables,
    TContext
  >,
) =>
  useMutation<SaDeleteAdminUserMutation, TError, SaDeleteAdminUserMutationVariables, TContext>(
    (variables?: SaDeleteAdminUserMutationVariables) =>
      fetcher<SaDeleteAdminUserMutation, SaDeleteAdminUserMutationVariables>(
        SaDeleteAdminUserDocument,
        variables,
      )(),
    options,
  );
export const SaUpdateAdminUserDocument = `
    mutation SAUpdateAdminUser($user: AdminUserInput!) {
  updateAdminUser(user: $user) {
    username
  }
}
    `;
export const useSaUpdateAdminUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SaUpdateAdminUserMutation,
    TError,
    SaUpdateAdminUserMutationVariables,
    TContext
  >,
) =>
  useMutation<SaUpdateAdminUserMutation, TError, SaUpdateAdminUserMutationVariables, TContext>(
    (variables?: SaUpdateAdminUserMutationVariables) =>
      fetcher<SaUpdateAdminUserMutation, SaUpdateAdminUserMutationVariables>(
        SaUpdateAdminUserDocument,
        variables,
      )(),
    options,
  );
export const SaAllUsersDocument = `
    query SAAllUsers($nextToken: String, $filter: AdminUsersFilterInput!) {
  adminUsers(nextToken: $nextToken, filter: $filter) {
    users {
      username
      email
      institution {
        name
        id
      }
      institutionUuids
      place {
        name
        id
      }
    }
    nextToken
  }
}
    `;
export const useSaAllUsersQuery = <TData = SaAllUsersQuery, TError = unknown>(
  variables: SaAllUsersQueryVariables,
  options?: UseQueryOptions<SaAllUsersQuery, TError, TData>,
) =>
  useQuery<SaAllUsersQuery, TError, TData>(
    ['SAAllUsers', variables],
    fetcher<SaAllUsersQuery, SaAllUsersQueryVariables>(SaAllUsersDocument, variables),
    options,
  );
useSaAllUsersQuery.document = SaAllUsersDocument;

useSaAllUsersQuery.getKey = (variables: SaAllUsersQueryVariables) => ['SAAllUsers', variables];

export const SaAdminUserDocument = `
    query SAAdminUser($username: String!) {
  adminUser(username: $username) {
    username
    email
    institutions {
      id
      name
    }
    institutionUuids
    place {
      id
      name
    }
  }
}
    `;
export const useSaAdminUserQuery = <TData = SaAdminUserQuery, TError = unknown>(
  variables: SaAdminUserQueryVariables,
  options?: UseQueryOptions<SaAdminUserQuery, TError, TData>,
) =>
  useQuery<SaAdminUserQuery, TError, TData>(
    ['SAAdminUser', variables],
    fetcher<SaAdminUserQuery, SaAdminUserQueryVariables>(SaAdminUserDocument, variables),
    options,
  );
useSaAdminUserQuery.document = SaAdminUserDocument;

useSaAdminUserQuery.getKey = (variables: SaAdminUserQueryVariables) => ['SAAdminUser', variables];

export { fetcher };
